import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React, { useMemo, useContext, createContext } from "react";
import PropTypes from "prop-types";
import { ComponentPropTypes } from "@jutro/prop-types";
import { useBreakpoint } from "@jutro/layout";
import { HeaderAction } from "./HeaderAction";
import { HeaderActions } from "./HeaderActions";
import { useViewContext } from "../../featuresProvider";
export var DataViewHeaderActionsContext = createContext({});
export var useHeaderActionsContext = function () {
  return useContext(DataViewHeaderActionsContext);
};
export var DataViewHeaderActions = function (props) {
  var view = useViewContext(),
    _useBreakpoint$breakp = useBreakpoint(props).breakpointProps,
    children = _useBreakpoint$breakp.children,
    render = _useBreakpoint$breakp.render,
    wrapHeaderActions = _useBreakpoint$breakp.wrapHeaderActions,
    contextValue = useMemo(function () {
      return {
        children: children,
        render: render,
        wrapHeaderActions: wrapHeaderActions
      };
    }, [children, render, wrapHeaderActions]);
  return React.createElement(DataViewHeaderActionsContext.Provider, {
    value: contextValue
  }, view);
};
DataViewHeaderActions.defaultProps = {
  render: HeaderActions
};
var basePropTypes = {
    children: ComponentPropTypes.childOfComponentType(HeaderAction),
    wrapHeaderActions: PropTypes.oneOfType([PropTypes.oneOf(["never", "always"]), PropTypes.shape({
      allWhen: PropTypes.number.isRequired
    }), PropTypes.shape({
      maxItems: PropTypes.number.isRequired
    })]),
    render: PropTypes.func
  },
  propTypes = _objectSpread(_objectSpread({}, basePropTypes), {}, {
    phone: PropTypes.shape(basePropTypes),
    phoneWide: PropTypes.shape(basePropTypes),
    tablet: PropTypes.shape(basePropTypes)
  });
DataViewHeaderActions.propTypes = propTypes, DataViewHeaderActions.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "DataViewHeaderActions",
  props: {
    render: {
      defaultValue: {
        value: "HeaderActions",
        computed: !0
      },
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback which renders a component to manage header actions, the arguments are: `wrapHeaderActions`, `selectedRows`, and `rowsSelectionSet`"
    },
    children: {
      type: {
        name: "custom",
        raw: "ComponentPropTypes.childOfComponentType(HeaderAction as any)"
      },
      required: !1,
      description: "Array of actions displayed in a header"
    },
    wrapHeaderActions: {
      type: {
        name: "union",
        value: [{
          name: "enum",
          value: [{
            value: "'never'",
            computed: !1
          }, {
            value: "'always'",
            computed: !1
          }]
        }, {
          name: "shape",
          value: {
            allWhen: {
              name: "number",
              required: !0
            }
          }
        }, {
          name: "shape",
          value: {
            maxItems: {
              name: "number",
              required: !0
            }
          }
        }]
      },
      required: !1,
      description: 'Sets how to wrap action items. By default, the dropdown is shown for 3 or more actions.\n- `never` - actions are never wrapped, all are visible in a row;\n- `always` - all actions are placed in a dropdown regardless of their number;\n- `{ "allWhen": N }` - all actions are placed in a dropdown when there are more than N (inclusive), otherwise are visible (by default N=3);\n- `{ "maxItems": N }` - maximum N items are visible (including dropdown), excess is placed in a dropdown.'
    },
    phone: {
      type: {
        name: "shape",
        value: {
          children: {
            name: "custom",
            raw: "ComponentPropTypes.childOfComponentType(HeaderAction as any)",
            description: "Array of actions displayed in a header",
            required: !1
          },
          wrapHeaderActions: {
            name: "union",
            value: [{
              name: "enum",
              value: [{
                value: "'never'",
                computed: !1
              }, {
                value: "'always'",
                computed: !1
              }]
            }, {
              name: "shape",
              value: {
                allWhen: {
                  name: "number",
                  required: !0
                }
              }
            }, {
              name: "shape",
              value: {
                maxItems: {
                  name: "number",
                  required: !0
                }
              }
            }],
            description: 'Sets how to wrap action items. By default, the dropdown is shown for 3 or more actions.\n- `never` - actions are never wrapped, all are visible in a row;\n- `always` - all actions are placed in a dropdown regardless of their number;\n- `{ "allWhen": N }` - all actions are placed in a dropdown when there are more than N (inclusive), otherwise are visible (by default N=3);\n- `{ "maxItems": N }` - maximum N items are visible (including dropdown), excess is placed in a dropdown.',
            required: !1
          },
          render: {
            name: "func",
            description: "Callback which renders a component to manage header actions, the arguments are: `wrapHeaderActions`, `selectedRows`, and `rowsSelectionSet`",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          children: {
            name: "custom",
            raw: "ComponentPropTypes.childOfComponentType(HeaderAction as any)",
            description: "Array of actions displayed in a header",
            required: !1
          },
          wrapHeaderActions: {
            name: "union",
            value: [{
              name: "enum",
              value: [{
                value: "'never'",
                computed: !1
              }, {
                value: "'always'",
                computed: !1
              }]
            }, {
              name: "shape",
              value: {
                allWhen: {
                  name: "number",
                  required: !0
                }
              }
            }, {
              name: "shape",
              value: {
                maxItems: {
                  name: "number",
                  required: !0
                }
              }
            }],
            description: 'Sets how to wrap action items. By default, the dropdown is shown for 3 or more actions.\n- `never` - actions are never wrapped, all are visible in a row;\n- `always` - all actions are placed in a dropdown regardless of their number;\n- `{ "allWhen": N }` - all actions are placed in a dropdown when there are more than N (inclusive), otherwise are visible (by default N=3);\n- `{ "maxItems": N }` - maximum N items are visible (including dropdown), excess is placed in a dropdown.',
            required: !1
          },
          render: {
            name: "func",
            description: "Callback which renders a component to manage header actions, the arguments are: `wrapHeaderActions`, `selectedRows`, and `rowsSelectionSet`",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          children: {
            name: "custom",
            raw: "ComponentPropTypes.childOfComponentType(HeaderAction as any)",
            description: "Array of actions displayed in a header",
            required: !1
          },
          wrapHeaderActions: {
            name: "union",
            value: [{
              name: "enum",
              value: [{
                value: "'never'",
                computed: !1
              }, {
                value: "'always'",
                computed: !1
              }]
            }, {
              name: "shape",
              value: {
                allWhen: {
                  name: "number",
                  required: !0
                }
              }
            }, {
              name: "shape",
              value: {
                maxItems: {
                  name: "number",
                  required: !0
                }
              }
            }],
            description: 'Sets how to wrap action items. By default, the dropdown is shown for 3 or more actions.\n- `never` - actions are never wrapped, all are visible in a row;\n- `always` - all actions are placed in a dropdown regardless of their number;\n- `{ "allWhen": N }` - all actions are placed in a dropdown when there are more than N (inclusive), otherwise are visible (by default N=3);\n- `{ "maxItems": N }` - maximum N items are visible (including dropdown), excess is placed in a dropdown.',
            required: !1
          },
          render: {
            name: "func",
            description: "Callback which renders a component to manage header actions, the arguments are: `wrapHeaderActions`, `selectedRows`, and `rowsSelectionSet`",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
}, DataViewHeaderActions.__docgenInfo = {
  componentName: "DataViewHeaderActions",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "DataViewHeaderActions",
  methods: [],
  actualName: "DataViewHeaderActions",
  props: {
    children: {
      type: {
        name: "custom",
        raw: "ComponentPropTypes.childOfComponentType(HeaderAction)"
      },
      required: !1,
      description: "Array of actions displayed in a header"
    },
    wrapHeaderActions: {
      type: {
        name: "union",
        value: [{
          name: "enum",
          value: [{
            value: "'never'",
            computed: !1
          }, {
            value: "'always'",
            computed: !1
          }]
        }, {
          name: "shape",
          value: {
            allWhen: {
              name: "number",
              required: !0
            }
          }
        }, {
          name: "shape",
          value: {
            maxItems: {
              name: "number",
              required: !0
            }
          }
        }]
      },
      required: !1,
      description: 'Sets how to wrap action items. By default, the dropdown is shown for 3 or more actions.\n- `never` - actions are never wrapped, all are visible in a row;\n- `always` - all actions are placed in a dropdown regardless of their number;\n- `{ "allWhen": N }` - all actions are placed in a dropdown when there are more than N (inclusive), otherwise are visible (by default N=3);\n- `{ "maxItems": N }` - maximum N items are visible (including dropdown), excess is placed in a dropdown.'
    },
    render: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback which renders a component to manage header actions, the arguments are: `wrapHeaderActions`, `selectedRows`, and `rowsSelectionSet`",
      defaultValue: {
        value: "HeaderActions",
        computed: !0
      }
    },
    phone: {
      type: {
        name: "shape",
        value: {
          children: {
            name: "custom",
            raw: "ComponentPropTypes.childOfComponentType(HeaderAction)",
            description: "Array of actions displayed in a header",
            required: !1
          },
          wrapHeaderActions: {
            name: "union",
            value: [{
              name: "enum",
              value: [{
                value: "'never'",
                computed: !1
              }, {
                value: "'always'",
                computed: !1
              }]
            }, {
              name: "shape",
              value: {
                allWhen: {
                  name: "number",
                  required: !0
                }
              }
            }, {
              name: "shape",
              value: {
                maxItems: {
                  name: "number",
                  required: !0
                }
              }
            }],
            description: 'Sets how to wrap action items. By default, the dropdown is shown for 3 or more actions.\n- `never` - actions are never wrapped, all are visible in a row;\n- `always` - all actions are placed in a dropdown regardless of their number;\n- `{ "allWhen": N }` - all actions are placed in a dropdown when there are more than N (inclusive), otherwise are visible (by default N=3);\n- `{ "maxItems": N }` - maximum N items are visible (including dropdown), excess is placed in a dropdown.',
            required: !1
          },
          render: {
            name: "func",
            description: "Callback which renders a component to manage header actions, the arguments are: `wrapHeaderActions`, `selectedRows`, and `rowsSelectionSet`",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          children: {
            name: "custom",
            raw: "ComponentPropTypes.childOfComponentType(HeaderAction)",
            description: "Array of actions displayed in a header",
            required: !1
          },
          wrapHeaderActions: {
            name: "union",
            value: [{
              name: "enum",
              value: [{
                value: "'never'",
                computed: !1
              }, {
                value: "'always'",
                computed: !1
              }]
            }, {
              name: "shape",
              value: {
                allWhen: {
                  name: "number",
                  required: !0
                }
              }
            }, {
              name: "shape",
              value: {
                maxItems: {
                  name: "number",
                  required: !0
                }
              }
            }],
            description: 'Sets how to wrap action items. By default, the dropdown is shown for 3 or more actions.\n- `never` - actions are never wrapped, all are visible in a row;\n- `always` - all actions are placed in a dropdown regardless of their number;\n- `{ "allWhen": N }` - all actions are placed in a dropdown when there are more than N (inclusive), otherwise are visible (by default N=3);\n- `{ "maxItems": N }` - maximum N items are visible (including dropdown), excess is placed in a dropdown.',
            required: !1
          },
          render: {
            name: "func",
            description: "Callback which renders a component to manage header actions, the arguments are: `wrapHeaderActions`, `selectedRows`, and `rowsSelectionSet`",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          children: {
            name: "custom",
            raw: "ComponentPropTypes.childOfComponentType(HeaderAction)",
            description: "Array of actions displayed in a header",
            required: !1
          },
          wrapHeaderActions: {
            name: "union",
            value: [{
              name: "enum",
              value: [{
                value: "'never'",
                computed: !1
              }, {
                value: "'always'",
                computed: !1
              }]
            }, {
              name: "shape",
              value: {
                allWhen: {
                  name: "number",
                  required: !0
                }
              }
            }, {
              name: "shape",
              value: {
                maxItems: {
                  name: "number",
                  required: !0
                }
              }
            }],
            description: 'Sets how to wrap action items. By default, the dropdown is shown for 3 or more actions.\n- `never` - actions are never wrapped, all are visible in a row;\n- `always` - all actions are placed in a dropdown regardless of their number;\n- `{ "allWhen": N }` - all actions are placed in a dropdown when there are more than N (inclusive), otherwise are visible (by default N=3);\n- `{ "maxItems": N }` - maximum N items are visible (including dropdown), excess is placed in a dropdown.',
            required: !1
          },
          render: {
            name: "func",
            description: "Callback which renders a component to manage header actions, the arguments are: `wrapHeaderActions`, `selectedRows`, and `rowsSelectionSet`",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
};