import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["header", "id", "path", "editConfig"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
export var getCommonEditProps = function (_ref) {
  var value = _ref.value,
    onValueChange = _ref.onValueChange,
    columnProps = _ref.columnProps,
    registerValidation = _ref.registerValidation,
    errorMessage = _ref.errorMessage,
    showErrors = _ref.showErrors,
    header = columnProps.header,
    id = columnProps.id,
    path = columnProps.path,
    _columnProps$editConf = columnProps.editConfig,
    editConfig = void 0 === _columnProps$editConf ? {} : _columnProps$editConf;
  return _objectSpread(_objectSpread(_objectSpread({
    id: id,
    value: value,
    onValueChange: onValueChange,
    label: header
  }, _objectWithoutProperties(columnProps, _excluded)), editConfig), {}, {
    registerValidation: registerValidation,
    errorMessage: errorMessage,
    showErrors: showErrors,
    path: null != path ? path : id
  });
};