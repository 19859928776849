import { defineMessages } from "react-intl";
export default defineMessages({
  descending: {
    id: "jutro-dataview.TableView.descending",
    defaultMessage: "descending"
  },
  ascending: {
    id: "jutro-dataview.TableView.ascending",
    defaultMessage: "ascending"
  },
  sortableColumnheader: {
    id: "jutro-dataview.TableView.sortableColumnheader",
    defaultMessage: "sortable columnheader"
  },
  sorted: {
    id: "jutro-dataview.TableView.sorted",
    defaultMessage: "sorted"
  }
});