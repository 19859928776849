import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import { useTranslator } from "@jutro/locale";
import { useKeyboardNavigation } from "./useKeyboardNavigation";
import messages from "./TableView.accessibility.messages";
import { HEADER_ROW } from "./constants";
export var useHeaderA11yProps = function (_ref) {
  var _context,
    _context2,
    _context3,
    headerRef = _ref.headerRef,
    columnIndex = _ref.columnIndex,
    headerAriaLabel = _ref.headerAriaLabel,
    onSortingTrigger = _ref.onSortingTrigger,
    sortedColumnDesc = _ref.sortedColumnDesc,
    isSortable = _ref.isSortable,
    isSorted = _ref.isSorted,
    translator = useTranslator(),
    _useKeyboardNavigatio = useKeyboardNavigation(headerRef, HEADER_ROW, columnIndex, "header"),
    tabIndex = _useKeyboardNavigatio.tabIndex,
    onKeyDown = _useKeyboardNavigatio.onKeyDown,
    onFocus = _useKeyboardNavigatio.onFocus,
    ariaSort = sortedColumnDesc ? "descending" : "ascending",
    ariaSortTranslatable = translator(sortedColumnDesc ? messages.descending : messages.ascending),
    ariaSortLabel = _concatInstanceProperty(_context = "".concat(isSortable ? "".concat(translator(messages.sortableColumnheader)) : "")).call(_context, isSorted ? _concatInstanceProperty(_context2 = " ".concat(translator(messages.sorted), " ")).call(_context2, ariaSortTranslatable) : ""),
    ariaLabel = headerAriaLabel ? _concatInstanceProperty(_context3 = "".concat(ariaSortLabel, " ")).call(_context3, translator(headerAriaLabel)) : ariaSortLabel,
    sortingProps = isSortable ? {
      "aria-sort": isSorted ? ariaSort : "none"
    } : {};
  return function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = null != arguments[i] ? arguments[i] : {};
      i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
        _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
      });
    }
    return target;
  }({
    tabIndex: isSortable ? 0 : tabIndex,
    onKeyUp: function (e) {
      " " === e.key && onSortingTrigger();
    },
    onKeyDown: function (e) {
      "Enter" === e.key ? onSortingTrigger() : onKeyDown(e);
    },
    onFocus: onFocus,
    role: "columnheader",
    scope: "col",
    "aria-label": ariaLabel && ariaLabel.length > 0 ? ariaLabel : void 0
  }, sortingProps);
};