import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import React from "react";
import cx from "classnames";
import { FlexItem } from "@jutro/layout";
import { useTranslator } from "@jutro/locale";
import styles from "./ListViewColumn.module.css";
import { getCellValue } from "../../features/utils/getCellValue";
import { useColumnsContext } from "../../features/columns";
import { useColumnsConfigContext } from "../../features/columnsConfig";
export var ListViewColumn = function (props) {
  var _context,
    _context2,
    columnIndex = props.columnIndex,
    rawRow = props.rawRow,
    rowId = props.rowId,
    column = props.column,
    rowIndex = props.rowIndex,
    classNames = props.classNames,
    translator = useTranslator(),
    columns = useColumnsContext().columns.current,
    showHeaders = useColumnsConfigContext().showHeaders,
    columnProps = columns[column.id],
    value = getCellValue({
      rowId: rowId,
      rawRow: rawRow,
      column: column,
      translator: translator
    }),
    renderHeader = column.renderHeader,
    renderCell = column.renderCell,
    columnId = column.id;
  if (!column.visible) return null;
  var hasActionsColumn = "action" === column.columnDataType,
    shouldRenderHeader = showHeaders && columnProps.renderHeader,
    columnClassNames = cx(styles.column, _defineProperty({}, styles.actionsColumn, hasActionsColumn), classNames.column),
    bodyCellClassNames = cx(_defineProperty({}, styles.listViewBodyCell, !hasActionsColumn), classNames.getBodyCellClassName(columnProps, value));
  return React.createElement(FlexItem, {
    className: columnClassNames,
    key: _concatInstanceProperty(_context = "".concat(columnIndex, "_")).call(_context, rowIndex),
    "data-testid": "item-column-container"
  }, shouldRenderHeader && React.createElement("div", {
    className: classNames.getHeaderCellClassName(columnProps),
    role: "columnheader"
  }, renderHeader && renderHeader({
    translator: translator,
    columnProps: column
  })), React.createElement("div", {
    className: bodyCellClassNames,
    role: "gridcell"
  }, renderCell && renderCell({
    cellId: _concatInstanceProperty(_context2 = "".concat(columnId, "_")).call(_context2, rowIndex, "_cell"),
    rowId: rowId,
    translator: translator,
    columnProps: column,
    value: value,
    row: rawRow
  })));
};
ListViewColumn.displayName = "ListViewColumn", ListViewColumn.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "ListViewColumn"
}, ListViewColumn.__docgenInfo = {
  componentName: "ListViewColumn",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "ListViewColumn",
  methods: [],
  actualName: "ListViewColumn"
};