import { defineMessages } from "react-intl";
export var messages = defineMessages({
  columnsHidden: {
    id: "jutro-dataview.Editing.editForm.columnsHidden",
    defaultMessage: "Some columns are hidden."
  },
  showAll: {
    id: "jutro-dataview.Editing.editForm.showAll",
    defaultMessage: "Show all"
  },
  columnsHiddenAriaLabel: {
    id: "jutro-dataview.Editing.editForm.columnsHiddenAriaLabel",
    defaultMessage: "Some columns are hidden. Click show all to display all columns."
  },
  editFormGroup: {
    id: "jutro-dataview.Editing.editForm.editFormGroup",
    defaultMessage: "Form fields of the edited item."
  }
});