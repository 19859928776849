import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import isEmpty from "lodash/isEmpty";
import { useReorderedColumnsContext } from "../columns";
import { useSelectionContext } from "../selection";
import { useExpandableContext } from "../expandable";
import { FIRST_DATA_COLUMN } from "./constants";
export var useKeyboardNavigationParams = function () {
  var rows = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : [],
    columns = useReorderedColumnsContext(),
    _useSelectionContext = useSelectionContext(),
    selectionEnabled = _useSelectionContext.selectionEnabled,
    _useExpandableContext = useExpandableContext(),
    expansionEnabled = _useExpandableContext.expansionEnabled,
    lastRowIndex = isEmpty(rows) ? 0 : rows.length - 1,
    lastColumnIndex = isEmpty(columns) ? 0 : _filterInstanceProperty(columns).call(columns, function (column) {
      return column.visible;
    }).length - 1,
    firstColumnIndex = FIRST_DATA_COLUMN;
  return expansionEnabled && (firstColumnIndex -= 1), selectionEnabled && (firstColumnIndex -= 1), {
    lastRowIndex: lastRowIndex,
    lastColumnIndex: lastColumnIndex,
    firstColumnIndex: firstColumnIndex
  };
};