import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import React, { useRef } from "react";
import cx from "classnames";
import { useTranslator } from "@jutro/locale";
import { useHeaderA11yProps } from "../../features/accessibility";
import { SortingTrigger, useColumnSorting } from "../../features/sorting";
import styles from "./Cells.module.css";
export var HeaderCell = function (_ref) {
  var column = _ref.column,
    sortingEnabled = _ref.sortingEnabled,
    onColumnSort = _ref.onColumnSort,
    sortedColumnId = _ref.sortedColumnId,
    sortedColumnDesc = _ref.sortedColumnDesc,
    className = _ref.className,
    columnIndex = _ref.columnIndex,
    translator = useTranslator(),
    _useColumnSorting = useColumnSorting({
      column: column,
      sortingEnabled: sortingEnabled,
      onColumnSort: onColumnSort,
      sortedColumnId: sortedColumnId,
      sortedColumnDesc: sortedColumnDesc
    }),
    isSortable = _useColumnSorting.isSortable,
    isSorted = _useColumnSorting.isSorted,
    onSortingTrigger = _useColumnSorting.onSortingTrigger,
    headerRef = useRef(null),
    renderHeader = column.renderHeader,
    headerAriaLabel = column.headerAriaLabel,
    a11yProps = useHeaderA11yProps({
      headerRef: headerRef,
      columnIndex: columnIndex,
      headerAriaLabel: headerAriaLabel,
      onSortingTrigger: onSortingTrigger,
      sortedColumnDesc: sortedColumnDesc,
      isSortable: isSortable,
      isSorted: isSorted
    });
  return React.createElement("th", _extends({
    className: cx(styles.cell, styles.headerCell, _defineProperty({}, styles.interactiveHeaderCell, sortingEnabled), className),
    onClick: onSortingTrigger,
    ref: headerRef
  }, a11yProps), React.createElement(SortingTrigger, {
    isSortable: isSortable,
    isSorted: isSorted,
    sortedDesc: null != sortedColumnDesc && sortedColumnDesc
  }, renderHeader && renderHeader({
    translator: translator,
    columnProps: column
  })));
};
HeaderCell.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "HeaderCell"
}, HeaderCell.__docgenInfo = {
  componentName: "HeaderCell",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "HeaderCell",
  methods: [],
  actualName: "HeaderCell"
};