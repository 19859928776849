import React from "react";
import { ErrorNotice } from "@jutro/components";
import { messages } from "../StateOverlay.messages";
export var ErrorContent = function (_ref) {
  var message = _ref.message,
    title = _ref.title,
    onRetryClick = _ref.onRetryClick,
    retryMessage = _ref.retryMessage;
  return React.createElement(ErrorNotice, {
    mainMessage: title,
    detailedMessage: message,
    actions: onRetryClick && retryMessage ? [{
      label: retryMessage,
      callback: onRetryClick
    }] : []
  });
};
ErrorContent.defaultProps = {
  title: messages.errorTitle,
  message: messages.errorText,
  retryMessage: messages.errorRetry
}, ErrorContent.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "ErrorContent",
  props: {
    title: {
      defaultValue: {
        value: "messages.errorTitle",
        computed: !0
      },
      required: !1
    },
    message: {
      defaultValue: {
        value: "messages.errorText",
        computed: !0
      },
      required: !1
    },
    retryMessage: {
      defaultValue: {
        value: "messages.errorRetry",
        computed: !0
      },
      required: !1
    }
  }
}, ErrorContent.__docgenInfo = {
  componentName: "ErrorContent",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "ErrorContent",
  methods: [],
  actualName: "ErrorContent",
  props: {
    title: {
      defaultValue: {
        value: "messages.errorTitle",
        computed: !0
      },
      required: !1
    },
    message: {
      defaultValue: {
        value: "messages.errorText",
        computed: !0
      },
      required: !1
    },
    retryMessage: {
      defaultValue: {
        value: "messages.errorRetry",
        computed: !0
      },
      required: !1
    }
  }
};