import _extends from "@babel/runtime-corejs3/helpers/extends";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import React from "react";
import { DropdownMenu } from "@jutro/components";
import { uniqueInnerId } from "@jutro/platform";
import { DropdownMenuTrigger } from "../utils/DropdownMenuTrigger";
import { MenuItem } from "./MenuItem";
import messages from "./HeaderActions.messages";
export var Menu = function (_ref) {
  var actions = _ref.actions,
    actionsType = _ref.actionsType,
    menuId = uniqueInnerId("headerActionsMenu", "menuId").menuId,
    triggerAriaLabel = "batch" === actionsType ? messages.moreBatchActionsButtonTrigger : messages.moreGlobalActionsButtonTrigger;
  return React.createElement(DropdownMenu, {
    alignRight: !0,
    id: menuId,
    renderTrigger: DropdownMenuTrigger,
    openMenuId: "".concat(menuId, "-open"),
    keepContext: !0,
    triggerAriaLabel: triggerAriaLabel
  }, _mapInstanceProperty(actions).call(actions, function (action) {
    var _context;
    return React.createElement(MenuItem, _extends({
      menuId: menuId,
      key: _concatInstanceProperty(_context = "".concat(menuId, "-")).call(_context, action.id)
    }, action));
  }));
};
Menu.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "Menu"
}, Menu.__docgenInfo = {
  componentName: "Menu",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "Menu",
  methods: [],
  actualName: "Menu"
};