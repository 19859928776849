import { appendSimpleNamedComponentMap } from "@jutro/uiconfig";
export { QuickViewContext, QuickViewContainerContext, QuickViewProvider, useQuickViewContainer } from "./QuickViewProvider";
export { QuickView } from "./QuickView";
export { QuickViewHeader } from "./QuickViewHeader/QuickViewHeader";
export { QuickViewHeaderActions, QuickViewHeaderAction } from "./QuickViewHeaderActions/QuickViewHeaderActions";
export { QuickViewBody } from "./QuickViewBody/QuickViewBody";
export { QuickViewFooter } from "./QuickViewFooter/QuickViewFooter";
import { QuickViewProvider } from "./QuickViewProvider";
import { QuickView } from "./QuickView";
import { QuickViewHeader } from "./QuickViewHeader/QuickViewHeader";
import { QuickViewHeaderActions, QuickViewHeaderAction } from "./QuickViewHeaderActions/QuickViewHeaderActions";
import { QuickViewBody } from "./QuickViewBody/QuickViewBody";
import { QuickViewFooter } from "./QuickViewFooter/QuickViewFooter";
appendSimpleNamedComponentMap({
  QuickViewProvider: {
    component: QuickViewProvider
  },
  QuickView: {
    component: QuickView
  },
  QuickViewHeader: {
    component: QuickViewHeader
  },
  QuickViewHeaderActions: {
    component: QuickViewHeaderActions
  },
  QuickViewHeaderAction: {
    component: QuickViewHeaderAction
  },
  QuickViewBody: {
    component: QuickViewBody
  },
  QuickViewFooter: {
    component: QuickViewFooter
  }
});
export { KEEP_CHANGES, DISCARD_CHANGES } from "./types";