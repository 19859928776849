import "core-js/modules/es.string.replace";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import range from "lodash/range";
var languageRE = /^([a-z]{2,3})([-_]|$)/,
  regionRE = /[-_]([A-Z]{2})([-_]|$)/;
export var getLanguageSubtag = function (locale) {
  var caseInsensitiveLocale = locale.toLowerCase(),
    r = languageRE.exec(caseInsensitiveLocale);
  return r ? r[1] : void 0;
};
export var getCountryCodeFromLocale = function (locale) {
  return function (locale) {
    var caseInsensitiveLocale = locale.toUpperCase(),
      r = regionRE.exec(caseInsensitiveLocale);
    return r ? r[1] : void 0;
  }(locale);
};
export var getLanguageFromLocale = function (locale) {
  return getLanguageSubtag(locale);
};
export var getBrowserLanguage = function () {
  return window.navigator.userLanguage || window.navigator.language;
};
export var normalizeToUnderscoreLocale = function (locale) {
  return locale.replace("-", "_");
};
export var isIntlShape = function (content) {
  return content && void 0 !== content.id && void 0 !== content.defaultMessage;
};
export var getFormattedMonthsForLocale = function (locale) {
  var _context;
  return _mapInstanceProperty(_context = range(12)).call(_context, function (month) {
    return locale.localize.month(month);
  });
};