import _Array$from from "@babel/runtime-corejs3/core-js-stable/array/from";
import _Object$values from "@babel/runtime-corejs3/core-js-stable/object/values";
import React, { useLayoutEffect, useRef } from "react";
import isEqual from "lodash/isEqual";
import isNumber from "lodash/isNumber";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import { Button } from "@jutro/components";
import { Flex } from "@jutro/layout";
import { useTranslator } from "@jutro/locale";
import { MetadataForm } from "@jutro/uiconfig";
import { useFilterForm } from "../useFilterForm";
import { messages } from "../DataViewFiltering.messages";
import styles from "./FilterBar.module.css";
import { useTitleContext } from "../../title";
var focusableSelector = ["input:not([disabled])", "select:not([disabled])", "textarea:not([disabled])", "button:not([disabled])", "a[href]", '[tabindex]:not([tabindex="-1"])'].join(", ");
export var FilterBar = function (_ref) {
  var callbackMap = _ref.callbackMap,
    componentMap = _ref.componentMap,
    classNameMap = _ref.classNameMap,
    filters = _ref.filters,
    onFiltersChange = _ref.onFiltersChange,
    uiProps = _ref.uiProps,
    _ref$areFiltersApplie = _ref.areFiltersApplied,
    areFiltersApplied = void 0 === _ref$areFiltersApplie || _ref$areFiltersApplie,
    translator = useTranslator(),
    _useFilterForm = useFilterForm({
      filters: filters,
      onFiltersChange: onFiltersChange
    }),
    formData = _useFilterForm.formData,
    readValue = _useFilterForm.readValue,
    applyFilters = _useFilterForm.applyFilters,
    handleDataChange = _useFilterForm.handleDataChange,
    handleClearFilter = _useFilterForm.handleClearFilter,
    handleFormEnter = _useFilterForm.handleFormEnter,
    filterBarContentRef = useRef(null);
  useLayoutEffect(function () {
    var _Array$from$, _filterBarContentRef$;
    null === (_Array$from$ = _Array$from((null === (_filterBarContentRef$ = filterBarContentRef.current) || void 0 === _filterBarContentRef$ ? void 0 : _filterBarContentRef$.querySelectorAll(focusableSelector)) || [])[0]) || void 0 === _Array$from$ || _Array$from$.focus();
  }, []);
  var areFiltersAndFormSame = isEqual(formData, filters),
    isFormEmpty = _Object$values(formData).every(function (value) {
      return !isNumber(value) && isEmpty(value);
    }),
    isNothingChosen = areFiltersAndFormSame || isFormEmpty,
    overrideProps = {
      "@field": {
        className: styles.filterItem
      }
    },
    title = useTitleContext().title;
  if (!uiProps) return null;
  var ariaLabel = translator(messages.containerAriaLabel, {
    tableTitle: translator(title)
  });
  return React.createElement("div", {
    className: styles.filters,
    "aria-label": ariaLabel,
    role: "group"
  }, React.createElement("div", {
    className: styles.titleBar
  }, React.createElement("div", {
    className: styles.filterTitle
  }, translator(messages.title)), React.createElement("div", null, React.createElement(Button, {
    type: "text",
    size: "small",
    disabled: isNothingChosen && !areFiltersApplied,
    onClick: handleClearFilter
  }, translator(messages.clearFilters)), React.createElement(Button, {
    type: "filled",
    size: "small",
    disabled: isNothingChosen,
    onClick: applyFilters,
    "aria-label": translator(messages.applyFiltersButtonAriaLabel)
  }, translator(messages.applyFilters)))), React.createElement("hr", {
    className: styles.titleDivider
  }), React.createElement("div", {
    ref: filterBarContentRef
  }, React.createElement(Flex, {
    gap: "none",
    onKeyDown: handleFormEnter,
    role: "presentation",
    className: styles.filterBarContent
  }, React.createElement(MetadataForm, {
    uiProps: null != uiProps ? uiProps : {},
    onDataChange: handleDataChange,
    classNameMap: classNameMap,
    resolveValue: readValue,
    callbackMap: callbackMap,
    componentMap: componentMap,
    overrideProps: overrideProps,
    data: formData
  }))));
};
var filterBarPropTypes = {
  uiProps: PropTypes.object,
  classNameMap: PropTypes.objectOf(PropTypes.any),
  componentMap: PropTypes.objectOf(PropTypes.any),
  callbackMap: PropTypes.objectOf(PropTypes.any),
  onFiltersChange: PropTypes.func,
  filters: PropTypes.objectOf(PropTypes.any),
  areFiltersApplied: PropTypes.bool
};
FilterBar.propTypes = filterBarPropTypes, FilterBar.__docgenInfo = {
  description: "Component for filters rendering, supplied through metadata\n\n@metadataType container",
  methods: [],
  displayName: "FilterBar",
  props: {
    areFiltersApplied: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "Can be used to control 'Clear filters' and 'Apply' buttons. When `false` is passed, these buttons are disabled."
    },
    uiProps: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Content metadata or an array of metadata"
    },
    classNameMap: {
      type: {
        name: "objectOf",
        value: {
          name: "any"
        }
      },
      required: !1,
      description: "Resolve class name to hashed class name"
    },
    componentMap: {
      type: {
        name: "objectOf",
        value: {
          name: "any"
        }
      },
      required: !1,
      description: "Resolve component map"
    },
    callbackMap: {
      type: {
        name: "objectOf",
        value: {
          name: "any"
        }
      },
      required: !1,
      description: "Resolve callback name to callback function"
    },
    onFiltersChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback for when filters are changed"
    },
    filters: {
      type: {
        name: "objectOf",
        value: {
          name: "any"
        }
      },
      required: !1,
      description: "Object with filters to be applied (if the component is controlled from the outside)"
    }
  }
}, FilterBar.__docgenInfo = {
  componentName: "FilterBar",
  packageName: "@jutro/lab-preview-dataview",
  description: "Component for filters rendering, supplied through metadata",
  displayName: "FilterBar",
  methods: [],
  actualName: "FilterBar",
  metadataType: "container",
  props: {
    uiProps: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Content metadata or an array of metadata"
    },
    classNameMap: {
      type: {
        name: "objectOf",
        value: {
          name: "any"
        }
      },
      required: !1,
      description: "Resolve class name to hashed class name"
    },
    componentMap: {
      type: {
        name: "objectOf",
        value: {
          name: "any"
        }
      },
      required: !1,
      description: "Resolve component map"
    },
    callbackMap: {
      type: {
        name: "objectOf",
        value: {
          name: "any"
        }
      },
      required: !1,
      description: "Resolve callback name to callback function"
    },
    onFiltersChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback for when filters are changed"
    },
    filters: {
      type: {
        name: "objectOf",
        value: {
          name: "any"
        }
      },
      required: !1,
      description: "Object with filters to be applied (if the component is controlled from the outside)"
    },
    areFiltersApplied: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Can be used to control 'Clear filters' and 'Apply' buttons. When `false` is passed, these buttons are disabled.",
      defaultValue: {
        value: "true",
        computed: !1
      }
    }
  }
};