import React from "react";
import { ModalHeader } from "@jutro/components";
import { useTranslator } from "@jutro/locale";
import { messages } from "./EditingModal.messages";
export var EditingModalHeader = function (_ref) {
  var title = _ref.title,
    onClose = _ref.onClose,
    titleId = _ref.titleId,
    translator = useTranslator();
  return React.createElement(ModalHeader, {
    title: translator(title),
    titleId: titleId,
    onClose: onClose,
    ariaLabel: translator(messages.closeModalAriaLabel)
  });
};
EditingModalHeader.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "EditingModalHeader"
}, EditingModalHeader.__docgenInfo = {
  componentName: "EditingModalHeader",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "EditingModalHeader",
  methods: [],
  actualName: "EditingModalHeader"
};