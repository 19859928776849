import _asyncToGenerator from "@babel/runtime-corejs3/helpers/asyncToGenerator";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _regeneratorRuntime from "@babel/runtime-corejs3/regenerator";
import React, { useCallback, useContext, useEffect, useState, useRef } from "react";
import { ModalNextContext } from "@jutro/components";
import { useHistory } from "react-router-dom";
import { ConfirmationModal } from "../ConfirmationModal";
import messages from "./RouterPrompt.messages";
import { KEEP_CHANGES } from "../types";
export var RouterPrompt = function (_ref) {
  var onClose = _ref.onClose,
    _ref$title = _ref.title,
    title = void 0 === _ref$title ? messages.title : _ref$title,
    _ref$notificationMess = _ref.notificationMessage,
    notificationMessage = void 0 === _ref$notificationMess ? messages.notification : _ref$notificationMess,
    _ref$keepChangesMessa = _ref.keepChangesMessage,
    keepChangesMessage = void 0 === _ref$keepChangesMessa ? messages.cancelMessage : _ref$keepChangesMessa,
    _ref$discardChangesMe = _ref.discardChangesMessage,
    discardChangesMessage = void 0 === _ref$discardChangesMe ? messages.submitMessage : _ref$discardChangesMe,
    history = useHistory(),
    showModal = useContext(ModalNextContext).showModal,
    _useState = useState(!1),
    _useState2 = _slicedToArray(_useState, 2),
    showPrompt = _useState2[0],
    setShowPrompt = _useState2[1],
    _useState3 = useState(""),
    _useState4 = _slicedToArray(_useState3, 2),
    currentPath = _useState4[0],
    setCurrentPath = _useState4[1],
    unblock = useRef();
  useEffect(function () {
    return unblock.current = history.block(function (prompt) {
      return setCurrentPath(prompt.pathname), setShowPrompt(!0), "true";
    }), function () {
      var _unblock$current;
      null === (_unblock$current = unblock.current) || void 0 === _unblock$current || _unblock$current.call(unblock);
    };
  }, [history]);
  var _ref2,
    handleSubmit = useCallback(function () {
      var _unblock$current2;
      null === (_unblock$current2 = unblock.current) || void 0 === _unblock$current2 || _unblock$current2.call(unblock), history.push(currentPath), null == onClose || onClose();
    }, [currentPath, history, onClose]),
    handleCancel = useCallback(function () {
      setShowPrompt(!1);
    }, []),
    show = (_ref2 = _asyncToGenerator(_regeneratorRuntime.mark(function _callee() {
      return _regeneratorRuntime.wrap(function (_context) {
        for (;;) switch (_context.prev = _context.next) {
          case 0:
            return _context.next = 2, showModal(React.createElement(ConfirmationModal, {
              closeModalAriaLabel: messages.closeModalAriaLabel,
              title: title,
              keepChangesMessage: keepChangesMessage,
              discardChangesMessage: discardChangesMessage,
              notificationMessage: notificationMessage
            }));
          case 2:
            if (_context.sent !== KEEP_CHANGES) {
              _context.next = 8;
              break;
            }
            return _context.next = 6, handleCancel();
          case 6:
            _context.next = 10;
            break;
          case 8:
            return _context.next = 10, handleSubmit();
          case 10:
          case "end":
            return _context.stop();
        }
      }, _callee);
    })), function () {
      return _ref2.apply(this, arguments);
    });
  return useEffect(function () {
    showPrompt && show();
  }, [showPrompt]), React.createElement(React.Fragment, null);
};
RouterPrompt.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "RouterPrompt",
  props: {
    title: {
      defaultValue: {
        value: "messages.title",
        computed: !0
      },
      required: !1
    },
    notificationMessage: {
      defaultValue: {
        value: "messages.notification",
        computed: !0
      },
      required: !1
    },
    keepChangesMessage: {
      defaultValue: {
        value: "messages.cancelMessage",
        computed: !0
      },
      required: !1
    },
    discardChangesMessage: {
      defaultValue: {
        value: "messages.submitMessage",
        computed: !0
      },
      required: !1
    }
  }
}, RouterPrompt.__docgenInfo = {
  componentName: "RouterPrompt",
  packageName: "@jutro/quick-view",
  description: "",
  displayName: "RouterPrompt",
  methods: [],
  actualName: "RouterPrompt",
  props: {
    title: {
      defaultValue: {
        value: "messages.title",
        computed: !0
      },
      required: !1
    },
    notificationMessage: {
      defaultValue: {
        value: "messages.notification",
        computed: !0
      },
      required: !1
    },
    keepChangesMessage: {
      defaultValue: {
        value: "messages.cancelMessage",
        computed: !0
      },
      required: !1
    },
    discardChangesMessage: {
      defaultValue: {
        value: "messages.submitMessage",
        computed: !0
      },
      required: !1
    }
  }
};