import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import React, { useState } from "react";
import cx from "classnames";
import { Collapse } from "@jutro/components";
import { useTranslator } from "@jutro/locale";
import styles from "./ExpandableRow.module.css";
import { messages } from "./ExpandableRow.messages";
import { useExpandableContext } from "../DataViewExpandable";
var FallbackContent = function () {
  var translator = useTranslator();
  return React.createElement("div", {
    className: styles.emptyContent
  }, translator(messages.noDetails));
};
export var ExpandableRow = function (_ref) {
  var rawRow = _ref.rawRow,
    rowId = _ref.rowId,
    isExpanded = _ref.isExpanded,
    colSpan = _ref.colSpan,
    _ref$selectionEnabled = _ref.selectionEnabled,
    selectionEnabled = void 0 !== _ref$selectionEnabled && _ref$selectionEnabled,
    className = _ref.className,
    _useState = useState(styles.noTdBorder),
    _useState2 = _slicedToArray(_useState, 2),
    expanderRowClass = _useState2[0],
    setClass = _useState2[1],
    ExpandableContent = useExpandableContext().renderContent,
    emptyColumnColSpan = selectionEnabled ? 2 : 1;
  return React.createElement("tr", {
    className: cx(expanderRowClass, className)
  }, React.createElement("td", {
    colSpan: emptyColumnColSpan
  }), React.createElement("td", {
    colSpan: colSpan - emptyColumnColSpan,
    className: styles.expanderCell
  }, React.createElement(Collapse, {
    isOpen: isExpanded,
    onEntering: function () {
      return setClass("");
    },
    onExited: function () {
      return setClass(styles.noTdBorder);
    },
    className: styles.expandableContent,
    mountOnEnter: !0,
    unmountOnExit: !0
  }, React.createElement(ExpandableContent, {
    row: rawRow,
    rowId: rowId,
    FallbackContent: FallbackContent
  }))));
};
ExpandableRow.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "ExpandableRow",
  props: {
    selectionEnabled: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      required: !1
    }
  }
}, FallbackContent.__docgenInfo = {
  componentName: "FallbackContent",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "FallbackContent",
  methods: [],
  actualName: "FallbackContent"
}, ExpandableRow.__docgenInfo = {
  componentName: "ExpandableRow",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "ExpandableRow",
  methods: [],
  actualName: "ExpandableRow",
  props: {
    selectionEnabled: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      required: !1
    }
  }
};