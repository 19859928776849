import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React, { useMemo, useContext, createContext } from "react";
import PropTypes from "prop-types";
import { useBreakpoint } from "@jutro/layout";
import { useViewContext } from "../../featuresProvider";
export var SelectionContext = createContext({
  selectionEnabled: !1,
  selectedRows: [],
  onRowSelect: function () {
    return !1;
  }
});
export var useSelectionContext = function () {
  return useContext(SelectionContext);
};
export var DataViewSelection = function (props) {
  var view = useViewContext(),
    breakpointProps = useBreakpoint(props).breakpointProps,
    selectedRows = breakpointProps.selectedRows,
    onRowSelect = breakpointProps.onRowSelect,
    onAllRowsSelect = breakpointProps.onAllRowsSelect,
    onSelectionReset = breakpointProps.onSelectionReset,
    useSelectAll = breakpointProps.useSelectAll,
    contextValue = useMemo(function () {
      return {
        selectionEnabled: !0,
        selectedRows: selectedRows,
        onRowSelect: onRowSelect,
        onAllRowsSelect: onAllRowsSelect,
        onSelectionReset: onSelectionReset,
        useSelectAll: useSelectAll
      };
    }, [selectedRows, onRowSelect, onAllRowsSelect, onSelectionReset, useSelectAll]);
  return React.createElement(SelectionContext.Provider, {
    value: contextValue
  }, view);
};
DataViewSelection.defaultProps = {
  useSelectAll: !1
};
var basePropTypes = {
    selectedRows: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired).isRequired,
    useSelectAll: PropTypes.bool,
    onRowSelect: PropTypes.func.isRequired,
    onAllRowsSelect: PropTypes.func,
    onSelectionReset: PropTypes.func
  },
  breakpointPropTypes = _objectSpread(_objectSpread({}, basePropTypes), {}, {
    selectedRows: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired),
    onRowSelect: PropTypes.func
  }),
  propTypes = _objectSpread(_objectSpread({}, basePropTypes), {}, {
    phone: PropTypes.shape(breakpointPropTypes),
    phoneWide: PropTypes.shape(breakpointPropTypes),
    tablet: PropTypes.shape(breakpointPropTypes)
  });
DataViewSelection.propTypes = propTypes, DataViewSelection.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "DataViewSelection",
  props: {
    useSelectAll: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "Show a 'Select all' button. Applicable only in ListView. In TableView 'Select all' is always available."
    },
    selectedRows: {
      type: {
        name: "arrayOf",
        value: {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "number"
          }]
        }
      },
      required: !0,
      description: "Array selected rows. Can contain row ids not included in current data."
    },
    onRowSelect: {
      type: {
        name: "func"
      },
      required: !0,
      description: "Callback called when a single row is selected or unselected."
    },
    onAllRowsSelect: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback called when 'Select all' is clicked.\nShould be passed in TableView."
    },
    onSelectionReset: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback called when filtering or search value is changed."
    },
    phone: {
      type: {
        name: "shape",
        value: {
          selectedRows: {
            name: "arrayOf",
            value: {
              name: "union",
              value: [{
                name: "string"
              }, {
                name: "number"
              }]
            },
            required: !1
          },
          onRowSelect: {
            name: "func",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          selectedRows: {
            name: "arrayOf",
            value: {
              name: "union",
              value: [{
                name: "string"
              }, {
                name: "number"
              }]
            },
            required: !1
          },
          onRowSelect: {
            name: "func",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          selectedRows: {
            name: "arrayOf",
            value: {
              name: "union",
              value: [{
                name: "string"
              }, {
                name: "number"
              }]
            },
            required: !1
          },
          onRowSelect: {
            name: "func",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
}, DataViewSelection.__docgenInfo = {
  componentName: "DataViewSelection",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "DataViewSelection",
  methods: [],
  actualName: "DataViewSelection",
  props: {
    selectedRows: {
      type: {
        name: "arrayOf",
        value: {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "number"
          }]
        }
      },
      required: !0,
      description: "Array selected rows. Can contain row ids not included in current data."
    },
    useSelectAll: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Show a 'Select all' button. Applicable only in ListView. In TableView 'Select all' is always available.",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    onRowSelect: {
      type: {
        name: "func"
      },
      required: !0,
      description: "Callback called when a single row is selected or unselected."
    },
    onAllRowsSelect: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback called when 'Select all' is clicked.\nShould be passed in TableView."
    },
    onSelectionReset: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback called when filtering or search value is changed."
    },
    phone: {
      type: {
        name: "shape",
        value: {
          selectedRows: {
            name: "arrayOf",
            value: {
              name: "union",
              value: [{
                name: "string"
              }, {
                name: "number"
              }]
            },
            required: !1
          },
          onRowSelect: {
            name: "func",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          selectedRows: {
            name: "arrayOf",
            value: {
              name: "union",
              value: [{
                name: "string"
              }, {
                name: "number"
              }]
            },
            required: !1
          },
          onRowSelect: {
            name: "func",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          selectedRows: {
            name: "arrayOf",
            value: {
              name: "union",
              value: [{
                name: "string"
              }, {
                name: "number"
              }]
            },
            required: !1
          },
          onRowSelect: {
            name: "func",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
};