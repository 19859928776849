import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import React, { useState } from "react";
import cx from "classnames";
import { useTranslator } from "@jutro/locale";
import { CheckboxField, IconButton } from "@jutro/components";
import { Flex } from "@jutro/layout";
import { messages } from "./ColumnConfigEntry.messages";
import styles from "./ColumnConfigEntry.module.css";
export var ColumnConfigEntry = function (_ref) {
  var id = _ref.id,
    header = _ref.header,
    visible = _ref.visible,
    onToggle = _ref.onToggle,
    onColumnReorder = _ref.onColumnReorder,
    isFirstEntry = _ref.isFirstEntry,
    isLastEntry = _ref.isLastEntry,
    _useState = useState(!1),
    _useState2 = _slicedToArray(_useState, 2),
    fadeOut = _useState2[0],
    setFadeOut = _useState2[1],
    _useState3 = useState(!1),
    _useState4 = _slicedToArray(_useState3, 2),
    fadeIn = _useState4[0],
    setFadeIn = _useState4[1],
    _useState5 = useState(null),
    _useState6 = _slicedToArray(_useState5, 2),
    direction = _useState6[0],
    setDirection = _useState6[1],
    translator = useTranslator(),
    classes = cx(_defineProperty({}, styles.fadeOut, fadeOut), _defineProperty({}, styles.fadeIn, fadeIn), styles.columnEntry),
    handleOnClick = function (newDirection) {
      setDirection(newDirection), setFadeIn(!1), setFadeOut(!0);
    },
    checkboxAriaLabel = translator(messages.checkboxAriaLabel, {
      column: header
    }),
    leftIconAriaLabel = translator(messages.moveColumnRight, {
      column: header
    }),
    rightIconAriaLabel = translator(messages.moveColumnLeft, {
      column: header
    });
  return header ? React.createElement(Flex, {
    className: classes,
    justifyContent: "between",
    alignItems: "center",
    onAnimationEnd: function () {
      fadeOut && onColumnReorder(direction), setFadeOut(!1), setFadeIn(function (value) {
        return !value;
      });
    },
    "data-testid": "column-entry"
  }, React.createElement(CheckboxField, {
    id: id,
    label: header,
    controlClassName: styles.columnEntryCheckbox,
    labelClassName: styles.columnEntryLabel,
    value: visible,
    showInlineLabel: !0,
    onValueChange: onToggle,
    "aria-label": checkboxAriaLabel
  }), React.createElement(Flex, {
    gap: "none",
    className: styles.orderControls
  }, React.createElement(IconButton, {
    icon: "gw-keyboard-arrow-down",
    ariaLabel: leftIconAriaLabel,
    className: styles.iconButton,
    iconClassName: styles.icon,
    disabled: isLastEntry,
    onClick: function () {
      return handleOnClick("right");
    },
    tabIndex: isLastEntry ? -1 : void 0
  }), React.createElement(IconButton, {
    icon: "gw-keyboard-arrow-up",
    ariaLabel: rightIconAriaLabel,
    className: styles.iconButton,
    iconClassName: styles.icon,
    disabled: isFirstEntry,
    onClick: function () {
      return handleOnClick("left");
    },
    tabIndex: isFirstEntry ? -1 : void 0
  }))) : null;
};
ColumnConfigEntry.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "ColumnConfigEntry"
}, ColumnConfigEntry.__docgenInfo = {
  componentName: "ColumnConfigEntry",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "ColumnConfigEntry",
  methods: [],
  actualName: "ColumnConfigEntry"
};