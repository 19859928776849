import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _asyncToGenerator from "@babel/runtime-corejs3/helpers/asyncToGenerator";
import _Promise from "@babel/runtime-corejs3/core-js-stable/promise";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _Object$values from "@babel/runtime-corejs3/core-js-stable/object/values";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _regeneratorRuntime from "@babel/runtime-corejs3/regenerator";
import React, { useContext, useMemo, createContext } from "react";
import PropTypes from "prop-types";
import { useBreakpoint } from "@jutro/layout";
import { intlMessageShape } from "@jutro/prop-types";
import { QuickViewContext } from "@jutro/quick-view";
import { useModalForm } from "./EditingModal";
import { useQuickViewForm } from "./EditingQuickView";
import { useColumnsConfigContext } from "../columnsConfig";
import { useReorderedColumnsContext } from "../columns";
import { DefaultEditForm } from "./EditForm";
import { messages } from "./DataViewEditing.messages";
import { useViewContext } from "../../featuresProvider";
var _onEditCancel,
  defaultProps = {
    title: messages.defaultTitle,
    formRules: {},
    displayAllColumns: !0,
    onRowEditSubmit: function () {
      return !0;
    },
    onEditCancel: (_onEditCancel = _asyncToGenerator(_regeneratorRuntime.mark(function _callee() {
      return _regeneratorRuntime.wrap(function (_context) {
        for (;;) switch (_context.prev = _context.next) {
          case 0:
            return _context.abrupt("return", !0);
          case 1:
          case "end":
            return _context.stop();
        }
      }, _callee);
    })), function () {
      return _onEditCancel.apply(this, arguments);
    }),
    displayBundledMessages: !1,
    confirmClose: !0
  },
  initialContextValue = _objectSpread(_objectSpread({}, defaultProps), {}, {
    editingEnabled: !1,
    isQuickViewAvailable: !1,
    renderFormWrapper: function () {
      return null;
    },
    onRowSave: function () {
      return _Promise.resolve("error");
    },
    renderHeader: function () {
      return null;
    },
    onEditStart: function () {}
  });
export var EditingContext = createContext(initialContextValue);
export var useEditingContext = function () {
  var props = useContext(EditingContext),
    columns = useReorderedColumnsContext(),
    modalAvailable = useColumnsConfigContext().modalAvailable;
  return _objectSpread({
    renderFormContent: function (formProps) {
      return React.createElement(DefaultEditForm, _extends({}, formProps, {
        columns: columns,
        columnsConfigModalAvailable: modalAvailable
      }));
    }
  }, props);
};
export var DataViewEditing = function (props) {
  var _context2,
    view = useViewContext(),
    breakpointProps = useBreakpoint(props).breakpointProps,
    isQuickViewAvailable = !!useContext(QuickViewContext),
    quickViewProps = useQuickViewForm(),
    modalProps = useModalForm(breakpointProps),
    wrapperProps = isQuickViewAvailable ? quickViewProps : modalProps,
    contextValue = useMemo(function () {
      return _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, initialContextValue), wrapperProps), breakpointProps), {}, {
        isQuickViewAvailable: isQuickViewAvailable,
        editingEnabled: !0
      });
    }, _concatInstanceProperty(_context2 = [breakpointProps.onEditStart, breakpointProps.onEditCancel, breakpointProps.displayAllColumns, breakpointProps.renderFormContent, breakpointProps.onRowSave, breakpointProps.onRowEditSubmit, breakpointProps.title, breakpointProps.renderHeader, breakpointProps.formRules, breakpointProps.displayBundledMessages, breakpointProps.confirmClose]).call(_context2, _toConsumableArray(_Object$values(wrapperProps)), [isQuickViewAvailable]));
  return React.createElement(EditingContext.Provider, {
    value: contextValue
  }, view);
};
var basePropTypes = {
    onEditStart: PropTypes.func,
    onEditCancel: PropTypes.func,
    displayAllColumns: PropTypes.bool,
    renderFormContent: PropTypes.func,
    onRowSave: PropTypes.func.isRequired,
    onRowEditSubmit: PropTypes.func,
    title: intlMessageShape,
    renderHeader: PropTypes.func,
    formRules: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.func.isRequired)]).isRequired),
    displayBundledMessages: PropTypes.bool,
    confirmClose: PropTypes.bool
  },
  breakpointPropTypes = _objectSpread(_objectSpread({}, basePropTypes), {}, {
    onRowSave: PropTypes.func
  }),
  propTypes = _objectSpread(_objectSpread({}, basePropTypes), {}, {
    phone: PropTypes.shape(breakpointPropTypes),
    phoneWide: PropTypes.shape(breakpointPropTypes),
    tablet: PropTypes.shape(breakpointPropTypes)
  });
DataViewEditing.propTypes = propTypes, DataViewEditing.defaultProps = defaultProps, DataViewEditing.__docgenInfo = {
  description: "To enable editing of the rows, use both DataViewEditing component and ActionsColumn with EditAction.\nUses QuickView panel, and if it's not available, ModalNext.",
  methods: [],
  displayName: "DataViewEditing",
  props: {
    title: {
      defaultValue: {
        value: "messages.defaultTitle",
        computed: !0
      },
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Title shown above editing form."
    },
    formRules: {
      defaultValue: {
        value: "{}",
        computed: !1
      },
      type: {
        name: "objectOf",
        value: {
          name: "union",
          value: [{
            name: "func"
          }, {
            name: "arrayOf",
            value: {
              name: "func"
            }
          }]
        }
      },
      required: !1,
      description: "Additional validation rules to evaluate when data changes - object of validation functions where keys are\npaths of the column or ids if paths not provided."
    },
    displayAllColumns: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: 'Controls if fields in edit form are visible for hidden columns.\nCan be overriden using `displayInEditForm` prop on individual columns.\nPossible states for each column:\ndisplayAllColumns=false, displayInEditForm="default" -> field is visible or hidden, depending on columns config\ndisplayAllColumns=true, displayInEditForm="default" -> field is always visible\ndisplayAllColumns=any value, displayInEditForm="always" -> field is always visible\ndisplayAllColumns=any value, displayInEditForm="never" -> field is always hidden\n@default true'
    },
    onRowEditSubmit: {
      defaultValue: {
        value: "() => true",
        computed: !1
      },
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback called when the edited row is successfully saved, not saved or editing modal was canceled."
    },
    onEditCancel: {
      defaultValue: {
        value: "async () => true",
        computed: !1
      },
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback called when the editing is cancelled by the user.\nReturn Promise[true] to close the form, or Promise[false] to leave it open."
    },
    displayBundledMessages: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, renders inline notification above form content with bundled validation messages."
    },
    confirmClose: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, display alert when there is some unsaved changes (works with quick view edit only)."
    },
    onEditStart: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback called when the edit action is clicked.\nOverride it to provide custom implementation of row editing."
    },
    renderFormContent: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Render content of the editing form."
    },
    onRowSave: {
      type: {
        name: "func"
      },
      required: !0,
      description: "Callback called when the edited row is saving."
    },
    renderHeader: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Render header above editing form, including the title."
    },
    phone: {
      type: {
        name: "shape",
        value: {
          onRowSave: {
            name: "func",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          onRowSave: {
            name: "func",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          onRowSave: {
            name: "func",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
}, DataViewEditing.__docgenInfo = {
  componentName: "DataViewEditing",
  packageName: "@jutro/lab-preview-dataview",
  description: "To enable editing of the rows, use both DataViewEditing component and ActionsColumn with EditAction.\nUses QuickView panel, and if it's not available, ModalNext.",
  displayName: "DataViewEditing",
  methods: [],
  actualName: "DataViewEditing",
  props: {
    onEditStart: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback called when the edit action is clicked.\nOverride it to provide custom implementation of row editing."
    },
    onEditCancel: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback called when the editing is cancelled by the user.\nReturn Promise[true] to close the form, or Promise[false] to leave it open.",
      defaultValue: {
        value: "async () => true",
        computed: !1
      }
    },
    displayAllColumns: {
      type: {
        name: "bool"
      },
      required: !1,
      description: 'Controls if fields in edit form are visible for hidden columns.\nCan be overriden using `displayInEditForm` prop on individual columns.\nPossible states for each column:\ndisplayAllColumns=false, displayInEditForm="default" -> field is visible or hidden, depending on columns config\ndisplayAllColumns=true, displayInEditForm="default" -> field is always visible\ndisplayAllColumns=any value, displayInEditForm="always" -> field is always visible\ndisplayAllColumns=any value, displayInEditForm="never" -> field is always hidden\n@default true',
      defaultValue: {
        value: "true",
        computed: !1
      }
    },
    renderFormContent: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Render content of the editing form."
    },
    onRowSave: {
      type: {
        name: "func"
      },
      required: !0,
      description: "Callback called when the edited row is saving."
    },
    onRowEditSubmit: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback called when the edited row is successfully saved, not saved or editing modal was canceled.",
      defaultValue: {
        value: "() => true",
        computed: !1
      }
    },
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Title shown above editing form.",
      defaultValue: {
        value: "messages.defaultTitle",
        computed: !0
      }
    },
    renderHeader: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Render header above editing form, including the title."
    },
    formRules: {
      type: {
        name: "objectOf",
        value: {
          name: "union",
          value: [{
            name: "func"
          }, {
            name: "arrayOf",
            value: {
              name: "func"
            }
          }]
        }
      },
      required: !1,
      description: "Additional validation rules to evaluate when data changes - object of validation functions where keys are\npaths of the column or ids if paths not provided.",
      defaultValue: {
        value: "{}",
        computed: !1
      }
    },
    displayBundledMessages: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, renders inline notification above form content with bundled validation messages.",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    confirmClose: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, display alert when there is some unsaved changes (works with quick view edit only).",
      defaultValue: {
        value: "true",
        computed: !1
      }
    },
    phone: {
      type: {
        name: "shape",
        value: {
          onRowSave: {
            name: "func",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          onRowSave: {
            name: "func",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          onRowSave: {
            name: "func",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
};