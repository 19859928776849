import React, { createContext, useContext } from "react";
import { useId } from "@jutro/platform";
export var DataViewIdContext = createContext("");
export var DataViewIdContextProvider = function (_ref) {
  var children = _ref.children,
    id = useId({
      prefix: "data_view"
    });
  return React.createElement(DataViewIdContext.Provider, {
    value: id
  }, children);
};
export var useDataViewId = function () {
  return useContext(DataViewIdContext);
};
DataViewIdContextProvider.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "DataViewIdContextProvider"
}, DataViewIdContextProvider.__docgenInfo = {
  componentName: "DataViewIdContextProvider",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "DataViewIdContextProvider",
  methods: [],
  actualName: "DataViewIdContextProvider"
};