import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _flatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/flat";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _findInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find";
import React, { useCallback, useRef, useMemo } from "react";
import isFunction from "lodash/isFunction";
import { useBreakpoint } from "@jutro/layout";
var getRadioColumn = function (children) {
    var _context, _context2, _context3, _context4;
    return _filterInstanceProperty(_context = _flatInstanceProperty(_context2 = _mapInstanceProperty(_context3 = _filterInstanceProperty(_context4 = React.Children.toArray(children)).call(_context4, function (child) {
      var _child$type;
      return "DataViewColumns" === (null == child || null === (_child$type = child.type) || void 0 === _child$type ? void 0 : _child$type.displayName);
    })).call(_context3, function (child) {
      var _child$props;
      return null == child || null === (_child$props = child.props) || void 0 === _child$props ? void 0 : _child$props.children;
    })).call(_context2)).call(_context, function (column) {
      var _column$type;
      return "RadioColumnAdapter" === (null == column || null === (_column$type = column.type) || void 0 === _column$type ? void 0 : _column$type.displayName);
    })[0];
  },
  useSelectedRows = function (_ref2) {
    var _context5,
      children = _ref2.children,
      data = _ref2.data,
      rowIdPath = _ref2.rowIdPath,
      onGetRowId = _ref2.onGetRowId,
      radioColumn = getRadioColumn(children),
      breakpointProps = useBreakpoint((null == radioColumn ? void 0 : radioColumn.props) || {}).breakpointProps;
    if (!radioColumn) return [];
    var option = breakpointProps.option,
      onOption = breakpointProps.onOption,
      value = breakpointProps.value,
      optionProp = option || onOption;
    if (!optionProp) return [];
    if (!isFunction(optionProp)) return (null == optionProp ? void 0 : optionProp.code) === value ? function (data, rowIdPath, onGetRowId) {
      return null == data ? void 0 : _mapInstanceProperty(data).call(data, function (row, index) {
        return onGetRowId ? row[onGetRowId(row, index)] : rowIdPath ? row[rowIdPath] : void 0;
      });
    }(data, rowIdPath, onGetRowId) : [];
    var options = null == data ? void 0 : _mapInstanceProperty(data).call(data, function (row, index) {
        return {
          index: index,
          option: optionProp(row)
        };
      }),
      selectedRows = null == options ? void 0 : _mapInstanceProperty(_context5 = _filterInstanceProperty(options).call(options, function (_ref3) {
        return _ref3.option.code === value;
      })).call(_context5, function (_ref4) {
        var code = _ref4.option.code,
          index = _ref4.index;
        return rowIdPath || onGetRowId ? code : index;
      });
    return null != selectedRows ? selectedRows : [];
  },
  useHandleSelectRow = function (_ref6) {
    var children = _ref6.children,
      data = _ref6.data,
      rowIdPath = _ref6.rowIdPath,
      onGetRowId = _ref6.onGetRowId,
      radioColumn = getRadioColumn(children),
      breakpointProps = useBreakpoint((null == radioColumn ? void 0 : radioColumn.props) || {}).breakpointProps,
      selectedRow = useRef(breakpointProps.value || void 0),
      onValueChange = breakpointProps.onValueChange,
      option = breakpointProps.option,
      onOption = breakpointProps.onOption,
      valueMapper = useMemo(function () {
        return function (option, onOption, data, rowIdPath, onGetRowId) {
          var optionProp = option || onOption;
          if (!optionProp || !isFunction(optionProp)) return function () {};
          var options = null == data ? void 0 : _mapInstanceProperty(data).call(data, function (row, index) {
            return {
              index: index,
              option: optionProp(row)
            };
          });
          return function (selectedRow) {
            var _options$find;
            return rowIdPath || onGetRowId ? selectedRow : null == options || null === (_options$find = _findInstanceProperty(options).call(options, function (_ref5) {
              return _ref5.index === selectedRow;
            })) || void 0 === _options$find ? void 0 : _options$find.option.code;
          };
        }(option, onOption, data, rowIdPath, onGetRowId);
      }, [option, onOption, data, rowIdPath, onGetRowId]);
    return {
      handleSelectRow: useCallback(function (newSelectedRow) {
        var mappedRow = valueMapper(newSelectedRow);
        void 0 !== mappedRow ? null == onValueChange || onValueChange(mappedRow) : null == onValueChange || onValueChange(), selectedRow.current = mappedRow;
      }, [onValueChange, valueMapper, selectedRow]),
      selectedRow: selectedRow
    };
  },
  useOnRowSelect = function (handleSelectRow) {
    return useCallback(function (_ref7) {
      var rowId = _ref7.rowId;
      handleSelectRow(rowId);
    }, [handleSelectRow]);
  },
  useOnAllRowsSelect = function (handleSelectRow, selectedRow) {
    return useCallback(function (_ref8) {
      var rowIds = _ref8.rowIds;
      Boolean(selectedRow.current) ? handleSelectRow() : handleSelectRow(rowIds[0]);
    }, [handleSelectRow, selectedRow]);
  };
export var useMappedSelectionProps = function (props) {
  var _ref,
    children,
    _useHandleSelectRow = useHandleSelectRow(props),
    handleSelectRow = _useHandleSelectRow.handleSelectRow,
    selectedRow = _useHandleSelectRow.selectedRow;
  return {
    selectionEnabled: (_ref = props, children = _ref.children, !!getRadioColumn(children)),
    onRowSelect: useOnRowSelect(handleSelectRow),
    onAllRowsSelect: useOnAllRowsSelect(handleSelectRow, selectedRow),
    selectedRows: useSelectedRows(props)
  };
};