import { defineMessages } from "react-intl";
export var messages = defineMessages({
  title: {
    id: "jutro-dataview.ColumnsConfigModal.Title",
    defaultMessage: "Customize Columns"
  },
  subtitle: {
    id: "jutro-dataview.ColumnsConfigModal.Subtitle",
    defaultMessage: "Uncheck columns to remove them from the table"
  },
  cancel: {
    id: "jutro-dataview.ColumnsConfigModal.Cancel",
    defaultMessage: "Cancel"
  },
  cancelAriaLabel: {
    id: "jutro-dataview.ColumnsConfigModal.CancelAriaLabel",
    defaultMessage: "Cancel changes"
  },
  applyChanges: {
    id: "jutro-dataview.ColumnsConfigModal.ApplyChange",
    defaultMessage: "Apply Changes"
  },
  closeModalAriaLabel: {
    id: "jutro-dataview.ColumnsConfigModal.CloseModalAriaLabel",
    defaultMessage: "Close modal"
  }
});