import { defineMessages } from "react-intl";
export var messages = defineMessages({
  header: {
    id: "jutro-dataview.ActionsColumn.header",
    defaultMessage: "Actions"
  },
  moreActions: {
    id: "jutro-dataview.ActionsColumn.moreActions",
    defaultMessage: "More actions"
  }
});