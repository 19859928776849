import _asyncToGenerator from "@babel/runtime-corejs3/helpers/asyncToGenerator";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _regeneratorRuntime from "@babel/runtime-corejs3/regenerator";
import { useState, useEffect } from "react";
import { useValidation } from "@jutro/validation/internal";
export function useRowEdit(_ref) {
  var row = _ref.row,
    formRules = _ref.formRules,
    onRowSave = _ref.onRowSave,
    onRowEditSubmit = _ref.onRowEditSubmit,
    onResolve = _ref.onResolve,
    onEditCancel = _ref.onEditCancel,
    onReject = _ref.onReject,
    _useState = useState(row),
    _useState2 = _slicedToArray(_useState, 2),
    editedRow = _useState2[0],
    setEditedRow = _useState2[1],
    _useState3 = useState(!1),
    _useState4 = _slicedToArray(_useState3, 2),
    isValid = _useState4[0],
    setIsValid = _useState4[1],
    _useState5 = useState(!1),
    _useState6 = _slicedToArray(_useState5, 2),
    isSubmitted = _useState6[0],
    setIsSubmitted = _useState6[1];
  useEffect(function () {
    setEditedRow(row);
  }, [row]);
  var _ref2,
    _ref3,
    onSaveButtonClick = (_ref2 = _asyncToGenerator(_regeneratorRuntime.mark(function _callee() {
      var isSuccessful;
      return _regeneratorRuntime.wrap(function (_context) {
        for (;;) switch (_context.prev = _context.next) {
          case 0:
            if (setIsSubmitted(!0), !isValid) {
              _context.next = 7;
              break;
            }
            return _context.next = 4, onRowSave({
              editedRow: editedRow
            });
          case 4:
            isSuccessful = _context.sent, null == onRowEditSubmit || onRowEditSubmit(isSuccessful), null == onResolve || onResolve();
          case 7:
          case "end":
            return _context.stop();
        }
      }, _callee);
    })), function () {
      return _ref2.apply(this, arguments);
    }),
    onCancelButtonClick = (_ref3 = _asyncToGenerator(_regeneratorRuntime.mark(function _callee2() {
      return _regeneratorRuntime.wrap(function (_context2) {
        for (;;) switch (_context2.prev = _context2.next) {
          case 0:
            return _context2.next = 2, null == onEditCancel ? void 0 : onEditCancel({
              currentRow: editedRow
            });
          case 2:
            _context2.sent && (null == onRowEditSubmit || onRowEditSubmit("canceled"), null == onReject || onReject());
          case 4:
          case "end":
            return _context2.stop();
        }
      }, _callee2);
    })), function () {
      return _ref3.apply(this, arguments);
    }),
    _useValidation = useValidation({
      formData: editedRow,
      formRules: formRules,
      onValidationChange: function (valid) {
        return setIsValid(valid), valid;
      }
    }),
    registerValidation = _useValidation.registerValidation,
    resolveFormValidation = _useValidation.resolveFormValidation,
    validationMessages = _useValidation.validationMessages;
  return {
    onSaveButtonClick: onSaveButtonClick,
    onCancelButtonClick: onCancelButtonClick,
    editedRow: editedRow,
    setEditedRow: setEditedRow,
    isSubmitted: isSubmitted,
    isValid: isValid,
    registerValidation: registerValidation,
    resolveFormValidation: resolveFormValidation,
    validationMessages: validationMessages
  };
}