import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React, { useMemo, useContext, createContext } from "react";
import PropTypes from "prop-types";
import { useBreakpoint } from "@jutro/layout";
import { useDataViewId } from "../../hooks";
import { useViewContext } from "../../featuresProvider";
var initialContextValue = {
  expansionEnabled: !1,
  id: "",
  renderContent: function () {
    return null;
  },
  expandedRows: [],
  onExpandRow: function () {
    return !1;
  }
};
export var ExpandableContext = createContext(initialContextValue);
export var useExpandableContext = function () {
  var id = useDataViewId(),
    expandableContext = useContext(ExpandableContext);
  return id !== expandableContext.id ? initialContextValue : expandableContext;
};
export var DataViewExpandable = function (props) {
  var view = useViewContext(),
    id = useDataViewId(),
    _useBreakpoint$breakp = useBreakpoint(props).breakpointProps,
    renderContent = _useBreakpoint$breakp.renderContent,
    onGetDisabled = _useBreakpoint$breakp.onGetDisabled,
    expandedRows = _useBreakpoint$breakp.expandedRows,
    onExpandRow = _useBreakpoint$breakp.onExpandRow,
    onExpandAllRows = _useBreakpoint$breakp.onExpandAllRows,
    contextValue = useMemo(function () {
      return {
        expansionEnabled: !0,
        id: id,
        renderContent: renderContent,
        expandedRows: expandedRows,
        onExpandRow: onExpandRow,
        onExpandAllRows: onExpandAllRows,
        onGetDisabled: onGetDisabled
      };
    }, [id, renderContent, expandedRows, onExpandRow, onExpandAllRows, onGetDisabled]);
  return React.createElement(ExpandableContext.Provider, {
    value: contextValue
  }, view);
};
var basePropTypes = {
    renderContent: PropTypes.func.isRequired,
    onGetDisabled: PropTypes.func,
    expandedRows: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired),
    onExpandRow: PropTypes.func,
    onExpandAllRows: PropTypes.func
  },
  breakpointPropTypes = _objectSpread(_objectSpread({}, basePropTypes), {}, {
    renderContent: PropTypes.func
  }),
  propTypes = _objectSpread(_objectSpread({}, basePropTypes), {}, {
    phone: PropTypes.shape(breakpointPropTypes),
    phoneWide: PropTypes.shape(breakpointPropTypes),
    tablet: PropTypes.shape(breakpointPropTypes)
  });
DataViewExpandable.propTypes = propTypes, DataViewExpandable.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "DataViewExpandable",
  props: {
    renderContent: {
      type: {
        name: "func"
      },
      required: !0,
      description: "Content of the expanded row"
    },
    onGetDisabled: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to disable expansion capability for individual rows"
    },
    expandedRows: {
      type: {
        name: "arrayOf",
        value: {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "number"
          }]
        }
      },
      required: !1,
      description: "Array of expanded row ids, controlled from outside"
    },
    onExpandRow: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback called when row expansion it toggled"
    },
    onExpandAllRows: {
      type: {
        name: "func"
      },
      required: !1,
      description: 'Callback called when all rows expansion is toggled. If the callback is not passed, the "Expand all" button is not rendered.'
    },
    phone: {
      type: {
        name: "shape",
        value: {
          renderContent: {
            name: "func",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          renderContent: {
            name: "func",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          renderContent: {
            name: "func",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
}, DataViewExpandable.__docgenInfo = {
  componentName: "DataViewExpandable",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "DataViewExpandable",
  methods: [],
  actualName: "DataViewExpandable",
  props: {
    renderContent: {
      type: {
        name: "func"
      },
      required: !0,
      description: "Content of the expanded row"
    },
    onGetDisabled: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to disable expansion capability for individual rows"
    },
    expandedRows: {
      type: {
        name: "arrayOf",
        value: {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "number"
          }]
        }
      },
      required: !1,
      description: "Array of expanded row ids, controlled from outside"
    },
    onExpandRow: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback called when row expansion it toggled"
    },
    onExpandAllRows: {
      type: {
        name: "func"
      },
      required: !1,
      description: 'Callback called when all rows expansion is toggled. If the callback is not passed, the "Expand all" button is not rendered.'
    },
    phone: {
      type: {
        name: "shape",
        value: {
          renderContent: {
            name: "func",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          renderContent: {
            name: "func",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          renderContent: {
            name: "func",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
};