import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import { useMemo } from "react";
import { generateComponentId } from "@jutro/platform";
import { useTranslator } from "@jutro/locale";
import { useTitleContext } from "./DataViewTitle";
import { useSortedColumnAriaLabel } from "./useSortedColumnAriaLabel";
export var useTitle = function (id, ariaLabel) {
  var _context,
    translator = useTranslator(),
    titleId = useMemo(function () {
      return generateComponentId(id);
    }, [id]),
    _useTitleContext = useTitleContext(),
    title = _useTitleContext.title,
    customTitleLabelId = _useTitleContext.ariaLabelledby,
    sortedColumnAriaLabel = useSortedColumnAriaLabel(),
    ariaLabelledby = customTitleLabelId || (title ? titleId : void 0);
  return ariaLabel ? {
    titleId: titleId,
    a11yLabelProps: {
      "aria-label": _filterInstanceProperty(_context = [translator(ariaLabel), sortedColumnAriaLabel]).call(_context, Boolean).join(", ")
    }
  } : ariaLabelledby ? {
    titleId: titleId,
    a11yLabelProps: {
      "aria-labelledby": ariaLabelledby
    }
  } : sortedColumnAriaLabel ? {
    titleId: titleId,
    a11yLabelProps: {
      "aria-label": sortedColumnAriaLabel
    }
  } : {
    titleId: titleId,
    a11yLabelProps: {}
  };
};