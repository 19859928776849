import _extends from "@babel/runtime-corejs3/helpers/extends";
import React, { useRef } from "react";
import { useTranslator } from "@jutro/locale";
import { useKeyboardNavigation } from "../../accessibility";
import { ExpandTrigger } from "./ExpandTrigger";
import { messages } from "./ExpandCells.messages";
export var ExpandCell = function (_ref) {
  var rowId = _ref.rowId,
    className = _ref.className,
    isExpanded = _ref.isExpanded,
    onExpandRow = _ref.onExpandRow,
    disabled = _ref.disabled,
    rowIndex = _ref.rowIndex,
    columnIndex = _ref.columnIndex,
    translator = useTranslator(),
    triggerRef = useRef(null),
    _useKeyboardNavigatio = useKeyboardNavigation(triggerRef, rowIndex, columnIndex, "expansion"),
    onKeyDown = _useKeyboardNavigatio.onKeyDown,
    onFocus = _useKeyboardNavigatio.onFocus,
    tdProps = disabled ? {
      "aria-label": translator(messages.noExpandRowLabel)
    } : {};
  return React.createElement("td", _extends({
    id: "expand_".concat(rowId),
    className: className,
    role: "gridcell",
    onKeyDown: onKeyDown
  }, tdProps), !disabled && React.createElement(ExpandTrigger, {
    id: rowId,
    ariaLabel: isExpanded ? messages.collapseRowLabel : messages.expandRowLabel,
    onClick: onExpandRow,
    isExpanded: isExpanded,
    ref: triggerRef,
    onFocus: onFocus
  }));
};
ExpandCell.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "ExpandCell"
}, ExpandCell.__docgenInfo = {
  componentName: "ExpandCell",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "ExpandCell",
  methods: [],
  actualName: "ExpandCell"
};