import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _extends from "@babel/runtime-corejs3/helpers/extends";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React from "react";
import { CurrencyField } from "@jutro/components";
import cx from "classnames";
import { getCommonEditProps } from "../editing/getCommonEditProps";
import { allowedCurrencyFields } from "../../types";
import { createColumnPropTypes, GenericColumn, ALIGN_TEXT_RIGHT } from "./GenericColumn";
import styles from "./Columns.module.css";
var CurrencyCell = function (_ref) {
    var _props$classNames,
      props = _ref.columnProps,
      value = _ref.value,
      cellId = _ref.cellId;
    return React.createElement(CurrencyField, _extends({}, props, {
      readOnly: !0,
      id: cellId,
      value: value,
      className: null === (_props$classNames = props.classNames) || void 0 === _props$classNames ? void 0 : _props$classNames.cellContent,
      noDeprecationWarnings: ["registerValidation"]
    }));
  },
  CurrencyEditCell = function (props) {
    return React.createElement(CurrencyField, _extends({}, getCommonEditProps(props), {
      noDeprecationWarnings: ["registerValidation"]
    }));
  };
export var CurrencyColumn = function (props) {
  var _props$classNames2,
    classNames = _objectSpread(_objectSpread({}, props.classNames), {}, {
      cellContent: cx(styles.currencyCellContent, null === (_props$classNames2 = props.classNames) || void 0 === _props$classNames2 ? void 0 : _props$classNames2.cellContent)
    });
  return React.createElement(GenericColumn, _extends({}, props, {
    classNames: classNames,
    renderCell: CurrencyCell,
    renderEditCell: CurrencyEditCell,
    columnDataType: "currency"
  }));
};
var currencyColumnPropTypes = createColumnPropTypes(CurrencyField.propTypes, allowedCurrencyFields);
CurrencyColumn.propTypes = currencyColumnPropTypes, CurrencyColumn.defaultProps = {
  alignText: ALIGN_TEXT_RIGHT
}, CurrencyColumn.displayName = "CurrencyColumn", CurrencyColumn.__docgenInfo = {
  description: "Column for currency format values\n\n@metadataType container",
  methods: [],
  displayName: "CurrencyColumn",
  props: {
    alignText: {
      defaultValue: {
        value: "ALIGN_TEXT_RIGHT",
        computed: !0
      },
      required: !1
    }
  },
  composes: ["./GenericColumn"]
}, CurrencyCell.__docgenInfo = {
  componentName: "CurrencyCell",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "CurrencyCell",
  methods: [],
  actualName: "CurrencyCell"
}, CurrencyEditCell.__docgenInfo = {
  componentName: "CurrencyEditCell",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "CurrencyEditCell",
  methods: [],
  actualName: "CurrencyEditCell"
}, CurrencyColumn.__docgenInfo = {
  componentName: "CurrencyColumn",
  packageName: "@jutro/lab-preview-dataview",
  description: "Column for currency format values",
  displayName: "CurrencyColumn",
  methods: [],
  actualName: "CurrencyColumn",
  metadataType: "container",
  props: {
    alignText: {
      defaultValue: {
        value: "ALIGN_TEXT_RIGHT",
        computed: !0
      },
      required: !1
    }
  },
  composes: ["./GenericColumn"]
};