import _extends from "@babel/runtime-corejs3/helpers/extends";
import _asyncToGenerator from "@babel/runtime-corejs3/helpers/asyncToGenerator";
import _regeneratorRuntime from "@babel/runtime-corejs3/regenerator";
import React, { useContext } from "react";
import { Button, ModalNextContext } from "@jutro/components";
import { useTranslator } from "@jutro/locale";
import { ListViewSortingModal } from "./ListViewSortingModal";
import styles from "./SortingModalTrigger.module.css";
import { messages } from "./ListViewSortingModal.messages";
import { useSortingContext } from "../DataViewSorting";
export var SortingModalTrigger = function (_ref) {
  var _ref2,
    columnOptions = _ref.columnOptions,
    onGetRowId = _ref.onGetRowId,
    showModal = useContext(ModalNextContext).showModal,
    translator = useTranslator(),
    _useSortingContext = useSortingContext(),
    sortingEnabled = _useSortingContext.sortingEnabled,
    sortedColumnId = _useSortingContext.sortedColumnId,
    sortedColumnDesc = _useSortingContext.sortedColumnDesc,
    onColumnSort = _useSortingContext.onColumnSort,
    isSortable = sortingEnabled && columnOptions.some(function (column) {
      return column.sortable;
    }),
    triggerModal = (_ref2 = _asyncToGenerator(_regeneratorRuntime.mark(function _callee() {
      var FixContextPassthroughForModal;
      return _regeneratorRuntime.wrap(function (_context) {
        for (;;) switch (_context.prev = _context.next) {
          case 0:
            return _context.prev = 0, FixContextPassthroughForModal = function (modalProps) {
              return React.createElement(ListViewSortingModal, _extends({
                columnOptions: columnOptions,
                onGetRowId: onGetRowId
              }, modalProps, {
                onColumnSort: onColumnSort,
                sortedColumnId: sortedColumnId,
                sortedColumnDesc: sortedColumnDesc
              }));
            }, _context.next = 4, showModal(React.createElement(FixContextPassthroughForModal, null));
          case 4:
            _context.next = 8;
            break;
          case 6:
            _context.prev = 6, _context.t0 = _context.catch(0);
          case 8:
          case "end":
            return _context.stop();
        }
      }, _callee, null, [[0, 6]]);
    })), function () {
      return _ref2.apply(this, arguments);
    });
  return isSortable ? React.createElement(Button, {
    icon: "gw-sort",
    onClick: triggerModal,
    size: "medium",
    type: "text",
    className: styles.sortingModalTrigger,
    iconClassName: styles.sortingModalTriggerIcon,
    "data-testid": "sorting-control",
    "aria-label": translator(messages.openSortingConfiguration)
  }) : null;
};
SortingModalTrigger.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "SortingModalTrigger"
}, SortingModalTrigger.__docgenInfo = {
  componentName: "SortingModalTrigger",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "SortingModalTrigger",
  methods: [],
  actualName: "SortingModalTrigger"
};