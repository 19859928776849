import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _findIndexInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find-index";
import _spliceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/splice";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import React, { useState, useCallback, useMemo } from "react";
import { ModalNext, ModalBody, ModalFooter, ModalHeader, Button } from "@jutro/components";
import { useTranslator } from "@jutro/locale";
import { uniqueInnerId } from "@jutro/platform";
import { messages } from "./ColumnsConfigModal.messages";
import { ColumnConfigEntry } from "./ColumnConfigEntry";
export var ColumnsConfigModal = function (_ref) {
  var isOpen = _ref.isOpen,
    onResolve = _ref.onResolve,
    onReject = _ref.onReject,
    parentSelector = _ref.parentSelector,
    _ref$initialColumns = _ref.initialColumns,
    initialColumns = void 0 === _ref$initialColumns ? [] : _ref$initialColumns,
    translator = useTranslator(),
    _useState = useState(initialColumns),
    _useState2 = _slicedToArray(_useState, 2),
    columns = _useState2[0],
    setColumns = _useState2[1],
    handleConfirm = useCallback(function () {
      return onResolve(columns);
    }, [onResolve, columns]),
    columnEntries = useMemo(function () {
      var onColumnReorder = function (id) {
        return function (direction) {
          setColumns(function (oldColumns) {
            var newColumns = _toConsumableArray(oldColumns),
              oldIndex = function (columns, columnId) {
                return _findIndexInstanceProperty(columns).call(columns, function (column) {
                  return column.id === columnId;
                });
              }(newColumns, id),
              newIndex = function (currentIndex, direction) {
                return currentIndex + ("left" === direction ? -1 : 1);
              }(oldIndex, direction);
            return function (columns, fromIndex, toIndex) {
              var _context,
                movedColumn = _spliceInstanceProperty(columns).call(columns, fromIndex, 1);
              _spliceInstanceProperty(columns).apply(columns, _concatInstanceProperty(_context = [toIndex, 0]).call(_context, _toConsumableArray(movedColumn)));
            }(newColumns, oldIndex, newIndex), newColumns;
          });
        };
      };
      return _mapInstanceProperty(columns).call(columns, function (column, index) {
        return React.createElement(ColumnConfigEntry, _extends({
          key: column.id
        }, column, {
          header: translator(column.header),
          onToggle: (id = column.id, function () {
            setColumns(function (old) {
              return _mapInstanceProperty(old).call(old, function (column) {
                return column.id === id ? _objectSpread(_objectSpread({}, column), {}, {
                  visible: !column.visible
                }) : column;
              });
            });
          }),
          onColumnReorder: onColumnReorder(column.id),
          isFirstEntry: !index,
          isLastEntry: index === columns.length - 1
        }));
        var id;
      });
    }, [columns]),
    titleId = uniqueInnerId("columnsConfigModalTitle", "titleId").titleId,
    title = translator(messages.title);
  return React.createElement(ModalNext, {
    isOpen: isOpen,
    onRequestClose: onReject,
    parentSelector: parentSelector,
    ariaLabelledby: titleId,
    shouldCloseOnEsc: !1
  }, React.createElement(ModalHeader, {
    title: title,
    titleId: titleId,
    subtitle: translator(messages.subtitle),
    onClose: onReject,
    ariaLabel: translator(messages.closeModalAriaLabel)
  }), React.createElement(ModalBody, {
    contentLayout: {
      component: "Grid",
      componentProps: {
        columns: ["1fr"],
        gap: "none",
        role: "group",
        "aria-label": title
      }
    }
  }, columnEntries), React.createElement(ModalFooter, null, React.createElement(Button, {
    type: "text",
    onClick: onReject,
    "aria-label": translator(messages.cancelAriaLabel)
  }, translator(messages.cancel)), React.createElement(Button, {
    onClick: handleConfirm
  }, translator(messages.applyChanges))));
};
ColumnsConfigModal.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "ColumnsConfigModal",
  props: {
    initialColumns: {
      defaultValue: {
        value: "[]",
        computed: !1
      },
      required: !1
    }
  }
}, ColumnsConfigModal.__docgenInfo = {
  componentName: "ColumnsConfigModal",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "ColumnsConfigModal",
  methods: [],
  actualName: "ColumnsConfigModal",
  props: {
    initialColumns: {
      defaultValue: {
        value: "[]",
        computed: !1
      },
      required: !1
    }
  }
};