import { useContext, useEffect } from "react";
import { RightSideBarContext } from "@jutro/router";
export var useRightSideBarInteraction = function (_ref) {
  var keepRightSideBarOpen = _ref.keepRightSideBarOpen,
    show = _ref.show,
    rightSideBarContext = useContext(RightSideBarContext);
  useEffect(function () {
    var _rightSideBarContext$;
    show && !keepRightSideBarOpen && (null == rightSideBarContext || null === (_rightSideBarContext$ = rightSideBarContext.collapse) || void 0 === _rightSideBarContext$ || _rightSideBarContext$.setIsCollapsed(!0));
  }, [show, keepRightSideBarOpen]);
};