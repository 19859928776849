import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React, { createContext, useMemo, useContext } from "react";
import PropTypes from "prop-types";
import { useBreakpoint } from "@jutro/layout";
import { intlMessageShape } from "@jutro/prop-types";
import { useViewContext } from "../../featuresProvider";
export var DataViewTitleContext = createContext({});
export var useTitleContext = function () {
  return useContext(DataViewTitleContext);
};
export var DataViewTitle = function (props) {
  var view = useViewContext(),
    _useBreakpoint$breakp = useBreakpoint(props).breakpointProps,
    title = _useBreakpoint$breakp.title,
    render = _useBreakpoint$breakp.render,
    ariaLabelledby = _useBreakpoint$breakp.ariaLabelledby,
    className = _useBreakpoint$breakp.className,
    contextValue = useMemo(function () {
      return {
        title: title,
        render: render,
        ariaLabelledby: ariaLabelledby,
        className: className
      };
    }, [title, render, ariaLabelledby, className]);
  return React.createElement(DataViewTitleContext.Provider, {
    value: contextValue
  }, view);
};
var basePropTypes = {
    title: intlMessageShape,
    render: PropTypes.func,
    ariaLabelledby: PropTypes.string,
    className: PropTypes.string
  },
  propTypes = _objectSpread(_objectSpread({}, basePropTypes), {}, {
    phone: PropTypes.shape(basePropTypes),
    phoneWide: PropTypes.shape(basePropTypes),
    tablet: PropTypes.shape(basePropTypes)
  });
DataViewTitle.propTypes = propTypes, DataViewTitle.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "DataViewTitle",
  props: {
    title: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Title placed above the component"
    },
    render: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to render a custom title component"
    },
    ariaLabelledby: {
      type: {
        name: "string"
      },
      required: !1,
      description: "ID assigned to the custom HTML node that is meant to describe the data view. Should be defined if a custom title's render method is provided, and ONLY in such case."
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Custom className passed to the title"
    },
    phone: {
      type: {
        name: "shape",
        value: {
          title: {
            name: "custom",
            raw: "intlMessageShape",
            description: "Title placed above the component",
            required: !1
          },
          render: {
            name: "func",
            description: "Callback to render a custom title component",
            required: !1
          },
          ariaLabelledby: {
            name: "string",
            description: "ID assigned to the custom HTML node that is meant to describe the data view. Should be defined if a custom title's render method is provided, and ONLY in such case.",
            required: !1
          },
          className: {
            name: "string",
            description: "Custom className passed to the title",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          title: {
            name: "custom",
            raw: "intlMessageShape",
            description: "Title placed above the component",
            required: !1
          },
          render: {
            name: "func",
            description: "Callback to render a custom title component",
            required: !1
          },
          ariaLabelledby: {
            name: "string",
            description: "ID assigned to the custom HTML node that is meant to describe the data view. Should be defined if a custom title's render method is provided, and ONLY in such case.",
            required: !1
          },
          className: {
            name: "string",
            description: "Custom className passed to the title",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          title: {
            name: "custom",
            raw: "intlMessageShape",
            description: "Title placed above the component",
            required: !1
          },
          render: {
            name: "func",
            description: "Callback to render a custom title component",
            required: !1
          },
          ariaLabelledby: {
            name: "string",
            description: "ID assigned to the custom HTML node that is meant to describe the data view. Should be defined if a custom title's render method is provided, and ONLY in such case.",
            required: !1
          },
          className: {
            name: "string",
            description: "Custom className passed to the title",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
}, DataViewTitle.__docgenInfo = {
  componentName: "DataViewTitle",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "DataViewTitle",
  methods: [],
  actualName: "DataViewTitle",
  props: {
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Title placed above the component"
    },
    render: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to render a custom title component"
    },
    ariaLabelledby: {
      type: {
        name: "string"
      },
      required: !1,
      description: "ID assigned to the custom HTML node that is meant to describe the data view. Should be defined if a custom title's render method is provided, and ONLY in such case."
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Custom className passed to the title"
    },
    phone: {
      type: {
        name: "shape",
        value: {
          title: {
            name: "union",
            description: "Title placed above the component",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          render: {
            name: "func",
            description: "Callback to render a custom title component",
            required: !1
          },
          ariaLabelledby: {
            name: "string",
            description: "ID assigned to the custom HTML node that is meant to describe the data view. Should be defined if a custom title's render method is provided, and ONLY in such case.",
            required: !1
          },
          className: {
            name: "string",
            description: "Custom className passed to the title",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          title: {
            name: "union",
            description: "Title placed above the component",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          render: {
            name: "func",
            description: "Callback to render a custom title component",
            required: !1
          },
          ariaLabelledby: {
            name: "string",
            description: "ID assigned to the custom HTML node that is meant to describe the data view. Should be defined if a custom title's render method is provided, and ONLY in such case.",
            required: !1
          },
          className: {
            name: "string",
            description: "Custom className passed to the title",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          title: {
            name: "union",
            description: "Title placed above the component",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          render: {
            name: "func",
            description: "Callback to render a custom title component",
            required: !1
          },
          ariaLabelledby: {
            name: "string",
            description: "ID assigned to the custom HTML node that is meant to describe the data view. Should be defined if a custom title's render method is provided, and ONLY in such case.",
            required: !1
          },
          className: {
            name: "string",
            description: "Custom className passed to the title",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
};