import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _asyncToGenerator from "@babel/runtime-corejs3/helpers/asyncToGenerator";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["editingEnabled", "onEditStart"];
import _regeneratorRuntime from "@babel/runtime-corejs3/regenerator";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React from "react";
import PropTypes from "prop-types";
import { makeUniqueWarningLog } from "@jutro/logger";
import { intlMessageShape } from "@jutro/prop-types";
import { Action, wrapperPropTypes } from "../../../features/columns";
import { messages } from "./EditAction.messages";
import { useEditingContext } from "../DataViewEditing";
var noDataViewEditingWarning = makeUniqueWarningLog("Edit action cannot be used without DataViewEditing component");
export var EditAction = function (props) {
  var _useEditingContext = useEditingContext(),
    editingEnabled = _useEditingContext.editingEnabled,
    onEditStart = _useEditingContext.onEditStart,
    otherProps = _objectWithoutProperties(_useEditingContext, _excluded);
  if (!editingEnabled) return noDataViewEditingWarning(), null;
  var _ref,
    onClick = (_ref = _asyncToGenerator(_regeneratorRuntime.mark(function _callee() {
      var row;
      return _regeneratorRuntime.wrap(function (_context) {
        for (;;) switch (_context.prev = _context.next) {
          case 0:
            return row = props.row, _context.next = 3, null == onEditStart ? void 0 : onEditStart(_objectSpread({
              row: row
            }, otherProps));
          case 3:
          case "end":
            return _context.stop();
        }
      }, _callee);
    })), function () {
      return _ref.apply(this, arguments);
    });
  return React.createElement(Action, _extends({}, props, {
    onClick: onClick
  }));
};
EditAction.displayName = "EditAction", EditAction.defaultProps = {
  label: messages.defaultLabel
};
var editActionPropTypes = {
    id: PropTypes.string.isRequired,
    label: intlMessageShape,
    wrapper: PropTypes.oneOf(["icon", "link"]),
    wrapperProps: PropTypes.shape(wrapperPropTypes),
    onGetDisabled: PropTypes.func
  },
  editActionBreakpointPropTypes = _objectSpread(_objectSpread({}, editActionPropTypes), {}, {
    id: PropTypes.string
  });
EditAction.propTypes = _objectSpread(_objectSpread({}, editActionPropTypes), {}, {
  phone: PropTypes.shape(editActionBreakpointPropTypes),
  phoneWide: PropTypes.shape(editActionBreakpointPropTypes),
  tablet: PropTypes.shape(editActionBreakpointPropTypes)
}), EditAction.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "EditAction",
  props: {
    label: {
      defaultValue: {
        value: "messages.defaultLabel",
        computed: !0
      },
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Edit action label.\n@default 'Edit'"
    },
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: ""
    },
    wrapper: {
      type: {
        name: "enum",
        value: [{
          value: "'icon'",
          computed: !1
        }, {
          value: "'link'",
          computed: !1
        }]
      },
      required: !1,
      description: "Determines the type of the Action, defaults to 'link'"
    },
    wrapperProps: {
      type: {
        name: "shape",
        value: "import { Action, wrapperPropTypes } from '../../../features/columns';",
        computed: !0
      },
      required: !1,
      description: "Specifies the properties to be passed to the wrapper component"
    },
    onGetDisabled: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to disable action for a row"
    },
    phone: {
      type: {
        name: "shape",
        value: {
          id: {
            name: "string",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          id: {
            name: "string",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          id: {
            name: "string",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
}, EditAction.__docgenInfo = {
  componentName: "EditAction",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "EditAction",
  methods: [],
  actualName: "EditAction",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: ""
    },
    label: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Edit action label.\n@default 'Edit'",
      defaultValue: {
        value: "messages.defaultLabel",
        computed: !0
      }
    },
    wrapper: {
      type: {
        name: "enum",
        value: [{
          value: "'icon'",
          computed: !1
        }, {
          value: "'link'",
          computed: !1
        }]
      },
      required: !1,
      description: "Determines the type of the Action, defaults to 'link'"
    },
    wrapperProps: {
      type: {
        name: "shape",
        value: "import { Action, wrapperPropTypes } from '../../../features/columns';",
        computed: !0
      },
      required: !1,
      description: "Specifies the properties to be passed to the wrapper component"
    },
    onGetDisabled: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to disable action for a row"
    },
    phone: {
      type: {
        name: "shape",
        value: {
          id: {
            name: "string",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          id: {
            name: "string",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          id: {
            name: "string",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
};