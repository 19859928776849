import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
var _excluded = ["username", "icon", "onClick", "className", "imageSource", "showImageBorder", "messageProps", "useAuthInfo", "dangerouslySetInnerHTML", "isNotButton"];
import _trimInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/trim";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import get from "lodash/get";
import { useTranslator } from "@jutro/locale";
import { intlMessageShape } from "@jutro/prop-types";
import { getMessageProp } from "@jutro/platform";
import { useAuth } from "@jutro/auth";
import { Icon } from "../Icon/Icon";
import { messages } from "./Avatar.messages";
import styles from "./Avatar.module.css";
var getInitials = function (name) {
  var _context;
  if (!name) return null;
  var names = _trimInstanceProperty(name).call(name).split(/\s+/),
    _names = _slicedToArray(names, 2),
    _names$ = _names[0],
    firstName = void 0 === _names$ ? "" : _names$,
    _names$2 = _names[1],
    secondName = void 0 === _names$2 ? "" : _names$2,
    firstInitial = firstName[0] || "",
    secondInitial = secondName[0] || "";
  return _concatInstanceProperty(_context = "".concat(firstInitial)).call(_context, secondInitial);
};
export var Avatar = forwardRef(function (props, ref) {
  var _cx,
    _cx2,
    username = props.username,
    icon = props.icon,
    onClick = props.onClick,
    className = props.className,
    imageSource = props.imageSource,
    showImageBorder = props.showImageBorder,
    messageProps = props.messageProps,
    useAuthInfo = props.useAuthInfo,
    isNotButton = (props.dangerouslySetInnerHTML, props.isNotButton),
    other = _objectWithoutProperties(props, _excluded),
    translator = useTranslator(),
    auth = useAuth(),
    userAvatar = getMessageProp("userAvatar", messageProps, messages);
  function getUsername() {
    return useAuthInfo ? get(auth, "userInfo.name", username) : username;
  }
  function getContent() {
    return imageSource ? React.createElement("img", {
      src: imageSource,
      className: styles.userImage,
      alt: username || translator(userAvatar)
    }) : isNotButton ? getInitials(getUsername()) || icon && React.createElement(Icon, {
      icon: icon
    }) : getInitials(getUsername()) || icon && React.createElement(Icon, {
      icon: icon,
      "aria-label": isNotButton ? "" : username || translator(userAvatar),
      role: "img"
    });
  }
  var classes = cx(styles.avatar, (_defineProperty(_cx = {}, styles.image, imageSource), _defineProperty(_cx, styles.userImage, imageSource), _defineProperty(_cx, styles.border, imageSource && showImageBorder), _defineProperty(_cx, styles.withBackground, !imageSource), _cx), className),
    notButtonClasses = cx(styles.avatar, styles.notButtonAvatar, (_defineProperty(_cx2 = {}, styles.image, imageSource), _defineProperty(_cx2, styles.userImage, imageSource), _defineProperty(_cx2, styles.border, imageSource && showImageBorder), _defineProperty(_cx2, styles.withBackground, !imageSource), _cx2), className),
    title = getUsername() || translator(userAvatar);
  return isNotButton ? React.createElement("span", _extends({
    ref: ref,
    className: notButtonClasses,
    title: ref ? null : title
  }, other), getContent(), React.createElement("div", {
    className: styles.overlay
  })) : React.createElement("button", _extends({
    ref: ref,
    type: "button",
    className: classes,
    onClick: onClick,
    "aria-label": title,
    title: ref ? null : title
  }, other), getContent(), React.createElement("div", {
    className: styles.overlay
  }));
});
var avatarProps = {
  username: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  imageSource: PropTypes.string,
  messageProps: PropTypes.shape({
    userAvatar: intlMessageShape
  }),
  useAuthInfo: PropTypes.bool,
  showImageBorder: PropTypes.bool,
  isNotButton: PropTypes.bool
};
Avatar.propTypes = avatarProps, Avatar.defaultProps = {
  useAuthInfo: !1,
  icon: "gw-person"
}, Avatar.displayName = "Avatar", Avatar.__docgenInfo = {
  description: 'Displays a "user avatar" element with an image, icon, or initials.\n\n@type {React.ForwardRefExoticComponent<PropTypes.InferProps<typeof avatarPropTypes>>}\n\n@metadataType action',
  methods: [],
  displayName: "Avatar",
  props: {
    useAuthInfo: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, displays user information based on Okta instead of passed props"
    },
    icon: {
      defaultValue: {
        value: "'gw-person'",
        computed: !1
      },
      type: {
        name: "string"
      },
      required: !1,
      description: "Icon name, like `gw-account-circle`"
    },
    username: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Users first and last names, used as component alt text and to display\ninitials if no image is selected"
    },
    onClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "On click event handler"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional component styling class name"
    },
    imageSource: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Path to user image, preferentially shown before user initials or icon"
    },
    messageProps: {
      type: {
        name: "shape",
        value: {
          userAvatar: {
            name: "custom",
            raw: "intlMessageShape",
            description: "userAvatar message",
            required: !1
          }
        }
      },
      required: !1,
      description: "Message props(error message/aria-label)"
    },
    showImageBorder: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, border will be displayed"
    },
    isNotButton: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, component rendered as span instead of button"
    }
  }
}, Avatar.__docgenInfo = {
  componentName: "Avatar",
  packageName: "@jutro/components",
  description: 'Displays a "user avatar" element with an image, icon, or initials.',
  displayName: "Avatar",
  methods: [],
  actualName: "Avatar",
  metadataType: "action",
  props: {
    username: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Users first and last names, used as component alt text and to display\ninitials if no image is selected"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Icon name, like `gw-account-circle`",
      defaultValue: {
        value: "'gw-person'",
        computed: !1
      }
    },
    onClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "On click event handler"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional component styling class name"
    },
    imageSource: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Path to user image, preferentially shown before user initials or icon"
    },
    messageProps: {
      type: {
        name: "shape",
        value: {
          userAvatar: {
            name: "union",
            description: "userAvatar message",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          }
        }
      },
      required: !1,
      description: "Message props(error message/aria-label)"
    },
    useAuthInfo: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, displays user information based on Okta instead of passed props",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    showImageBorder: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, border will be displayed"
    },
    isNotButton: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, component rendered as span instead of button"
    }
  }
};