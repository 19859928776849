import { defineMessages } from "react-intl";
export default defineMessages({
  closeButton: {
    id: "jutro-quick-view.QuickView.closeButton",
    defaultMessage: "Close panel"
  },
  saveButton: {
    id: "jutro-quick-view.QuickViewFooter.saveButtonLabel",
    defaultMessage: "Save"
  },
  cancelButton: {
    id: "jutro-quick-view.QuickViewFooter.cancelButtonLabel",
    defaultMessage: "Cancel"
  },
  saveButtonAriaLabel: {
    id: "jutro-quick-view.QuickViewFooter.saveButtonAriaLabel",
    defaultMessage: "Save changes"
  },
  cancelButtonAriaLabel: {
    id: "jutro-quick-view.QuickViewFooter.cancelButtonAriaLabel",
    defaultMessage: "Cancel changes"
  }
});