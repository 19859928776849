import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import React from "react";
import cx from "classnames";
import { Flex, FlexItem } from "@jutro/layout";
import { CheckboxField } from "@jutro/components";
import { useTranslator } from "@jutro/locale";
import styles from "./ListViewItem.module.css";
import { useSelectionContext } from "../../features/selection";
import { useReorderedColumnsContext } from "../../features/columns";
import { useColumnsConfigContext } from "../../features/columnsConfig";
import { ListViewColumn } from "./ListViewColumn";
import { messages } from "./ListViewItem.messages";
export var ListViewItem = function (_ref) {
  var classNames = _ref.classNames,
    rawRow = _ref.rawRow,
    rowId = _ref.rowId,
    rowIndex = _ref.rowIndex,
    translator = useTranslator(),
    columns = useReorderedColumnsContext(),
    itemActionsPosition = useColumnsConfigContext().itemActionsPosition,
    _useSelectionContext = useSelectionContext(),
    selectedRows = _useSelectionContext.selectedRows,
    onRowSelect = _useSelectionContext.onRowSelect,
    selectionEnabled = _useSelectionContext.selectionEnabled,
    isItemSelected = selectionEnabled && _includesInstanceProperty(selectedRows).call(selectedRows, rowId),
    _separateActionColumn = function (columns) {
      return _reduceInstanceProperty(columns).call(columns, function (acc, column) {
        return "action" === column.columnDataType ? acc.actionsColumns.push(column) : acc.displayColumns.push(column), acc;
      }, {
        actionsColumns: [],
        displayColumns: []
      });
    }(columns),
    actionsColumns = _separateActionColumn.actionsColumns,
    displayColumns = _separateActionColumn.displayColumns,
    hasActions = actionsColumns.length > 0,
    hasActionPositionBottom = "bottom" === itemActionsPosition,
    itemClassNames = cx(styles.listViewItem, _defineProperty({}, styles.actionsRightAlignment, hasActions && !hasActionPositionBottom), classNames.row),
    actionsClassNames = cx(_defineProperty({}, styles.actionsBottomAlignment, hasActionPositionBottom), styles.listViewActions, classNames.actionContainer),
    contentColumns = hasActionPositionBottom ? displayColumns : columns,
    displayBottomActions = hasActions && hasActionPositionBottom;
  return React.createElement("div", {
    className: itemClassNames,
    role: "row",
    tabIndex: 0
  }, React.createElement(Flex, {
    "data-testid": "content-container",
    className: cx(styles.listViewItemContent, classNames.contentContainer),
    gap: "none"
  }, selectionEnabled && React.createElement(FlexItem, {
    className: styles.listViewSelectionColumn,
    role: "gridcell"
  }, React.createElement(CheckboxField, {
    id: "select-".concat(rowId),
    value: isItemSelected,
    onValueChange: function () {
      return onRowSelect({
        rowId: rowId
      });
    },
    label: translator(messages.selectRow),
    hideLabel: !0
  })), _mapInstanceProperty(contentColumns).call(contentColumns, function (column, columnIndex) {
    return React.createElement(ListViewColumn, {
      key: column.id,
      columnIndex: columnIndex,
      rawRow: rawRow,
      rowId: rowId,
      rowIndex: rowIndex,
      column: column,
      classNames: {
        getHeaderCellClassName: classNames.getHeaderCellClassName,
        getBodyCellClassName: classNames.getBodyCellClassName,
        column: classNames.column
      }
    });
  })), displayBottomActions && React.createElement("div", {
    "data-testid": "bottom-actions-container",
    className: actionsClassNames
  }, _mapInstanceProperty(actionsColumns).call(actionsColumns, function (columnProps, columnIndex) {
    var _context;
    return columnProps.renderCell && columnProps.renderCell({
      value: null,
      row: rawRow,
      columnProps: columnProps,
      rowId: rowId,
      translator: translator,
      cellId: _concatInstanceProperty(_context = "".concat(columnIndex, "_")).call(_context, rowIndex, "_action_cell")
    });
  })));
};
ListViewItem.displayName = "ListViewItem", ListViewItem.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "ListViewItem"
}, ListViewItem.__docgenInfo = {
  componentName: "ListViewItem",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "ListViewItem",
  methods: [],
  actualName: "ListViewItem"
};