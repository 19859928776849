export var getCellValue = function (_ref) {
  var _column$onResolveValu,
    rowId = _ref.rowId,
    rawRow = _ref.rawRow,
    column = _ref.column,
    translator = _ref.translator,
    cellValue = null === (_column$onResolveValu = column.onResolveValue) || void 0 === _column$onResolveValu ? void 0 : _column$onResolveValu.call(column, {
      row: rawRow,
      rowId: rowId,
      path: column.path || column.id,
      columnProps: column
    });
  return column.shouldTranslateValue && translator(cellValue) || cellValue;
};