import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["id"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import React from "react";
import omit from "lodash/omit";
import PropTypes from "prop-types";
import { useDeprecationWarning } from "@jutro/platform";
import { ActionItem } from "@jutro/datatable";
import { useBreakpoint } from "@jutro/layout";
import { useTranslator } from "@jutro/locale";
import { useMappedActionItemProps } from "./useMappedProps/useMappedActionItemProps";
import { useUnsupportedPropsWarning } from "../useUnsupportedPropsWarning";
import { Action } from "../../features/columns";
export var ActionItemAdapter = function (props) {
  useDeprecationWarning("ActionItemAdapter", "Action", "Refer to the documentation for the details", "9.0.0");
  var translator = useTranslator(),
    breakpointProps = useBreakpoint(props).breakpointProps;
  useUnsupportedPropsWarning(breakpointProps);
  var mappedProps = useMappedActionItemProps(breakpointProps, translator),
    _ref = omit(breakpointProps, _Object$keys(omit(ActionItem.propTypes, "onClick"))),
    actionPropsOnly = (_ref.id, _objectWithoutProperties(_ref, _excluded));
  return React.createElement(Action, _extends({}, mappedProps, actionPropsOnly));
};
var actionItemAdapterPropsTypes = _objectSpread(_objectSpread(_objectSpread({}, Action.propTypes), ActionItem.propTypes), {}, {
  id: PropTypes.string,
  phone: PropTypes.object,
  phoneWide: PropTypes.object,
  tablet: PropTypes.object
});
ActionItemAdapter.propTypes = actionItemAdapterPropsTypes, ActionItemAdapter.displayName = "ActionItemAdapter", ActionItemAdapter.__docgenInfo = {
  description: "Maps a part of ActionColumn props to data view ActionsColumn\n@deprecated",
  methods: [],
  displayName: "ActionItemAdapter",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: ""
    },
    phone: {
      type: {
        name: "object"
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "object"
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "object"
      },
      required: !1,
      description: ""
    }
  },
  composes: ["../../features/columns", "@jutro/datatable"]
}, ActionItemAdapter.__docgenInfo = {
  componentName: "ActionItemAdapter",
  packageName: "@jutro/lab-preview-dataview",
  description: "Maps a part of ActionColumn props to data view ActionsColumn",
  displayName: "ActionItemAdapter",
  methods: [],
  actualName: "ActionItemAdapter",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: ""
    },
    phone: {
      type: {
        name: "object"
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "object"
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "object"
      },
      required: !1,
      description: ""
    }
  },
  composes: ["../../features/columns", "@jutro/datatable"]
};