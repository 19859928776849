import React from "react";
import { InputField } from "@jutro/components";
import styles from "./SearchInput.module.css";
export var SearchInput = function (_ref) {
  var onSearchChange = _ref.onSearchChange,
    searchValue = _ref.searchValue,
    placeholder = _ref.placeholder,
    label = _ref.label;
  return React.createElement(InputField, {
    id: "searchField",
    icon: "gw-search",
    iconPosition: "right",
    value: searchValue,
    onValueChange: onSearchChange,
    placeholder: placeholder,
    label: label,
    hideLabel: !0,
    controlClassName: styles.searchField,
    phone: {
      className: styles.fullWidthSearchField,
      controlClassName: styles.fullWidthSearchFieldControl
    }
  });
};
SearchInput.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "SearchInput"
}, SearchInput.__docgenInfo = {
  componentName: "SearchInput",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "SearchInput",
  methods: [],
  actualName: "SearchInput"
};