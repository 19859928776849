import _Set from "@babel/runtime-corejs3/core-js-stable/set";
import { useEffect } from "react";
import { warning } from "@jutro/logger";
export var useDistinctRowIdsCheck = function (rowIds, componentName) {
  useEffect(function () {
    var distinctRowIds = new _Set(rowIds);
    distinctRowIds.size !== rowIds.length && warning("Values provided for rows ids (using 'rowIdPath'/'onGetRowId' props) should be unique across the given data set!"), [void 0, null, ""].some(function (forbiddenValue) {
      return distinctRowIds.has(forbiddenValue);
    }) && warning("All values provided for rows ids (using 'rowIdPath'/'onGetRowId' props) across the given data set should always be defined, non-empty strings! Not adhering to this constraint puts a risk of ".concat(componentName, " working improperly."));
  }, [rowIds, componentName]);
};