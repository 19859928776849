import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import { useContext, useEffect, useMemo } from "react";
import ReactDOM from "react-dom";
import { KeyboardNavigationContext } from "./KeyboardNavigationProvider";
import { FIRST_DATA_CELL, NO_FOCUS } from "./constants";
var isCell = function (matchToCell, rowIndex, columnIndex) {
    return matchToCell && rowIndex === matchToCell.row && columnIndex === matchToCell.column;
  },
  handleDefaultFocus = function (_ref3) {
    var _cellRef$current3,
      cellRef = _ref3.cellRef;
    null == cellRef || null === (_cellRef$current3 = cellRef.current) || void 0 === _cellRef$current3 || _cellRef$current3.focus();
  },
  focusHandlersMap = {
    cell: function (_ref) {
      var _focused$focusTarget,
        cellRef = _ref.cellRef,
        params = _ref.params,
        _ref2 = null != params ? params : {},
        focused = _ref2.focused,
        columnDataType = _ref2.columnDataType,
        customFocusHandler = _ref2.customFocusHandler,
        currentCell = ReactDOM.findDOMNode(cellRef.current),
        targetElement = null == currentCell ? void 0 : currentCell.querySelector(null !== (_focused$focusTarget = null == focused ? void 0 : focused.focusTarget) && void 0 !== _focused$focusTarget ? _focused$focusTarget : "");
      if (targetElement) targetElement.focus();else if (customFocusHandler) customFocusHandler({
        cellRef: cellRef
      });else if ("action" === columnDataType) {
        var firstAction = null == currentCell ? void 0 : currentCell.querySelector("[tabIndex]");
        null == firstAction || firstAction.focus();
      } else {
        var _cellRef$current2;
        null == cellRef || null === (_cellRef$current2 = cellRef.current) || void 0 === _cellRef$current2 || _cellRef$current2.focus();
      }
    },
    expansion: handleDefaultFocus,
    selection: handleDefaultFocus,
    header: handleDefaultFocus
  };
export var useKeyboardNavigation = function (cellRef, rowIndex, columnIndex, cellType, params) {
  var _useContext,
    _ref4 = null !== (_useContext = useContext(KeyboardNavigationContext)) && void 0 !== _useContext ? _useContext : {},
    _onKeyDown = _ref4.onKeyDown,
    _onFocus = _ref4.onFocus,
    focused = _ref4.focused,
    focusHandler = focusHandlersMap[cellType];
  useEffect(function () {
    (function (cellRef, rowIndex, columnIndex, cellType, focused) {
      return isCell(focused, rowIndex, columnIndex) && ("expansion" === cellType || "selection" === cellType || function (focused) {
        return (null == focused ? void 0 : focused.keyDownTarget) === (null == focused ? void 0 : focused.focusTarget);
      }(focused) || function (cellRef, focused) {
        var _cellRef$current$cont, _cellRef$current;
        return null !== (_cellRef$current$cont = null === (_cellRef$current = cellRef.current) || void 0 === _cellRef$current ? void 0 : _cellRef$current.contains((null == focused ? void 0 : focused.rawTarget) || null)) && void 0 !== _cellRef$current$cont && _cellRef$current$cont;
      }(cellRef, focused));
    })(cellRef, rowIndex, columnIndex, cellType, focused) && focusHandler({
      cellRef: cellRef,
      params: _objectSpread(_objectSpread({}, params), {}, {
        focused: focused
      })
    });
  }, [rowIndex, columnIndex, focused.row, focused.column, focused.focusTarget]);
  var tabIndex = useMemo(function () {
    return function (focused, rowIndex, columnIndex) {
      return isCell(NO_FOCUS, focused.row, focused.column) ? isCell(FIRST_DATA_CELL, rowIndex, columnIndex) ? 0 : -1 : isCell(focused, rowIndex, columnIndex) ? 0 : -1;
    }(focused, rowIndex, columnIndex);
  }, [rowIndex, columnIndex, focused.row, focused.column, focused.focusTarget]);
  return useMemo(function () {
    return {
      tabIndex: tabIndex,
      onKeyDown: function (e) {
        return _onKeyDown && _onKeyDown(e);
      },
      onFocus: function (e) {
        return _onFocus && _onFocus(e, columnIndex, rowIndex);
      }
    };
  }, [tabIndex, columnIndex, rowIndex]);
};