import React from "react";
import { usePaginationContext } from "./usePaginationContext";
import { NavigationInternal } from "./NavigationInternal";
export var Navigation = function (_ref) {
  var className = _ref.className;
  return usePaginationContext().paginationEnabled ? React.createElement("div", {
    className: className,
    "data-testid": "testNavigation"
  }, React.createElement(NavigationInternal, null)) : null;
};
Navigation.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "Navigation"
}, Navigation.__docgenInfo = {
  componentName: "Navigation",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "Navigation",
  methods: [],
  actualName: "Navigation"
};