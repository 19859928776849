import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React, { useMemo, createContext, useContext } from "react";
import PropTypes from "prop-types";
import { useBreakpoint } from "@jutro/layout";
import { useViewContext } from "../../featuresProvider";
var defaultProps = {
  showHeaders: !0,
  modalTriggerIcon: "gw-settings",
  itemActionsPosition: "right"
};
export var ColumnsConfigContext = createContext(_objectSpread({
  columnsConfigEnabled: !1,
  columnsConfig: []
}, defaultProps));
export var useColumnsConfigContext = function () {
  return useContext(ColumnsConfigContext);
};
var toConfigurationItem = function (item) {
  var _item$visible;
  return "string" == typeof item ? {
    id: item,
    visible: !0
  } : _objectSpread(_objectSpread({}, item), {}, {
    visible: null === (_item$visible = item.visible) || void 0 === _item$visible || _item$visible
  });
};
export var DataViewColumnsConfig = function (props) {
  var _context,
    view = useViewContext(),
    breakpointProps = useBreakpoint(props).breakpointProps,
    columnsConfig = _mapInstanceProperty(_context = breakpointProps.columnsConfig || []).call(_context, toConfigurationItem),
    contextValue = useMemo(function () {
      return _objectSpread(_objectSpread({}, breakpointProps), {}, {
        columnsConfig: columnsConfig,
        columnsConfigEnabled: !0
      });
    }, [breakpointProps.columnsConfig, breakpointProps.onColumnsConfigChange, breakpointProps.modalAvailable, breakpointProps.modalTriggerIcon, breakpointProps.renderModalTrigger, breakpointProps.showHeaders, breakpointProps.itemActionsPosition]);
  return React.createElement(ColumnsConfigContext.Provider, {
    value: contextValue
  }, view);
};
var basePropTypes = {
    columnsConfig: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({
      id: PropTypes.string.isRequired,
      visible: PropTypes.bool,
      width: PropTypes.string
    }), PropTypes.string]).isRequired),
    onColumnsConfigChange: PropTypes.func,
    modalAvailable: PropTypes.bool,
    modalTriggerIcon: PropTypes.string,
    showHeaders: PropTypes.bool,
    itemActionsPosition: PropTypes.oneOf(["bottom", "right"])
  },
  propTypes = _objectSpread(_objectSpread({}, basePropTypes), {}, {
    phone: PropTypes.shape(basePropTypes),
    phoneWide: PropTypes.shape(basePropTypes),
    tablet: PropTypes.shape(basePropTypes)
  });
DataViewColumnsConfig.propTypes = propTypes, DataViewColumnsConfig.defaultProps = defaultProps, DataViewColumnsConfig.__docgenInfo = {
  description: "Component used to configure columns in DataView (visibility, width, order).\nThe component operates only in controlled mode. Can be used with useColumnsConfig hook.",
  methods: [],
  displayName: "DataViewColumnsConfig",
  props: {
    showHeaders: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "Defaults to true. If false, disable the rendering for the data headers inside each item (applicable in ListView)."
    },
    modalTriggerIcon: {
      defaultValue: {
        value: "'gw-settings'",
        computed: !1
      },
      type: {
        name: "string"
      },
      required: !1,
      description: "Icon used in the column config modal trigger button"
    },
    itemActionsPosition: {
      defaultValue: {
        value: "'right'",
        computed: !1
      },
      type: {
        name: "enum",
        value: [{
          value: "'bottom'",
          computed: !1
        }, {
          value: "'right'",
          computed: !1
        }]
      },
      required: !1,
      description: "Defines the placement for the item action container. Defaults to right (applicable in ListView)."
    },
    columnsConfig: {
      type: {
        name: "custom",
        raw: "columnsConfigArrayPropType"
      },
      required: !1,
      description: "Array of column configuration items"
    },
    onColumnsConfigChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Function called on every column config change, the arguments are: new column config and column ids"
    },
    modalAvailable: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, default column config modal is available"
    },
    phone: {
      type: {
        name: "shape",
        value: {
          columnsConfig: {
            name: "custom",
            raw: "columnsConfigArrayPropType",
            description: "Array of column configuration items",
            required: !1
          },
          onColumnsConfigChange: {
            name: "func",
            description: "Function called on every column config change, the arguments are: new column config and column ids",
            required: !1
          },
          modalAvailable: {
            name: "bool",
            description: "If true, default column config modal is available",
            required: !1
          },
          modalTriggerIcon: {
            name: "string",
            description: "Icon used in the column config modal trigger button",
            required: !1
          },
          showHeaders: {
            name: "bool",
            description: "Defaults to true. If false, disable the rendering for the data headers inside each item (applicable in ListView).",
            required: !1
          },
          itemActionsPosition: {
            name: "enum",
            value: [{
              value: "'bottom'",
              computed: !1
            }, {
              value: "'right'",
              computed: !1
            }],
            description: "Defines the placement for the item action container. Defaults to right (applicable in ListView).",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          columnsConfig: {
            name: "custom",
            raw: "columnsConfigArrayPropType",
            description: "Array of column configuration items",
            required: !1
          },
          onColumnsConfigChange: {
            name: "func",
            description: "Function called on every column config change, the arguments are: new column config and column ids",
            required: !1
          },
          modalAvailable: {
            name: "bool",
            description: "If true, default column config modal is available",
            required: !1
          },
          modalTriggerIcon: {
            name: "string",
            description: "Icon used in the column config modal trigger button",
            required: !1
          },
          showHeaders: {
            name: "bool",
            description: "Defaults to true. If false, disable the rendering for the data headers inside each item (applicable in ListView).",
            required: !1
          },
          itemActionsPosition: {
            name: "enum",
            value: [{
              value: "'bottom'",
              computed: !1
            }, {
              value: "'right'",
              computed: !1
            }],
            description: "Defines the placement for the item action container. Defaults to right (applicable in ListView).",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          columnsConfig: {
            name: "custom",
            raw: "columnsConfigArrayPropType",
            description: "Array of column configuration items",
            required: !1
          },
          onColumnsConfigChange: {
            name: "func",
            description: "Function called on every column config change, the arguments are: new column config and column ids",
            required: !1
          },
          modalAvailable: {
            name: "bool",
            description: "If true, default column config modal is available",
            required: !1
          },
          modalTriggerIcon: {
            name: "string",
            description: "Icon used in the column config modal trigger button",
            required: !1
          },
          showHeaders: {
            name: "bool",
            description: "Defaults to true. If false, disable the rendering for the data headers inside each item (applicable in ListView).",
            required: !1
          },
          itemActionsPosition: {
            name: "enum",
            value: [{
              value: "'bottom'",
              computed: !1
            }, {
              value: "'right'",
              computed: !1
            }],
            description: "Defines the placement for the item action container. Defaults to right (applicable in ListView).",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
}, DataViewColumnsConfig.__docgenInfo = {
  componentName: "DataViewColumnsConfig",
  packageName: "@jutro/lab-preview-dataview",
  description: "Component used to configure columns in DataView (visibility, width, order).\nThe component operates only in controlled mode. Can be used with useColumnsConfig hook.",
  displayName: "DataViewColumnsConfig",
  methods: [],
  actualName: "DataViewColumnsConfig",
  props: {
    columnsConfig: {
      type: {
        name: "custom",
        raw: "columnsConfigArrayPropType"
      },
      required: !1,
      description: "Array of column configuration items"
    },
    onColumnsConfigChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Function called on every column config change, the arguments are: new column config and column ids"
    },
    modalAvailable: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, default column config modal is available"
    },
    modalTriggerIcon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Icon used in the column config modal trigger button",
      defaultValue: {
        value: "'gw-settings'",
        computed: !1
      }
    },
    showHeaders: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Defaults to true. If false, disable the rendering for the data headers inside each item (applicable in ListView).",
      defaultValue: {
        value: "true",
        computed: !1
      }
    },
    itemActionsPosition: {
      type: {
        name: "enum",
        value: [{
          value: "'bottom'",
          computed: !1
        }, {
          value: "'right'",
          computed: !1
        }]
      },
      required: !1,
      description: "Defines the placement for the item action container. Defaults to right (applicable in ListView).",
      defaultValue: {
        value: "'right'",
        computed: !1
      }
    },
    phone: {
      type: {
        name: "shape",
        value: {
          columnsConfig: {
            name: "custom",
            raw: "columnsConfigArrayPropType",
            description: "Array of column configuration items",
            required: !1
          },
          onColumnsConfigChange: {
            name: "func",
            description: "Function called on every column config change, the arguments are: new column config and column ids",
            required: !1
          },
          modalAvailable: {
            name: "bool",
            description: "If true, default column config modal is available",
            required: !1
          },
          modalTriggerIcon: {
            name: "string",
            description: "Icon used in the column config modal trigger button",
            required: !1
          },
          showHeaders: {
            name: "bool",
            description: "Defaults to true. If false, disable the rendering for the data headers inside each item (applicable in ListView).",
            required: !1
          },
          itemActionsPosition: {
            name: "enum",
            value: [{
              value: "'bottom'",
              computed: !1
            }, {
              value: "'right'",
              computed: !1
            }],
            description: "Defines the placement for the item action container. Defaults to right (applicable in ListView).",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          columnsConfig: {
            name: "custom",
            raw: "columnsConfigArrayPropType",
            description: "Array of column configuration items",
            required: !1
          },
          onColumnsConfigChange: {
            name: "func",
            description: "Function called on every column config change, the arguments are: new column config and column ids",
            required: !1
          },
          modalAvailable: {
            name: "bool",
            description: "If true, default column config modal is available",
            required: !1
          },
          modalTriggerIcon: {
            name: "string",
            description: "Icon used in the column config modal trigger button",
            required: !1
          },
          showHeaders: {
            name: "bool",
            description: "Defaults to true. If false, disable the rendering for the data headers inside each item (applicable in ListView).",
            required: !1
          },
          itemActionsPosition: {
            name: "enum",
            value: [{
              value: "'bottom'",
              computed: !1
            }, {
              value: "'right'",
              computed: !1
            }],
            description: "Defines the placement for the item action container. Defaults to right (applicable in ListView).",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          columnsConfig: {
            name: "custom",
            raw: "columnsConfigArrayPropType",
            description: "Array of column configuration items",
            required: !1
          },
          onColumnsConfigChange: {
            name: "func",
            description: "Function called on every column config change, the arguments are: new column config and column ids",
            required: !1
          },
          modalAvailable: {
            name: "bool",
            description: "If true, default column config modal is available",
            required: !1
          },
          modalTriggerIcon: {
            name: "string",
            description: "Icon used in the column config modal trigger button",
            required: !1
          },
          showHeaders: {
            name: "bool",
            description: "Defaults to true. If false, disable the rendering for the data headers inside each item (applicable in ListView).",
            required: !1
          },
          itemActionsPosition: {
            name: "enum",
            value: [{
              value: "'bottom'",
              computed: !1
            }, {
              value: "'right'",
              computed: !1
            }],
            description: "Defines the placement for the item action container. Defaults to right (applicable in ListView).",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
};