import { defineMessages } from "react-intl";
export var messages = defineMessages({
  checkboxAriaLabel: {
    id: "jutro-datatable.ColumnsConfigModal.checkboxAriaLabel",
    defaultMessage: "{column} column visible"
  },
  moveColumnLeft: {
    id: "jutro-datatable.ColumnsConfigModal.MoveColumnLeft",
    defaultMessage: "Move column {column} left"
  },
  moveColumnRight: {
    id: "jutro-datatable.ColumnsConfigModal.MoveColumnRight",
    defaultMessage: "Move column {column} right"
  }
});