import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
export var determineSelectionState = function (_ref) {
  var _ref$rowIds = _ref.rowIds,
    rowIds = void 0 === _ref$rowIds ? [] : _ref$rowIds,
    _ref$selectedRows = _ref.selectedRows,
    selectedRows = void 0 === _ref$selectedRows ? [] : _ref$selectedRows;
  return selectedRows.length > 0 && rowIds.length > 0 && rowIds.every(function (rowId) {
    return _includesInstanceProperty(selectedRows).call(selectedRows, rowId);
  }) ? "all" : selectedRows.length > 0 && rowIds.some(function (rowId) {
    return _includesInstanceProperty(selectedRows).call(selectedRows, rowId);
  }) ? "some" : "none";
};