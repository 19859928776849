import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import React from "react";
import { useColumnOptions } from "../../hooks/useColumnOptions";
import { getOverlayState, StateOverlay as _StateOverlay } from "./StateOverlay";
import { usePaginationContext } from "../pagination";
import { useOverlayContext } from "./DataViewOverlay";
export var TableStateOverlay = function (_ref) {
  var data = _ref.data,
    _useColumnOptions = useColumnOptions(),
    columnOptions = _slicedToArray(_useColumnOptions, 1)[0];
  return React.createElement("tr", null, React.createElement("td", {
    colSpan: columnOptions.length,
    "data-testid": "table-view-overlay"
  }, React.createElement(_StateOverlay, {
    data: data
  })));
};
export var useStateOverlay = function (data) {
  var paginationType = usePaginationContext().paginationType,
    _useOverlayContext = useOverlayContext(),
    isError = _useOverlayContext.isError,
    isLoading = _useOverlayContext.isLoading,
    overlayState = getOverlayState({
      isLoading: isLoading,
      isError: isError,
      data: data
    });
  return {
    shouldRenderOverlay: "loadMore" !== paginationType && !!overlayState,
    StateOverlay: function () {
      return React.createElement(_StateOverlay, {
        data: data
      });
    }
  };
};
export var useTableStateOverlay = function (data) {
  return {
    shouldRenderOverlay: useStateOverlay(data).shouldRenderOverlay,
    StateOverlay: function () {
      return React.createElement(TableStateOverlay, {
        data: data
      });
    }
  };
};
TableStateOverlay.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "TableStateOverlay"
}, TableStateOverlay.__docgenInfo = {
  componentName: "TableStateOverlay",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "TableStateOverlay",
  methods: [],
  actualName: "TableStateOverlay"
};