import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _Promise from "@babel/runtime-corejs3/core-js-stable/promise";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import isArray from "lodash/isArray";
import cx from "classnames";
import { isPromise, uniqueInnerId } from "@jutro/platform";
import { useTranslator } from "@jutro/locale";
import { intlMessageShape } from "@jutro/prop-types";
import { Icon } from "../../Icon/Icon";
import { LoadingIcon } from "./LoadingIcon";
import { messages } from "./InlineLoader.messages";
import styles from "./InlineLoader.module.css";
export var InlineLoader = function (props) {
  var _ref = props,
    className = _ref.className,
    loading = _ref.loading,
    loadingIcon = _ref.loadingIcon,
    loadingMessage = _ref.loadingMessage,
    successIcon = _ref.successIcon,
    successMessage = _ref.successMessage,
    translator = useTranslator(),
    _useState = useState(!!loading),
    _useState2 = _slicedToArray(_useState, 2),
    isLoading = _useState2[0],
    setIsLoading = _useState2[1];
  useEffect(function () {
    if (isPromise(loading) || isArray(loading)) {
      var loaderPromise = isArray(loading) ? _Promise.all(loading) : loading;
      return setIsLoading(!0), void loaderPromise.then(function () {
        return setIsLoading(!1);
      });
    }
    setIsLoading(Boolean(loading));
  }, [loading]);
  var loaderMessageStyle,
    loaderStyle = cx(className, styles.inlineLoader),
    iconLabelId = uniqueInnerId("InlineLoader", "iconLabelId").iconLabelId;
  return React.createElement("div", {
    className: loaderStyle,
    role: "alert"
  }, isLoading ? (loaderMessageStyle = cx(_defineProperty({}, styles.withLoadingMessage, loadingMessage), styles.loaderIcon), React.createElement(React.Fragment, null, React.createElement(LoadingIcon, {
    className: loaderMessageStyle,
    icon: loadingIcon,
    "aria-labelledby": iconLabelId
  }), React.createElement("div", {
    id: iconLabelId,
    className: styles.screenReaderOnly
  }, translator(messages.loading)), loadingMessage && React.createElement("div", {
    className: styles.withMessage
  }, translator(loadingMessage)))) : successMessage && React.createElement(React.Fragment, null, React.createElement(Icon, {
    className: cx(styles.withSuccessMessage, styles.loaderIcon),
    icon: successIcon
  }), React.createElement("div", {
    className: styles.withMessage
  }, translator(successMessage))));
};
export var inlineLoaderPropTypes = {
  className: PropTypes.string,
  loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.instanceOf(_Promise)), PropTypes.instanceOf(_Promise)]),
  loadingIcon: PropTypes.string,
  loadingMessage: intlMessageShape,
  successIcon: PropTypes.string,
  successMessage: intlMessageShape
};
InlineLoader.propTypes = inlineLoaderPropTypes, InlineLoader.defaultProps = {
  successIcon: "gw-check"
}, InlineLoader.__docgenInfo = {
  description: "InlineLoader\n\n@metadataType element",
  methods: [],
  displayName: "InlineLoader",
  props: {
    successIcon: {
      defaultValue: {
        value: "'gw-check'",
        computed: !1
      },
      type: {
        name: "string"
      },
      required: !1,
      description: "Icon name to be shown when execution trigger/promise is successfully resolved"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    loading: {
      type: {
        name: "union",
        value: [{
          name: "bool"
        }, {
          name: "arrayOf",
          value: {
            name: "instanceOf",
            value: "Promise"
          }
        }, {
          name: "instanceOf",
          value: "Promise"
        }]
      },
      required: !1,
      description: "Controls whether the Inline Loader is shown;\nFor boolean values, if `true` is passed then the Inline Loader is shown;\nFor Promise value(s), the Inline Loader will be shown until the promise has completed."
    },
    loadingIcon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Icon name to be used when executing trigger/promise"
    },
    loadingMessage: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "The message shown when executing trigger/promise"
    },
    successMessage: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "The message shown when execution trigger/promise is successfully done"
    }
  }
}, InlineLoader.__docgenInfo = {
  componentName: "InlineLoader",
  packageName: "@jutro/components",
  description: "InlineLoader",
  displayName: "InlineLoader",
  methods: [],
  actualName: "InlineLoader",
  metadataType: "element",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    loading: {
      type: {
        name: "union",
        value: [{
          name: "bool"
        }, {
          name: "arrayOf",
          value: {
            name: "instanceOf",
            value: "Promise"
          }
        }, {
          name: "instanceOf",
          value: "Promise"
        }]
      },
      required: !1,
      description: "Controls whether the Inline Loader is shown;\nFor boolean values, if `true` is passed then the Inline Loader is shown;\nFor Promise value(s), the Inline Loader will be shown until the promise has completed."
    },
    loadingIcon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Icon name to be used when executing trigger/promise"
    },
    loadingMessage: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "The message shown when executing trigger/promise"
    },
    successIcon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Icon name to be shown when execution trigger/promise is successfully resolved",
      defaultValue: {
        value: "'gw-check'",
        computed: !1
      }
    },
    successMessage: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "The message shown when execution trigger/promise is successfully done"
    }
  }
};