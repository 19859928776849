import { defineMessages } from "react-intl";
export var messages = defineMessages({
  loading: {
    id: "jutro-dataview.pagination.loading",
    defaultMessage: "Loading data"
  },
  loadMore: {
    id: "jutro-dataview.pagination.loadMore",
    defaultMessage: "Load more"
  },
  loadMoreLabel: {
    id: "jutro-dataview.pagination.loadMoreLabel",
    defaultMessage: "Load next {pageSize} rows"
  }
});