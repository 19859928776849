import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["value", "columnProps", "translator", "cellId"];
import React from "react";
import cx from "classnames";
import { dataTypeShapeWithDateTime } from "@jutro/prop-types";
import { DateTimeField, DateValue } from "@jutro/components";
import { getCommonEditProps } from "../editing/getCommonEditProps";
import { allowedDateTimeFields } from "../../types";
import { GenericColumn, DefaultCell, createColumnPropTypes } from "./GenericColumn";
import styles from "./Columns.module.css";
var DateTimeColumnCell = function (_ref) {
    var _props$classNames,
      value = _ref.value,
      props = _ref.columnProps,
      translator = _ref.translator,
      cellId = _ref.cellId,
      other = _objectWithoutProperties(_ref, _excluded);
    return value ? React.createElement(DateValue, _extends({
      value: value,
      displayFormat: "short",
      inputFormat: props.showTime ? "Mdyhm" : "Mdy"
    }, props, {
      id: cellId,
      className: cx(styles.dateTimeCell, null === (_props$classNames = props.classNames) || void 0 === _props$classNames ? void 0 : _props$classNames.cellContent)
    })) : React.createElement(DefaultCell, _extends({
      cellId: cellId,
      value: value,
      columnProps: props,
      translator: translator
    }, other));
  },
  DateTimeEditCell = function (props) {
    return React.createElement(DateTimeField, getCommonEditProps(props));
  };
export var DateTimeColumn = function (props) {
  return React.createElement(GenericColumn, _extends({}, props, {
    renderCell: DateTimeColumnCell,
    renderEditCell: DateTimeEditCell,
    columnDataType: "datetime"
  }));
};
var dateTimeColumnPropTypes = createColumnPropTypes(DateTimeField.propTypes, allowedDateTimeFields, [], {
  dataType: dataTypeShapeWithDateTime
});
DateTimeColumn.propTypes = dateTimeColumnPropTypes, DateTimeColumn.displayName = "DateTimeColumn", DateTimeColumn.defaultProps = {
  showTime: !0
}, DateTimeColumn.__docgenInfo = {
  description: "Column for datetime values\n\n@metadataType field",
  methods: [],
  displayName: "DateTimeColumn",
  props: {
    showTime: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      required: !1
    }
  },
  composes: ["./GenericColumn"]
}, DateTimeColumnCell.__docgenInfo = {
  componentName: "DateTimeColumnCell",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "DateTimeColumnCell",
  methods: [],
  actualName: "DateTimeColumnCell"
}, DateTimeEditCell.__docgenInfo = {
  componentName: "DateTimeEditCell",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "DateTimeEditCell",
  methods: [],
  actualName: "DateTimeEditCell"
}, DateTimeColumn.__docgenInfo = {
  componentName: "DateTimeColumn",
  packageName: "@jutro/lab-preview-dataview",
  description: "Column for datetime values",
  displayName: "DateTimeColumn",
  methods: [],
  actualName: "DateTimeColumn",
  metadataType: "field",
  props: {
    showTime: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      required: !1
    }
  },
  composes: ["./GenericColumn"]
};