import { defineMessages } from "react-intl";
export var messages = defineMessages({
  title: {
    id: "jutro-dataview.filtering.title.filters",
    defaultMessage: "Filters"
  },
  clearFilters: {
    id: "jutro-dataview.filtering.clearFiltersButton.clearFilters",
    defaultMessage: "Clear filters"
  },
  applyFilters: {
    id: "jutro-dataview.filtering.applyFiltersButton.apply",
    defaultMessage: "Apply"
  },
  applyFiltersButtonAriaLabel: {
    id: "jutro-dataview.filtering.applyFiltersButton-ariaLabel.applyFilters",
    defaultMessage: "Apply filters"
  },
  cancelFiltersButtonLabel: {
    id: "jutro-dataview.filtering.cancelFiltersButton.cancel",
    defaultMessage: "Cancel"
  },
  cancelFiltersButtonAriaLabel: {
    id: "jutro-dataview.filtering.cancelFiltersButton-ariaLabel.cancelFilters",
    defaultMessage: "Cancel filters"
  },
  closeModalAriaLabel: {
    id: "jutro-dataview.filtering.filteringModal.closeButton-ariaLabel.closeModal",
    defaultMessage: "Close modal"
  },
  containerAriaLabel: {
    id: "jutro-dataview.filtering.filterbar.container-ariaLabel",
    defaultMessage: "{tableTitle} filterbar"
  }
});