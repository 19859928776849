import { defineMessages } from "react-intl";
export var messages = defineMessages({
  searchInputPlaceholder: {
    id: "jutro-dataview.TableView.searchInputPlaceholder",
    defaultMessage: "Search table"
  },
  searchInputLabel: {
    id: "jutro-dataview.TableView.searchInputLabel",
    defaultMessage: "Search table - entering text will update the table data below"
  }
});