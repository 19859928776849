import _asyncToGenerator from "@babel/runtime-corejs3/helpers/asyncToGenerator";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _regeneratorRuntime from "@babel/runtime-corejs3/regenerator";
import _Array$from3 from "@babel/runtime-corejs3/core-js-stable/array/from";
import React, { useRef, useCallback, useEffect, useState, useContext } from "react";
import { ModalNextContext } from "@jutro/components";
import { KEEP_CHANGES, DISCARD_CHANGES } from "../types";
import { ConfirmationModal } from "../ConfirmationModal";
import { useQuickViewInternal } from "../QuickViewProvider";
import { focusableElementsSelector } from "../utils/a11y.utils";
export var useVisibilityObserver = function (id, showExternal, setShowExternal, confirmClose, confirmationModalMessages) {
  var _useState = useState(!1),
    _useState2 = _slicedToArray(_useState, 2),
    showInternal = _useState2[0],
    setShowInternal = _useState2[1],
    previousFocus = useRef(null),
    showModal = useContext(ModalNextContext).showModal,
    _useQuickViewInternal = useQuickViewInternal(),
    close = _useQuickViewInternal.close,
    open = _useQuickViewInternal.open,
    register = _useQuickViewInternal.register,
    unregister = _useQuickViewInternal.unregister,
    focusPreviousElement = useCallback(function () {
      var _previousFocus$curren,
        dialogElement = document.getElementById(id);
      if (null != previousFocus && null !== (_previousFocus$curren = previousFocus.current) && void 0 !== _previousFocus$curren && _previousFocus$curren.matches(focusableElementsSelector) && (null == previousFocus ? void 0 : previousFocus.current) !== dialogElement) previousFocus.current.focus();else {
        var _Array$from = _Array$from3(document.body.querySelectorAll(focusableElementsSelector)),
          firstFocusableElementInBody = _slicedToArray(_Array$from, 1)[0];
        null == firstFocusableElementInBody || firstFocusableElementInBody.focus();
      }
    }, [previousFocus, id]),
    onClose = useCallback(_asyncToGenerator(_regeneratorRuntime.mark(function _callee() {
      var ignoreConfirmation,
        _ref2,
        title,
        notificationMessage,
        discardChangesMessage,
        keepChangesMessage,
        _args = arguments;
      return _regeneratorRuntime.wrap(function (_context) {
        for (;;) switch (_context.prev = _context.next) {
          case 0:
            if ((ignoreConfirmation = _args.length > 0 && void 0 !== _args[0] && _args[0]) || !confirmClose) {
              _context.next = 8;
              break;
            }
            return title = (_ref2 = null != confirmationModalMessages ? confirmationModalMessages : {}).title, notificationMessage = _ref2.notification, discardChangesMessage = _ref2.discardChanges, keepChangesMessage = _ref2.keepChanges, _context.next = 5, showModal(React.createElement(ConfirmationModal, {
              title: title,
              notificationMessage: notificationMessage,
              discardChangesMessage: discardChangesMessage,
              keepChangesMessage: keepChangesMessage
            }));
          case 5:
            if (_context.sent !== KEEP_CHANGES) {
              _context.next = 8;
              break;
            }
            return _context.abrupt("return", {
              shouldClose: !1
            });
          case 8:
            return setShowInternal(!1), _context.abrupt("return", {
              shouldClose: !0,
              afterClose: function () {
                var _previousFocus$curren2;
                setShowExternal({
                  show: !1,
                  actionType: ignoreConfirmation ? KEEP_CHANGES : DISCARD_CHANGES
                }), null == previousFocus || null === (_previousFocus$curren2 = previousFocus.current) || void 0 === _previousFocus$curren2 || _previousFocus$curren2.focus();
              }
            });
          case 10:
          case "end":
            return _context.stop();
        }
      }, _callee);
    })), [setShowExternal, confirmClose, showModal]),
    onOpen = useCallback(function (stateToUpdate) {
      "internal" === stateToUpdate ? setShowInternal(!0) : setShowExternal({
        show: !0
      });
    }, [setShowExternal]);
  useEffect(function () {
    register({
      id: id,
      show: showInternal,
      onClose: onClose,
      onOpen: onOpen
    });
  }, [id, onOpen, onClose, register]), useEffect(function () {
    return function () {
      return unregister(id);
    };
  }, [id, unregister]), useEffect(function () {
    showExternal !== showInternal && (showExternal ? open(id) : close(id));
  }, [id, showExternal, open, close]);
  return {
    show: showInternal,
    onClose: function (ignoreConfirmation) {
      close(id, ignoreConfirmation);
    },
    setPreviousFocus: function (element) {
      previousFocus.current = element;
    },
    focusPreviousElement: focusPreviousElement
  };
};