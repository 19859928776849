import "core-js/modules/web.dom-collections.iterator";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _Promise from "@babel/runtime-corejs3/core-js-stable/promise";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { isPromise } from "@jutro/platform";
import { intlMessageShape } from "@jutro/prop-types";
import { useTranslator } from "@jutro/locale";
import styles from "./Loader.module.css";
import { BrandedLoader } from "./BrandedLoader";
var isLoadingActive = function (loaded) {
  return "boolean" == typeof loaded || void 0 === loaded ? !loaded : Array.isArray(loaded) || isPromise(loaded);
};
export var Loader = function (_ref) {
  var loaded = _ref.loaded,
    text = _ref.text,
    children = _ref.children,
    className = _ref.className,
    _ref$renderLoader = _ref.renderLoader,
    renderLoader = void 0 === _ref$renderLoader ? function () {
      return React.createElement(BrandedLoader, null);
    } : _ref$renderLoader,
    _useState = useState(isLoadingActive(loaded)),
    _useState2 = _slicedToArray(_useState, 2),
    isloaderActive = _useState2[0],
    setIsLoaderActive = _useState2[1];
  useEffect(function () {
    var isActive = !0;
    isLoadingActive(loaded) ? (setIsLoaderActive(!0), (isPromise(loaded) || Array.isArray(loaded)) && (Array.isArray(loaded) ? _Promise.all(loaded) : loaded).then(function () {
      return isActive && setIsLoaderActive(!1);
    }).catch(function () {
      return isActive && setIsLoaderActive(!1);
    })) : setIsLoaderActive(!1);
    return function () {
      isActive = !1;
    };
  }, [loaded]);
  var classes = cx(_defineProperty({}, styles.loader, isloaderActive), className),
    translator = useTranslator(),
    loaderText = text ? React.createElement("div", {
      className: styles.text
    }, translator(text)) : null;
  return React.createElement("div", {
    className: classes
  }, isloaderActive ? React.createElement("span", {
    role: "alert",
    className: styles.loaderAlertWrapper
  }, renderLoader(), loaderText) : children);
};
Loader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  loaded: PropTypes.oneOfType([PropTypes.bool, PropTypes.array, PropTypes.instanceOf(_Promise)]),
  text: PropTypes.oneOfType([PropTypes.string, intlMessageShape]),
  renderLoader: PropTypes.func
}, Loader.displayName = "Loader", Loader.__docgenInfo = {
  description: "Renders an animated component that you can show when loading some other content.\n\n@typedef {typeof Loader.propTypes} LoaderPropTypes\n@extends Component<PropTypes.InferProps<LoaderPropTypes>>\n\n@metadataType element",
  methods: [],
  displayName: "Loader",
  props: {
    renderLoader: {
      defaultValue: {
        value: "() => <BrandedLoader />",
        computed: !1
      },
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to render loader"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "The children elements to render inside of this component"
    },
    loaded: {
      type: {
        name: "union",
        value: [{
          name: "bool"
        }, {
          name: "array"
        }, {
          name: "instanceOf",
          value: "Promise"
        }]
      },
      required: !1,
      description: "Controls whether the Loader UI is shown vs the `children`;\n     For boolean values, if `false`, the Loader UI is displayed. Otherwise the `children` are displayed; For\n     Promise value(s), the Loader UI is displayed until the promise has completed, then the `children` are displayed."
    },
    text: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "custom",
          raw: "intlMessageShape"
        }]
      },
      required: !1,
      description: "Optional message text to display when the Loader UI is shown"
    }
  }
}, Loader.__docgenInfo = {
  componentName: "Loader",
  packageName: "@jutro/components",
  description: "Renders an animated component that you can show when loading some other content.",
  displayName: "Loader",
  methods: [],
  actualName: "Loader",
  metadataType: "element",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "The children elements to render inside of this component"
    },
    loaded: {
      type: {
        name: "union",
        value: [{
          name: "bool"
        }, {
          name: "array"
        }, {
          name: "instanceOf",
          value: "Promise"
        }]
      },
      required: !1,
      description: "Controls whether the Loader UI is shown vs the `children`;\n     For boolean values, if `false`, the Loader UI is displayed. Otherwise the `children` are displayed; For\n     Promise value(s), the Loader UI is displayed until the promise has completed, then the `children` are displayed."
    },
    text: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "shape",
            value: {
              id: {
                name: "string",
                required: !1
              },
              defaultMessage: {
                name: "string",
                required: !1
              },
              args: {
                name: "shape",
                value: {},
                required: !1
              }
            }
          }]
        }]
      },
      required: !1,
      description: "Optional message text to display when the Loader UI is shown"
    },
    renderLoader: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to render loader",
      defaultValue: {
        value: "() => React.createElement(BrandedLoader, null)",
        computed: !1
      }
    }
  }
};