import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
import React, { createContext, useContext, useRef, useMemo } from "react";
import useMeasure from "react-use-measure";
import { useDynamicStyles } from "./hooks/useDynamicStyles";
import { useVisibilityManager } from "./hooks/useVisibilityManager";
export var QuickViewContainerContext = createContext(null);
export var QuickViewContext = createContext(null);
export var QuickViewProvider = function (_ref) {
  var children = _ref.children,
    _useMeasure = useMeasure({
      scroll: !0
    }),
    _useMeasure2 = _slicedToArray(_useMeasure, 2),
    measureContainer = _useMeasure2[0],
    containerBounds = _useMeasure2[1],
    containerRef = useRef(null),
    manager = useVisibilityManager(),
    styles = useDynamicStyles(containerBounds),
    quickViewContext = useMemo(function () {
      return function (target) {
        for (var i = 1; i < arguments.length; i++) {
          var source = null != arguments[i] ? arguments[i] : {};
          i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
            _defineProperty(target, key, source[key]);
          }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
            _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
          });
        }
        return target;
      }({
        containerRef: containerRef,
        styles: styles
      }, manager);
    }, [styles, manager]),
    quickViewContainerContext = useMemo(function () {
      return function (element) {
        measureContainer(element), containerRef.current = element;
      };
    }, []);
  return React.createElement(QuickViewContainerContext.Provider, {
    value: quickViewContainerContext
  }, React.createElement(QuickViewContext.Provider, {
    value: quickViewContext
  }, children));
};
export var useQuickViewInternal = function () {
  var contextValue = useContext(QuickViewContext);
  if (!contextValue) throw new Error("Please check that your app is wrapped in QuickViewProvider");
  return contextValue;
};
export var useQuickViewContainer = function () {
  var contextValue = useContext(QuickViewContainerContext);
  if (!contextValue) throw new Error("Please check that your app is wrapped in QuickViewProvider");
  return contextValue;
};
QuickViewProvider.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "QuickViewProvider"
}, QuickViewProvider.__docgenInfo = {
  componentName: "QuickViewProvider",
  packageName: "@jutro/quick-view",
  description: "",
  displayName: "QuickViewProvider",
  methods: [],
  actualName: "QuickViewProvider"
};