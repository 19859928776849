import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import React from "react";
import cx from "classnames";
import { Icon, InlineLabel } from "@jutro/components";
import styles from "./SortingTrigger.module.css";
export var SortingTrigger = function (_ref) {
  var isSortable = _ref.isSortable,
    isSorted = _ref.isSorted,
    sortedDesc = _ref.sortedDesc,
    children = _ref.children,
    icon = isSortable ? React.createElement(Icon, {
      icon: isSorted && !sortedDesc ? "gw-arrow-drop-up" : "gw-arrow-drop-down",
      size: "large",
      className: cx(styles.icon, _defineProperty({}, styles.inactive, !isSorted))
    }) : null;
  return React.createElement(InlineLabel, {
    icon: icon,
    iconPosition: "right"
  }, children);
};
SortingTrigger.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "SortingTrigger"
}, SortingTrigger.__docgenInfo = {
  componentName: "SortingTrigger",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "SortingTrigger",
  methods: [],
  actualName: "SortingTrigger"
};