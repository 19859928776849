import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _extends from "@babel/runtime-corejs3/helpers/extends";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import React from "react";
import omit from "lodash/fp/omit";
import PropTypes from "prop-types";
import { TableColumn } from "@jutro/datatable";
import { useBreakpoint } from "@jutro/layout";
import { useDeprecationWarning } from "@jutro/platform";
import { warning } from "@jutro/logger";
import { GenericColumn } from "../../features/columns";
import { useMappedProps } from "./useMappedProps/useMappedProps";
import { useUnsupportedPropsWarning } from "../useUnsupportedPropsWarning";
var tableColumnPropsKeys = _Object$keys(TableColumn.propTypes);
export var TableColumnAdapter = function (props) {
  useDeprecationWarning("TableColumnAdapter", "GenericColumn (or other column types)", "Refer to the documentation for the details", "9.0.0");
  var breakpointProps = useBreakpoint(props).breakpointProps;
  useUnsupportedPropsWarning(breakpointProps);
  var genericColumnProps = useMappedProps(breakpointProps).props;
  return React.createElement(GenericColumn, _extends({
    shouldTranslateValue: !0
  }, genericColumnProps));
};
var tableColumnAdapterPropsTypes = _objectSpread(_objectSpread(_objectSpread({}, omit(tableColumnPropsKeys, GenericColumn.propTypes)), TableColumn.propTypes), {}, {
  phone: PropTypes.object,
  phoneWide: PropTypes.object,
  tablet: PropTypes.object
});
TableColumnAdapter.propTypes = tableColumnAdapterPropsTypes, TableColumnAdapter.displayName = "TableColumnAdapter", TableColumnAdapter.renderHeader = function () {
  return warning("Static method 'renderHeader' is not available in columns. Use renderHeader prop instead.");
}, TableColumnAdapter.renderCell = function () {
  return warning("Static method 'renderCell' is not available in data view columns. Use renderCell prop instead.");
}, TableColumnAdapter.__docgenInfo = {
  description: "Maps a part of TableColumn props to data view GenericColumn\n@deprecated",
  methods: [{
    name: "renderHeader",
    docblock: null,
    modifiers: ["static"],
    params: [],
    returns: null
  }, {
    name: "renderCell",
    docblock: null,
    modifiers: ["static"],
    params: [],
    returns: null
  }],
  displayName: "TableColumnAdapter",
  props: {
    phone: {
      type: {
        name: "object"
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "object"
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "object"
      },
      required: !1,
      description: ""
    }
  },
  composes: ["lodash/fp", "@jutro/datatable"]
}, TableColumnAdapter.__docgenInfo = {
  componentName: "TableColumnAdapter",
  packageName: "@jutro/lab-preview-dataview",
  description: "Maps a part of TableColumn props to data view GenericColumn",
  displayName: "TableColumnAdapter",
  methods: [{
    name: "renderHeader",
    docblock: null,
    modifiers: ["static"],
    params: [],
    returns: null
  }, {
    name: "renderCell",
    docblock: null,
    modifiers: ["static"],
    params: [],
    returns: null
  }],
  actualName: "TableColumnAdapter",
  props: {
    phone: {
      type: {
        name: "object"
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "object"
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "object"
      },
      required: !1,
      description: ""
    }
  },
  composes: ["lodash/fp", "@jutro/datatable"]
};