import _Set from "@babel/runtime-corejs3/core-js-stable/set";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import { useMemo } from "react";
import { uniqueInnerId } from "@jutro/platform";
import { warning } from "@jutro/logger";
import { createUseMappedProps, excludeNull } from "../../mappers";
var loggedMessages = new _Set();
export var useMappedActionItemProps = createUseMappedProps({
  id: function (_ref) {
    var id = _ref.id,
      label = _ref.label,
      tooltip = _ref.tooltip,
      rowId = _ref.rowId;
    return useMemo(function () {
      if (!id) {
        var _context,
          message = "ActionItemAdapter with label ".concat(label || (null == tooltip ? void 0 : tooltip.content), " does not have an 'id' prop, but it is required in DataView row actions.");
        return loggedMessages.has(message) || (loggedMessages.add(message), warning(message)), _concatInstanceProperty(_context = "".concat(uniqueInnerId("actionId", "id").id, "_")).call(_context, rowId);
      }
      return id;
    }, [id]);
  },
  onClick: function (_ref2) {
    var onClick = _ref2.onClick,
      message = 'ActionItemAdapter expects that onClick callback takes two arguments "onClick(row, rowId)", after migration to Action the onClick callback will take object "onClick({ row, rowId })" as an argument.';
    return loggedMessages.has(message) || (loggedMessages.add(message), warning(message)), excludeNull(onClick);
  },
  label: function (_ref3) {
    var label = _ref3.label;
    return excludeNull(label);
  },
  wrapper: function (_ref4) {
    var singleActionType = _ref4.singleActionType;
    return excludeNull(singleActionType);
  },
  wrapperProps: function (_ref5) {
    var icon = _ref5.icon,
      tooltip = _ref5.tooltip,
      label = _ref5.label;
    return {
      icon: excludeNull(icon),
      tooltip: tooltip ? {
        text: tooltip.content || label,
        placement: tooltip.placement
      } : void 0
    };
  }
});