import _flatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/flat";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import React, { useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import { warning } from "@jutro/logger";
import { uniqueInnerId } from "@jutro/platform";
import { createUseMappedProps } from "../mappers";
var columnsConfigMapper = {
  columnsConfig: function (_ref) {
    var _context,
      _context2,
      _context3,
      children = _ref.children,
      columnsProportion = _ref.columnsProportion,
      columns = _flatInstanceProperty(_context = _mapInstanceProperty(_context2 = _filterInstanceProperty(_context3 = React.Children.toArray(children)).call(_context3, function (child) {
        return "DataViewColumns" === child.type.displayName;
      })).call(_context2, function (child) {
        return child.props.children;
      })).call(_context);
    return useEffect(function () {
      null != columnsProportion && columnsProportion.some(function (proportion) {
        return 0 !== proportion && 1 !== proportion && void 0 !== proportion;
      }) && warning("Values in `columnsProportion` other than 0, 1 or undefined are not supported by TableViewAdapter. Consult UX for alternative solutions."), isEmpty(columnsProportion) || (null == columnsProportion ? void 0 : columnsProportion.length) === columns.length || warning("Number of TableAdapter columns and columns proportion does not match");
    }, [columnsProportion, columns]), _mapInstanceProperty(columns).call(columns, function (column, i) {
      var _column$type,
        _ref2 = (null == column ? void 0 : column.props) || {},
        id = _ref2.id,
        visible = _ref2.visible,
        isRadioColumn = "RadioColumnAdapter" === (null == column || null === (_column$type = column.type) || void 0 === _column$type ? void 0 : _column$type.displayName);
      return {
        id: null != id ? id : uniqueInnerId("columnId", "id").id,
        visible: column && !1 !== visible && (!columnsProportion || 1 === columnsProportion[i]) && !isRadioColumn
      };
    });
  }
};
export var useMappedColumnsConfigProps = createUseMappedProps(columnsConfigMapper);