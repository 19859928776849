import { defineMessages } from "react-intl";
export var messages = defineMessages({
  closeModalAriaLabel: {
    id: "jutro-dataview.Editing.modal.closeModalAriaLabel",
    defaultMessage: "Exit editing"
  },
  save: {
    id: "jutro-dataview.Editing.modal.saveButtonLabel",
    defaultMessage: "Save"
  },
  saveAriaLabel: {
    id: "jutro-dataview.Editing.applyFilterButtonAriaLabel",
    defaultMessage: "Save changes"
  },
  cancel: {
    id: "jutro-dataview.Editing.modal.cancelButtonLabel",
    defaultMessage: "Cancel"
  },
  cancelArialabel: {
    id: "jutro-dataview.Editing.cancelFilterButtonAriaLabel",
    defaultMessage: "Cancel changes"
  }
});