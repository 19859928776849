import _extends from "@babel/runtime-corejs3/helpers/extends";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import React, { useMemo, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { InlineFiltersTrigger, InlineFilters } from "./InlineFilters";
import { FilteringModalTrigger } from "./FilteringModal";
import { useFilteringContext } from "./DataViewFiltering";
export var useFiltering = function (_ref) {
  var onBeforeFilter = _ref.onBeforeFilter,
    defaultView = _ref.defaultView,
    _useFilteringContext = useFilteringContext(),
    renderInlineFilters = _useFilteringContext.renderInlineFilters,
    CustomFiltersTrigger = _useFilteringContext.renderFiltersTrigger,
    uiProps = _useFilteringContext.uiProps,
    _useState = useState(!1),
    _useState2 = _slicedToArray(_useState, 2),
    areFiltersOpen = _useState2[0],
    setFiltersOpen = _useState2[1];
  return {
    FiltersComponent: useMemo(function () {
      if ("modal" !== defaultView || CustomFiltersTrigger || renderInlineFilters) return function (props) {
        return React.createElement(InlineFilters, _extends({
          isOpen: areFiltersOpen,
          renderInlineFilters: renderInlineFilters
        }, props));
      };
    }, [areFiltersOpen, defaultView, renderInlineFilters, CustomFiltersTrigger]),
    FiltersTriggerComponent: useMemo(function () {
      var onToggleFilters = function () {
        null == onBeforeFilter || onBeforeFilter(), setFiltersOpen(function (open) {
          return !open;
        });
      };
      if (CustomFiltersTrigger) return function (props) {
        return React.createElement(CustomFiltersTrigger, _extends({}, props, {
          onToggleFilters: onToggleFilters
        }));
      };
      if ("modal" === defaultView) {
        if (renderInlineFilters) return function (props) {
          return React.createElement(InlineFiltersTrigger, _extends({}, props, {
            onToggleFilters: onToggleFilters
          }));
        };
        if (!isEmpty(uiProps)) return FilteringModalTrigger;
      }
      return "inline" !== defaultView || isEmpty(uiProps) && !renderInlineFilters ? void 0 : function (props) {
        return React.createElement(InlineFiltersTrigger, _extends({}, props, {
          onToggleFilters: onToggleFilters
        }));
      };
    }, [CustomFiltersTrigger, defaultView, uiProps, renderInlineFilters, onBeforeFilter])
  };
};