import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
var _excluded = ["onSearchChange"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React, { useCallback, useMemo, useContext, createContext } from "react";
import noop from "lodash/noop";
import { useBreakpoint } from "@jutro/layout";
import { intlMessageShape } from "@jutro/prop-types";
import PropTypes from "prop-types";
import { useSelectionContext } from "../selection";
import { SearchInput } from "./SearchInput";
import { useViewContext } from "../../featuresProvider";
var defaultProps = {
  render: SearchInput
};
export var DataViewSearchContext = createContext(_objectSpread({
  searchEnabled: !1,
  searchValue: "",
  onSearchChange: noop
}, defaultProps));
export var useSearchContext = function () {
  var _useContext = useContext(DataViewSearchContext),
    onSearchChange = _useContext.onSearchChange,
    rest = _objectWithoutProperties(_useContext, _excluded),
    onSelectionReset = useSelectionContext().onSelectionReset;
  return _objectSpread({
    onSearchChange: useCallback(function (newSearchValue) {
      return null == onSelectionReset || onSelectionReset(), null == onSearchChange ? void 0 : onSearchChange(newSearchValue);
    }, [onSearchChange, onSelectionReset])
  }, rest);
};
export var DataViewSearch = function (props) {
  var view = useViewContext(),
    _useBreakpoint$breakp = useBreakpoint(props).breakpointProps,
    render = _useBreakpoint$breakp.render,
    onSearchChange = _useBreakpoint$breakp.onSearchChange,
    searchValue = _useBreakpoint$breakp.searchValue,
    placeholder = _useBreakpoint$breakp.placeholder,
    label = _useBreakpoint$breakp.label,
    contextValue = useMemo(function () {
      return _objectSpread(_objectSpread({
        searchEnabled: !0
      }, defaultProps), {}, {
        render: render || defaultProps.render,
        onSearchChange: onSearchChange,
        searchValue: searchValue,
        placeholder: placeholder,
        label: label
      });
    }, [onSearchChange, render, searchValue, placeholder, label]);
  return React.createElement(DataViewSearchContext.Provider, {
    value: contextValue
  }, view);
};
var basePropTypes = {
    render: PropTypes.func,
    onSearchChange: PropTypes.func.isRequired,
    searchValue: PropTypes.string.isRequired,
    placeholder: intlMessageShape,
    label: intlMessageShape
  },
  breakpointPropTypes = _objectSpread(_objectSpread({}, basePropTypes), {}, {
    onSearchChange: PropTypes.func,
    searchValue: PropTypes.string
  }),
  propTypes = _objectSpread(_objectSpread({}, basePropTypes), {}, {
    phone: PropTypes.shape(breakpointPropTypes),
    phoneWide: PropTypes.shape(breakpointPropTypes),
    tablet: PropTypes.shape(breakpointPropTypes)
  });
DataViewSearch.propTypes = propTypes, DataViewSearch.defaultProps = defaultProps, DataViewSearch.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "DataViewSearch",
  props: {
    render: {
      defaultValue: {
        value: "SearchInput",
        computed: !0
      },
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback which renders the search field"
    },
    onSearchChange: {
      type: {
        name: "func"
      },
      required: !0,
      description: "Set the search value"
    },
    searchValue: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Search value"
    },
    placeholder: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Placeholder for search input"
    },
    label: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Label for search input (always hidden, available for screen readers only)"
    },
    phone: {
      type: {
        name: "shape",
        value: {
          onSearchChange: {
            name: "func",
            required: !1
          },
          searchValue: {
            name: "string",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          onSearchChange: {
            name: "func",
            required: !1
          },
          searchValue: {
            name: "string",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          onSearchChange: {
            name: "func",
            required: !1
          },
          searchValue: {
            name: "string",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
}, DataViewSearch.__docgenInfo = {
  componentName: "DataViewSearch",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "DataViewSearch",
  methods: [],
  actualName: "DataViewSearch",
  props: {
    render: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback which renders the search field",
      defaultValue: {
        value: "SearchInput",
        computed: !0
      }
    },
    onSearchChange: {
      type: {
        name: "func"
      },
      required: !0,
      description: "Set the search value"
    },
    searchValue: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Search value"
    },
    placeholder: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Placeholder for search input"
    },
    label: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Label for search input (always hidden, available for screen readers only)"
    },
    phone: {
      type: {
        name: "shape",
        value: {
          onSearchChange: {
            name: "func",
            required: !1
          },
          searchValue: {
            name: "string",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          onSearchChange: {
            name: "func",
            required: !1
          },
          searchValue: {
            name: "string",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          onSearchChange: {
            name: "func",
            required: !1
          },
          searchValue: {
            name: "string",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
};