import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _extends from "@babel/runtime-corejs3/helpers/extends";
var _context, _context2, _context3, _context4;
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import React from "react";
import keys from "lodash/keys";
import pull from "lodash/pull";
import { allowedNumberFields, allowedCurrencyFields, allowedTextFields, allowedCategoryFields, allowedBooleanFields, allowedDateTimeFields, allowedPhoneFields, allowedActionsColumnProps } from "../../../types";
import { GenericColumn, NumberColumn, TextColumn, CurrencyColumn, CategoryColumn, BooleanColumn, DateTimeColumn, PhoneColumn, ActionsColumn } from "../../../features/columns";
var DateColumn = function (props) {
  return React.createElement(DateTimeColumn, _extends({
    dataType: "string",
    showTime: !1
  }, props));
};
export var columnsMapping = {
  currency: {
    component: CurrencyColumn,
    allowedProps: allowedCurrencyFields
  },
  number: {
    component: NumberColumn,
    allowedProps: allowedNumberFields
  },
  integer: {
    component: NumberColumn,
    allowedProps: allowedNumberFields
  },
  string: {
    component: TextColumn,
    allowedProps: allowedTextFields
  },
  text: {
    component: TextColumn,
    allowedProps: allowedTextFields
  },
  textarea: {
    component: TextColumn,
    allowedProps: allowedTextFields
  },
  select: {
    component: CategoryColumn,
    allowedProps: allowedCategoryFields
  },
  typelist: {
    component: CategoryColumn,
    allowedProps: allowedCategoryFields
  },
  unknown: {
    component: GenericColumn,
    allowedProps: pull(keys(GenericColumn.propTypes), "renderEditCell")
  },
  boolean: {
    component: BooleanColumn,
    allowedProps: _concatInstanceProperty(_context = []).call(_context, _toConsumableArray(allowedBooleanFields), ["availableValues"])
  },
  checkbox: {
    component: BooleanColumn,
    allowedProps: _concatInstanceProperty(_context2 = []).call(_context2, _toConsumableArray(allowedBooleanFields), ["availableValues"])
  },
  radio: {
    component: BooleanColumn,
    allowedProps: _concatInstanceProperty(_context3 = []).call(_context3, _toConsumableArray(allowedBooleanFields), ["availableValues"])
  },
  yesno: {
    component: BooleanColumn,
    allowedProps: _concatInstanceProperty(_context4 = []).call(_context4, _toConsumableArray(allowedBooleanFields), ["availableValues"])
  },
  tel: {
    component: PhoneColumn,
    allowedProps: allowedPhoneFields
  },
  date: {
    component: DateColumn,
    allowedProps: allowedDateTimeFields
  },
  year: {
    component: TextColumn,
    allowedProps: allowedTextFields
  },
  action: {
    component: ActionsColumn,
    allowedProps: allowedActionsColumnProps
  }
};
DateColumn.__docgenInfo = {
  componentName: "DateColumn",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "DateColumn",
  methods: [],
  actualName: "DateColumn"
};