import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _Object$values from "@babel/runtime-corejs3/core-js-stable/object/values";
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import React from "react";
import isEmpty from "lodash/isEmpty";
import isString from "lodash/isString";
import { useColumnsContext } from "..";
import { useColumnsConfigContext } from "../../columnsConfig";
import { ReorderedColumnsContext } from "./ReorderedColumnsContext";
export var ReorderedColumnsContextProvider = function (_ref) {
  var children = _ref.children,
    reorderedColumns = function (columns) {
      var _context,
        columnsConfig = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : [];
      return _concatInstanceProperty(_context = []).call(_context, _toConsumableArray(isEmpty(columnsConfig) ? _Object$values(columns) : []), _toConsumableArray(_reduceInstanceProperty(columnsConfig).call(columnsConfig, function (acc, columnConfig) {
        var _context2,
          column = columns[isString(columnConfig) ? columnConfig : columnConfig.id],
          isVisible = !!isString(columnConfig) || columnConfig.visible;
        return column ? _concatInstanceProperty(_context2 = []).call(_context2, _toConsumableArray(acc), [_objectSpread(_objectSpread({}, column), {}, {
          visible: isVisible
        })]) : acc;
      }, [])));
    }(useColumnsContext().columns.current, useColumnsConfigContext().columnsConfig);
  return React.createElement(ReorderedColumnsContext.Provider, {
    value: reorderedColumns
  }, children);
};
ReorderedColumnsContextProvider.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "ReorderedColumnsContextProvider"
}, ReorderedColumnsContextProvider.__docgenInfo = {
  componentName: "ReorderedColumnsContextProvider",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "ReorderedColumnsContextProvider",
  methods: [],
  actualName: "ReorderedColumnsContextProvider"
};