import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import { useContext, useState } from "react";
import { BreakpointTrackerContext } from "@jutro/layout";
export var useColumnsConfig = function (props) {
  var _props$initialColumns,
    getInitialBreakpointConfig = function (breakpoint) {
      var _props$breakpoint, _props$breakpoint2;
      return (null === (_props$breakpoint = props[breakpoint]) || void 0 === _props$breakpoint ? void 0 : _props$breakpoint.initialColumnsConfig) && _defineProperty({}, breakpoint, {
        columnsConfig: null === (_props$breakpoint2 = props[breakpoint]) || void 0 === _props$breakpoint2 ? void 0 : _props$breakpoint2.initialColumnsConfig
      });
    },
    breakpoint = useContext(BreakpointTrackerContext),
    _useState = useState(_objectSpread(_objectSpread(_objectSpread({
      columnsConfig: null !== (_props$initialColumns = props.initialColumnsConfig) && void 0 !== _props$initialColumns ? _props$initialColumns : []
    }, getInitialBreakpointConfig("tablet")), getInitialBreakpointConfig("phoneWide")), getInitialBreakpointConfig("phone"))),
    _useState2 = _slicedToArray(_useState, 2),
    columnsConfig = _useState2[0],
    setColumnsConfig = _useState2[1];
  return {
    columnsConfigProps: _objectSpread({
      onColumnsConfigChange: function (newColumnsConfig) {
        return setColumnsConfig("desktop" === breakpoint ? _objectSpread(_objectSpread({}, columnsConfig), newColumnsConfig) : _objectSpread(_objectSpread({}, columnsConfig), {}, _defineProperty({}, breakpoint, newColumnsConfig)));
      }
    }, columnsConfig)
  };
};