import "core-js/modules/es.string.replace";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import _regeneratorRuntime from "@babel/runtime-corejs3/regenerator";
var _marked = _regeneratorRuntime.mark(generateInterval);
import addMinutes from "date-fns/addMinutes";
import formatDate from "date-fns/format";
import { isDateInRange } from "@jutro/locale";
function generateInterval(startDate, intervalPeriod) {
  var prevDate, newDate, _ref;
  return _regeneratorRuntime.wrap(function (_context) {
    for (;;) switch (_context.prev = _context.next) {
      case 0:
        return _context.next = 2, startDate;
      case 2:
        prevDate = startDate, newDate = addMinutes(startDate, intervalPeriod);
      case 4:
        if (prevDate.getDay() !== newDate.getDay()) {
          _context.next = 12;
          break;
        }
        return _context.next = 7, newDate;
      case 7:
        _ref = [newDate, addMinutes(newDate, intervalPeriod)], prevDate = _ref[0], newDate = _ref[1], _context.next = 4;
        break;
      case 12:
      case "end":
        return _context.stop();
    }
  }, _marked);
}
export function generateTimeIntervals(intervalPeriod, format, min, max) {
  for (var date, timeFormat, dates = [], intervalGenerator = generateInterval(new Date(1970, 0, 1, 0, 0, 0), intervalPeriod), interval = intervalGenerator.next(); !interval.done;) isInRange(interval.value, min, max) && dates.push((date = interval.value, {
    label: formatDate(date, timeFormat = format),
    value: formatDate(date, timeFormat)
  })), interval = intervalGenerator.next();
  return dates;
}
export function filterOptions(_ref2, rawInput) {
  var value = _ref2.value,
    normalizeInput = rawInput.replace(/_|\s/g, "").toLowerCase(),
    normalizeValue = value.replace(/_|\s/g, "").toLowerCase();
  return _includesInstanceProperty(normalizeValue).call(normalizeValue, normalizeInput);
}
export function isInRange(value, min, max) {
  if (!value) return !0;
  if (!0 === min || !0 === max) return !1;
  var dateValue, minDate, maxDate;
  dateValue = value instanceof Date ? new Date(1970, 0, 1, value.getHours(), value.getMinutes()) : new Date(1970, 0, 1, value.hour, value.minute), min && (minDate = new Date(1970, 0, 1, min.hour, min.minute)), max && (maxDate = new Date(1970, 0, 1, max.hour, max.minute));
  var _isDateInRange = isDateInRange(dateValue, minDate, maxDate, !0),
    fulfillMinRequirement = _isDateInRange.fulfillMinRequirement,
    fulfillMaxRequirement = _isDateInRange.fulfillMaxRequirement;
  return fulfillMinRequirement && fulfillMaxRequirement;
}