import { defineMessages } from "react-intl";
export var messages = defineMessages({
  itemsXofY: {
    id: "jutro-dataview.Pagination.itemsXofY",
    defaultMessage: "{range} of {total}"
  },
  currentRows: {
    id: "jutro-dataview.Pagination.currentRows",
    defaultMessage: "Current rows:"
  },
  firstPageButton: {
    id: "jutro-dataview.Pagination.firstPageButton",
    defaultMessage: "Go to first page"
  },
  previousPageButton: {
    id: "jutro-dataview.Pagination.previousPageButton",
    defaultMessage: "Go to previous page"
  },
  nextPageButton: {
    id: "jutro-dataview.Pagination.nextPageButton",
    defaultMessage: "Go to next page"
  },
  lastPageButton: {
    id: "jutro-dataview.Pagination.lastPageButton",
    defaultMessage: "Go to last page"
  }
});