import _extends from "@babel/runtime-corejs3/helpers/extends";
import React from "react";
import { InputNumberField } from "@jutro/components";
import { getCommonEditProps } from "../editing/getCommonEditProps";
import { allowedNumberFields } from "../../types";
import { GenericColumn, createColumnPropTypes, ALIGN_TEXT_RIGHT } from "./GenericColumn";
var NumberCell = function (_ref) {
    var props = _ref.columnProps,
      value = _ref.value,
      cellId = _ref.cellId;
    return React.createElement(InputNumberField, _extends({}, props, {
      readOnly: !0,
      id: cellId,
      value: value
    }));
  },
  NumberEditCell = function (props) {
    return React.createElement(InputNumberField, _extends({}, getCommonEditProps(props), {
      noDeprecationWarnings: ["registerValidation"]
    }));
  };
export var NumberColumn = function (props) {
  return React.createElement(GenericColumn, _extends({}, props, {
    renderCell: NumberCell,
    renderEditCell: NumberEditCell,
    columnDataType: "number"
  }));
};
var numberColumnPropTypes = createColumnPropTypes(InputNumberField.propTypes, allowedNumberFields);
NumberColumn.propTypes = numberColumnPropTypes, NumberColumn.defaultProps = {
  alignText: ALIGN_TEXT_RIGHT
}, NumberColumn.displayName = "NumberColumn", NumberColumn.__docgenInfo = {
  description: "Column for number values\n\n@metadataType container",
  methods: [],
  displayName: "NumberColumn",
  props: {
    alignText: {
      defaultValue: {
        value: "ALIGN_TEXT_RIGHT",
        computed: !0
      },
      required: !1
    }
  },
  composes: ["./GenericColumn"]
}, NumberCell.__docgenInfo = {
  componentName: "NumberCell",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "NumberCell",
  methods: [],
  actualName: "NumberCell"
}, NumberEditCell.__docgenInfo = {
  componentName: "NumberEditCell",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "NumberEditCell",
  methods: [],
  actualName: "NumberEditCell"
}, NumberColumn.__docgenInfo = {
  componentName: "NumberColumn",
  packageName: "@jutro/lab-preview-dataview",
  description: "Column for number values",
  displayName: "NumberColumn",
  methods: [],
  actualName: "NumberColumn",
  metadataType: "container",
  props: {
    alignText: {
      defaultValue: {
        value: "ALIGN_TEXT_RIGHT",
        computed: !0
      },
      required: !1
    }
  },
  composes: ["./GenericColumn"]
};