import _Object$values from "@babel/runtime-corejs3/core-js-stable/object/values";
import React from "react";
import isEqual from "lodash/isEqual";
import isNumber from "lodash/isNumber";
import isEmpty from "lodash/isEmpty";
import { Button, ModalNext, ModalHeader, ModalFooter, ModalBody } from "@jutro/components";
import { uniqueInnerId } from "@jutro/platform";
import { useTranslator } from "@jutro/locale";
import { MetadataForm } from "@jutro/uiconfig";
import { Flex } from "@jutro/layout";
import { useFilterForm } from "../useFilterForm";
import { messages } from "../DataViewFiltering.messages";
import styles from "./FilteringModal.module.css";
export var FilteringModal = function (_ref) {
  var isOpen = _ref.isOpen,
    onResolve = _ref.onResolve,
    onReject = _ref.onReject,
    onFiltersChange = _ref.onFiltersChange,
    uiProps = _ref.uiProps,
    filters = _ref.filters,
    _ref$areFiltersApplie = _ref.areFiltersApplied,
    areFiltersApplied = void 0 === _ref$areFiltersApplie || _ref$areFiltersApplie,
    translator = useTranslator(),
    _useFilterForm = useFilterForm({
      filters: filters,
      onFiltersChange: onFiltersChange
    }),
    formData = _useFilterForm.formData,
    readValue = _useFilterForm.readValue,
    handleDataChange = _useFilterForm.handleDataChange,
    handleClearFilter = _useFilterForm.handleClearFilter,
    applyFilters = _useFilterForm.applyFilters,
    handleWithResolve = function (action) {
      return function () {
        action(), null == onResolve || onResolve();
      };
    },
    overrideProps = {
      "@field": {
        className: styles.filterItem
      }
    },
    titleId = uniqueInnerId("filteringModalTitle", "titleId").titleId,
    areFiltersAndFormSame = isEqual(formData, filters),
    isFormEmpty = _Object$values(formData).every(function (value) {
      return !isNumber(value) && isEmpty(value);
    }),
    isNothingChosen = areFiltersAndFormSame || isFormEmpty;
  return React.createElement(ModalNext, {
    isOpen: isOpen,
    onRequestClose: onReject,
    shouldCloseOnEsc: !1,
    ariaLabelledby: titleId
  }, React.createElement(ModalHeader, {
    title: translator(messages.title),
    titleId: titleId,
    onClose: onReject,
    ariaLabel: translator(messages.closeModalAriaLabel)
  }), React.createElement(ModalBody, null, React.createElement(MetadataForm, {
    data: formData,
    overrideProps: overrideProps,
    resolveValue: readValue,
    onDataChange: handleDataChange,
    uiProps: null != uiProps ? uiProps : {}
  }), React.createElement(Flex, {
    justifyContent: "center",
    className: styles.controlButton
  }, React.createElement(Button, {
    type: "text",
    disabled: isNothingChosen && !areFiltersApplied,
    onClick: handleWithResolve(handleClearFilter)
  }, translator(messages.clearFilters)))), React.createElement(ModalFooter, null, React.createElement(Button, {
    type: "text",
    onClick: onReject,
    "aria-label": translator(messages.cancelFiltersButtonAriaLabel)
  }, translator(messages.cancelFiltersButtonLabel)), React.createElement(Button, {
    onClick: handleWithResolve(applyFilters),
    "aria-label": translator(messages.applyFiltersButtonAriaLabel),
    disabled: isNothingChosen
  }, translator(messages.applyFilters))));
};
FilteringModal.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "FilteringModal",
  props: {
    areFiltersApplied: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      required: !1
    }
  }
}, FilteringModal.__docgenInfo = {
  componentName: "FilteringModal",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "FilteringModal",
  methods: [],
  actualName: "FilteringModal",
  props: {
    areFiltersApplied: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      required: !1
    }
  }
};