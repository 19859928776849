import { defineMessages } from "react-intl";
export var messages = defineMessages({
  sortingModalTitle: {
    id: "jutro-dataview.ListView.sortingModalTitle",
    defaultMessage: "Sorting"
  },
  closeModalAriaLabel: {
    id: "jutro-dataview.ListView.closeModalAriaLabel",
    defaultMessage: "Close Modal"
  },
  applySortButtonLabel: {
    id: "jutro-dataview.ListView.sortButtonLabel",
    defaultMessage: "Apply"
  },
  cancelSortButtonLabel: {
    id: "jutro-dataview.ListView.cancelSortButtonLabel",
    defaultMessage: "Cancel"
  },
  applySortButtonAriaLabel: {
    id: "jutro-dataview.ListView.applySortButtonAriaLabel",
    defaultMessage: "Apply changes"
  },
  cancelSortButtonAriaLabel: {
    id: "jutro-dataview.ListView.cancelSortButtonAriaLabel",
    defaultMessage: "Cancel changes"
  },
  sortOptionsDropdownLabel: {
    id: "jutro-dataview.ListView.sortOptionsDropdownLabel",
    defaultMessage: "Sort by"
  },
  sortOrderToggleLabel: {
    id: "jutro-dataview.ListView.sortOrderToggleLabel",
    defaultMessage: "Sorting order"
  },
  ascendingOrderButtonLabel: {
    id: "jutro-dataview.ListView.ascendingOrderButtonLabel",
    defaultMessage: "Ascending order"
  },
  descendingOrderButtonLabel: {
    id: "jutro-dataview.ListView.descendingOrderButtonLabel",
    defaultMessage: "Descending order"
  },
  openSortingConfiguration: {
    id: "jutro-dataview.ListView.openSortingConfiguration",
    defaultMessage: "Open sorting configuration"
  }
});