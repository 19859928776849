import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import React from "react";
import cx from "classnames";
import { useQuickViewInternal } from "./QuickViewProvider";
import styles from "./QuickView.module.css";
export var QuickViewOverlay = function (_ref) {
  var show = _ref.show,
    onClose = _ref.onClose,
    closeOnOverlayClick = _ref.closeOnOverlayClick,
    overlayClassName = _ref.overlayClassName,
    _useQuickViewInternal2 = useQuickViewInternal().styles,
    right = _useQuickViewInternal2.right,
    width = _useQuickViewInternal2.width,
    totalHeight = _useQuickViewInternal2.totalHeight;
  return React.createElement("div", {
    id: "quick-view-overlay",
    className: cx(styles.quickViewOverlay, _defineProperty({}, styles.quickViewOverlayHidden, !show), overlayClassName),
    style: {
      right: right,
      width: width,
      height: totalHeight
    },
    onClick: function (evt) {
      closeOnOverlayClick && (evt.preventDefault(), evt.stopPropagation(), onClose());
    },
    role: "presentation"
  });
};
QuickViewOverlay.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "QuickViewOverlay"
}, QuickViewOverlay.__docgenInfo = {
  componentName: "QuickViewOverlay",
  packageName: "@jutro/quick-view",
  description: "",
  displayName: "QuickViewOverlay",
  methods: [],
  actualName: "QuickViewOverlay"
};