import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React from "react";
import PropTypes from "prop-types";
import { Flex, FlexItem, useBreakpoint } from "@jutro/layout";
import { useTranslator } from "@jutro/locale";
import { intlMessageShape } from "@jutro/prop-types";
import { PageSizeSwitcher } from "./PageSizeSwitcher/PageSizeSwitcher";
import { PageSwitcher } from "./PageSwitcher/PageSwitcher";
var defaultProps = {
  pageSize: 10,
  pageSizes: [10, 25, 50],
  totalItems: 0,
  page: 1,
  isLoading: !1
};
export var Pagination = function (props) {
  var translator = useTranslator(),
    breakpointProps = useBreakpoint(props).breakpointProps,
    _defaultProps$breakpo = _objectSpread(_objectSpread({}, defaultProps), breakpointProps),
    id = _defaultProps$breakpo.id,
    pageSize = _defaultProps$breakpo.pageSize,
    pageSizes = _defaultProps$breakpo.pageSizes,
    totalItems = _defaultProps$breakpo.totalItems,
    onPaginationChange = _defaultProps$breakpo.onPaginationChange,
    page = _defaultProps$breakpo.page,
    isLoading = _defaultProps$breakpo.isLoading,
    className = _defaultProps$breakpo.className,
    ariaLabel = _defaultProps$breakpo.ariaLabel,
    navId = id ? {
      id: id
    } : {};
  return React.createElement("nav", _extends({}, navId, {
    role: "navigation",
    "aria-label": translator(ariaLabel)
  }), React.createElement(Flex, {
    className: className,
    alignItems: "center",
    justifyContent: "between"
  }, React.createElement(FlexItem, null, React.createElement(PageSizeSwitcher, {
    pageSize: pageSize,
    onPageSizeChange: function (newPageSize) {
      null == onPaginationChange || onPaginationChange({
        pageSizes: pageSizes,
        pageSize: newPageSize,
        page: 1,
        paginationType: "pages"
      });
    },
    totalItems: totalItems,
    pageSizes: pageSizes,
    isLoading: isLoading
  })), React.createElement(FlexItem, null, React.createElement(PageSwitcher, {
    totalItems: totalItems,
    page: page,
    onPageChange: function (newPage) {
      null == onPaginationChange || onPaginationChange({
        pageSize: pageSize,
        pageSizes: pageSizes,
        page: newPage,
        paginationType: "pages"
      });
    },
    pageSize: pageSize,
    isLoading: isLoading
  }))));
};
var basePropTypes = {
    id: PropTypes.string,
    ariaLabel: intlMessageShape,
    onPaginationChange: PropTypes.func,
    totalItems: PropTypes.number,
    pageSizes: PropTypes.arrayOf(PropTypes.number.isRequired),
    pageSize: PropTypes.number,
    page: PropTypes.number.isRequired,
    isLoading: PropTypes.bool
  },
  breakpointPropTypes = _objectSpread(_objectSpread({}, basePropTypes), {}, {
    page: PropTypes.number
  }),
  propTypes = _objectSpread(_objectSpread({}, basePropTypes), {}, {
    phone: PropTypes.shape(breakpointPropTypes),
    phoneWide: PropTypes.shape(breakpointPropTypes),
    tablet: PropTypes.shape(breakpointPropTypes)
  });
Pagination.propTypes = propTypes, Pagination.defaultProps = defaultProps, Pagination.__docgenInfo = {
  description: "Component for adding pagination capabilities to data views",
  methods: [],
  displayName: "Pagination",
  props: {
    pageSize: {
      defaultValue: {
        value: "10",
        computed: !1
      },
      type: {
        name: "number"
      },
      required: !1,
      description: "Page size, needs to be included in 'pageSizes' for \"pages\" paginationType, used to control it from outside\n@default 10"
    },
    pageSizes: {
      defaultValue: {
        value: "[10, 25, 50]",
        computed: !1
      },
      type: {
        name: "arrayOf",
        value: {
          name: "number"
        }
      },
      required: !1,
      description: "Array of available page sizes. Values below `totalItems` are not displayed.\n@default [10,25,50]"
    },
    totalItems: {
      defaultValue: {
        value: "0",
        computed: !1
      },
      type: {
        name: "number"
      },
      required: !1,
      description: "The total number of items (not only the displayed ones) for calculating the number of pages"
    },
    page: {
      defaultValue: {
        value: "1",
        computed: !1
      },
      type: {
        name: "number"
      },
      required: !1,
      description: "Current page number to be displayed"
    },
    isLoading: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, then changing `page` and `pageSize` is disabled"
    },
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The identifier of the component"
    },
    ariaLabel: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Aria label text"
    },
    onPaginationChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback called on every `page` and `pageSize` change"
    },
    phone: {
      type: {
        name: "shape",
        value: {
          page: {
            name: "number",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          page: {
            name: "number",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          page: {
            name: "number",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
}, Pagination.__docgenInfo = {
  componentName: "Pagination",
  packageName: "@jutro/lab-preview-dataview",
  description: "Component for adding pagination capabilities to data views",
  displayName: "Pagination",
  methods: [],
  actualName: "Pagination",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The identifier of the component"
    },
    ariaLabel: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Aria label text"
    },
    onPaginationChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback called on every `page` and `pageSize` change"
    },
    totalItems: {
      type: {
        name: "number"
      },
      required: !1,
      description: "The total number of items (not only the displayed ones) for calculating the number of pages",
      defaultValue: {
        value: "0",
        computed: !1
      }
    },
    pageSizes: {
      type: {
        name: "arrayOf",
        value: {
          name: "number"
        }
      },
      required: !1,
      description: "Array of available page sizes. Values below `totalItems` are not displayed.\n@default [10,25,50]",
      defaultValue: {
        value: "[10, 25, 50]",
        computed: !1
      }
    },
    pageSize: {
      type: {
        name: "number"
      },
      required: !1,
      description: "Page size, needs to be included in 'pageSizes' for \"pages\" paginationType, used to control it from outside\n@default 10",
      defaultValue: {
        value: "10",
        computed: !1
      }
    },
    page: {
      type: {
        name: "number"
      },
      required: !1,
      description: "Current page number to be displayed",
      defaultValue: {
        value: "1",
        computed: !1
      }
    },
    isLoading: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, then changing `page` and `pageSize` is disabled",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    phone: {
      type: {
        name: "shape",
        value: {
          page: {
            name: "number",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          page: {
            name: "number",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          page: {
            name: "number",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
};