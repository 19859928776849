import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React, { createContext, useMemo, useRef, useContext } from "react";
import { useViewContext } from "../../featuresProvider";
var columnsContextInitialValue = {
  columns: {
    current: {}
  },
  registerColumn: void 0
};
export var ColumnsContext = createContext(columnsContextInitialValue);
export var useColumnsContext = function () {
  return useContext(ColumnsContext);
};
export var DataViewColumns = function (_ref) {
  var children = _ref.children,
    columns = useRef({}),
    view = useViewContext(),
    registerColumn = function (columnProps) {
      var newColumns = _objectSpread(_objectSpread({}, columns.current), {}, _defineProperty({}, columnProps.id, columnProps));
      columns.current = newColumns;
    },
    contextValue = useMemo(function () {
      return {
        columns: columns,
        registerColumn: registerColumn
      };
    }, [columns]);
  return React.createElement(ColumnsContext.Provider, {
    value: contextValue
  }, children, view);
};
DataViewColumns.displayName = "DataViewColumns", DataViewColumns.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "DataViewColumns"
}, DataViewColumns.__docgenInfo = {
  componentName: "DataViewColumns",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "DataViewColumns",
  methods: [],
  actualName: "DataViewColumns"
};