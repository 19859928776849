import { defineMessages } from "react-intl";
export var messages = defineMessages({
  selectAllRows: {
    id: "jutro-dataview.tableview.selection.selectAllRows",
    defaultMessage: "{selectedRowsNumber} rows selected, select all visible rows"
  },
  selectRow: {
    id: "jutro-dataview.tableview.selection.selectRow",
    defaultMessage: "Select row"
  }
});