import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
var _defaultRenderers, _defaultMessages, _renderGetters, _messageGetters, _propsGetter;
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React from "react";
import { Flex } from "@jutro/layout";
import { useTranslator } from "@jutro/locale";
import cond from "lodash/fp/cond";
import getOr from "lodash/fp/getOr";
import mapValues from "lodash/fp/mapValues";
import { ErrorContent, LoadingContent, NoRowsContent } from "./OverlayContent";
import { messages as overlayMessages } from "./StateOverlay.messages";
import styles from "./StateOverlay.module.css";
import { useOverlayContext } from "./DataViewOverlay";
export var overlayStates = {
  loading: "loading",
  error: "error",
  noRows: "noRows"
};
var defaultRenderers = (_defineProperty(_defaultRenderers = {}, overlayStates.loading, LoadingContent), _defineProperty(_defaultRenderers, overlayStates.error, ErrorContent), _defineProperty(_defaultRenderers, overlayStates.noRows, NoRowsContent), _defaultRenderers),
  defaultMessages = (_defineProperty(_defaultMessages = {}, overlayStates.loading, overlayMessages.loadingText), _defineProperty(_defaultMessages, overlayStates.error, overlayMessages.errorText), _defineProperty(_defaultMessages, overlayStates.noRows, overlayMessages.noRowsText), _defaultMessages),
  renderGetters = (_defineProperty(_renderGetters = {}, overlayStates.loading, getOr(defaultRenderers.loading, "renderLoader")), _defineProperty(_renderGetters, overlayStates.error, getOr(defaultRenderers.error, "renderError")), _defineProperty(_renderGetters, overlayStates.noRows, getOr(defaultRenderers.noRows, "renderNoRows")), _renderGetters),
  messageGetters = (_defineProperty(_messageGetters = {}, overlayStates.loading, getOr(defaultMessages.loading, "loaderMessage")), _defineProperty(_messageGetters, overlayStates.error, getOr(defaultMessages.error, "errorMessage")), _defineProperty(_messageGetters, overlayStates.noRows, getOr(defaultMessages.noRows, "noRowsMessage")), _messageGetters),
  propsGetter = (_defineProperty(_propsGetter = {}, overlayStates.loading, getOr({}, "renderLoaderProps")), _defineProperty(_propsGetter, overlayStates.error, getOr({}, "renderErrorProps")), _defineProperty(_propsGetter, overlayStates.noRows, getOr({}, "renderNoRowsProps")), _propsGetter);
export var getOverlayState = cond([[function (_ref) {
  var isLoading = _ref.isLoading;
  return Boolean(isLoading);
}, function () {
  return overlayStates.loading;
}], [function (_ref2) {
  var isError = _ref2.isError;
  return Boolean(isError);
}, function () {
  return overlayStates.error;
}], [function (_ref3) {
  var data = _ref3.data;
  return !data || 0 === data.length;
}, function () {
  return overlayStates.noRows;
}]]);
var valueMapper = function (props, getters) {
  return mapValues(function (value) {
    return value(props);
  }, getters);
};
export var getStateOverlayProps = function (props) {
  var state = getOverlayState(props);
  return {
    valid: !!state,
    renderer: valueMapper(props, renderGetters)[state],
    message: valueMapper(props, messageGetters)[state],
    props: valueMapper(props, propsGetter)[state]
  };
};
export var StateOverlay = function (_ref4) {
  var data = _ref4.data,
    translator = useTranslator(),
    props = useOverlayContext(),
    _getStateOverlayProps = getStateOverlayProps(_objectSpread(_objectSpread({}, props), {}, {
      data: data
    })),
    Content = _getStateOverlayProps.renderer,
    message = _getStateOverlayProps.message,
    otherProps = _getStateOverlayProps.props;
  return React.createElement(Flex, {
    justifyContent: "center",
    alignItems: "center",
    direction: "column",
    className: styles.stateOverlay
  }, React.createElement(Content, _extends({
    message: translator(message)
  }, otherProps)));
};
StateOverlay.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "StateOverlay"
}, StateOverlay.__docgenInfo = {
  componentName: "StateOverlay",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "StateOverlay",
  methods: [],
  actualName: "StateOverlay"
};