import _extends from "@babel/runtime-corejs3/helpers/extends";
import React from "react";
import PropTypes from "prop-types";
import { intlMessageShape } from "@jutro/prop-types";
import { useBreakpoint } from "@jutro/layout";
import { useTranslator } from "@jutro/locale";
import { Button } from "@jutro/components";
import { handleOnClick } from "../utils/handleOnClick";
export var DEFAULT_ACTION_TYPE = "global";
export var HeaderAction = function (props) {
  var translator = useTranslator(),
    breakpointProps = useBreakpoint(props).breakpointProps,
    _onClick = breakpointProps.onClick,
    labelProp = breakpointProps.label,
    ariaLabelProp = breakpointProps.ariaLabel,
    wrapperProps = breakpointProps.wrapperProps,
    label = translator(labelProp),
    ariaLabel = translator(ariaLabelProp) || label;
  return React.createElement(Button, _extends({
    onClick: function (e) {
      handleOnClick(e, _onClick);
    }
  }, wrapperProps, {
    "aria-label": ariaLabel
  }), label);
};
var wrapperPropTypes = {
  icon: PropTypes.string,
  className: PropTypes.string,
  iconClassName: PropTypes.string
};
export var headerActionPropTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["global", "batch"]),
  label: intlMessageShape.isRequired,
  onClick: PropTypes.func.isRequired,
  wrapperProps: PropTypes.shape(wrapperPropTypes)
};
HeaderAction.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "HeaderAction"
}, HeaderAction.__docgenInfo = {
  componentName: "HeaderAction",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "HeaderAction",
  methods: [],
  actualName: "HeaderAction"
};