import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["onClose"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _Promise from "@babel/runtime-corejs3/core-js-stable/promise";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import React, { useCallback } from "react";
import { Button } from "@jutro/components";
import { AsyncButtonLink } from "@jutro/router";
import { Flex, useBreakpoint } from "@jutro/layout";
import { useTranslator } from "@jutro/locale";
import PropTypes from "prop-types";
import messages from "../QuickView.messages";
import styles from "./QuickViewFooter.module.css";
var DefaultFooterContent = function (_ref) {
  var onCancelClick = _ref.onCancelClick,
    onSaveClick = _ref.onSaveClick,
    translator = useTranslator();
  return React.createElement(Flex, {
    direction: "row",
    justifyContent: "bottom",
    alignContent: "middle",
    className: styles.quickViewFooterFlex
  }, React.createElement(Button, {
    className: styles.cancelButton,
    type: "text",
    onClick: onCancelClick,
    "aria-label": translator(messages.cancelButtonAriaLabel)
  }, translator(messages.cancelButton)), React.createElement(AsyncButtonLink, {
    className: styles.saveButton,
    onTrigger: onSaveClick,
    "aria-label": translator(messages.saveButtonAriaLabel)
  }, translator(messages.saveButton)));
};
export var QuickViewFooter = function (_ref2) {
  var onClose = _ref2.onClose,
    props = _objectWithoutProperties(_ref2, _excluded),
    _useBreakpoint$breakp = useBreakpoint(props).breakpointProps,
    _useBreakpoint$breakp2 = _useBreakpoint$breakp.onSaveClick,
    onSaveClickProp = void 0 === _useBreakpoint$breakp2 ? function () {
      return _Promise.resolve();
    } : _useBreakpoint$breakp2,
    onCancelClickProp = _useBreakpoint$breakp.onCancelClick,
    _useBreakpoint$breakp3 = _useBreakpoint$breakp.render,
    FooterContent = void 0 === _useBreakpoint$breakp3 ? DefaultFooterContent : _useBreakpoint$breakp3,
    closeOnSave = _useBreakpoint$breakp.closeOnSave,
    closeOnCancel = _useBreakpoint$breakp.closeOnCancel,
    onCancelClick = useCallback(function () {
      null == onCancelClickProp || onCancelClickProp(), closeOnCancel && (null == onClose || onClose());
    }, [onCancelClickProp, closeOnCancel, onClose]),
    onSaveClick = useCallback(function () {
      return onSaveClickProp().then(function () {
        closeOnSave && (null == onClose || onClose(!0));
      });
    }, [onSaveClickProp, closeOnSave, onClose]);
  return React.createElement("div", {
    "data-testid": "quickViewFooter",
    className: styles.quickViewFooter
  }, React.createElement(FooterContent, {
    onSaveClick: onSaveClick,
    onCancelClick: onCancelClick
  }));
};
var quickViewFooterBasePropTypes = {
  onSaveClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  render: PropTypes.func,
  closeOnSave: PropTypes.bool,
  closeOnCancel: PropTypes.bool,
  onClose: PropTypes.func
};
QuickViewFooter.propTypes = _objectSpread(_objectSpread({}, quickViewFooterBasePropTypes), {}, {
  phone: PropTypes.shape(quickViewFooterBasePropTypes),
  phoneWide: PropTypes.shape(quickViewFooterBasePropTypes),
  tablet: PropTypes.shape(quickViewFooterBasePropTypes)
}), QuickViewFooter.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "QuickViewFooter",
  props: {
    onSaveClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback called when save button is clicked"
    },
    onCancelClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback called when cancel button is clicked"
    },
    render: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback which renders custom footer content"
    },
    closeOnSave: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Defines if panel should be closed on save"
    },
    closeOnCancel: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Defines if panel should be closed on cancel"
    },
    onClose: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to close the panel"
    },
    phone: {
      type: {
        name: "shape",
        value: {
          onSaveClick: {
            name: "func",
            description: "Callback called when save button is clicked",
            required: !1
          },
          onCancelClick: {
            name: "func",
            description: "Callback called when cancel button is clicked",
            required: !1
          },
          render: {
            name: "func",
            description: "Callback which renders custom footer content",
            required: !1
          },
          closeOnSave: {
            name: "bool",
            description: "Defines if panel should be closed on save",
            required: !1
          },
          closeOnCancel: {
            name: "bool",
            description: "Defines if panel should be closed on cancel",
            required: !1
          },
          onClose: {
            name: "func",
            description: "Callback to close the panel",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          onSaveClick: {
            name: "func",
            description: "Callback called when save button is clicked",
            required: !1
          },
          onCancelClick: {
            name: "func",
            description: "Callback called when cancel button is clicked",
            required: !1
          },
          render: {
            name: "func",
            description: "Callback which renders custom footer content",
            required: !1
          },
          closeOnSave: {
            name: "bool",
            description: "Defines if panel should be closed on save",
            required: !1
          },
          closeOnCancel: {
            name: "bool",
            description: "Defines if panel should be closed on cancel",
            required: !1
          },
          onClose: {
            name: "func",
            description: "Callback to close the panel",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          onSaveClick: {
            name: "func",
            description: "Callback called when save button is clicked",
            required: !1
          },
          onCancelClick: {
            name: "func",
            description: "Callback called when cancel button is clicked",
            required: !1
          },
          render: {
            name: "func",
            description: "Callback which renders custom footer content",
            required: !1
          },
          closeOnSave: {
            name: "bool",
            description: "Defines if panel should be closed on save",
            required: !1
          },
          closeOnCancel: {
            name: "bool",
            description: "Defines if panel should be closed on cancel",
            required: !1
          },
          onClose: {
            name: "func",
            description: "Callback to close the panel",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
}, DefaultFooterContent.__docgenInfo = {
  componentName: "DefaultFooterContent",
  packageName: "@jutro/quick-view",
  description: "",
  displayName: "DefaultFooterContent",
  methods: [],
  actualName: "DefaultFooterContent"
}, QuickViewFooter.__docgenInfo = {
  componentName: "QuickViewFooter",
  packageName: "@jutro/quick-view",
  description: "",
  displayName: "QuickViewFooter",
  methods: [],
  actualName: "QuickViewFooter",
  props: {
    onSaveClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback called when save button is clicked"
    },
    onCancelClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback called when cancel button is clicked"
    },
    render: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback which renders custom footer content"
    },
    closeOnSave: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Defines if panel should be closed on save"
    },
    closeOnCancel: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Defines if panel should be closed on cancel"
    },
    onClose: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to close the panel"
    },
    phone: {
      type: {
        name: "shape",
        value: {
          onSaveClick: {
            name: "func",
            description: "Callback called when save button is clicked",
            required: !1
          },
          onCancelClick: {
            name: "func",
            description: "Callback called when cancel button is clicked",
            required: !1
          },
          render: {
            name: "func",
            description: "Callback which renders custom footer content",
            required: !1
          },
          closeOnSave: {
            name: "bool",
            description: "Defines if panel should be closed on save",
            required: !1
          },
          closeOnCancel: {
            name: "bool",
            description: "Defines if panel should be closed on cancel",
            required: !1
          },
          onClose: {
            name: "func",
            description: "Callback to close the panel",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          onSaveClick: {
            name: "func",
            description: "Callback called when save button is clicked",
            required: !1
          },
          onCancelClick: {
            name: "func",
            description: "Callback called when cancel button is clicked",
            required: !1
          },
          render: {
            name: "func",
            description: "Callback which renders custom footer content",
            required: !1
          },
          closeOnSave: {
            name: "bool",
            description: "Defines if panel should be closed on save",
            required: !1
          },
          closeOnCancel: {
            name: "bool",
            description: "Defines if panel should be closed on cancel",
            required: !1
          },
          onClose: {
            name: "func",
            description: "Callback to close the panel",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          onSaveClick: {
            name: "func",
            description: "Callback called when save button is clicked",
            required: !1
          },
          onCancelClick: {
            name: "func",
            description: "Callback called when cancel button is clicked",
            required: !1
          },
          render: {
            name: "func",
            description: "Callback which renders custom footer content",
            required: !1
          },
          closeOnSave: {
            name: "bool",
            description: "Defines if panel should be closed on save",
            required: !1
          },
          closeOnCancel: {
            name: "bool",
            description: "Defines if panel should be closed on cancel",
            required: !1
          },
          onClose: {
            name: "func",
            description: "Callback to close the panel",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
};