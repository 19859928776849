import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["children"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useBreakpoint } from "@jutro/layout";
import { intlMessageShape } from "@jutro/prop-types";
import { ListViewHeader } from "./ListViewHeader/ListViewHeader";
import { ListViewItem } from "./ListViewItem/ListViewItem";
import styles from "./ListView.module.css";
import { useOnGetRowId, useDistinctRowIdsCheck, useColumnOptions } from "../hooks";
import { withDataViewFeaturesProvider } from "../featuresProvider";
import { useFilteringContext, useFiltering } from "../features/filtering";
import { FeaturesSideEffects } from "../features/FeaturesSideEffects";
import { useStateOverlay } from "../features/overlay";
import { Navigation } from "../features/pagination";
import { useTitle } from "../features/title";
import { useRowIds } from "../hooks/useRowIds";
import { useItemClassNames } from "./ListViewItem/useItemClassNames";
var defaultData = [];
export var ListView = withDataViewFeaturesProvider(function (_ref) {
  _ref.children;
  var props = _objectWithoutProperties(_ref, _excluded),
    breakpointProps = useBreakpoint(props).breakpointProps,
    id = breakpointProps.id,
    className = breakpointProps.className,
    _breakpointProps$data = breakpointProps.data,
    data = void 0 === _breakpointProps$data ? defaultData : _breakpointProps$data,
    ariaLabel = breakpointProps.ariaLabel,
    _breakpointProps$inte = breakpointProps.internalClassNames,
    internalClassNames = void 0 === _breakpointProps$inte ? {} : _breakpointProps$inte,
    rowIdPath = breakpointProps.rowIdPath,
    onGetRowIdProp = breakpointProps.onGetRowId,
    onStyleHeaderCell = breakpointProps.onStyleHeaderCell,
    onStyleRow = breakpointProps.onStyleRow,
    onStyleCell = breakpointProps.onStyleCell,
    onComponentRender = breakpointProps.onComponentRender,
    onGetRowId = useOnGetRowId(onGetRowIdProp, rowIdPath),
    rowIds = useRowIds(data, onGetRowId);
  useDistinctRowIdsCheck(rowIds, "ListView");
  var _useColumnOptions = useColumnOptions(),
    columnOptions = _slicedToArray(_useColumnOptions, 1)[0];
  useEffect(function () {
    null == onComponentRender || onComponentRender({
      columnOptions: columnOptions,
      onGetRowId: onGetRowId
    });
  }, []);
  var _useTitle = useTitle(id, ariaLabel),
    titleId = _useTitle.titleId,
    a11yLabelProps = _useTitle.a11yLabelProps,
    _useFilteringContext = useFilteringContext(),
    onFiltersChange = _useFilteringContext.onFiltersChange,
    uiProps = _useFilteringContext.uiProps,
    areFiltersApplied = _useFilteringContext.areFiltersApplied,
    filters = _useFilteringContext.filters,
    _useFiltering = useFiltering({
      defaultView: "modal"
    }),
    FiltersTriggerComponent = _useFiltering.FiltersTriggerComponent,
    FiltersComponent = _useFiltering.FiltersComponent,
    _useStateOverlay = useStateOverlay(data),
    shouldRenderOverlay = _useStateOverlay.shouldRenderOverlay,
    StateOverlay = _useStateOverlay.StateOverlay,
    getItemClassNames = useItemClassNames({
      internalClassNames: internalClassNames,
      onStyleRow: onStyleRow,
      onStyleHeaderCell: onStyleHeaderCell,
      onStyleCell: onStyleCell
    });
  return React.createElement(React.Fragment, null, React.createElement(FeaturesSideEffects, null), React.createElement("div", {
    id: id,
    "data-testid": "list-wrapper",
    className: cx(styles.listView, className)
  }, React.createElement(ListViewHeader, {
    id: "".concat(id, "Header"),
    titleId: titleId,
    className: internalClassNames.header,
    columnOptions: columnOptions,
    onGetRowId: onGetRowId,
    rowIds: rowIds,
    FiltersTriggerComponent: FiltersTriggerComponent
  }), FiltersComponent && React.createElement(FiltersComponent, {
    onFiltersChange: onFiltersChange,
    uiProps: uiProps,
    areFiltersApplied: areFiltersApplied,
    filters: filters
  }), shouldRenderOverlay ? React.createElement(StateOverlay, null) : React.createElement("div", _extends({
    id: "".concat(id, "ItemsWrapper"),
    className: styles.listViewItemsWrapper,
    role: "grid"
  }, a11yLabelProps), _mapInstanceProperty(data).call(data, function (row, rowIndex) {
    var rowId = onGetRowId(row, rowIndex);
    return React.createElement(ListViewItem, {
      key: "".concat(rowIndex, "_item"),
      rawRow: row,
      rowId: rowId,
      rowIndex: rowIndex,
      classNames: getItemClassNames(row, rowId, rowIndex)
    });
  })), React.createElement(Navigation, {
    className: null == internalClassNames ? void 0 : internalClassNames.footer
  })));
});
var classNamesPropTypes = {
  item: PropTypes.shape({
    contentContainer: PropTypes.string,
    actionContainer: PropTypes.string
  }),
  footer: PropTypes.string,
  header: PropTypes.string,
  row: PropTypes.string,
  column: PropTypes.string,
  headerCell: PropTypes.string,
  cell: PropTypes.string
};
export var listViewBasePropTypes = {
  id: PropTypes.string.isRequired,
  ariaLabel: intlMessageShape,
  className: PropTypes.string,
  data: PropTypes.array,
  internalClassNames: PropTypes.shape(classNamesPropTypes),
  rowIdPath: PropTypes.string,
  onGetRowId: PropTypes.func,
  onStyleHeaderCell: PropTypes.func,
  onStyleRow: PropTypes.func,
  onStyleCell: PropTypes.func,
  onComponentRender: PropTypes.func
};
var listViewBreakpointProps = _objectSpread(_objectSpread({}, listViewBasePropTypes), {}, {
  id: PropTypes.string
});
ListView.propTypes = _objectSpread(_objectSpread({}, listViewBasePropTypes), {}, {
  phone: PropTypes.shape(listViewBreakpointProps),
  phoneWide: PropTypes.shape(listViewBreakpointProps),
  tablet: PropTypes.shape(listViewBreakpointProps)
}), ListView.displayName = "ListView", ListView.__docgenInfo = {
  componentName: "ListView",
  packageName: "@jutro/lab-preview-dataview",
  description: "List View component",
  displayName: "ListView",
  methods: [],
  actualName: "ListView",
  metadataType: "container",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Identifier for the component"
    },
    ariaLabel: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Aria label text"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Class name to be applied in the ListView"
    },
    data: {
      type: {
        name: "array"
      },
      required: !1,
      description: "Data to be displayed in the ListView"
    },
    internalClassNames: {
      type: {
        name: "shape",
        value: {
          item: {
            name: "shape",
            value: {
              contentContainer: {
                name: "string",
                required: !1
              },
              actionContainer: {
                name: "string",
                required: !1
              }
            },
            required: !1
          },
          footer: {
            name: "string",
            required: !1
          },
          header: {
            name: "string",
            required: !1
          },
          row: {
            name: "string",
            required: !1
          },
          column: {
            name: "string",
            required: !1
          },
          headerCell: {
            name: "string",
            required: !1
          },
          cell: {
            name: "string",
            required: !1
          }
        }
      },
      required: !1,
      description: "Custom class names for the list and its major components."
    },
    rowIdPath: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Path to the id of a row. By default it is equal to 'id'."
    },
    onGetRowId: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to get a unique identifier of a row. The identifier is passed to other callbacks.\nReceives two arguments - `row` and `rowIndex`. Should return a string.\nBy default, uses `rowIdPath` to get the identifier from a row.\nBe careful with using `rowIndex` for the id - it's not available in sorting and might be changed with filtering."
    },
    onStyleHeaderCell: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback for a dynamic calculation of a custom className for a cell in the header row"
    },
    onStyleRow: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback for a dynamic calculation of a custom className for a table body row"
    },
    onStyleCell: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback for a dynamic calculation of a custom className for a cell within the table body"
    },
    onComponentRender: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback called on every render to access information on columns definitions"
    },
    phone: {
      type: {
        name: "shape",
        value: {
          id: {
            name: "string",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          id: {
            name: "string",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          id: {
            name: "string",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
};