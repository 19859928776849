import "core-js/modules/es.string.replace";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _indexOfInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/index-of";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import { isIntlShape } from "@jutro/locale";
import get from "lodash/get";
import set from "lodash/set";
import isArray from "lodash/isArray";
import cloneDeep from "lodash/cloneDeep";
import isNil from "lodash/isNil";
import { _ITEM_DATA_, _ITEM_INDEX_, _ITEM_PATH_, VARIABLE_FORMAT_REGEX } from "../common/templates";
function formatString(content, repeatItem, repeatItemIndex, repeatItemPath) {
  var _objectSpread2,
    isIntl = isIntlShape(content),
    str = isIntl ? content.defaultMessage : content;
  if (!str || !_includesInstanceProperty(str).call(str, "{") || !repeatItem) return content;
  var variables = str.match(VARIABLE_FORMAT_REGEX),
    formatted = _reduceInstanceProperty(variables).call(variables, function (formattedString, variable) {
      var value,
        key = variable.substr(1, variable.length - 2);
      switch (key) {
        case _ITEM_PATH_:
          value = repeatItemPath;
          break;
        case _ITEM_INDEX_:
          value = repeatItemIndex;
          break;
        default:
          value = get(repeatItem, key, "");
      }
      return formattedString === variable ? value : formattedString.replace(variable, value);
    }, str);
  return isIntl ? _objectSpread(_objectSpread({}, content), {}, {
    id: content.id,
    defaultMessage: formatted,
    args: _objectSpread((_objectSpread2 = {}, _defineProperty(_objectSpread2, _ITEM_PATH_, repeatItemPath), _defineProperty(_objectSpread2, _ITEM_INDEX_, repeatItemIndex), _objectSpread2), repeatItem)
  }) : formatted;
}
function resolveContent(template, repeatPath, repeatItem, repeatIndex, variableProps) {
  var _context,
    _context2,
    id = template.id,
    templateContent = template.content,
    componentProps = template.componentProps,
    templateOverrides = {
      componentProps: {}
    },
    repeatItemPath = repeatPath ? _concatInstanceProperty(_context = "".concat(repeatPath, "[")).call(_context, repeatIndex, "]") : repeatIndex;
  isArray(variableProps) && variableProps.forEach(function (variableProp) {
    switch (variableProp) {
      case "content":
        if (!templateContent) break;
        templateOverrides.content = isArray(templateContent) ? _mapInstanceProperty(templateContent).call(templateContent, function (nestedTemplate) {
          return resolveContent(nestedTemplate, repeatPath, repeatItem, repeatIndex, variableProps);
        }) : formatString(templateContent, repeatItem, repeatIndex, repeatItemPath);
        break;
      case "data":
        componentProps && componentProps.data && (templateOverrides.componentProps.data = componentProps.data === "{".concat(_ITEM_DATA_, "}") ? repeatItem : formatString(componentProps.data, repeatItem, repeatIndex, repeatItemPath));
        break;
      default:
        var variableValue = get(componentProps, variableProp);
        if (function (variableProp, componentProps, templateOverrides) {
          var firstDotIndex = _indexOfInstanceProperty(variableProp).call(variableProp, ".");
          if (-1 !== firstDotIndex) {
            var firstProp = variableProp.substr(0, firstDotIndex),
              firstValue = get(componentProps, firstProp);
            firstValue instanceof Object && void 0 === templateOverrides.componentProps[firstProp] && set(templateOverrides.componentProps, firstProp, cloneDeep(firstValue));
          }
        }(variableProp, componentProps, templateOverrides), void 0 !== variableValue) {
          var resolvedValue = formatString(variableValue, repeatItem, repeatIndex, repeatItemPath);
          isNil(resolvedValue) || "" === resolvedValue ? set(templateOverrides.componentProps, variableProp, void 0) : set(templateOverrides.componentProps, variableProp, resolvedValue);
        }
    }
  });
  var repeatIndexId = _concatInstanceProperty(_context2 = "".concat(id)).call(_context2, repeatIndex);
  return _objectSpread(_objectSpread(_objectSpread({}, template), templateOverrides), {}, {
    componentProps: _objectSpread(_objectSpread({}, componentProps), templateOverrides.componentProps),
    key: repeatIndexId,
    id: repeatIndexId
  });
}
export function iterateMetadata(_ref) {
  var resolvedContent,
    repeatDataLength,
    _context3,
    contentAfter = _ref.contentAfter,
    contentBefore = _ref.contentBefore,
    contentEmpty = _ref.contentEmpty,
    contentRepeat = _ref.contentRepeat,
    contentRepeatAfter = _ref.contentRepeatAfter,
    contentRepeatBefore = _ref.contentRepeatBefore,
    repeatData = _ref.repeatData,
    repeatPath = _ref.repeatPath;
  if (isArray(repeatData) && repeatData.length > 0) {
    var template = contentRepeat.content,
      variableProps = contentRepeat.variableProps;
    repeatDataLength = repeatData.length, resolvedContent = _mapInstanceProperty(repeatData).call(repeatData, function (repeatItem, repeatIndex) {
      return resolveContent(template, repeatPath, repeatItem, repeatIndex, variableProps);
    });
  } else resolvedContent = contentEmpty || [];
  (contentBefore || contentAfter || contentRepeatBefore || contentRepeatAfter) && (resolvedContent = _concatInstanceProperty(_context3 = []).call(_context3, _toConsumableArray(contentBefore || []), _toConsumableArray(repeatDataLength && contentRepeatBefore ? contentRepeatBefore : []), _toConsumableArray(resolvedContent), _toConsumableArray(repeatDataLength && contentRepeatAfter ? contentRepeatAfter : []), _toConsumableArray(contentAfter || [])));
  return resolvedContent;
}