import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import unique from "unique-selector";
import { FIRST_DATA_ROW, HEADER_ROW } from "./constants";
export var createOnKeyDown = function (focusedRef, parametersRef) {
  return function (event) {
    var _parametersRef$curren = parametersRef.current,
      lastRowIndex = _parametersRef$curren.lastRowIndex,
      firstColumnIndex = _parametersRef$curren.firstColumnIndex,
      lastColumnIndex = _parametersRef$curren.lastColumnIndex,
      _focusedRef$current$f = focusedRef.current.focused,
      prevFocusedColumn = _focusedRef$current$f.column,
      prevFocusedRow = _focusedRef$current$f.row,
      newFocusedColumn = prevFocusedColumn,
      newFocusedRow = prevFocusedRow;
    switch (event.key) {
      case "ArrowLeft":
        prevFocusedColumn > firstColumnIndex && (newFocusedColumn = prevFocusedColumn - 1);
        break;
      case "ArrowRight":
        prevFocusedColumn < lastColumnIndex && (newFocusedColumn = prevFocusedColumn + 1);
        break;
      case "ArrowUp":
        prevFocusedRow > HEADER_ROW && (newFocusedRow = prevFocusedRow - 1);
        break;
      case "ArrowDown":
        prevFocusedRow < lastRowIndex && (newFocusedRow = prevFocusedRow + 1);
        break;
      case "PageUp":
        newFocusedRow = FIRST_DATA_ROW;
        break;
      case "PageDown":
        newFocusedRow = lastRowIndex;
        break;
      case "Home":
        event.ctrlKey && (newFocusedRow = FIRST_DATA_ROW), newFocusedColumn = firstColumnIndex;
        break;
      case "End":
        event.ctrlKey && (newFocusedRow = lastRowIndex), newFocusedColumn = lastColumnIndex;
        break;
      default:
        return;
    }
    focusedRef.current.setFocused(function (current) {
      return _objectSpread(_objectSpread({}, current), {}, {
        column: newFocusedColumn,
        row: newFocusedRow,
        keyDownTarget: unique(event.target)
      });
    });
  };
};