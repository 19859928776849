import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _JSON$stringify from "@babel/runtime-corejs3/core-js-stable/json/stringify";
import React, { useState } from "react";
import { uniqueInnerId } from "@jutro/platform";
import { useTranslator } from "@jutro/locale";
import { QuickView, QuickViewHeader, QuickViewBody, QuickViewFooter } from "@jutro/quick-view";
import { useRowEdit } from "../EditForm/useRowEdit";
export var EditingQuickView = function (_ref) {
  var isOpen = _ref.isOpen,
    onShowChange = _ref.onShowChange,
    row = _ref.row,
    rowId = _ref.rowId,
    EditFormContent = _ref.renderFormContent,
    displayAllColumns = _ref.displayAllColumns,
    onRowSave = _ref.onRowSave,
    onRowEditSubmit = _ref.onRowEditSubmit,
    onEditCancel = _ref.onEditCancel,
    EditHeader = _ref.renderHeader,
    title = _ref.title,
    formRules = _ref.formRules,
    displayBundledMessages = _ref.displayBundledMessages,
    confirmClose = _ref.confirmClose,
    _useState = useState(!1),
    _useState2 = _slicedToArray(_useState, 2),
    hasUnsavedChanges = _useState2[0],
    setHasUnsavedChanges = _useState2[1],
    _useRowEdit = useRowEdit({
      row: row,
      formRules: formRules,
      onRowSave: onRowSave,
      onRowEditSubmit: onRowEditSubmit,
      onEditCancel: onEditCancel
    }),
    onSaveButtonClick = _useRowEdit.onSaveButtonClick,
    onCancelButtonClick = _useRowEdit.onCancelButtonClick,
    editedRow = _useRowEdit.editedRow,
    _setEditedRow = _useRowEdit.setEditedRow,
    isSubmitted = _useRowEdit.isSubmitted,
    isValid = _useRowEdit.isValid,
    registerValidation = _useRowEdit.registerValidation,
    resolveFormValidation = _useRowEdit.resolveFormValidation,
    validationMessages = _useRowEdit.validationMessages,
    translator = useTranslator(),
    titleId = uniqueInnerId("editingModalTitle", "titleId").titleId,
    headerProps = {
      icon: "gw-edit",
      title: translator(title)
    };
  return EditHeader && (headerProps = {
    renderTitle: function () {
      return React.createElement(EditHeader, {
        title: translator(title),
        titleId: titleId,
        row: row,
        rowId: rowId
      });
    }
  }), React.createElement(QuickView, {
    id: "editing-quick-view",
    show: isOpen,
    onShowChange: onShowChange,
    confirmClose: confirmClose && hasUnsavedChanges,
    focusAfterRender: "element"
  }, React.createElement(QuickViewHeader, headerProps), React.createElement(QuickViewBody, null, EditFormContent && React.createElement(EditFormContent, {
    editedRow: editedRow,
    setEditedRow: function (newEditedRow) {
      _setEditedRow(newEditedRow), setHasUnsavedChanges(_JSON$stringify(row) !== _JSON$stringify(newEditedRow));
    },
    displayAllColumns: displayAllColumns,
    registerValidation: registerValidation,
    resolveFormValidation: resolveFormValidation,
    showErrors: isSubmitted,
    validationMessages: validationMessages,
    displayBundledMessages: displayBundledMessages
  })), React.createElement(QuickViewFooter, {
    onSaveClick: onSaveButtonClick,
    onCancelClick: onCancelButtonClick,
    closeOnSave: isValid,
    closeOnCancel: !0
  }));
};
EditingQuickView.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "EditingQuickView"
}, EditingQuickView.__docgenInfo = {
  componentName: "EditingQuickView",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "EditingQuickView",
  methods: [],
  actualName: "EditingQuickView"
};