import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["menuId", "id"];
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import React from "react";
import { DropdownMenuLink } from "@jutro/components";
import { useTranslator } from "@jutro/locale";
import { useBreakpoint } from "@jutro/layout";
export var MenuItem = function (_ref) {
  var _context,
    menuId = _ref.menuId,
    id = _ref.id,
    otherProps = _objectWithoutProperties(_ref, _excluded),
    translator = useTranslator(),
    breakpointProps = useBreakpoint(otherProps).breakpointProps,
    label = breakpointProps.label,
    onClick = breakpointProps.onClick,
    _breakpointProps$wrap = breakpointProps.wrapperProps,
    wrapperProps = void 0 === _breakpointProps$wrap ? {} : _breakpointProps$wrap,
    icon = wrapperProps.icon,
    iconPosition = wrapperProps.iconPosition,
    iconClassName = wrapperProps.iconClassName,
    className = wrapperProps.className,
    labelComponent = React.createElement("span", {
      className: className
    }, translator(label));
  return React.createElement(DropdownMenuLink, {
    id: _concatInstanceProperty(_context = "".concat(menuId, "-")).call(_context, id),
    onClick: onClick,
    icon: icon,
    iconPosition: iconPosition,
    iconClassName: iconClassName
  }, labelComponent);
};
MenuItem.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "MenuItem"
}, MenuItem.__docgenInfo = {
  componentName: "MenuItem",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "MenuItem",
  methods: [],
  actualName: "MenuItem"
};