import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import React, { useMemo, useState } from "react";
import { Icon, Link } from "@jutro/components";
import { useTranslator } from "@jutro/locale";
import { generateComponentId } from "@jutro/platform";
import { messages } from "./DefaultEditForm.messages";
import styles from "./DefaultEditForm.module.css";
var HiddenColumnsNotification = function (_ref2) {
  var onClick = _ref2.onClick,
    translator = useTranslator(),
    notificationId = useMemo(function () {
      return generateComponentId("hiddenColumnsNotification");
    }, []);
  return React.createElement("div", {
    id: notificationId,
    className: styles.notification
  }, React.createElement(Icon, {
    icon: "gw-info",
    size: "large",
    className: styles.notificationIcon
  }), React.createElement("div", {
    className: styles.notificationMessage
  }, React.createElement("p", null, translator(messages.columnsHidden)), React.createElement(Link, {
    onClick: onClick,
    className: styles.showAll
  }, translator(messages.showAll))));
};
export var useColumnsHiddenByUser = function (_ref3) {
  var _ref3$displayAllColum = _ref3.displayAllColumns,
    displayAllColumns = void 0 === _ref3$displayAllColum || _ref3$displayAllColum,
    columnsConfigModalAvailable = _ref3.columnsConfigModalAvailable,
    columns = _ref3.columns,
    translator = useTranslator(),
    _useState = useState(!1),
    _useState2 = _slicedToArray(_useState, 2),
    showAllColumns = _useState2[0],
    setShowAllColumns = _useState2[1],
    filteredColumns = _filterInstanceProperty(columns).call(columns, function (displayAllColumns, showAllColumns) {
      return function (_ref) {
        var displayInEditForm = _ref.displayInEditForm,
          visible = _ref.visible;
        return !!showAllColumns || !("ifVisible" === displayInEditForm && !displayAllColumns && !visible);
      };
    }(displayAllColumns, showAllColumns)),
    notification = columnsConfigModalAvailable && filteredColumns.length !== columns.length ? React.createElement(HiddenColumnsNotification, {
      onClick: function () {
        return setShowAllColumns(!0);
      }
    }) : null;
  return {
    filteredColumns: filteredColumns,
    notification: notification,
    notificationAriaLabel: notification ? translator(messages.columnsHiddenAriaLabel) : ""
  };
};
HiddenColumnsNotification.__docgenInfo = {
  componentName: "HiddenColumnsNotification",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "HiddenColumnsNotification",
  methods: [],
  actualName: "HiddenColumnsNotification"
};