import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import React, { useContext } from "react";
import { IntlContext, useTranslator } from "@jutro/locale";
import { messages } from "./PageSwitcher.messages";
import { isPageInvalid } from "./helpers";
import styles from "./PageSwitcherSummary.module.css";
export var PageSwitcherSummary = function (_ref) {
  var _formatNumber3,
    totalItems = _ref.totalItems,
    page = _ref.page,
    lastPage = _ref.lastPage,
    pageSize = _ref.pageSize,
    translator = useTranslator(),
    formatNumber = (useContext(IntlContext) || {}).formatNumber,
    range = function () {
      var _formatNumber, _formatNumber2, _context;
      if (isPageInvalid(page, lastPage)) return "?";
      if (!totalItems) return "0";
      var firstItemIndex = function (pageSize, page) {
          return pageSize * (page - 1) + 1;
        }(pageSize, page),
        lastItemIndex = function (pageSize, page, totalItems) {
          var lastItemIndex = pageSize * page;
          return lastItemIndex > totalItems ? totalItems : lastItemIndex;
        }(pageSize, page, totalItems),
        firstItemIndexFormatted = null !== (_formatNumber = null == formatNumber ? void 0 : formatNumber(firstItemIndex)) && void 0 !== _formatNumber ? _formatNumber : firstItemIndex,
        lastItemIndexFormatted = null !== (_formatNumber2 = null == formatNumber ? void 0 : formatNumber(lastItemIndex)) && void 0 !== _formatNumber2 ? _formatNumber2 : lastItemIndex;
      return _concatInstanceProperty(_context = "".concat(firstItemIndexFormatted, " - ")).call(_context, lastItemIndexFormatted);
    }(),
    total = String(null !== (_formatNumber3 = null == formatNumber ? void 0 : formatNumber(totalItems)) && void 0 !== _formatNumber3 ? _formatNumber3 : totalItems);
  return React.createElement("p", null, React.createElement("span", {
    className: styles.screenReaderOnly
  }, translator(messages.currentRows)), translator(messages.itemsXofY, {
    range: range,
    total: total
  }));
};
PageSwitcherSummary.__docgenInfo = {
  description: "Component showing displayed items range\n\n@metadataType container",
  methods: [],
  displayName: "PageSwitcherSummary"
}, PageSwitcherSummary.__docgenInfo = {
  componentName: "PageSwitcherSummary",
  packageName: "@jutro/lab-preview-dataview",
  description: "Component showing displayed items range",
  displayName: "PageSwitcherSummary",
  methods: [],
  actualName: "PageSwitcherSummary",
  metadataType: "container"
};