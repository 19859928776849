import { defineMessages } from "react-intl";
export default defineMessages({
  yes: {
    id: "jutro-dataview.hooks.useSyncData.formatterByType.yes",
    defaultMessage: "Yes"
  },
  no: {
    id: "jutro-dataview.hooks.useSyncData.formatterByType.no",
    defaultMessage: "No"
  },
  true: {
    id: "jutro-dataview.hooks.useSyncData.formatterByType.true",
    defaultMessage: "True"
  },
  false: {
    id: "jutro-dataview.hooks.useSyncData.formatterByType.false",
    defaultMessage: "False"
  }
});