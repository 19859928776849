import React from "react";
import { ErrorNotice } from "@jutro/components";
import { messages } from "../StateOverlay.messages";
export var NoRowsContent = function (_ref) {
  var message = _ref.message,
    title = _ref.title;
  return React.createElement(ErrorNotice, {
    mainMessage: title,
    detailedMessage: message,
    actions: []
  });
};
NoRowsContent.defaultProps = {
  title: messages.noRowsTitle,
  message: messages.noRowsText
}, NoRowsContent.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "NoRowsContent",
  props: {
    title: {
      defaultValue: {
        value: "messages.noRowsTitle",
        computed: !0
      },
      required: !1
    },
    message: {
      defaultValue: {
        value: "messages.noRowsText",
        computed: !0
      },
      required: !1
    }
  }
}, NoRowsContent.__docgenInfo = {
  componentName: "NoRowsContent",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "NoRowsContent",
  methods: [],
  actualName: "NoRowsContent",
  props: {
    title: {
      defaultValue: {
        value: "messages.noRowsTitle",
        computed: !0
      },
      required: !1
    },
    message: {
      defaultValue: {
        value: "messages.noRowsText",
        computed: !0
      },
      required: !1
    }
  }
};