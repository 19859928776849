import { defineMessages } from "react-intl";
export var messages = defineMessages({
  loadMoreError: {
    id: "jutro.lab-preview-dataview.ListView.loadMoreError",
    defaultMessage: "Could not load more"
  },
  paginationAriaLabel: {
    id: "jutro-dataview.pagination.paginationAriaLabel",
    defaultMessage: "{tableName} pagination"
  }
});