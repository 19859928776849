import { defineMessages } from "react-intl";
export var messages = defineMessages({
  openFilters: {
    id: "jutro-dataview.filterBar.openFilters",
    defaultMessage: "Open filters"
  },
  openFiltersWithBadge: {
    id: "jutro-dataview.filterBar.openFiltersWithBadge",
    defaultMessage: "Open filters, some filters are applied"
  }
});