import { defineMessages } from "react-intl";
export default defineMessages({
  title: {
    id: "jutro-quick-view.QuickViewLeaveModal.Title",
    defaultMessage: "Unsaved changes"
  },
  cancel: {
    id: "jutro-quick-view.QuickViewLeaveModal.Cancel",
    defaultMessage: "Keep editing"
  },
  cancelAriaLabel: {
    id: "jutro-quick-view.QuickViewLeaveModal.CancelAriaLabel",
    defaultMessage: "Keep editing"
  },
  ignoreChanges: {
    id: "jutro-quick-view.QuickViewLeaveModal.IgnoreChange",
    defaultMessage: "Discard changes"
  },
  closeModalAriaLabel: {
    id: "jutro-quick-view.QuickViewLeaveModal.CloseModalAriaLabel",
    defaultMessage: "Close and discard changes"
  },
  notification: {
    id: "jutro-quick-view.QuickViewLeaveModal.Notification",
    defaultMessage: "You have some unsaved changes that will be lost if you close this panel."
  }
});