import { useMemo } from "react";
import get from "lodash/fp/get";
export var useOnGetRowId = function (onGetRowIdProp, rowIdPath) {
  return useMemo(function () {
    if (onGetRowIdProp) return onGetRowIdProp;
    var getter = get(null != rowIdPath ? rowIdPath : "id");
    return function (row) {
      return getter(row);
    };
  }, [onGetRowIdProp, rowIdPath]);
};