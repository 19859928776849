import "core-js/modules/es.string.replace";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import isNil from "lodash/isNil";
import isString from "lodash/isString";
import { format, parse, isEqual, isAfter, isBefore } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { DATA_TYPE_OBJECT } from "@jutro/prop-types";
import { formatDateToDataType, parseDateFromDataType } from "@jutro/locale";
function resetTime(date) {
  return date.setHours(0), date.setMinutes(0), date.setSeconds(0), date.setMilliseconds(0), date;
}
export function formatValueToDataType(value, options) {
  if (value) {
    var today,
      dataType = options.dataType,
      includeTime = options.includeTime,
      timezone = options.timezone,
      preserveTimeZone = options.preserveTimeZone,
      _getDefaultDate$value = _objectSpread(_objectSpread({}, {
        year: (today = new Date()).getFullYear(),
        month: today.getMonth(),
        day: today.getDate(),
        hour: 0,
        minute: 0
      }), value),
      year = _getDefaultDate$value.year,
      month = _getDefaultDate$value.month,
      day = _getDefaultDate$value.day,
      hour = _getDefaultDate$value.hour,
      minute = _getDefaultDate$value.minute;
    if (preserveTimeZone) return function (value, dataType) {
      var _context;
      if (isValidDate(value)) {
        if (dataType === DATA_TYPE_OBJECT) return value;
        var year = value.year,
          _value$month = value.month,
          month = void 0 === _value$month ? 0 : _value$month,
          _value$day = value.day,
          day = void 0 === _value$day ? 1 : _value$day,
          _value$hour = value.hour,
          hour = void 0 === _value$hour ? 0 : _value$hour,
          _value$minute = value.minute,
          date = new Date(year, month, day, hour, void 0 === _value$minute ? 0 : _value$minute);
        return _concatInstanceProperty(_context = "".concat(format(date, "yyyy-MM-dd"), "T")).call(_context, format(date, "HH:mm"), ":00.000Z");
      }
    }({
      year: year,
      month: month,
      day: day,
      hour: hour,
      minute: minute
    }, dataType);
    if (timezone && includeTime) {
      if (!isValidDate(value)) return;
      var utcDate = zonedTimeToUtc(new Date(year, month, day, hour, minute), timezone);
      return dataType === DATA_TYPE_OBJECT ? {
        year: utcDate.getUTCFullYear(),
        month: utcDate.getUTCMonth(),
        day: utcDate.getUTCDate(),
        hour: utcDate.getUTCHours(),
        minute: utcDate.getUTCMinutes()
      } : utcDate.toISOString();
    }
    var date = new Date(year, month, day, hour, minute);
    return formatDateToDataType(date, dataType, includeTime);
  }
}
export function formatValueFromDataType(value, options) {
  if (value) {
    var includeTime = options.includeTime,
      timezone = options.timezone;
    if (options.preserveTimeZone) return function (value) {
      if (value instanceof Object && !(value instanceof Date)) return value;
      if (isString(value)) {
        var _value$replace$split = value.replace("Z", "").split("T"),
          _value$replace$split2 = _slicedToArray(_value$replace$split, 2),
          datePart = _value$replace$split2[0],
          timePart = _value$replace$split2[1],
          date = parse(datePart, "yyyy-MM-dd", new Date()),
          time = parse(timePart, "HH:mm:ss.SSS", new Date());
        return {
          year: date.getFullYear(),
          month: date.getMonth(),
          day: date.getDate(),
          hour: time.getHours(),
          minute: time.getMinutes()
        };
      }
    }(value);
    if (timezone && includeTime) {
      var zonedDate = utcToZonedTime(parseDateFromDataType(value, !0), timezone);
      return {
        year: zonedDate.getFullYear(),
        month: zonedDate.getMonth(),
        day: zonedDate.getDate(),
        hour: zonedDate.getHours(),
        minute: zonedDate.getMinutes()
      };
    }
    var date = parseDateFromDataType(value, includeTime);
    if (!Number.isNaN(Number(date.getTime()))) return {
      year: date.getFullYear(),
      month: date.getMonth(),
      day: date.getDate(),
      hour: date.getHours(),
      minute: date.getMinutes()
    };
  }
}
export function parseBoundaryDate(date) {
  if (date) {
    var dateObject = parseDateFromDataType(date, !0);
    return format(dateObject, "yyyy-MM-dd");
  }
}
export function getMinTime(minDate, value) {
  if (value && minDate) {
    var minDateObject = parseDateFromDataType(minDate, !0),
      valueDateObject = parseDateFromDataType(value, !0),
      minTime = {
        hour: minDateObject.getHours(),
        minute: minDateObject.getMinutes()
      };
    return resetTime(minDateObject), resetTime(valueDateObject), isEqual(valueDateObject, minDateObject) ? minTime : !!isBefore(valueDateObject, minDateObject) || void 0;
  }
}
export function getMaxTime(maxDate, value) {
  if (value && maxDate) {
    var maxDateObject = parseDateFromDataType(maxDate, !0),
      valueDateObject = parseDateFromDataType(value, !0),
      maxTime = {
        hour: maxDateObject.getHours(),
        minute: maxDateObject.getMinutes()
      };
    return resetTime(maxDateObject), resetTime(valueDateObject), isEqual(valueDateObject, maxDateObject) ? maxTime : !!isAfter(valueDateObject, maxDateObject) || void 0;
  }
}
export function isValidDate(value) {
  if (!value) return !1;
  var year = value.year,
    month = value.month,
    day = value.day;
  return !isNil(year) && !isNil(month) && !isNil(day);
}
export function isValidTime(value) {
  if (!value) return !1;
  var hour = value.hour,
    minute = value.minute;
  return !isNil(hour) && !isNil(minute);
}