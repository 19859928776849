import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React, { useContext, createContext, useMemo } from "react";
import isEmpty from "lodash/isEmpty";
import noop from "lodash/noop";
import PropTypes from "prop-types";
import { useBreakpoint } from "@jutro/layout";
import { useViewContext } from "../../featuresProvider";
var filteringContextInitialValue = {
  filters: {},
  onFiltersChange: noop,
  areFiltersApplied: !1
};
export var FilteringContext = createContext(filteringContextInitialValue);
export var useFilteringContext = function () {
  return useContext(FilteringContext);
};
export var DataViewFiltering = function (props) {
  var view = useViewContext(),
    breakpointProps = useBreakpoint(props).breakpointProps,
    filters = breakpointProps.filters,
    onFiltersChange = breakpointProps.onFiltersChange,
    renderInlineFilters = breakpointProps.renderInlineFilters,
    renderFiltersTrigger = breakpointProps.renderFiltersTrigger,
    triggerIcon = breakpointProps.triggerIcon,
    uiProps = breakpointProps.uiProps,
    contextValue = useMemo(function () {
      return {
        onFiltersChange: onFiltersChange,
        renderInlineFilters: renderInlineFilters,
        renderFiltersTrigger: renderFiltersTrigger,
        triggerIcon: triggerIcon,
        filters: filters,
        uiProps: uiProps,
        areFiltersApplied: !isEmpty(filters)
      };
    }, [onFiltersChange, renderInlineFilters, renderFiltersTrigger, triggerIcon, uiProps, filters]);
  return React.createElement(FilteringContext.Provider, {
    value: contextValue
  }, view);
};
var basePropTypes = {
    onFiltersChange: PropTypes.func.isRequired,
    renderInlineFilters: PropTypes.func,
    renderFiltersTrigger: PropTypes.func,
    triggerIcon: PropTypes.string,
    uiProps: PropTypes.objectOf(PropTypes.any),
    filters: PropTypes.objectOf(PropTypes.any).isRequired
  },
  breakpointPropTypes = _objectSpread(_objectSpread({}, basePropTypes), {}, {
    onFiltersChange: PropTypes.func,
    filters: PropTypes.objectOf(PropTypes.any)
  }),
  propTypes = _objectSpread(_objectSpread({}, basePropTypes), {}, {
    phone: PropTypes.shape(breakpointPropTypes),
    phoneWide: PropTypes.shape(breakpointPropTypes),
    tablet: PropTypes.shape(breakpointPropTypes)
  });
DataViewFiltering.propTypes = propTypes, DataViewFiltering.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "DataViewFiltering",
  props: {
    onFiltersChange: {
      type: {
        name: "func"
      },
      required: !0,
      description: "Callback to be triggered when filter values change"
    },
    renderInlineFilters: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback which renders a component to manage filtering.\nIf you provide it in ListView, filters are not rendered in modal."
    },
    renderFiltersTrigger: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback which renders the button to trigger filters.\nIn TableView by default toggles filters element.\nIn ListView by default trigger shows a modal.\nIf you provide `renderInlineFilters`, the trigger doesn't show the modal but toggles the element which contains the filters."
    },
    triggerIcon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Allows customization of an icon used inside the trigger button"
    },
    uiProps: {
      type: {
        name: "objectOf",
        value: {
          name: "any"
        }
      },
      required: !1,
      description: "UI props to render filtering form fields"
    },
    filters: {
      type: {
        name: "objectOf",
        value: {
          name: "any"
        }
      },
      required: !0,
      description: "Object with filters to be applied"
    },
    phone: {
      type: {
        name: "shape",
        value: {
          onFiltersChange: {
            name: "func",
            required: !1
          },
          filters: {
            name: "objectOf",
            value: {
              name: "any"
            },
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          onFiltersChange: {
            name: "func",
            required: !1
          },
          filters: {
            name: "objectOf",
            value: {
              name: "any"
            },
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          onFiltersChange: {
            name: "func",
            required: !1
          },
          filters: {
            name: "objectOf",
            value: {
              name: "any"
            },
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
}, DataViewFiltering.__docgenInfo = {
  componentName: "DataViewFiltering",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "DataViewFiltering",
  methods: [],
  actualName: "DataViewFiltering",
  props: {
    onFiltersChange: {
      type: {
        name: "func"
      },
      required: !0,
      description: "Callback to be triggered when filter values change"
    },
    renderInlineFilters: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback which renders a component to manage filtering.\nIf you provide it in ListView, filters are not rendered in modal."
    },
    renderFiltersTrigger: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback which renders the button to trigger filters.\nIn TableView by default toggles filters element.\nIn ListView by default trigger shows a modal.\nIf you provide `renderInlineFilters`, the trigger doesn't show the modal but toggles the element which contains the filters."
    },
    triggerIcon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Allows customization of an icon used inside the trigger button"
    },
    uiProps: {
      type: {
        name: "objectOf",
        value: {
          name: "any"
        }
      },
      required: !1,
      description: "UI props to render filtering form fields"
    },
    filters: {
      type: {
        name: "objectOf",
        value: {
          name: "any"
        }
      },
      required: !0,
      description: "Object with filters to be applied"
    },
    phone: {
      type: {
        name: "shape",
        value: {
          onFiltersChange: {
            name: "func",
            required: !1
          },
          filters: {
            name: "objectOf",
            value: {
              name: "any"
            },
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          onFiltersChange: {
            name: "func",
            required: !1
          },
          filters: {
            name: "objectOf",
            value: {
              name: "any"
            },
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          onFiltersChange: {
            name: "func",
            required: !1
          },
          filters: {
            name: "objectOf",
            value: {
              name: "any"
            },
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
};