import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Array$from from "@babel/runtime-corejs3/core-js-stable/array/from";
import React, { useEffect, useContext } from "react";
import cx from "classnames";
import { IconButton } from "@jutro/components";
import { useEvent } from "@jutro/platform";
import { BreakpointTrackerContext, Flex, FlexItem } from "@jutro/layout";
import { useTranslator } from "@jutro/locale";
import { QuickViewAnimation } from "./QuickViewAnimation";
import { splitQuickViewChildren } from "./helpers/splitQuickViewChildren";
import { useQuickViewInternal } from "./QuickViewProvider";
import messages from "./QuickView.messages";
import styles from "./QuickView.module.css";
import { useKeyboardNavigation } from "./hooks/useKeyboardNavigation";
export var QuickViewContent = function (_ref) {
  var children = _ref.children,
    show = _ref.show,
    id = _ref.id,
    closeOnEsc = _ref.closeOnEsc,
    onClose = _ref.onClose,
    className = _ref.className,
    useOverlay = _ref.useOverlay,
    dialogRef = _ref.dialogRef,
    focusPreviousElement = _ref.focusPreviousElement,
    _ref$focusAfterRender = _ref.focusAfterRender,
    focusAfterRender = void 0 === _ref$focusAfterRender ? "panel" : _ref$focusAfterRender,
    breakpoint = useContext(BreakpointTrackerContext),
    translator = useTranslator(),
    _useQuickViewInternal2 = useQuickViewInternal().styles,
    right = _useQuickViewInternal2.right,
    visibleHeight = _useQuickViewInternal2.visibleHeight,
    totalHeight = _useQuickViewInternal2.totalHeight,
    _useKeyboardNavigatio = useKeyboardNavigation({
      dialogRef: dialogRef,
      useOverlay: useOverlay,
      focusPreviousElement: focusPreviousElement
    }),
    quickViewRef = _useKeyboardNavigatio.contentRef,
    focusableElementsSelector = _useKeyboardNavigatio.focusableElementsSelector,
    onEscapePress = useEvent(function (evt) {
      closeOnEsc && "Escape" === evt.key && (evt.preventDefault(), evt.stopPropagation(), onClose());
    }),
    onQuickViewEntered = useEvent(function () {
      var _quickViewRef$current;
      if (show) if ("panel" !== focusAfterRender) {
        if ("element" === focusAfterRender) {
          var _context,
            focusableElements = quickViewRef.current.querySelectorAll(focusableElementsSelector),
            _Array$from$filter = _filterInstanceProperty(_context = _Array$from(focusableElements)).call(_context, function (e) {
              return "close-icon-button" !== e.id;
            }),
            firstFocusableElement = _slicedToArray(_Array$from$filter, 1)[0];
          null == firstFocusableElement || firstFocusableElement.focus();
        }
      } else null === (_quickViewRef$current = quickViewRef.current) || void 0 === _quickViewRef$current || _quickViewRef$current.focus({
        preventScroll: !0
      });
    });
  useEffect(function () {
    return onQuickViewEntered();
  }, [onQuickViewEntered]);
  var _splitQuickViewChildr = splitQuickViewChildren(children),
    header = _splitQuickViewChildr.header,
    actions = _splitQuickViewChildr.actions,
    body = _splitQuickViewChildr.body,
    footer = _splitQuickViewChildr.footer;
  return React.createElement(React.Fragment, null, React.createElement(QuickViewAnimation, {
    show: show,
    onEntered: onQuickViewEntered
  }, React.createElement("div", {
    id: id,
    "data-testid": id,
    ref: quickViewRef,
    onKeyDown: onEscapePress,
    className: cx(styles.absoluteWrapper, _defineProperty({}, styles.fullWidth, "phone" === breakpoint), className),
    style: {
      right: right,
      height: totalHeight
    },
    role: "dialog",
    tabIndex: 0
  }, React.createElement("div", {
    className: styles.stickyWrapper,
    style: {
      height: visibleHeight
    }
  }, React.createElement(Flex, {
    alignItems: "center",
    gap: "small",
    className: styles.quickViewHeaderContainer
  }, React.createElement(FlexItem, {
    grow: 1,
    className: styles.quickViewTitleContainer
  }, header), React.createElement(Flex, {
    justifyContent: "right",
    alignItems: "center",
    gap: "large"
  }, actions, React.createElement(IconButton, {
    id: "close-icon-button",
    icon: "gw-close",
    onClick: function () {
      return onClose();
    },
    className: styles.closeButton,
    ariaLabel: translator(messages.closeButton)
  }))), React.createElement("div", {
    className: styles.contentWrapper
  }, body), footer && React.cloneElement(footer, {
    onClose: onClose
  })))));
};
QuickViewContent.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "QuickViewContent",
  props: {
    focusAfterRender: {
      defaultValue: {
        value: "'panel'",
        computed: !1
      },
      required: !1
    }
  }
}, QuickViewContent.__docgenInfo = {
  componentName: "QuickViewContent",
  packageName: "@jutro/quick-view",
  description: "",
  displayName: "QuickViewContent",
  methods: [],
  actualName: "QuickViewContent",
  props: {
    focusAfterRender: {
      defaultValue: {
        value: "'panel'",
        computed: !1
      },
      required: !1
    }
  }
};