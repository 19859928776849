import { useCallback } from "react";
import cx from "classnames";
export var useItemClassNames = function (_ref) {
  var internalClassNames = _ref.internalClassNames,
    onStyleRow = _ref.onStyleRow,
    onStyleHeaderCell = _ref.onStyleHeaderCell,
    onStyleCell = _ref.onStyleCell;
  return useCallback(function (rawRow, rowId, index) {
    var _internalClassNames$i, _internalClassNames$i2;
    return {
      item: cx(null == internalClassNames ? void 0 : internalClassNames.item),
      row: cx(null == internalClassNames ? void 0 : internalClassNames.row, null == onStyleRow ? void 0 : onStyleRow({
        rowId: rowId,
        rowIndex: index,
        row: rawRow
      })),
      column: cx(null == internalClassNames ? void 0 : internalClassNames.column),
      contentContainer: cx(null == internalClassNames || null === (_internalClassNames$i = internalClassNames.item) || void 0 === _internalClassNames$i ? void 0 : _internalClassNames$i.contentContainer),
      actionContainer: cx(null == internalClassNames || null === (_internalClassNames$i2 = internalClassNames.item) || void 0 === _internalClassNames$i2 ? void 0 : _internalClassNames$i2.actionContainer),
      getHeaderCellClassName: function (columnProps) {
        var _columnProps$classNam, _columnProps$onStyleH;
        return cx(null == internalClassNames ? void 0 : internalClassNames.headerCell, null == onStyleHeaderCell ? void 0 : onStyleHeaderCell(columnProps), null === (_columnProps$classNam = columnProps.classNames) || void 0 === _columnProps$classNam ? void 0 : _columnProps$classNam.header, null === (_columnProps$onStyleH = columnProps.onStyleHeaderCell) || void 0 === _columnProps$onStyleH ? void 0 : _columnProps$onStyleH.call(columnProps, columnProps));
      },
      getBodyCellClassName: function (columnProps, value) {
        var _columnProps$classNam2, _columnProps$onStyleC;
        return cx(null == internalClassNames ? void 0 : internalClassNames.cell, null == onStyleCell ? void 0 : onStyleCell({
          columnProps: columnProps,
          rowIndex: index,
          value: value
        }), null === (_columnProps$classNam2 = columnProps.classNames) || void 0 === _columnProps$classNam2 ? void 0 : _columnProps$classNam2.cell, null === (_columnProps$onStyleC = columnProps.onStyleCell) || void 0 === _columnProps$onStyleC ? void 0 : _columnProps$onStyleC.call(columnProps, {
          columnProps: columnProps,
          rowIndex: index,
          value: value
        }));
      }
    };
  }, [internalClassNames, onStyleRow, onStyleHeaderCell, onStyleCell]);
};