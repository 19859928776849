import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["viewChangeBreakpoint"],
  _excluded2 = ["selectionEnabled"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import React, { useEffect, useMemo } from "react";
import omit from "lodash/omit";
import PropTypes from "prop-types";
import { generateComponentId, logDeprecationMessage } from "@jutro/platform";
import { useBreakpoint } from "@jutro/layout";
import { warning } from "@jutro/logger";
import { Table } from "@jutro/datatable";
import { useTranslator } from "@jutro/locale";
import { AdaptiveDataView, DataViewColumnsConfig, DataViewHeaderActions, DataViewOverlay, DataViewTitle, DataViewSelection, adaptiveDataViewBasePropTypes } from "../..";
import { useUnsupportedPropsWarning } from "../useUnsupportedPropsWarning";
import { useMappedTitleProps } from "./useMappedTitleProps";
import { useMappedHeaderActionsProps } from "./useMappedHeaderActionsProps";
import { useMappedColumnsConfigProps } from "./useMappedColumnsConfigProps";
import { useMappedOverlayProps } from "./useMappedOverlayProps";
import { excludeNull } from "../mappers";
import { useMappedSelectionProps } from "./useMappedSelection";
export var TableAdapter = function (props) {
  useEffect(function () {
    logDeprecationMessage("TableAdapter", "AdaptiveDataView (or other data view component)", "Refer to the documentation for the details", "9.0.0");
  }, []);
  var viewChangeBreakpoint = props.viewChangeBreakpoint,
    otherProps = _objectWithoutProperties(props, _excluded),
    breakpointProps = useBreakpoint(otherProps).breakpointProps,
    children = breakpointProps.children,
    className = breakpointProps.className,
    data = breakpointProps.data,
    idProp = breakpointProps.id,
    translator = useTranslator();
  useUnsupportedPropsWarning(breakpointProps);
  var titleProps = useMappedTitleProps(breakpointProps, translator),
    headerActionsProps = useMappedHeaderActionsProps(breakpointProps),
    columnsConfigProps = useMappedColumnsConfigProps(breakpointProps),
    overlayProps = useMappedOverlayProps(breakpointProps),
    _useMappedSelectionPr = useMappedSelectionProps(breakpointProps),
    selectionEnabled = _useMappedSelectionPr.selectionEnabled,
    selectionProps = _objectWithoutProperties(_useMappedSelectionPr, _excluded2),
    id = useMemo(function () {
      return idProp || (warning("TableAdapter does not have an 'id' prop, but it is required in AdaptiveDataView component."), generateComponentId("tableAdapter"));
    }, [idProp]),
    adaptiveDataViewPropsOnly = omit(breakpointProps, _Object$keys(tableBasePropTypes)),
    rowIdFunc = breakpointProps.onGetRowId || breakpointProps.rowIdPath ? {} : {
      onGetRowId: function (row, rowIndex) {
        return null != rowIndex ? rowIndex : row.id;
      }
    };
  return React.createElement(AdaptiveDataView, _extends({}, rowIdFunc, adaptiveDataViewPropsOnly, {
    id: id,
    data: excludeNull(data),
    className: excludeNull(className),
    viewChangeBreakpoint: viewChangeBreakpoint
  }), React.createElement(DataViewTitle, titleProps), React.createElement(DataViewHeaderActions, headerActionsProps), React.createElement(DataViewOverlay, overlayProps), React.createElement(DataViewColumnsConfig, columnsConfigProps), selectionEnabled && React.createElement(DataViewSelection, selectionProps), children);
};
var tableBasePropTypes = omit(Table.propTypes, ["data", "phone", "tablet", "phoneWide"]),
  tableAdapterBasePropTypes = _objectSpread(_objectSpread(_objectSpread({}, tableBasePropTypes), adaptiveDataViewBasePropTypes), {}, {
    id: PropTypes.string
  }),
  tableAdapterPropTypes = _objectSpread(_objectSpread({}, tableAdapterBasePropTypes), {}, {
    viewChangeBreakpoint: PropTypes.oneOf(["phone", "phoneWide", "tablet"]),
    phone: PropTypes.shape(tableAdapterBasePropTypes),
    phoneWide: PropTypes.shape(tableAdapterBasePropTypes),
    tablet: PropTypes.shape(tableAdapterBasePropTypes)
  });
TableAdapter.propTypes = tableAdapterPropTypes, TableAdapter.__docgenInfo = {
  description: "Maps a part of Table props and renders AdaptiveDataView\n@deprecated",
  methods: [],
  displayName: "TableAdapter",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: ""
    },
    viewChangeBreakpoint: {
      type: {
        name: "enum",
        value: [{
          value: "'phone'",
          computed: !1
        }, {
          value: "'phoneWide'",
          computed: !1
        }, {
          value: "'tablet'",
          computed: !1
        }]
      },
      required: !1,
      description: ""
    },
    phone: {
      type: {
        name: "shape",
        value: {
          id: {
            name: "string",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          id: {
            name: "string",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          id: {
            name: "string",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
}, TableAdapter.__docgenInfo = {
  componentName: "TableAdapter",
  packageName: "@jutro/lab-preview-dataview",
  description: "Maps a part of Table props and renders AdaptiveDataView",
  displayName: "TableAdapter",
  methods: [],
  actualName: "TableAdapter",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: ""
    },
    viewChangeBreakpoint: {
      type: {
        name: "enum",
        value: [{
          value: "'phone'",
          computed: !1
        }, {
          value: "'phoneWide'",
          computed: !1
        }, {
          value: "'tablet'",
          computed: !1
        }]
      },
      required: !1,
      description: ""
    },
    phone: {
      type: {
        name: "shape",
        value: {
          id: {
            name: "string",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          id: {
            name: "string",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          id: {
            name: "string",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
};