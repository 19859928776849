import _parseFloat from "@babel/runtime-corejs3/core-js-stable/parse-float";
import _findInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$values from "@babel/runtime-corejs3/core-js-stable/object/values";
import isBoolean from "lodash/isBoolean";
import isString from "lodash/isString";
import isNumber from "lodash/isNumber";
import isDate from "lodash/isDate";
import isNaN from "lodash/isNaN";
import isNil from "lodash/isNil";
import messages from "./useSyncData.messages";
export var POSITIVE_BOOLEAN_DISPLAY_VALUE = messages.yes;
export var NEGATIVE_BOOLEAN_DISPLAY_VALUE = messages.no;
export var parseCurrencyToNumber = function (value) {
  return isNumber(value) ? value : (result = isString(value) ? _parseFloat(value) : _parseFloat(String(value.amount)), isNaN(result) ? void 0 : result);
  var result;
};
export var parseDateTimeToNumber = function (value) {
  if (isNumber(value)) return value;
  var result, _Date;
  if (isString(value) || isDate(value)) result = null === (_Date = new Date(value)) || void 0 === _Date ? void 0 : _Date.valueOf();else if (value instanceof Object) {
    var _Date2,
      year = value.year,
      _value$month = value.month,
      month = void 0 === _value$month ? 0 : _value$month,
      _value$day = value.day,
      day = void 0 === _value$day ? 1 : _value$day,
      _value$hour = value.hour,
      hour = void 0 === _value$hour ? 0 : _value$hour,
      _value$minute = value.minute,
      minute = void 0 === _value$minute ? 0 : _value$minute;
    result = null === (_Date2 = new Date(Date.UTC(year, month, day, hour, minute))) || void 0 === _Date2 ? void 0 : _Date2.valueOf();
  }
  return isNaN(result) ? void 0 : result;
};
export var parseBooleanToNumber = function (value) {
  return isNil(value) ? void 0 : Number(value);
};
export var parseUnknownToString = function (value) {
  return isNil(value) ? void 0 : String(value);
};
export var isBooleanOrEmpty = function (value) {
  return isBoolean(value) || isNil(value);
};
var hasCode = function (option) {
    return void 0 !== (null == option ? void 0 : option.code);
  },
  extractCodeFromValue = function (value) {
    if (isNil(value)) return value;
    if (isNumber(value) || isString(value)) return String(value);
    var code = hasCode(value) ? value.code : value.id;
    return isNil(code) ? code : String(code);
  };
export var getDisplayedValueForCategory = function (options, value) {
  var option = function (options, value) {
    return null == options ? void 0 : _findInstanceProperty(options).call(options, function (option) {
      return hasCode(option) ? String(option.code) === extractCodeFromValue(value) : String(null == option ? void 0 : option.id) === extractCodeFromValue(value);
    });
  }(options, value);
  return hasCode(option) ? null == option ? void 0 : option.name : null == option ? void 0 : option.displayName;
};
export var hasAnyFilterApplied = function (filters, searchValue) {
  var _context;
  return !(!_filterInstanceProperty(_context = _Object$values(filters)).call(_context, Boolean).length && !searchValue);
};