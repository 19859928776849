import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _Object$entries from "@babel/runtime-corejs3/core-js-stable/object/entries";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import isArray from "lodash/isArray";
var getDefaultParams = function (options) {
    return _objectSpread(_objectSpread(_objectSpread({}, (filters = options.filters) ? {
      filter: _reduceInstanceProperty(_context = _Object$entries(filters)).call(_context, function (acc, _ref2) {
        var _context2,
          _context3,
          _ref3 = _slicedToArray(_ref2, 2),
          filter = _ref3[0],
          value = _ref3[1];
        return _concatInstanceProperty(_context2 = []).call(_context2, _toConsumableArray(acc), [_concatInstanceProperty(_context3 = "".concat(filter, ":eq:")).call(_context3, value)]);
      }, [])
    } : {}), null != (sorting = options.sorting) && sorting.sortedColumn ? {
      sort: sorting.sortedColumn
    } : {}), function (_ref4) {
      var pagination = _ref4.pagination;
      if (!pagination) return {};
      var _pagination$pageSize = pagination.pageSize,
        pageSize = void 0 === _pagination$pageSize ? 10 : _pagination$pageSize,
        _pagination$page = pagination.page,
        page = void 0 === _pagination$page ? 1 : _pagination$page;
      return {
        pageSize: String(pageSize),
        pageOffset: String((page - 1) * pageSize),
        includeTotal: String(!0)
      };
    }(options));
    var sorting, _context, filters;
  },
  getDefaultReadConfig = function (defaultParams) {
    return {
      url: "",
      params: defaultParams,
      optionsOverrides: {},
      transformResponse: function (data) {
        if (!function (data) {
          return !!(data instanceof Object && "data" in data && isArray(data.data));
        }(data)) throw new Error("Error handling response, expected payload of shape { data: DataRow[] }. To fix, supply custom `transformResponse` function to createHttpClient config.");
        return {
          data: data.data,
          totalItems: data.total
        };
      }
    };
  };
export var getReadConfig = function (clientConfig, options) {
  var _clientConfig$getRead,
    defaultParams = getDefaultParams(options),
    defaultConfig = getDefaultReadConfig(defaultParams),
    readConfig = null == clientConfig || null === (_clientConfig$getRead = clientConfig.getReadConfig) || void 0 === _clientConfig$getRead ? void 0 : _clientConfig$getRead.call(clientConfig, options, {
      defaultParams: defaultParams
    });
  return _objectSpread(_objectSpread({}, defaultConfig), readConfig);
};