import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React, { useContext, createContext, useMemo } from "react";
import PropTypes from "prop-types";
import { useBreakpoint } from "@jutro/layout";
import { intlMessageShape } from "@jutro/prop-types";
import { useViewContext } from "../../featuresProvider";
export var OverlayContext = createContext({});
export var useOverlayContext = function () {
  return useContext(OverlayContext);
};
export var DataViewOverlay = function (props) {
  var view = useViewContext(),
    _useBreakpoint$breakp = useBreakpoint(props).breakpointProps,
    isLoading = _useBreakpoint$breakp.isLoading,
    isError = _useBreakpoint$breakp.isError,
    renderLoader = _useBreakpoint$breakp.renderLoader,
    renderError = _useBreakpoint$breakp.renderError,
    renderNoRows = _useBreakpoint$breakp.renderNoRows,
    loaderMessage = _useBreakpoint$breakp.loaderMessage,
    errorMessage = _useBreakpoint$breakp.errorMessage,
    noRowsMessage = _useBreakpoint$breakp.noRowsMessage,
    renderLoaderProps = _useBreakpoint$breakp.renderLoaderProps,
    renderErrorProps = _useBreakpoint$breakp.renderErrorProps,
    renderNoRowsProps = _useBreakpoint$breakp.renderNoRowsProps,
    contextValue = useMemo(function () {
      return {
        isLoading: isLoading,
        isError: isError,
        renderLoader: renderLoader,
        renderError: renderError,
        renderNoRows: renderNoRows,
        loaderMessage: loaderMessage,
        errorMessage: errorMessage,
        noRowsMessage: noRowsMessage,
        renderLoaderProps: renderLoaderProps,
        renderErrorProps: renderErrorProps,
        renderNoRowsProps: renderNoRowsProps
      };
    }, [isLoading, isError, renderLoader, renderError, renderNoRows, loaderMessage, errorMessage, noRowsMessage, renderLoaderProps, renderErrorProps, renderNoRowsProps]);
  return React.createElement(OverlayContext.Provider, {
    value: contextValue
  }, view);
};
var basePropTypes = {
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    renderLoader: PropTypes.func,
    renderError: PropTypes.func,
    renderNoRows: PropTypes.func,
    loaderMessage: intlMessageShape,
    errorMessage: intlMessageShape,
    noRowsMessage: intlMessageShape,
    renderLoaderProps: PropTypes.object,
    renderErrorProps: PropTypes.object,
    renderNoRowsProps: PropTypes.object
  },
  propTypes = _objectSpread(_objectSpread({}, basePropTypes), {}, {
    phone: PropTypes.shape(basePropTypes),
    phoneWide: PropTypes.shape(basePropTypes),
    tablet: PropTypes.shape(basePropTypes)
  });
DataViewOverlay.propTypes = propTypes, DataViewOverlay.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "DataViewOverlay",
  props: {
    isLoading: {
      type: {
        name: "bool"
      },
      required: !1,
      description: 'Defines if the table should show the "loading" message overlay'
    },
    isError: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Defines if table should show an error message overlay"
    },
    renderLoader: {
      type: {
        name: "func"
      },
      required: !1,
      description: '"Loading" overlay content component'
    },
    renderError: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Error overlay content component"
    },
    renderNoRows: {
      type: {
        name: "func"
      },
      required: !1,
      description: "NoRows overlay content component"
    },
    loaderMessage: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: '"Loading" label text'
    },
    errorMessage: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Error label text"
    },
    noRowsMessage: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "NoRows label text"
    },
    renderLoaderProps: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Extra props which could be passed to the `renderLoader` component"
    },
    renderErrorProps: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Extra props which could be passed to the `renderError` component"
    },
    renderNoRowsProps: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Extra props which could be passed to the `renderNoRows` component"
    },
    phone: {
      type: {
        name: "shape",
        value: {
          isLoading: {
            name: "bool",
            description: 'Defines if the table should show the "loading" message overlay',
            required: !1
          },
          isError: {
            name: "bool",
            description: "Defines if table should show an error message overlay",
            required: !1
          },
          renderLoader: {
            name: "func",
            description: '"Loading" overlay content component',
            required: !1
          },
          renderError: {
            name: "func",
            description: "Error overlay content component",
            required: !1
          },
          renderNoRows: {
            name: "func",
            description: "NoRows overlay content component",
            required: !1
          },
          loaderMessage: {
            name: "custom",
            raw: "intlMessageShape",
            description: '"Loading" label text',
            required: !1
          },
          errorMessage: {
            name: "custom",
            raw: "intlMessageShape",
            description: "Error label text",
            required: !1
          },
          noRowsMessage: {
            name: "custom",
            raw: "intlMessageShape",
            description: "NoRows label text",
            required: !1
          },
          renderLoaderProps: {
            name: "object",
            description: "Extra props which could be passed to the `renderLoader` component",
            required: !1
          },
          renderErrorProps: {
            name: "object",
            description: "Extra props which could be passed to the `renderError` component",
            required: !1
          },
          renderNoRowsProps: {
            name: "object",
            description: "Extra props which could be passed to the `renderNoRows` component",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          isLoading: {
            name: "bool",
            description: 'Defines if the table should show the "loading" message overlay',
            required: !1
          },
          isError: {
            name: "bool",
            description: "Defines if table should show an error message overlay",
            required: !1
          },
          renderLoader: {
            name: "func",
            description: '"Loading" overlay content component',
            required: !1
          },
          renderError: {
            name: "func",
            description: "Error overlay content component",
            required: !1
          },
          renderNoRows: {
            name: "func",
            description: "NoRows overlay content component",
            required: !1
          },
          loaderMessage: {
            name: "custom",
            raw: "intlMessageShape",
            description: '"Loading" label text',
            required: !1
          },
          errorMessage: {
            name: "custom",
            raw: "intlMessageShape",
            description: "Error label text",
            required: !1
          },
          noRowsMessage: {
            name: "custom",
            raw: "intlMessageShape",
            description: "NoRows label text",
            required: !1
          },
          renderLoaderProps: {
            name: "object",
            description: "Extra props which could be passed to the `renderLoader` component",
            required: !1
          },
          renderErrorProps: {
            name: "object",
            description: "Extra props which could be passed to the `renderError` component",
            required: !1
          },
          renderNoRowsProps: {
            name: "object",
            description: "Extra props which could be passed to the `renderNoRows` component",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          isLoading: {
            name: "bool",
            description: 'Defines if the table should show the "loading" message overlay',
            required: !1
          },
          isError: {
            name: "bool",
            description: "Defines if table should show an error message overlay",
            required: !1
          },
          renderLoader: {
            name: "func",
            description: '"Loading" overlay content component',
            required: !1
          },
          renderError: {
            name: "func",
            description: "Error overlay content component",
            required: !1
          },
          renderNoRows: {
            name: "func",
            description: "NoRows overlay content component",
            required: !1
          },
          loaderMessage: {
            name: "custom",
            raw: "intlMessageShape",
            description: '"Loading" label text',
            required: !1
          },
          errorMessage: {
            name: "custom",
            raw: "intlMessageShape",
            description: "Error label text",
            required: !1
          },
          noRowsMessage: {
            name: "custom",
            raw: "intlMessageShape",
            description: "NoRows label text",
            required: !1
          },
          renderLoaderProps: {
            name: "object",
            description: "Extra props which could be passed to the `renderLoader` component",
            required: !1
          },
          renderErrorProps: {
            name: "object",
            description: "Extra props which could be passed to the `renderError` component",
            required: !1
          },
          renderNoRowsProps: {
            name: "object",
            description: "Extra props which could be passed to the `renderNoRows` component",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
}, DataViewOverlay.__docgenInfo = {
  componentName: "DataViewOverlay",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "DataViewOverlay",
  methods: [],
  actualName: "DataViewOverlay",
  props: {
    isLoading: {
      type: {
        name: "bool"
      },
      required: !1,
      description: 'Defines if the table should show the "loading" message overlay'
    },
    isError: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Defines if table should show an error message overlay"
    },
    renderLoader: {
      type: {
        name: "func"
      },
      required: !1,
      description: '"Loading" overlay content component'
    },
    renderError: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Error overlay content component"
    },
    renderNoRows: {
      type: {
        name: "func"
      },
      required: !1,
      description: "NoRows overlay content component"
    },
    loaderMessage: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: '"Loading" label text'
    },
    errorMessage: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Error label text"
    },
    noRowsMessage: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "NoRows label text"
    },
    renderLoaderProps: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Extra props which could be passed to the `renderLoader` component"
    },
    renderErrorProps: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Extra props which could be passed to the `renderError` component"
    },
    renderNoRowsProps: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Extra props which could be passed to the `renderNoRows` component"
    },
    phone: {
      type: {
        name: "shape",
        value: {
          isLoading: {
            name: "bool",
            description: 'Defines if the table should show the "loading" message overlay',
            required: !1
          },
          isError: {
            name: "bool",
            description: "Defines if table should show an error message overlay",
            required: !1
          },
          renderLoader: {
            name: "func",
            description: '"Loading" overlay content component',
            required: !1
          },
          renderError: {
            name: "func",
            description: "Error overlay content component",
            required: !1
          },
          renderNoRows: {
            name: "func",
            description: "NoRows overlay content component",
            required: !1
          },
          loaderMessage: {
            name: "union",
            description: '"Loading" label text',
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          errorMessage: {
            name: "union",
            description: "Error label text",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          noRowsMessage: {
            name: "union",
            description: "NoRows label text",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          renderLoaderProps: {
            name: "object",
            description: "Extra props which could be passed to the `renderLoader` component",
            required: !1
          },
          renderErrorProps: {
            name: "object",
            description: "Extra props which could be passed to the `renderError` component",
            required: !1
          },
          renderNoRowsProps: {
            name: "object",
            description: "Extra props which could be passed to the `renderNoRows` component",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          isLoading: {
            name: "bool",
            description: 'Defines if the table should show the "loading" message overlay',
            required: !1
          },
          isError: {
            name: "bool",
            description: "Defines if table should show an error message overlay",
            required: !1
          },
          renderLoader: {
            name: "func",
            description: '"Loading" overlay content component',
            required: !1
          },
          renderError: {
            name: "func",
            description: "Error overlay content component",
            required: !1
          },
          renderNoRows: {
            name: "func",
            description: "NoRows overlay content component",
            required: !1
          },
          loaderMessage: {
            name: "union",
            description: '"Loading" label text',
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          errorMessage: {
            name: "union",
            description: "Error label text",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          noRowsMessage: {
            name: "union",
            description: "NoRows label text",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          renderLoaderProps: {
            name: "object",
            description: "Extra props which could be passed to the `renderLoader` component",
            required: !1
          },
          renderErrorProps: {
            name: "object",
            description: "Extra props which could be passed to the `renderError` component",
            required: !1
          },
          renderNoRowsProps: {
            name: "object",
            description: "Extra props which could be passed to the `renderNoRows` component",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          isLoading: {
            name: "bool",
            description: 'Defines if the table should show the "loading" message overlay',
            required: !1
          },
          isError: {
            name: "bool",
            description: "Defines if table should show an error message overlay",
            required: !1
          },
          renderLoader: {
            name: "func",
            description: '"Loading" overlay content component',
            required: !1
          },
          renderError: {
            name: "func",
            description: "Error overlay content component",
            required: !1
          },
          renderNoRows: {
            name: "func",
            description: "NoRows overlay content component",
            required: !1
          },
          loaderMessage: {
            name: "union",
            description: '"Loading" label text',
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          errorMessage: {
            name: "union",
            description: "Error label text",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          noRowsMessage: {
            name: "union",
            description: "NoRows label text",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          renderLoaderProps: {
            name: "object",
            description: "Extra props which could be passed to the `renderLoader` component",
            required: !1
          },
          renderErrorProps: {
            name: "object",
            description: "Extra props which could be passed to the `renderError` component",
            required: !1
          },
          renderNoRowsProps: {
            name: "object",
            description: "Extra props which could be passed to the `renderNoRows` component",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
};