import { defineMessages } from "react-intl";
export default defineMessages({
  sortedBy: {
    id: "jutro-dataview.hooks.useTitle.sortedBy",
    defaultMessage: "sorted by"
  },
  descending: {
    id: "jutro-dataview.hooks.useTitle.descending",
    defaultMessage: "descending"
  },
  ascending: {
    id: "jutro-dataview.hooks.useTitle.ascending",
    defaultMessage: "ascending"
  }
});