import React from "react";
import { InlineLoader } from "@jutro/components";
import styles from "./OverlayContent.module.css";
export var LoadingContent = function (_ref) {
  var message = _ref.message;
  return React.createElement(InlineLoader, {
    className: styles.loading,
    loadingMessage: message,
    loading: !0,
    loadingIcon: "gw-spinner"
  });
};
LoadingContent.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "LoadingContent"
}, LoadingContent.__docgenInfo = {
  componentName: "LoadingContent",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "LoadingContent",
  methods: [],
  actualName: "LoadingContent"
};