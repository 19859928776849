import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _typeof from "@babel/runtime-corejs3/helpers/typeof";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _Set from "@babel/runtime-corejs3/core-js-stable/set";
import React, { useMemo } from "react";
import pick from "lodash/pick";
import isEmpty from "lodash/isEmpty";
import isFunction from "lodash/isFunction";
import cx from "classnames";
import { generateComponentId } from "@jutro/platform";
import { warning } from "@jutro/logger";
import { columnsMapping } from "./columnsMapping";
import styles from "./ColumnAdapter.module.css";
var loggedMessages = new _Set(),
  getDeprecationMessage = function (propName) {
    return "Prop '".concat(propName, '\' in data view columns should return React.Element, returning { id, defaultMessage } object is deprecated and will be removed in the "8.0" Jutro release.');
  },
  textAlignMapping = {
    left: styles.textLeft,
    right: styles.textRight,
    center: styles.textCenter,
    none: ""
  };
export var propsMapping = {
  id: function (_ref) {
    var id = _ref.id,
      header = _ref.header;
    return useMemo(function () {
      return id || (warning("Column with header ".concat(header, " does not have an 'id' prop, but it is required in DataView columns.")), generateComponentId("placeholderColumnId"));
    }, [id]);
  },
  header: function (_ref2) {
    var header = _ref2.header;
    if ("function" != typeof header) return header;
  },
  headerAriaLabel: function (props, translator) {
    var header = props.header,
      headerAriaLabel = props.headerAriaLabel;
    return headerAriaLabel || (isFunction(header) ? header(_objectSpread(_objectSpread({}, props), {}, {
      translator: translator
    })) : header);
  },
  renderHeader: function (props, translator) {
    var header = props.header,
      renderHeaderProp = props.renderHeader,
      renderHeaderFunc = "function" == typeof header ? header : renderHeaderProp;
    if (renderHeaderFunc) return function () {
      var headerContent = renderHeaderFunc(_objectSpread(_objectSpread({}, props), {}, {
        translator: translator
      }));
      if ("object" === _typeof(headerContent)) {
        var message = getDeprecationMessage("renderHeader");
        return loggedMessages.has(message) || (loggedMessages.add(message), warning(message, {
          bold: !0
        })), React.createElement(React.Fragment, null, translator(headerContent));
      }
      return headerContent;
    };
  },
  renderCell: function (props, translator) {
    var cell = props.cell,
      renderCell = props.renderCell;
    if (cell || renderCell) {
      if ("string" == typeof cell || "object" === _typeof(cell)) return function () {
        return React.createElement(React.Fragment, null, translator(cell));
      };
      var renderFunc = null != cell ? cell : renderCell;
      return function (_ref3) {
        var _renderFunc,
          row = _ref3.row,
          rowId = _ref3.rowId,
          cellContent = null !== (_renderFunc = null == renderFunc ? void 0 : renderFunc(row, rowId, _objectSpread(_objectSpread({}, props), {}, {
            translator: translator
          }))) && void 0 !== _renderFunc ? _renderFunc : null;
        if ("object" === _typeof(cellContent)) {
          var message = getDeprecationMessage("renderCell");
          return loggedMessages.has(message) || (loggedMessages.add(message), warning(message, {
            bold: !0
          })), React.createElement(React.Fragment, null, translator(cellContent));
        }
        return cellContent;
      };
    }
  },
  classNames: function (_ref4) {
    var cellClassName = _ref4.cellClassName,
      columnClassName = _ref4.columnClassName,
      headerClassName = _ref4.headerClassName,
      textAlign = _ref4.textAlign,
      textAlignClassName = _defineProperty({}, textAlignMapping[textAlign], textAlign),
      header = headerClassName || textAlign ? {
        header: cx(headerClassName, textAlignClassName)
      } : {},
      cell = cellClassName || columnClassName || textAlign ? {
        cell: cx(cellClassName, columnClassName, textAlignClassName)
      } : {};
    if (!isEmpty(header) || !isEmpty(cell)) return _objectSpread(_objectSpread({}, header), cell);
  },
  onResolveValue: function (_ref5) {
    var onAccessor = _ref5.onAccessor;
    if (onAccessor) return function (_ref6) {
      var row = _ref6.row;
      return onAccessor(row);
    };
  }
};
export var mappedProps = ["id", "cell", "header", "headerAriaLabel", "renderHeader", "renderCell", "cellClassName", "columnClassName", "headerClassName", "textAlign", "valueProps", "onAccessor"];
export var mapValueProps = function (_ref7) {
  var valueProps = _ref7.valueProps,
    fieldDatatype = _ref7.fieldDatatype;
  return pick(valueProps, columnsMapping[fieldDatatype || "unknown"].allowedProps);
};