import _extends from "@babel/runtime-corejs3/helpers/extends";
import React from "react";
import { DropdownSelectField } from "@jutro/components";
import { getCommonEditProps } from "../editing/getCommonEditProps";
import { allowedCategoryFields } from "../../types";
import { GenericColumn, createColumnPropTypes } from "./GenericColumn";
var CategoryCell = function (_ref) {
    var _props$classNames,
      props = _ref.columnProps,
      value = _ref.value,
      cellId = _ref.cellId;
    return React.createElement(DropdownSelectField, _extends({}, props, {
      readOnly: !0,
      id: cellId,
      value: value,
      className: null === (_props$classNames = props.classNames) || void 0 === _props$classNames ? void 0 : _props$classNames.cellContent
    }));
  },
  CategoryEditCell = function (props) {
    return React.createElement(DropdownSelectField, getCommonEditProps(props));
  };
export var CategoryColumn = function (props) {
  return React.createElement(GenericColumn, _extends({}, props, {
    renderCell: CategoryCell,
    renderEditCell: CategoryEditCell,
    columnDataType: "category"
  }));
};
var categoryColumnPropTypes = createColumnPropTypes(DropdownSelectField.propTypes, allowedCategoryFields);
CategoryColumn.propTypes = categoryColumnPropTypes, CategoryColumn.displayName = "CategoryColumn", CategoryColumn.__docgenInfo = {
  description: "Column for category format values\n\n@metadataType container",
  methods: [],
  displayName: "CategoryColumn",
  composes: ["./GenericColumn"]
}, CategoryCell.__docgenInfo = {
  componentName: "CategoryCell",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "CategoryCell",
  methods: [],
  actualName: "CategoryCell"
}, CategoryEditCell.__docgenInfo = {
  componentName: "CategoryEditCell",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "CategoryEditCell",
  methods: [],
  actualName: "CategoryEditCell"
}, CategoryColumn.__docgenInfo = {
  componentName: "CategoryColumn",
  packageName: "@jutro/lab-preview-dataview",
  description: "Column for category format values",
  displayName: "CategoryColumn",
  methods: [],
  actualName: "CategoryColumn",
  metadataType: "container",
  composes: ["./GenericColumn"]
};