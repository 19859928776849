import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import { useTranslator } from "@jutro/locale";
import { useSortingContext } from "../sorting";
import { useColumnsContext } from "../columns";
import messages from "./Title.messages";
export var useSortedColumnAriaLabel = function () {
  var _context,
    translator = useTranslator(),
    columns = useColumnsContext().columns.current,
    _useSortingContext = useSortingContext(),
    sortedColumnId = _useSortingContext.sortedColumnId,
    sortedColumnDesc = _useSortingContext.sortedColumnDesc;
  if (sortedColumnId) {
    var _columns$sortedColumn = columns[sortedColumnId],
      headerAriaLabel = _columns$sortedColumn.headerAriaLabel,
      header = _columns$sortedColumn.header,
      sortingDirectionLabel = translator(sortedColumnDesc ? messages.descending : messages.ascending),
      columnAriaLabel = translator(headerAriaLabel) || translator(header);
    return _filterInstanceProperty(_context = [translator(messages.sortedBy), columnAriaLabel, sortingDirectionLabel]).call(_context, Boolean).join(" ");
  }
};