import _findInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find";
import React, { useEffect } from "react";
import { useEditingContext } from "./editing";
import { useReorderedColumnsContext } from "./columns";
import { useSortingContext } from "./sorting";
export var FeaturesSideEffects = function () {
  var columns = useReorderedColumnsContext(),
    _useEditingContext = useEditingContext(),
    editingEnabled = _useEditingContext.editingEnabled,
    EditingWrapper = _useEditingContext.renderFormWrapper,
    _useSortingContext = useSortingContext(),
    sortedColumnId = _useSortingContext.sortedColumnId,
    onColumnSort = _useSortingContext.onColumnSort,
    sortedColumn = _useSortingContext.sortedColumn,
    sortedColumnProps = _findInstanceProperty(columns).call(columns, function (_ref) {
      return _ref.id === sortedColumnId;
    });
  return useEffect(function () {
    sortedColumn && onColumnSort({
      sortedColumn: sortedColumn,
      columnProps: sortedColumnProps
    });
  }, []), editingEnabled ? React.createElement(EditingWrapper, null) : null;
};
FeaturesSideEffects.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "FeaturesSideEffects"
}, FeaturesSideEffects.__docgenInfo = {
  componentName: "FeaturesSideEffects",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "FeaturesSideEffects",
  methods: [],
  actualName: "FeaturesSideEffects"
};