import { useCallback } from "react";
import cx from "classnames";
export var useRowClassNames = function (_ref) {
  var internalClassNames = _ref.internalClassNames,
    onStyleRow = _ref.onStyleRow,
    onStyleSelectionCell = _ref.onStyleSelectionCell,
    onStyleCell = _ref.onStyleCell;
  return useCallback(function (rawRow, rowId, index) {
    return {
      row: cx(null == internalClassNames ? void 0 : internalClassNames.row, null == onStyleRow ? void 0 : onStyleRow({
        rowId: rowId,
        rowIndex: index,
        row: rawRow
      })),
      selectionCell: function (isSelected) {
        return cx(null == internalClassNames ? void 0 : internalClassNames.selectionCell, null == onStyleSelectionCell ? void 0 : onStyleSelectionCell({
          rowId: rowId,
          rowIndex: index,
          isSelected: isSelected
        }));
      },
      getCellClassName: function (columnProps, value) {
        var _columnProps$classNam, _columnProps$onStyleC;
        return cx(null == internalClassNames ? void 0 : internalClassNames.cell, null == onStyleCell ? void 0 : onStyleCell({
          columnProps: columnProps,
          rowIndex: index,
          value: value
        }), null === (_columnProps$classNam = columnProps.classNames) || void 0 === _columnProps$classNam ? void 0 : _columnProps$classNam.cell, null === (_columnProps$onStyleC = columnProps.onStyleCell) || void 0 === _columnProps$onStyleC ? void 0 : _columnProps$onStyleC.call(columnProps, {
          columnProps: columnProps,
          rowIndex: index,
          value: value
        }));
      }
    };
  }, [internalClassNames, onStyleRow, onStyleSelectionCell, onStyleCell]);
};