import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _asyncToGenerator from "@babel/runtime-corejs3/helpers/asyncToGenerator";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _regeneratorRuntime from "@babel/runtime-corejs3/regenerator";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import React, { useContext } from "react";
import { IconButton, ModalNextContext } from "@jutro/components";
import { useTranslator } from "@jutro/locale";
import { useColumnsContext } from "../../../features/columns";
import { useColumnsConfigContext } from "../DataViewColumnsConfig";
import { ColumnsConfigModal } from "./ColumnsConfigModal";
import { messages } from "./ColumnsConfigModal.messages";
export var ColumnsConfigTrigger = function () {
  var _ref,
    translator = useTranslator(),
    _useColumnsConfigCont = useColumnsConfigContext(),
    columnsConfig = _useColumnsConfigCont.columnsConfig,
    onColumnsConfigChange = _useColumnsConfigCont.onColumnsConfigChange,
    modalTriggerIcon = _useColumnsConfigCont.modalTriggerIcon,
    CustomTrigger = _useColumnsConfigCont.renderModalTrigger,
    columns = useColumnsContext().columns.current,
    showModal = useContext(ModalNextContext).showModal,
    initialConfig = null == columnsConfig ? void 0 : _mapInstanceProperty(columnsConfig).call(columnsConfig, function (baseItem) {
      var _columns$baseItem$id;
      return _objectSpread(_objectSpread({}, baseItem), {}, {
        header: translator((null === (_columns$baseItem$id = columns[baseItem.id]) || void 0 === _columns$baseItem$id ? void 0 : _columns$baseItem$id.header) || baseItem.id)
      });
    }),
    triggerColumnsConfigModal = (_ref = _asyncToGenerator(_regeneratorRuntime.mark(function _callee() {
      var updatedConfig;
      return _regeneratorRuntime.wrap(function (_context) {
        for (;;) switch (_context.prev = _context.next) {
          case 0:
            return _context.prev = 0, _context.next = 3, showModal(React.createElement(ColumnsConfigModal, {
              initialColumns: initialConfig
            }));
          case 3:
            updatedConfig = _context.sent, null == onColumnsConfigChange || onColumnsConfigChange({
              columnsConfig: updatedConfig
            }), _context.next = 9;
            break;
          case 7:
            _context.prev = 7, _context.t0 = _context.catch(0);
          case 9:
          case "end":
            return _context.stop();
        }
      }, _callee, null, [[0, 7]]);
    })), function () {
      return _ref.apply(this, arguments);
    });
  return CustomTrigger ? React.createElement(CustomTrigger, {
    columnsConfig: initialConfig,
    modalTriggerIcon: modalTriggerIcon
  }) : React.createElement(function () {
    return React.createElement(IconButton, {
      icon: modalTriggerIcon || "",
      onClick: triggerColumnsConfigModal,
      ariaLabel: messages.title
    });
  }, null);
};
ColumnsConfigTrigger.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "ColumnsConfigTrigger"
}, ColumnsConfigTrigger.__docgenInfo = {
  componentName: "ColumnsConfigTrigger",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "ColumnsConfigTrigger",
  methods: [],
  actualName: "ColumnsConfigTrigger"
};