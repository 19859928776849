import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import React, { useState } from "react";
import cx from "classnames";
import { Button } from "@jutro/components";
import { useTranslator } from "@jutro/locale";
import styles from "./FiltersTriggerButton.module.css";
import { messages } from "./FiltersTriggerButton.messages";
export var FiltersTriggerButton = function (_ref) {
  var onClickProp = _ref.onClick,
    showBadge = _ref.showBadge,
    _ref$triggerIcon = _ref.triggerIcon,
    triggerIcon = void 0 === _ref$triggerIcon ? "gw-tune" : _ref$triggerIcon,
    translator = useTranslator(),
    _useState = useState(!1),
    _useState2 = _slicedToArray(_useState, 2),
    isExpanded = _useState2[0],
    setIsExpanded = _useState2[1],
    ariaLabel = translator(showBadge ? messages.openFiltersWithBadge : messages.openFilters);
  return React.createElement(Button, {
    actionType: "button",
    icon: triggerIcon,
    iconPosition: "left",
    onClick: function () {
      null == onClickProp || onClickProp(), setIsExpanded(function (expanded) {
        return !expanded;
      });
    },
    size: "medium",
    type: "text",
    className: cx(styles.triggerButton),
    iconClassName: styles.buttonIcon,
    "data-testid": "filtering-control",
    renderContent: function (content) {
      return React.createElement(React.Fragment, null, showBadge && React.createElement("div", {
        className: styles.filtersBadge,
        "data-testid": "filters-badge"
      }), content);
    },
    "aria-label": ariaLabel,
    "aria-expanded": isExpanded
  });
};
FiltersTriggerButton.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "FiltersTriggerButton",
  props: {
    triggerIcon: {
      defaultValue: {
        value: "'gw-tune'",
        computed: !1
      },
      required: !1
    }
  }
}, FiltersTriggerButton.__docgenInfo = {
  componentName: "FiltersTriggerButton",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "FiltersTriggerButton",
  methods: [],
  actualName: "FiltersTriggerButton",
  props: {
    triggerIcon: {
      defaultValue: {
        value: "'gw-tune'",
        computed: !1
      },
      required: !1
    }
  }
};