import React from "react";
import { FiltersTriggerButton } from "../FiltersTriggerButton";
export var InlineFiltersTrigger = function (_ref) {
  var onToggleFilters = _ref.onToggleFilters,
    triggerIcon = _ref.triggerIcon,
    areFiltersApplied = _ref.areFiltersApplied;
  return React.createElement(FiltersTriggerButton, {
    onClick: onToggleFilters,
    showBadge: areFiltersApplied,
    triggerIcon: triggerIcon
  });
};
InlineFiltersTrigger.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "InlineFiltersTrigger"
}, InlineFiltersTrigger.__docgenInfo = {
  componentName: "InlineFiltersTrigger",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "InlineFiltersTrigger",
  methods: [],
  actualName: "InlineFiltersTrigger"
};