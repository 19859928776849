import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
import React from "react";
import cx from "classnames";
import { useTranslator } from "@jutro/locale";
import { useTitleContext } from "./DataViewTitle";
import styles from "./Title.module.css";
import { useSortedColumnAriaLabel } from "./useSortedColumnAriaLabel";
export var Title = function (_ref) {
  var _render,
    _context,
    titleId = _ref.titleId,
    translator = useTranslator(),
    _useTitleContext = useTitleContext(),
    title = _useTitleContext.title,
    render = _useTitleContext.render,
    className = _useTitleContext.className,
    ariaLabelledby = _useTitleContext.ariaLabelledby,
    sortedColumnAriaLabel = useSortedColumnAriaLabel(),
    titleContent = null !== (_render = null == render ? void 0 : render(function (target) {
      for (var i = 1; i < arguments.length; i++) {
        var source = null != arguments[i] ? arguments[i] : {};
        i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
          _defineProperty(target, key, source[key]);
        }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
          _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
        });
      }
      return target;
    }({
      title: title,
      translator: translator
    }, ariaLabelledby && {
      ariaLabel: sortedColumnAriaLabel
    } || {}))) && void 0 !== _render ? _render : title && React.createElement("h2", null, translator(title)),
    resolvedAriaLabel = _filterInstanceProperty(_context = [translator(title), sortedColumnAriaLabel]).call(_context, Boolean).join(", ");
  return React.createElement("div", _extends({
    className: cx(styles.title, className),
    id: titleId
  }, !ariaLabelledby && resolvedAriaLabel && {
    "aria-label": resolvedAriaLabel,
    "aria-level": 1,
    role: "heading"
  } || {}), titleContent);
};
Title.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "Title"
}, Title.__docgenInfo = {
  componentName: "Title",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "Title",
  methods: [],
  actualName: "Title"
};