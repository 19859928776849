import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { useBreakpoint } from "@jutro/layout";
import { GenericColumn, createColumnPropTypes, ALIGN_TEXT_RIGHT } from "../GenericColumn";
import { ActionsDropdownMenu } from "./ActionsDropdownMenu";
import styles from "../Columns.module.css";
import { messages } from "./ActionsColumn.messages";
import { filterActions, overrideActionsProps } from "./helpers";
import { splitActions } from "../../utils/splitActions";
var ActionsCell = function (_ref) {
  var _context,
    _context2,
    columnProps = _ref.columnProps,
    row = _ref.row,
    rowId = _ref.rowId,
    cellId = _ref.cellId,
    id = columnProps.id,
    children = columnProps.children,
    wrapActions = columnProps.wrapActions,
    showOnHover = columnProps.showOnHover,
    classNames = columnProps.classNames,
    onGetRowActions = columnProps.onGetRowActions,
    actions = React.Children.toArray(children),
    actionIds = _mapInstanceProperty(_context = React.Children).call(_context, actions, function (_ref2) {
      return _ref2.props.id;
    });
  if (!children) return null;
  var filteredActions = onGetRowActions ? filterActions(actions, actionIds, rowId, row, onGetRowActions) : actions,
    overriddenActions = overrideActionsProps(filteredActions, {
      rowId: rowId,
      row: row
    }),
    _splitActions = splitActions(wrapActions, overriddenActions),
    inlineActions = _splitActions.inlineActions,
    wrappedActions = _splitActions.wrappedActions;
  return React.createElement("div", {
    key: _concatInstanceProperty(_context2 = "".concat(id, "-")).call(_context2, cellId),
    className: cx(styles.actions, _defineProperty({}, styles.actionsVisible, !showOnHover))
  }, inlineActions, wrappedActions.length > 0 && React.createElement(ActionsDropdownMenu, {
    id: id,
    actionsToBeWrapped: wrappedActions,
    rowId: cellId,
    rawRow: row,
    className: null == classNames ? void 0 : classNames.dropDownMenuWrapper
  }));
};
export var ActionsColumn = function (props) {
  var _props$classNames,
    classNames = _objectSpread(_objectSpread({}, props.classNames), {}, {
      cell: cx(styles.actionsCell, null === (_props$classNames = props.classNames) || void 0 === _props$classNames ? void 0 : _props$classNames.cell)
    }),
    breakpointProps = useBreakpoint(props).breakpointProps,
    showHeader = breakpointProps.showHeader,
    header = breakpointProps.header;
  return React.createElement(GenericColumn, _extends({}, props, {
    header: showHeader ? header : void 0,
    columnDataType: "action",
    renderCell: ActionsCell,
    classNames: classNames,
    sortable: !1,
    displayInEditForm: "never"
  }));
};
ActionsColumn.displayName = "ActionsColumn";
var actionsColumnPropTypes = createColumnPropTypes({
  onGetRowActions: PropTypes.func,
  wrapActions: PropTypes.oneOfType([PropTypes.oneOf(["never", "always"]), PropTypes.shape({
    allWhen: PropTypes.number.isRequired
  }), PropTypes.shape({
    maxItems: PropTypes.number.isRequired
  })]),
  showOnHover: PropTypes.bool
}, [], ["path", "onResolveValue", "sortable", "columnDataType", "renderCell", "displayInEditForm", "editConfig"]);
ActionsColumn.propTypes = actionsColumnPropTypes, ActionsColumn.defaultProps = {
  header: messages.header,
  wrapActions: {
    allWhen: 3
  },
  showOnHover: !1,
  showHeader: !0,
  alignText: ALIGN_TEXT_RIGHT
}, ActionsColumn.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "ActionsColumn",
  props: {
    header: {
      defaultValue: {
        value: "messages.header",
        computed: !0
      },
      required: !1
    },
    wrapActions: {
      defaultValue: {
        value: "{ allWhen: 3 }",
        computed: !1
      },
      required: !1
    },
    showOnHover: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      required: !1
    },
    showHeader: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      required: !1
    },
    alignText: {
      defaultValue: {
        value: "ALIGN_TEXT_RIGHT",
        computed: !0
      },
      required: !1
    }
  },
  composes: ["../GenericColumn"]
}, ActionsCell.__docgenInfo = {
  componentName: "ActionsCell",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "ActionsCell",
  methods: [],
  actualName: "ActionsCell"
}, ActionsColumn.__docgenInfo = {
  componentName: "ActionsColumn",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "ActionsColumn",
  methods: [],
  actualName: "ActionsColumn",
  props: {
    header: {
      defaultValue: {
        value: "messages.header",
        computed: !0
      },
      required: !1
    },
    wrapActions: {
      defaultValue: {
        value: "{ allWhen: 3 }",
        computed: !1
      },
      required: !1
    },
    showOnHover: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      required: !1
    },
    showHeader: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      required: !1
    },
    alignText: {
      defaultValue: {
        value: "ALIGN_TEXT_RIGHT",
        computed: !0
      },
      required: !1
    }
  },
  composes: ["../GenericColumn"]
};