import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["wrapperProps"];
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React from "react";
import isEmpty from "lodash/isEmpty";
import { Flex } from "@jutro/layout";
import { useTranslator } from "@jutro/locale";
import { useTitleContext } from "../title";
import { DEFAULT_ACTION_TYPE, HeaderAction } from "./HeaderAction";
import { splitActions } from "../utils/splitActions";
import { Menu } from "./Menu";
import messages from "./HeaderActions.messages";
import styles from "./HeaderActions.module.css";
var OutlinedAction = function (_ref) {
    var wrapperProps = _ref.wrapperProps,
      otherProps = _objectWithoutProperties(_ref, _excluded);
    return React.createElement(HeaderAction, _extends({
      wrapperProps: _objectSpread({
        type: "outlined"
      }, wrapperProps)
    }, otherProps));
  },
  FilledAction = function (props) {
    return React.createElement(HeaderAction, props);
  };
export var HeaderActions = function (_ref2) {
  var _context,
    _context2,
    _context3,
    translator,
    title,
    children = _ref2.children,
    _ref2$wrapHeaderActio = _ref2.wrapHeaderActions,
    wrapHeaderActions = void 0 === _ref2$wrapHeaderActio ? {
      allWhen: 3
    } : _ref2$wrapHeaderActio,
    selectedRows = _ref2.selectedRows,
    ariaLabel = (translator = useTranslator(), title = useTitleContext().title, translator(messages.headerActionsContainerLabel, {
      dataViewTitle: translator(title)
    }));
  if (!children) return React.createElement(React.Fragment, null);
  var headerActionsType = isEmpty(selectedRows) ? "global" : "batch",
    actions = _mapInstanceProperty(_context = _filterInstanceProperty(_context2 = _filterInstanceProperty(_context3 = React.Children.toArray(children)).call(_context3, Boolean)).call(_context2, function (headerActionsType) {
      return function (action) {
        var _props$type;
        return (null !== (_props$type = action.props.type) && void 0 !== _props$type ? _props$type : DEFAULT_ACTION_TYPE) === headerActionsType;
      };
    }(headerActionsType))).call(_context, function (selectedRows) {
      return function (action) {
        return _objectSpread(_objectSpread({}, action.props), {}, {
          onClick: function () {
            return action.props.onClick({
              selectedRows: selectedRows
            });
          }
        });
      };
    }(selectedRows)),
    _splitActions = splitActions(wrapHeaderActions, actions),
    inlineActions = _splitActions.inlineActions,
    wrappedActions = _splitActions.wrappedActions;
  return React.createElement(Flex, {
    justifyContent: "right",
    className: styles.headerActionsWrapper,
    role: "group",
    "aria-label": ariaLabel
  }, null == inlineActions ? void 0 : _mapInstanceProperty(inlineActions).call(inlineActions, function (actionProps, index) {
    return index === inlineActions.length - 1 ? React.createElement(FilledAction, _extends({}, actionProps, {
      key: "".concat(actionProps.id)
    })) : React.createElement(OutlinedAction, _extends({}, actionProps, {
      key: "".concat(actionProps.id)
    }));
  }), !isEmpty(wrappedActions) && React.createElement(Menu, {
    actions: wrappedActions,
    actionsType: headerActionsType
  }));
};
HeaderActions.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "HeaderActions",
  props: {
    wrapHeaderActions: {
      defaultValue: {
        value: "{ allWhen: 3 }",
        computed: !1
      },
      required: !1
    }
  }
}, OutlinedAction.__docgenInfo = {
  componentName: "OutlinedAction",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "OutlinedAction",
  methods: [],
  actualName: "OutlinedAction"
}, FilledAction.__docgenInfo = {
  componentName: "FilledAction",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "FilledAction",
  methods: [],
  actualName: "FilledAction"
}, HeaderActions.__docgenInfo = {
  componentName: "HeaderActions",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "HeaderActions",
  methods: [],
  actualName: "HeaderActions",
  props: {
    wrapHeaderActions: {
      defaultValue: {
        value: "{ allWhen: 3 }",
        computed: !1
      },
      required: !1
    }
  }
};