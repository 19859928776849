import _typeof from "@babel/runtime-corejs3/helpers/typeof";
import _sliceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/slice";
export var splitActions = function (wrapActions, actions) {
  if ("object" === _typeof(wrapActions)) {
    var allWhen = wrapActions.allWhen,
      maxItems = wrapActions.maxItems;
    if (allWhen) return allWhen <= actions.length ? {
      inlineActions: [],
      wrappedActions: actions
    } : {
      inlineActions: actions,
      wrappedActions: []
    };
    if (maxItems) return maxItems >= actions.length ? {
      inlineActions: actions,
      wrappedActions: []
    } : {
      inlineActions: _sliceInstanceProperty(actions).call(actions, 0, maxItems - 1),
      wrappedActions: _sliceInstanceProperty(actions).call(actions, maxItems - 1)
    };
  } else if ("always" === wrapActions) return {
    inlineActions: [],
    wrappedActions: actions
  };
  return {
    inlineActions: actions,
    wrappedActions: []
  };
};