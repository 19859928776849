import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import React from "react";
export var overrideActionsProps = function (actions, props) {
  var _context,
    rowId = props.rowId,
    row = props.row;
  return _mapInstanceProperty(_context = React.Children).call(_context, actions, function (action) {
    var _context2, _action$props$onGetDi, _action$props2;
    return React.cloneElement(action, {
      id: action.props.id ? _concatInstanceProperty(_context2 = "".concat(action.props.id, "_")).call(_context2, rowId) : void 0,
      disabled: null === (_action$props$onGetDi = (_action$props2 = action.props).onGetDisabled) || void 0 === _action$props$onGetDi ? void 0 : _action$props$onGetDi.call(_action$props2, {
        rowId: rowId,
        row: row
      }),
      onClick: function () {
        var _action$props$onClick, _action$props;
        return null === (_action$props$onClick = (_action$props = action.props).onClick) || void 0 === _action$props$onClick ? void 0 : _action$props$onClick.call(_action$props, {
          rowId: rowId,
          row: row
        });
      },
      row: row,
      rowId: rowId
    });
  });
};
export var filterActions = function (actions, actionIds, rowId, rawRow, onGetRowActions) {
  var _context3,
    filteredIds = onGetRowActions({
      rowId: rowId,
      row: rawRow,
      actions: actionIds
    }) || actionIds;
  return filteredIds.length ? _filterInstanceProperty(_context3 = React.Children.toArray(actions)).call(_context3, function (action) {
    return _includesInstanceProperty(filteredIds).call(filteredIds, action.props.id);
  }) : [];
};