import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useBreakpoint } from "@jutro/layout";
import { paginationTypes } from "./types";
import { useViewContext } from "../../featuresProvider";
import { PaginationContext } from "./usePaginationContext";
export var DataViewPagination = function (props) {
  var view = useViewContext(),
    breakpointProps = useBreakpoint(props).breakpointProps,
    contextValue = useMemo(function () {
      return _objectSpread(_objectSpread({}, breakpointProps), {}, {
        paginationEnabled: !0
      });
    }, [breakpointProps.className, breakpointProps.ariaLabel, breakpointProps.totalItems, breakpointProps.pageSizes, breakpointProps.pageSize, breakpointProps.page, breakpointProps.onPaginationChange, breakpointProps.paginationType, breakpointProps.render]);
  return React.createElement(PaginationContext.Provider, {
    value: contextValue
  }, view);
};
var basePropTypes = {
    paginationType: PropTypes.oneOf(paginationTypes),
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number,
    pageSizes: PropTypes.arrayOf(PropTypes.number.isRequired),
    totalItems: PropTypes.number,
    render: PropTypes.func,
    onPaginationChange: PropTypes.func
  },
  breakpointPropTypes = _objectSpread(_objectSpread({}, basePropTypes), {}, {
    page: PropTypes.number
  }),
  propTypes = _objectSpread(_objectSpread({}, basePropTypes), {}, {
    phone: PropTypes.shape(breakpointPropTypes),
    phoneWide: PropTypes.shape(breakpointPropTypes),
    tablet: PropTypes.shape(breakpointPropTypes)
  });
DataViewPagination.propTypes = propTypes, DataViewPagination.defaultProps = {
  paginationType: "pages",
  totalItems: 0,
  pageSizes: [10, 25, 50],
  pageSize: 10
}, DataViewPagination.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "DataViewPagination",
  props: {
    paginationType: {
      defaultValue: {
        value: "'pages'",
        computed: !1
      },
      type: {
        name: "enum",
        computed: !0,
        value: "paginationTypes"
      },
      required: !1,
      description: 'The type of pagination to use, "pages" | "loadMore"'
    },
    totalItems: {
      defaultValue: {
        value: "0",
        computed: !1
      },
      type: {
        name: "number"
      },
      required: !1,
      description: "The total length of available data, used to determine if `onPaginationChange` can be called"
    },
    pageSizes: {
      defaultValue: {
        value: "[10, 25, 50]",
        computed: !1
      },
      type: {
        name: "arrayOf",
        value: {
          name: "number"
        }
      },
      required: !1,
      description: "Array of available page sizes"
    },
    pageSize: {
      defaultValue: {
        value: "10",
        computed: !1
      },
      type: {
        name: "number"
      },
      required: !1,
      description: "Page size, needs to be included in 'pageSizes' for \"pages\" paginationType"
    },
    page: {
      type: {
        name: "number"
      },
      required: !0,
      description: "Current page number, used to control it from outside"
    },
    render: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback which renders a component to manage pagination"
    },
    onPaginationChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to be triggered when `page`, `pageSize` and `paginationType` change"
    },
    phone: {
      type: {
        name: "shape",
        value: {
          page: {
            name: "number",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          page: {
            name: "number",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          page: {
            name: "number",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
}, DataViewPagination.__docgenInfo = {
  componentName: "DataViewPagination",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "DataViewPagination",
  methods: [],
  actualName: "DataViewPagination",
  props: {
    paginationType: {
      type: {
        name: "enum",
        computed: !0,
        value: "paginationTypes"
      },
      required: !1,
      description: 'The type of pagination to use, "pages" | "loadMore"',
      defaultValue: {
        value: "'pages'",
        computed: !1
      }
    },
    page: {
      type: {
        name: "number"
      },
      required: !0,
      description: "Current page number, used to control it from outside"
    },
    pageSize: {
      type: {
        name: "number"
      },
      required: !1,
      description: "Page size, needs to be included in 'pageSizes' for \"pages\" paginationType",
      defaultValue: {
        value: "10",
        computed: !1
      }
    },
    pageSizes: {
      type: {
        name: "arrayOf",
        value: {
          name: "number"
        }
      },
      required: !1,
      description: "Array of available page sizes",
      defaultValue: {
        value: "[10, 25, 50]",
        computed: !1
      }
    },
    totalItems: {
      type: {
        name: "number"
      },
      required: !1,
      description: "The total length of available data, used to determine if `onPaginationChange` can be called",
      defaultValue: {
        value: "0",
        computed: !1
      }
    },
    render: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback which renders a component to manage pagination"
    },
    onPaginationChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to be triggered when `page`, `pageSize` and `paginationType` change"
    },
    phone: {
      type: {
        name: "shape",
        value: {
          page: {
            name: "number",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          page: {
            name: "number",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          page: {
            name: "number",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
};