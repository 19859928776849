import React from "react";
import PropTypes from "prop-types";
import { useBreakpoint } from "@jutro/layout";
export var QuickViewBody = function (props) {
  var className = useBreakpoint(props).breakpointProps.className;
  return React.createElement("div", {
    className: className
  }, props.children);
};
var quickViewBodyPropTypes = {
  className: PropTypes.string
};
QuickViewBody.propTypes = quickViewBodyPropTypes, QuickViewBody.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "QuickViewBody",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Class name for the content."
    }
  }
}, QuickViewBody.__docgenInfo = {
  componentName: "QuickViewBody",
  packageName: "@jutro/quick-view",
  description: "",
  displayName: "QuickViewBody",
  methods: [],
  actualName: "QuickViewBody",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Class name for the content."
    }
  }
};