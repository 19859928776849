import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
import React, { useState, useCallback } from "react";
import { EditingQuickView } from "./EditingQuickView";
export var useQuickViewForm = function () {
  var _useState = useState(void 0),
    _useState2 = _slicedToArray(_useState, 2),
    quickViewProps = _useState2[0],
    setQuickViewProps = _useState2[1],
    _useState3 = useState(!1),
    _useState4 = _slicedToArray(_useState3, 2),
    isOpen = _useState4[0],
    setIsOpen = _useState4[1],
    onEditStart = useCallback(function (editProps) {
      setQuickViewProps(function (target) {
        for (var i = 1; i < arguments.length; i++) {
          var source = null != arguments[i] ? arguments[i] : {};
          i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
            _defineProperty(target, key, source[key]);
          }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
            _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
          });
        }
        return target;
      }({}, editProps)), setIsOpen(!0);
    }, []);
  return {
    onEditStart: onEditStart,
    renderFormWrapper: useCallback(function () {
      return quickViewProps ? React.createElement(EditingQuickView, _extends({}, quickViewProps, {
        isOpen: isOpen,
        onShowChange: function (_ref) {
          var show = _ref.show;
          setIsOpen(show), show || setQuickViewProps(void 0);
        }
      })) : React.createElement(React.Fragment, null);
    }, [isOpen, quickViewProps]),
    renderHeader: null
  };
};