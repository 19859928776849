import _extends from "@babel/runtime-corejs3/helpers/extends";
import React from "react";
import { InputField } from "@jutro/components";
import { getCommonEditProps } from "../editing/getCommonEditProps";
import { allowedTextFields } from "../../types";
import { createColumnPropTypes, GenericColumn } from "./GenericColumn";
var TextCell = function (_ref) {
    var props = _ref.columnProps,
      value = _ref.value,
      cellId = _ref.cellId;
    return React.createElement(InputField, _extends({}, props, {
      id: cellId,
      value: value,
      inputType: "text",
      readOnly: !0,
      noDeprecationWarnings: ["registerValidation"]
    }));
  },
  TextEditCell = function (props) {
    return React.createElement(InputField, _extends({}, getCommonEditProps(props), {
      inputType: "text",
      noDeprecationWarnings: ["registerValidation"]
    }));
  };
export var TextColumn = function (props) {
  return React.createElement(GenericColumn, _extends({}, props, {
    renderCell: TextCell,
    renderEditCell: TextEditCell,
    columnDataType: "text",
    shouldTranslateValue: !0
  }));
};
var textColumnPropTypes = createColumnPropTypes(InputField.propTypes, allowedTextFields);
TextColumn.propTypes = textColumnPropTypes, TextColumn.displayName = "TextColumn", TextColumn.__docgenInfo = {
  description: "Column for currency format values\n\n@metadataType container",
  methods: [],
  displayName: "TextColumn",
  composes: ["./GenericColumn"]
}, TextCell.__docgenInfo = {
  componentName: "TextCell",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "TextCell",
  methods: [],
  actualName: "TextCell"
}, TextEditCell.__docgenInfo = {
  componentName: "TextEditCell",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "TextEditCell",
  methods: [],
  actualName: "TextEditCell"
}, TextColumn.__docgenInfo = {
  componentName: "TextColumn",
  packageName: "@jutro/lab-preview-dataview",
  description: "Column for currency format values",
  displayName: "TextColumn",
  methods: [],
  actualName: "TextColumn",
  metadataType: "container",
  composes: ["./GenericColumn"]
};