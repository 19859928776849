import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React, { useContext, createContext, useMemo } from "react";
import PropTypes from "prop-types";
import { useBreakpoint } from "@jutro/layout";
import noop from "lodash/noop";
import { useViewContext } from "../../featuresProvider";
import { extractDescFromColumn } from "./helpers";
var sortingContextInitialValue = {
  sortingEnabled: !1,
  onColumnSort: noop
};
export var SortingContext = createContext(sortingContextInitialValue);
export var useSortingContext = function () {
  return useContext(SortingContext);
};
export var DataViewSorting = function (props) {
  var view = useViewContext(),
    _useBreakpoint$breakp = useBreakpoint(props).breakpointProps,
    onColumnSort = _useBreakpoint$breakp.onColumnSort,
    sortedColumn = _useBreakpoint$breakp.sortedColumn,
    contextValue = useMemo(function () {
      var contextBaseProperties = {
        sortingEnabled: !!onColumnSort,
        onColumnSort: onColumnSort
      };
      if (!sortedColumn) return contextBaseProperties;
      var _extractDescFromColum = extractDescFromColumn(sortedColumn),
        sortedColumnId = _extractDescFromColum.sortedColumnId,
        sortedColumnDesc = _extractDescFromColum.sortedColumnDesc;
      return _objectSpread(_objectSpread({}, contextBaseProperties), {}, {
        sortedColumn: sortedColumn,
        sortedColumnId: sortedColumnId,
        sortedColumnDesc: sortedColumnDesc
      });
    }, [onColumnSort, sortedColumn]);
  return React.createElement(SortingContext.Provider, {
    value: contextValue
  }, view);
};
var basePropTypes = {
    onColumnSort: PropTypes.func.isRequired,
    sortedColumn: PropTypes.string
  },
  breakpointPropTypes = _objectSpread(_objectSpread({}, basePropTypes), {}, {
    onColumnSort: PropTypes.func
  }),
  propTypes = _objectSpread(_objectSpread({}, basePropTypes), {}, {
    phone: PropTypes.shape(breakpointPropTypes),
    phoneWide: PropTypes.shape(breakpointPropTypes),
    tablet: PropTypes.shape(breakpointPropTypes)
  });
DataViewSorting.propTypes = propTypes, DataViewSorting.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "DataViewSorting",
  props: {
    onColumnSort: {
      type: {
        name: "func"
      },
      required: !0,
      description: "Callback to be triggered when sorting gets triggered for one of the columns"
    },
    sortedColumn: {
      type: {
        name: "string"
      },
      required: !1,
      description: 'ID of the sorted column (in case the component is controlled from the outside).\n"columnId" -> ascending, "-columnId" -> descending'
    },
    phone: {
      type: {
        name: "shape",
        value: {
          onColumnSort: {
            name: "func",
            description: "Callback to be triggered when sorting gets triggered for one of the columns",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          onColumnSort: {
            name: "func",
            description: "Callback to be triggered when sorting gets triggered for one of the columns",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          onColumnSort: {
            name: "func",
            description: "Callback to be triggered when sorting gets triggered for one of the columns",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
}, DataViewSorting.__docgenInfo = {
  componentName: "DataViewSorting",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "DataViewSorting",
  methods: [],
  actualName: "DataViewSorting",
  props: {
    onColumnSort: {
      type: {
        name: "func"
      },
      required: !0,
      description: "Callback to be triggered when sorting gets triggered for one of the columns"
    },
    sortedColumn: {
      type: {
        name: "string"
      },
      required: !1,
      description: 'ID of the sorted column (in case the component is controlled from the outside).\n"columnId" -> ascending, "-columnId" -> descending'
    },
    phone: {
      type: {
        name: "shape",
        value: {
          onColumnSort: {
            name: "func",
            description: "Callback to be triggered when sorting gets triggered for one of the columns",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          onColumnSort: {
            name: "func",
            description: "Callback to be triggered when sorting gets triggered for one of the columns",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          onColumnSort: {
            name: "func",
            description: "Callback to be triggered when sorting gets triggered for one of the columns",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
};