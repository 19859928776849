import _findInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import { SortingOrder } from "./ListViewSortingModal";
export var useListViewColumnSorting = function (_ref) {
  var onColumnSort = _ref.onColumnSort,
    onResolve = _ref.onResolve,
    columnOptions = _ref.columnOptions;
  return function (_ref2) {
    var _context,
      selectedColumnId = _ref2.selectedColumnId,
      sortingOrder = _ref2.sortingOrder,
      selectedColumn = _findInstanceProperty(columnOptions).call(columnOptions, function (column) {
        return column.id === selectedColumnId;
      }),
      newSortingDirection = sortingOrder === SortingOrder.DESCENDING ? "-" : "";
    selectedColumn && selectedColumn.sortable && (null == onColumnSort || onColumnSort({
      sortedColumn: _concatInstanceProperty(_context = "".concat(newSortingDirection)).call(_context, selectedColumnId),
      columnProps: selectedColumn.columnProps
    }));
    null == onResolve || onResolve();
  };
};