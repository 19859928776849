import _extends from "@babel/runtime-corejs3/helpers/extends";
import React from "react";
import { ToggleField } from "@jutro/components";
import { getCommonEditProps } from "../editing/getCommonEditProps";
import { GenericColumn, createColumnPropTypes } from "./GenericColumn";
var BooleanCell = function (_ref) {
    var columnProps = _ref.columnProps,
      value = _ref.value,
      cellId = _ref.cellId;
    return React.createElement(ToggleField, _extends({}, columnProps, {
      id: cellId,
      readOnly: !0,
      value: value,
      noDeprecationWarnings: ["registerValidation"]
    }));
  },
  BooleanEditCell = function (props) {
    return React.createElement(ToggleField, _extends({}, getCommonEditProps(props), {
      noDeprecationWarnings: ["registerValidation"]
    }));
  };
export var BooleanColumn = function (props) {
  return React.createElement(GenericColumn, _extends({}, props, {
    renderCell: BooleanCell,
    renderEditCell: BooleanEditCell,
    columnDataType: "boolean"
  }));
};
var booleanColumnPropTypes = createColumnPropTypes();
BooleanColumn.propTypes = booleanColumnPropTypes, BooleanColumn.displayName = "BooleanColumn", BooleanColumn.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "BooleanColumn",
  composes: ["./GenericColumn"]
}, BooleanCell.__docgenInfo = {
  componentName: "BooleanCell",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "BooleanCell",
  methods: [],
  actualName: "BooleanCell"
}, BooleanEditCell.__docgenInfo = {
  componentName: "BooleanEditCell",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "BooleanEditCell",
  methods: [],
  actualName: "BooleanEditCell"
}, BooleanColumn.__docgenInfo = {
  componentName: "BooleanColumn",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "BooleanColumn",
  methods: [],
  actualName: "BooleanColumn",
  composes: ["./GenericColumn"]
};