import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _extends from "@babel/runtime-corejs3/helpers/extends";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import debounce from "lodash/debounce";
import { useBreakpoint } from "@jutro/layout";
import { warning } from "@jutro/logger";
import { intlMessageShape } from "@jutro/prop-types";
import { useVisibilityObserver, useRightSideBarInteraction } from "./hooks";
import { useQuickViewInternal } from "./QuickViewProvider";
import { QuickViewWrapper } from "./QuickViewWrapper";
import { RouterPrompt } from "./RouterPrompt";
var debouncedWarning = debounce(warning, 100);
export var QuickView = function (props) {
  var breakpointProps = useBreakpoint(props).breakpointProps,
    id = breakpointProps.id,
    showProp = breakpointProps.show,
    confirmClose = breakpointProps.confirmClose,
    keepRightSideBarOpen = breakpointProps.keepRightSideBarOpen,
    onShowChange = breakpointProps.onShowChange,
    confirmationModalMessages = breakpointProps.confirmationModalMessages,
    routerPromptMessages = breakpointProps.routerPromptMessages,
    _useVisibilityObserve = useVisibilityObserver(id, showProp, onShowChange, confirmClose, confirmationModalMessages),
    show = _useVisibilityObserve.show,
    onClose = _useVisibilityObserve.onClose,
    setPreviousFocus = _useVisibilityObserve.setPreviousFocus,
    focusPreviousElement = _useVisibilityObserve.focusPreviousElement,
    parent = useQuickViewInternal().containerRef.current;
  if (useRightSideBarInteraction({
    keepRightSideBarOpen: keepRightSideBarOpen,
    show: show
  }), !parent) return debouncedWarning("No parent container for QuickView exists. Please check if container ref returned by 'useQuickViewContainer' was passed to any DOM element."), null;
  debouncedWarning.cancel(), show && setPreviousFocus(document.activeElement);
  var view = React.createElement(QuickViewWrapper, _extends({}, breakpointProps, {
      show: show,
      onClose: onClose,
      focusPreviousElement: focusPreviousElement
    }), props.children),
    quickView = ReactDOM.createPortal(view, parent),
    _ref = null != routerPromptMessages ? routerPromptMessages : {},
    title = _ref.title,
    notificationMessage = _ref.notification,
    discardChangesMessage = _ref.discardChanges,
    keepChangesMessage = _ref.keepChanges;
  return React.createElement(React.Fragment, null, confirmClose && React.createElement(RouterPrompt, {
    onClose: onClose,
    title: title,
    notificationMessage: notificationMessage,
    keepChangesMessage: keepChangesMessage,
    discardChangesMessage: discardChangesMessage
  }), quickView);
};
var modalMessages = {
    title: intlMessageShape,
    notification: intlMessageShape,
    discardChanges: intlMessageShape,
    keepChanges: intlMessageShape
  },
  quickViewBasePropTypes = {
    id: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    onShowChange: PropTypes.func.isRequired,
    closeOnEsc: PropTypes.bool,
    focusAfterRender: PropTypes.oneOf(["panel", "element", "unchanged"]),
    useOverlay: PropTypes.bool,
    closeOnOverlayClick: PropTypes.bool,
    className: PropTypes.string,
    overlayClassName: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.arrayOf(PropTypes.string.isRequired)]),
    confirmClose: PropTypes.bool,
    keepRightSideBarOpen: PropTypes.bool,
    confirmationModalMessages: PropTypes.shape(modalMessages),
    routerPromptMessages: PropTypes.shape(modalMessages),
    dialogRef: PropTypes.shape({
      current: PropTypes.instanceOf(HTMLDivElement)
    })
  };
QuickView.propTypes = _objectSpread(_objectSpread({}, quickViewBasePropTypes), {}, {
  phone: PropTypes.shape(quickViewBasePropTypes),
  phoneWide: PropTypes.shape(quickViewBasePropTypes),
  tablet: PropTypes.shape(quickViewBasePropTypes)
}), QuickView.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "QuickView",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "ID of the component, needs to be unique across application"
    },
    show: {
      type: {
        name: "bool"
      },
      required: !0,
      description: "If set to 'true', QuickView is shown"
    },
    onShowChange: {
      type: {
        name: "func"
      },
      required: !0,
      description: "Callback triggered when QuickView visibility changes"
    },
    closeOnEsc: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Defines if the QuickView panel should be closed on 'esc' key press"
    },
    focusAfterRender: {
      type: {
        name: "enum",
        value: [{
          value: "'panel'",
          computed: !1
        }, {
          value: "'element'",
          computed: !1
        }, {
          value: "'unchanged'",
          computed: !1
        }]
      },
      required: !1,
      description: "Defines where should browser's focus move after opening the panel. Defaults to 'element'\n'panel' - panel itself will be focused\n'element' - first interactive element within panel will be focused\n'unchanged' - focus will not change"
    },
    useOverlay: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Defines if QuickView overlay should be rendered when the panel is opened"
    },
    closeOnOverlayClick: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Defines if the QuickView panel should be closed when overlay has been clicked"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Allows customizing QuickView wrapper styles"
    },
    overlayClassName: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "arrayOf",
          value: {
            name: "string"
          }
        }]
      },
      required: !1,
      description: "Allows customizing QuickView overlay styles"
    },
    confirmClose: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If set to 'true', QuickView will ask for confirmation before beeing closed"
    },
    keepRightSideBarOpen: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If set to 'true', opening QuickView will not collapse right sidebar\nThis prop only takes effect while using QuickView inside floorplan with right sidebar visible"
    },
    confirmationModalMessages: {
      type: {
        name: "shape",
        value: {
          title: {
            name: "custom",
            raw: "intlMessageShape",
            required: !1
          },
          notification: {
            name: "custom",
            raw: "intlMessageShape",
            required: !1
          },
          discardChanges: {
            name: "custom",
            raw: "intlMessageShape",
            required: !1
          },
          keepChanges: {
            name: "custom",
            raw: "intlMessageShape",
            required: !1
          }
        }
      },
      required: !1,
      description: "Message props for confirmation modal displayed before quick view is closed"
    },
    routerPromptMessages: {
      type: {
        name: "shape",
        value: {
          title: {
            name: "custom",
            raw: "intlMessageShape",
            required: !1
          },
          notification: {
            name: "custom",
            raw: "intlMessageShape",
            required: !1
          },
          discardChanges: {
            name: "custom",
            raw: "intlMessageShape",
            required: !1
          },
          keepChanges: {
            name: "custom",
            raw: "intlMessageShape",
            required: !1
          }
        }
      },
      required: !1,
      description: "Message props for confirmation modal displayed before route is changed"
    },
    dialogRef: {
      type: {
        name: "shape",
        value: {
          current: {
            name: "instanceOf",
            value: "HTMLDivElement",
            required: !1
          }
        }
      },
      required: !1,
      description: "Reference of the dialog wrapper element. Use it when you need to access the dialog wrapper.\nIf not provided it will be provided internally."
    },
    phone: {
      type: {
        name: "shape",
        value: {
          id: {
            name: "string",
            description: "ID of the component, needs to be unique across application",
            required: !0
          },
          show: {
            name: "bool",
            description: "If set to 'true', QuickView is shown",
            required: !0
          },
          onShowChange: {
            name: "func",
            description: "Callback triggered when QuickView visibility changes",
            required: !0
          },
          closeOnEsc: {
            name: "bool",
            description: "Defines if the QuickView panel should be closed on 'esc' key press",
            required: !1
          },
          focusAfterRender: {
            name: "enum",
            value: [{
              value: "'panel'",
              computed: !1
            }, {
              value: "'element'",
              computed: !1
            }, {
              value: "'unchanged'",
              computed: !1
            }],
            description: "Defines where should browser's focus move after opening the panel. Defaults to 'element'\n'panel' - panel itself will be focused\n'element' - first interactive element within panel will be focused\n'unchanged' - focus will not change",
            required: !1
          },
          useOverlay: {
            name: "bool",
            description: "Defines if QuickView overlay should be rendered when the panel is opened",
            required: !1
          },
          closeOnOverlayClick: {
            name: "bool",
            description: "Defines if the QuickView panel should be closed when overlay has been clicked",
            required: !1
          },
          className: {
            name: "string",
            description: "Allows customizing QuickView wrapper styles",
            required: !1
          },
          overlayClassName: {
            name: "union",
            value: [{
              name: "string"
            }, {
              name: "arrayOf",
              value: {
                name: "string"
              }
            }],
            description: "Allows customizing QuickView overlay styles",
            required: !1
          },
          confirmClose: {
            name: "bool",
            description: "If set to 'true', QuickView will ask for confirmation before beeing closed",
            required: !1
          },
          keepRightSideBarOpen: {
            name: "bool",
            description: "If set to 'true', opening QuickView will not collapse right sidebar\nThis prop only takes effect while using QuickView inside floorplan with right sidebar visible",
            required: !1
          },
          confirmationModalMessages: {
            name: "shape",
            value: {
              title: {
                name: "custom",
                raw: "intlMessageShape",
                required: !1
              },
              notification: {
                name: "custom",
                raw: "intlMessageShape",
                required: !1
              },
              discardChanges: {
                name: "custom",
                raw: "intlMessageShape",
                required: !1
              },
              keepChanges: {
                name: "custom",
                raw: "intlMessageShape",
                required: !1
              }
            },
            description: "Message props for confirmation modal displayed before quick view is closed",
            required: !1
          },
          routerPromptMessages: {
            name: "shape",
            value: {
              title: {
                name: "custom",
                raw: "intlMessageShape",
                required: !1
              },
              notification: {
                name: "custom",
                raw: "intlMessageShape",
                required: !1
              },
              discardChanges: {
                name: "custom",
                raw: "intlMessageShape",
                required: !1
              },
              keepChanges: {
                name: "custom",
                raw: "intlMessageShape",
                required: !1
              }
            },
            description: "Message props for confirmation modal displayed before route is changed",
            required: !1
          },
          dialogRef: {
            name: "shape",
            value: {
              current: {
                name: "instanceOf",
                value: "HTMLDivElement",
                required: !1
              }
            },
            description: "Reference of the dialog wrapper element. Use it when you need to access the dialog wrapper.\nIf not provided it will be provided internally.",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          id: {
            name: "string",
            description: "ID of the component, needs to be unique across application",
            required: !0
          },
          show: {
            name: "bool",
            description: "If set to 'true', QuickView is shown",
            required: !0
          },
          onShowChange: {
            name: "func",
            description: "Callback triggered when QuickView visibility changes",
            required: !0
          },
          closeOnEsc: {
            name: "bool",
            description: "Defines if the QuickView panel should be closed on 'esc' key press",
            required: !1
          },
          focusAfterRender: {
            name: "enum",
            value: [{
              value: "'panel'",
              computed: !1
            }, {
              value: "'element'",
              computed: !1
            }, {
              value: "'unchanged'",
              computed: !1
            }],
            description: "Defines where should browser's focus move after opening the panel. Defaults to 'element'\n'panel' - panel itself will be focused\n'element' - first interactive element within panel will be focused\n'unchanged' - focus will not change",
            required: !1
          },
          useOverlay: {
            name: "bool",
            description: "Defines if QuickView overlay should be rendered when the panel is opened",
            required: !1
          },
          closeOnOverlayClick: {
            name: "bool",
            description: "Defines if the QuickView panel should be closed when overlay has been clicked",
            required: !1
          },
          className: {
            name: "string",
            description: "Allows customizing QuickView wrapper styles",
            required: !1
          },
          overlayClassName: {
            name: "union",
            value: [{
              name: "string"
            }, {
              name: "arrayOf",
              value: {
                name: "string"
              }
            }],
            description: "Allows customizing QuickView overlay styles",
            required: !1
          },
          confirmClose: {
            name: "bool",
            description: "If set to 'true', QuickView will ask for confirmation before beeing closed",
            required: !1
          },
          keepRightSideBarOpen: {
            name: "bool",
            description: "If set to 'true', opening QuickView will not collapse right sidebar\nThis prop only takes effect while using QuickView inside floorplan with right sidebar visible",
            required: !1
          },
          confirmationModalMessages: {
            name: "shape",
            value: {
              title: {
                name: "custom",
                raw: "intlMessageShape",
                required: !1
              },
              notification: {
                name: "custom",
                raw: "intlMessageShape",
                required: !1
              },
              discardChanges: {
                name: "custom",
                raw: "intlMessageShape",
                required: !1
              },
              keepChanges: {
                name: "custom",
                raw: "intlMessageShape",
                required: !1
              }
            },
            description: "Message props for confirmation modal displayed before quick view is closed",
            required: !1
          },
          routerPromptMessages: {
            name: "shape",
            value: {
              title: {
                name: "custom",
                raw: "intlMessageShape",
                required: !1
              },
              notification: {
                name: "custom",
                raw: "intlMessageShape",
                required: !1
              },
              discardChanges: {
                name: "custom",
                raw: "intlMessageShape",
                required: !1
              },
              keepChanges: {
                name: "custom",
                raw: "intlMessageShape",
                required: !1
              }
            },
            description: "Message props for confirmation modal displayed before route is changed",
            required: !1
          },
          dialogRef: {
            name: "shape",
            value: {
              current: {
                name: "instanceOf",
                value: "HTMLDivElement",
                required: !1
              }
            },
            description: "Reference of the dialog wrapper element. Use it when you need to access the dialog wrapper.\nIf not provided it will be provided internally.",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          id: {
            name: "string",
            description: "ID of the component, needs to be unique across application",
            required: !0
          },
          show: {
            name: "bool",
            description: "If set to 'true', QuickView is shown",
            required: !0
          },
          onShowChange: {
            name: "func",
            description: "Callback triggered when QuickView visibility changes",
            required: !0
          },
          closeOnEsc: {
            name: "bool",
            description: "Defines if the QuickView panel should be closed on 'esc' key press",
            required: !1
          },
          focusAfterRender: {
            name: "enum",
            value: [{
              value: "'panel'",
              computed: !1
            }, {
              value: "'element'",
              computed: !1
            }, {
              value: "'unchanged'",
              computed: !1
            }],
            description: "Defines where should browser's focus move after opening the panel. Defaults to 'element'\n'panel' - panel itself will be focused\n'element' - first interactive element within panel will be focused\n'unchanged' - focus will not change",
            required: !1
          },
          useOverlay: {
            name: "bool",
            description: "Defines if QuickView overlay should be rendered when the panel is opened",
            required: !1
          },
          closeOnOverlayClick: {
            name: "bool",
            description: "Defines if the QuickView panel should be closed when overlay has been clicked",
            required: !1
          },
          className: {
            name: "string",
            description: "Allows customizing QuickView wrapper styles",
            required: !1
          },
          overlayClassName: {
            name: "union",
            value: [{
              name: "string"
            }, {
              name: "arrayOf",
              value: {
                name: "string"
              }
            }],
            description: "Allows customizing QuickView overlay styles",
            required: !1
          },
          confirmClose: {
            name: "bool",
            description: "If set to 'true', QuickView will ask for confirmation before beeing closed",
            required: !1
          },
          keepRightSideBarOpen: {
            name: "bool",
            description: "If set to 'true', opening QuickView will not collapse right sidebar\nThis prop only takes effect while using QuickView inside floorplan with right sidebar visible",
            required: !1
          },
          confirmationModalMessages: {
            name: "shape",
            value: {
              title: {
                name: "custom",
                raw: "intlMessageShape",
                required: !1
              },
              notification: {
                name: "custom",
                raw: "intlMessageShape",
                required: !1
              },
              discardChanges: {
                name: "custom",
                raw: "intlMessageShape",
                required: !1
              },
              keepChanges: {
                name: "custom",
                raw: "intlMessageShape",
                required: !1
              }
            },
            description: "Message props for confirmation modal displayed before quick view is closed",
            required: !1
          },
          routerPromptMessages: {
            name: "shape",
            value: {
              title: {
                name: "custom",
                raw: "intlMessageShape",
                required: !1
              },
              notification: {
                name: "custom",
                raw: "intlMessageShape",
                required: !1
              },
              discardChanges: {
                name: "custom",
                raw: "intlMessageShape",
                required: !1
              },
              keepChanges: {
                name: "custom",
                raw: "intlMessageShape",
                required: !1
              }
            },
            description: "Message props for confirmation modal displayed before route is changed",
            required: !1
          },
          dialogRef: {
            name: "shape",
            value: {
              current: {
                name: "instanceOf",
                value: "HTMLDivElement",
                required: !1
              }
            },
            description: "Reference of the dialog wrapper element. Use it when you need to access the dialog wrapper.\nIf not provided it will be provided internally.",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
}, QuickView.__docgenInfo = {
  componentName: "QuickView",
  packageName: "@jutro/quick-view",
  description: "",
  displayName: "QuickView",
  methods: [],
  actualName: "QuickView",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "ID of the component, needs to be unique across application"
    },
    show: {
      type: {
        name: "bool"
      },
      required: !0,
      description: "If set to 'true', QuickView is shown"
    },
    onShowChange: {
      type: {
        name: "func"
      },
      required: !0,
      description: "Callback triggered when QuickView visibility changes"
    },
    closeOnEsc: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Defines if the QuickView panel should be closed on 'esc' key press"
    },
    focusAfterRender: {
      type: {
        name: "enum",
        value: [{
          value: "'panel'",
          computed: !1
        }, {
          value: "'element'",
          computed: !1
        }, {
          value: "'unchanged'",
          computed: !1
        }]
      },
      required: !1,
      description: "Defines where should browser's focus move after opening the panel. Defaults to 'element'\n'panel' - panel itself will be focused\n'element' - first interactive element within panel will be focused\n'unchanged' - focus will not change"
    },
    useOverlay: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Defines if QuickView overlay should be rendered when the panel is opened"
    },
    closeOnOverlayClick: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Defines if the QuickView panel should be closed when overlay has been clicked"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Allows customizing QuickView wrapper styles"
    },
    overlayClassName: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "arrayOf",
          value: {
            name: "string"
          }
        }]
      },
      required: !1,
      description: "Allows customizing QuickView overlay styles"
    },
    confirmClose: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If set to 'true', QuickView will ask for confirmation before beeing closed"
    },
    keepRightSideBarOpen: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If set to 'true', opening QuickView will not collapse right sidebar\nThis prop only takes effect while using QuickView inside floorplan with right sidebar visible"
    },
    confirmationModalMessages: {
      type: {
        name: "shape",
        value: {
          title: {
            name: "union",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          notification: {
            name: "union",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          discardChanges: {
            name: "union",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          keepChanges: {
            name: "union",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          }
        }
      },
      required: !1,
      description: "Message props for confirmation modal displayed before quick view is closed"
    },
    routerPromptMessages: {
      type: {
        name: "shape",
        value: {
          title: {
            name: "union",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          notification: {
            name: "union",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          discardChanges: {
            name: "union",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          keepChanges: {
            name: "union",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          }
        }
      },
      required: !1,
      description: "Message props for confirmation modal displayed before route is changed"
    },
    dialogRef: {
      type: {
        name: "shape",
        value: {
          current: {
            name: "instanceOf",
            value: "HTMLDivElement",
            required: !1
          }
        }
      },
      required: !1,
      description: "Reference of the dialog wrapper element. Use it when you need to access the dialog wrapper.\nIf not provided it will be provided internally."
    },
    phone: {
      type: {
        name: "shape",
        value: {
          id: {
            name: "string",
            description: "ID of the component, needs to be unique across application",
            required: !0
          },
          show: {
            name: "bool",
            description: "If set to 'true', QuickView is shown",
            required: !0
          },
          onShowChange: {
            name: "func",
            description: "Callback triggered when QuickView visibility changes",
            required: !0
          },
          closeOnEsc: {
            name: "bool",
            description: "Defines if the QuickView panel should be closed on 'esc' key press",
            required: !1
          },
          focusAfterRender: {
            name: "enum",
            value: [{
              value: "'panel'",
              computed: !1
            }, {
              value: "'element'",
              computed: !1
            }, {
              value: "'unchanged'",
              computed: !1
            }],
            description: "Defines where should browser's focus move after opening the panel. Defaults to 'element'\n'panel' - panel itself will be focused\n'element' - first interactive element within panel will be focused\n'unchanged' - focus will not change",
            required: !1
          },
          useOverlay: {
            name: "bool",
            description: "Defines if QuickView overlay should be rendered when the panel is opened",
            required: !1
          },
          closeOnOverlayClick: {
            name: "bool",
            description: "Defines if the QuickView panel should be closed when overlay has been clicked",
            required: !1
          },
          className: {
            name: "string",
            description: "Allows customizing QuickView wrapper styles",
            required: !1
          },
          overlayClassName: {
            name: "union",
            value: [{
              name: "string"
            }, {
              name: "arrayOf",
              value: {
                name: "string"
              }
            }],
            description: "Allows customizing QuickView overlay styles",
            required: !1
          },
          confirmClose: {
            name: "bool",
            description: "If set to 'true', QuickView will ask for confirmation before beeing closed",
            required: !1
          },
          keepRightSideBarOpen: {
            name: "bool",
            description: "If set to 'true', opening QuickView will not collapse right sidebar\nThis prop only takes effect while using QuickView inside floorplan with right sidebar visible",
            required: !1
          },
          confirmationModalMessages: {
            name: "shape",
            value: {
              title: {
                name: "union",
                required: !1,
                value: [{
                  name: "string"
                }, {
                  name: "shape",
                  value: {
                    id: {
                      name: "string",
                      required: !1
                    },
                    defaultMessage: {
                      name: "string",
                      required: !1
                    },
                    args: {
                      name: "shape",
                      value: {},
                      required: !1
                    }
                  }
                }]
              },
              notification: {
                name: "union",
                required: !1,
                value: [{
                  name: "string"
                }, {
                  name: "shape",
                  value: {
                    id: {
                      name: "string",
                      required: !1
                    },
                    defaultMessage: {
                      name: "string",
                      required: !1
                    },
                    args: {
                      name: "shape",
                      value: {},
                      required: !1
                    }
                  }
                }]
              },
              discardChanges: {
                name: "union",
                required: !1,
                value: [{
                  name: "string"
                }, {
                  name: "shape",
                  value: {
                    id: {
                      name: "string",
                      required: !1
                    },
                    defaultMessage: {
                      name: "string",
                      required: !1
                    },
                    args: {
                      name: "shape",
                      value: {},
                      required: !1
                    }
                  }
                }]
              },
              keepChanges: {
                name: "union",
                required: !1,
                value: [{
                  name: "string"
                }, {
                  name: "shape",
                  value: {
                    id: {
                      name: "string",
                      required: !1
                    },
                    defaultMessage: {
                      name: "string",
                      required: !1
                    },
                    args: {
                      name: "shape",
                      value: {},
                      required: !1
                    }
                  }
                }]
              }
            },
            description: "Message props for confirmation modal displayed before quick view is closed",
            required: !1
          },
          routerPromptMessages: {
            name: "shape",
            value: {
              title: {
                name: "union",
                required: !1,
                value: [{
                  name: "string"
                }, {
                  name: "shape",
                  value: {
                    id: {
                      name: "string",
                      required: !1
                    },
                    defaultMessage: {
                      name: "string",
                      required: !1
                    },
                    args: {
                      name: "shape",
                      value: {},
                      required: !1
                    }
                  }
                }]
              },
              notification: {
                name: "union",
                required: !1,
                value: [{
                  name: "string"
                }, {
                  name: "shape",
                  value: {
                    id: {
                      name: "string",
                      required: !1
                    },
                    defaultMessage: {
                      name: "string",
                      required: !1
                    },
                    args: {
                      name: "shape",
                      value: {},
                      required: !1
                    }
                  }
                }]
              },
              discardChanges: {
                name: "union",
                required: !1,
                value: [{
                  name: "string"
                }, {
                  name: "shape",
                  value: {
                    id: {
                      name: "string",
                      required: !1
                    },
                    defaultMessage: {
                      name: "string",
                      required: !1
                    },
                    args: {
                      name: "shape",
                      value: {},
                      required: !1
                    }
                  }
                }]
              },
              keepChanges: {
                name: "union",
                required: !1,
                value: [{
                  name: "string"
                }, {
                  name: "shape",
                  value: {
                    id: {
                      name: "string",
                      required: !1
                    },
                    defaultMessage: {
                      name: "string",
                      required: !1
                    },
                    args: {
                      name: "shape",
                      value: {},
                      required: !1
                    }
                  }
                }]
              }
            },
            description: "Message props for confirmation modal displayed before route is changed",
            required: !1
          },
          dialogRef: {
            name: "shape",
            value: {
              current: {
                name: "instanceOf",
                value: "HTMLDivElement",
                required: !1
              }
            },
            description: "Reference of the dialog wrapper element. Use it when you need to access the dialog wrapper.\nIf not provided it will be provided internally.",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          id: {
            name: "string",
            description: "ID of the component, needs to be unique across application",
            required: !0
          },
          show: {
            name: "bool",
            description: "If set to 'true', QuickView is shown",
            required: !0
          },
          onShowChange: {
            name: "func",
            description: "Callback triggered when QuickView visibility changes",
            required: !0
          },
          closeOnEsc: {
            name: "bool",
            description: "Defines if the QuickView panel should be closed on 'esc' key press",
            required: !1
          },
          focusAfterRender: {
            name: "enum",
            value: [{
              value: "'panel'",
              computed: !1
            }, {
              value: "'element'",
              computed: !1
            }, {
              value: "'unchanged'",
              computed: !1
            }],
            description: "Defines where should browser's focus move after opening the panel. Defaults to 'element'\n'panel' - panel itself will be focused\n'element' - first interactive element within panel will be focused\n'unchanged' - focus will not change",
            required: !1
          },
          useOverlay: {
            name: "bool",
            description: "Defines if QuickView overlay should be rendered when the panel is opened",
            required: !1
          },
          closeOnOverlayClick: {
            name: "bool",
            description: "Defines if the QuickView panel should be closed when overlay has been clicked",
            required: !1
          },
          className: {
            name: "string",
            description: "Allows customizing QuickView wrapper styles",
            required: !1
          },
          overlayClassName: {
            name: "union",
            value: [{
              name: "string"
            }, {
              name: "arrayOf",
              value: {
                name: "string"
              }
            }],
            description: "Allows customizing QuickView overlay styles",
            required: !1
          },
          confirmClose: {
            name: "bool",
            description: "If set to 'true', QuickView will ask for confirmation before beeing closed",
            required: !1
          },
          keepRightSideBarOpen: {
            name: "bool",
            description: "If set to 'true', opening QuickView will not collapse right sidebar\nThis prop only takes effect while using QuickView inside floorplan with right sidebar visible",
            required: !1
          },
          confirmationModalMessages: {
            name: "shape",
            value: {
              title: {
                name: "union",
                required: !1,
                value: [{
                  name: "string"
                }, {
                  name: "shape",
                  value: {
                    id: {
                      name: "string",
                      required: !1
                    },
                    defaultMessage: {
                      name: "string",
                      required: !1
                    },
                    args: {
                      name: "shape",
                      value: {},
                      required: !1
                    }
                  }
                }]
              },
              notification: {
                name: "union",
                required: !1,
                value: [{
                  name: "string"
                }, {
                  name: "shape",
                  value: {
                    id: {
                      name: "string",
                      required: !1
                    },
                    defaultMessage: {
                      name: "string",
                      required: !1
                    },
                    args: {
                      name: "shape",
                      value: {},
                      required: !1
                    }
                  }
                }]
              },
              discardChanges: {
                name: "union",
                required: !1,
                value: [{
                  name: "string"
                }, {
                  name: "shape",
                  value: {
                    id: {
                      name: "string",
                      required: !1
                    },
                    defaultMessage: {
                      name: "string",
                      required: !1
                    },
                    args: {
                      name: "shape",
                      value: {},
                      required: !1
                    }
                  }
                }]
              },
              keepChanges: {
                name: "union",
                required: !1,
                value: [{
                  name: "string"
                }, {
                  name: "shape",
                  value: {
                    id: {
                      name: "string",
                      required: !1
                    },
                    defaultMessage: {
                      name: "string",
                      required: !1
                    },
                    args: {
                      name: "shape",
                      value: {},
                      required: !1
                    }
                  }
                }]
              }
            },
            description: "Message props for confirmation modal displayed before quick view is closed",
            required: !1
          },
          routerPromptMessages: {
            name: "shape",
            value: {
              title: {
                name: "union",
                required: !1,
                value: [{
                  name: "string"
                }, {
                  name: "shape",
                  value: {
                    id: {
                      name: "string",
                      required: !1
                    },
                    defaultMessage: {
                      name: "string",
                      required: !1
                    },
                    args: {
                      name: "shape",
                      value: {},
                      required: !1
                    }
                  }
                }]
              },
              notification: {
                name: "union",
                required: !1,
                value: [{
                  name: "string"
                }, {
                  name: "shape",
                  value: {
                    id: {
                      name: "string",
                      required: !1
                    },
                    defaultMessage: {
                      name: "string",
                      required: !1
                    },
                    args: {
                      name: "shape",
                      value: {},
                      required: !1
                    }
                  }
                }]
              },
              discardChanges: {
                name: "union",
                required: !1,
                value: [{
                  name: "string"
                }, {
                  name: "shape",
                  value: {
                    id: {
                      name: "string",
                      required: !1
                    },
                    defaultMessage: {
                      name: "string",
                      required: !1
                    },
                    args: {
                      name: "shape",
                      value: {},
                      required: !1
                    }
                  }
                }]
              },
              keepChanges: {
                name: "union",
                required: !1,
                value: [{
                  name: "string"
                }, {
                  name: "shape",
                  value: {
                    id: {
                      name: "string",
                      required: !1
                    },
                    defaultMessage: {
                      name: "string",
                      required: !1
                    },
                    args: {
                      name: "shape",
                      value: {},
                      required: !1
                    }
                  }
                }]
              }
            },
            description: "Message props for confirmation modal displayed before route is changed",
            required: !1
          },
          dialogRef: {
            name: "shape",
            value: {
              current: {
                name: "instanceOf",
                value: "HTMLDivElement",
                required: !1
              }
            },
            description: "Reference of the dialog wrapper element. Use it when you need to access the dialog wrapper.\nIf not provided it will be provided internally.",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          id: {
            name: "string",
            description: "ID of the component, needs to be unique across application",
            required: !0
          },
          show: {
            name: "bool",
            description: "If set to 'true', QuickView is shown",
            required: !0
          },
          onShowChange: {
            name: "func",
            description: "Callback triggered when QuickView visibility changes",
            required: !0
          },
          closeOnEsc: {
            name: "bool",
            description: "Defines if the QuickView panel should be closed on 'esc' key press",
            required: !1
          },
          focusAfterRender: {
            name: "enum",
            value: [{
              value: "'panel'",
              computed: !1
            }, {
              value: "'element'",
              computed: !1
            }, {
              value: "'unchanged'",
              computed: !1
            }],
            description: "Defines where should browser's focus move after opening the panel. Defaults to 'element'\n'panel' - panel itself will be focused\n'element' - first interactive element within panel will be focused\n'unchanged' - focus will not change",
            required: !1
          },
          useOverlay: {
            name: "bool",
            description: "Defines if QuickView overlay should be rendered when the panel is opened",
            required: !1
          },
          closeOnOverlayClick: {
            name: "bool",
            description: "Defines if the QuickView panel should be closed when overlay has been clicked",
            required: !1
          },
          className: {
            name: "string",
            description: "Allows customizing QuickView wrapper styles",
            required: !1
          },
          overlayClassName: {
            name: "union",
            value: [{
              name: "string"
            }, {
              name: "arrayOf",
              value: {
                name: "string"
              }
            }],
            description: "Allows customizing QuickView overlay styles",
            required: !1
          },
          confirmClose: {
            name: "bool",
            description: "If set to 'true', QuickView will ask for confirmation before beeing closed",
            required: !1
          },
          keepRightSideBarOpen: {
            name: "bool",
            description: "If set to 'true', opening QuickView will not collapse right sidebar\nThis prop only takes effect while using QuickView inside floorplan with right sidebar visible",
            required: !1
          },
          confirmationModalMessages: {
            name: "shape",
            value: {
              title: {
                name: "union",
                required: !1,
                value: [{
                  name: "string"
                }, {
                  name: "shape",
                  value: {
                    id: {
                      name: "string",
                      required: !1
                    },
                    defaultMessage: {
                      name: "string",
                      required: !1
                    },
                    args: {
                      name: "shape",
                      value: {},
                      required: !1
                    }
                  }
                }]
              },
              notification: {
                name: "union",
                required: !1,
                value: [{
                  name: "string"
                }, {
                  name: "shape",
                  value: {
                    id: {
                      name: "string",
                      required: !1
                    },
                    defaultMessage: {
                      name: "string",
                      required: !1
                    },
                    args: {
                      name: "shape",
                      value: {},
                      required: !1
                    }
                  }
                }]
              },
              discardChanges: {
                name: "union",
                required: !1,
                value: [{
                  name: "string"
                }, {
                  name: "shape",
                  value: {
                    id: {
                      name: "string",
                      required: !1
                    },
                    defaultMessage: {
                      name: "string",
                      required: !1
                    },
                    args: {
                      name: "shape",
                      value: {},
                      required: !1
                    }
                  }
                }]
              },
              keepChanges: {
                name: "union",
                required: !1,
                value: [{
                  name: "string"
                }, {
                  name: "shape",
                  value: {
                    id: {
                      name: "string",
                      required: !1
                    },
                    defaultMessage: {
                      name: "string",
                      required: !1
                    },
                    args: {
                      name: "shape",
                      value: {},
                      required: !1
                    }
                  }
                }]
              }
            },
            description: "Message props for confirmation modal displayed before quick view is closed",
            required: !1
          },
          routerPromptMessages: {
            name: "shape",
            value: {
              title: {
                name: "union",
                required: !1,
                value: [{
                  name: "string"
                }, {
                  name: "shape",
                  value: {
                    id: {
                      name: "string",
                      required: !1
                    },
                    defaultMessage: {
                      name: "string",
                      required: !1
                    },
                    args: {
                      name: "shape",
                      value: {},
                      required: !1
                    }
                  }
                }]
              },
              notification: {
                name: "union",
                required: !1,
                value: [{
                  name: "string"
                }, {
                  name: "shape",
                  value: {
                    id: {
                      name: "string",
                      required: !1
                    },
                    defaultMessage: {
                      name: "string",
                      required: !1
                    },
                    args: {
                      name: "shape",
                      value: {},
                      required: !1
                    }
                  }
                }]
              },
              discardChanges: {
                name: "union",
                required: !1,
                value: [{
                  name: "string"
                }, {
                  name: "shape",
                  value: {
                    id: {
                      name: "string",
                      required: !1
                    },
                    defaultMessage: {
                      name: "string",
                      required: !1
                    },
                    args: {
                      name: "shape",
                      value: {},
                      required: !1
                    }
                  }
                }]
              },
              keepChanges: {
                name: "union",
                required: !1,
                value: [{
                  name: "string"
                }, {
                  name: "shape",
                  value: {
                    id: {
                      name: "string",
                      required: !1
                    },
                    defaultMessage: {
                      name: "string",
                      required: !1
                    },
                    args: {
                      name: "shape",
                      value: {},
                      required: !1
                    }
                  }
                }]
              }
            },
            description: "Message props for confirmation modal displayed before route is changed",
            required: !1
          },
          dialogRef: {
            name: "shape",
            value: {
              current: {
                name: "instanceOf",
                value: "HTMLDivElement",
                required: !1
              }
            },
            description: "Reference of the dialog wrapper element. Use it when you need to access the dialog wrapper.\nIf not provided it will be provided internally.",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
};