import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import React, { useState, useEffect } from "react";
import { ModalNext, ModalHeader, ModalBody, ToggleField, DropdownSelectField, ModalFooter, Button } from "@jutro/components";
import { uniqueInnerId } from "@jutro/platform";
import { useTranslator } from "@jutro/locale";
import { GridItem } from "@jutro/layout";
import { messages } from "./ListViewSortingModal.messages";
import { useListViewColumnSorting } from "./useListViewColumnSorting";
export var ListViewSortingModal = function (_ref) {
  var _context,
    isOpen = _ref.isOpen,
    onResolve = _ref.onResolve,
    onReject = _ref.onReject,
    columnOptions = _ref.columnOptions,
    onColumnSort = _ref.onColumnSort,
    sortedColumnId = _ref.sortedColumnId,
    sortedColumnDesc = _ref.sortedColumnDesc,
    translator = useTranslator(),
    onSortingTrigger = useListViewColumnSorting({
      onColumnSort: onColumnSort,
      onResolve: onResolve,
      columnOptions: columnOptions
    }),
    dropdownOptions = _mapInstanceProperty(_context = _filterInstanceProperty(columnOptions).call(columnOptions, function (columnOption) {
      return columnOption.sortable;
    })).call(_context, function (columnOption) {
      return {
        code: columnOption.id,
        name: columnOption.columnProps.header
      };
    }),
    _useState = useState(sortedColumnId),
    _useState2 = _slicedToArray(_useState, 2),
    selectedColumnId = _useState2[0],
    setSelectedColumnId = _useState2[1],
    _useState3 = useState(sortedColumnDesc ? SortingOrder.DESCENDING : SortingOrder.ASCENDING),
    _useState4 = _slicedToArray(_useState3, 2),
    sortingOrder = _useState4[0],
    setSortingOrder = _useState4[1];
  useEffect(function () {
    setSelectedColumnId(sortedColumnId), setSortingOrder(sortedColumnDesc ? SortingOrder.DESCENDING : SortingOrder.ASCENDING);
  }, [sortedColumnId, sortedColumnDesc]);
  var titleId = uniqueInnerId("sortingModalTitle", "titleId").titleId;
  return React.createElement(ModalNext, {
    isOpen: isOpen,
    onRequestClose: onReject,
    ariaLabelledby: titleId,
    shouldCloseOnEsc: !1
  }, React.createElement(ModalHeader, {
    title: translator(messages.sortingModalTitle),
    titleId: titleId,
    onClose: onReject,
    ariaLabel: translator(messages.closeModalAriaLabel)
  }), React.createElement(ModalBody, {
    contentLayout: {
      component: "Grid",
      componentProps: {
        columns: ["2fr", "1fr"]
      }
    },
    autoFocus: !0
  }, React.createElement(GridItem, null, React.createElement(DropdownSelectField, {
    availableValues: dropdownOptions,
    id: "list-view-sorting-options",
    label: translator(messages.sortOptionsDropdownLabel),
    alwaysShowPlaceholder: !1,
    onValueChange: function (columnId) {
      return setSelectedColumnId(columnId);
    },
    value: selectedColumnId
  })), React.createElement(GridItem, null, React.createElement(ToggleField, {
    availableValues: [{
      code: SortingOrder.ASCENDING,
      name: "A ➔ Z"
    }, {
      code: SortingOrder.DESCENDING,
      name: "Z ➔ A"
    }],
    id: "toggle-sorting-order",
    label: messages.sortOrderToggleLabel,
    onValueChange: function (prefix) {
      return setSortingOrder(prefix);
    },
    value: sortingOrder,
    buttonAriaLabels: [messages.ascendingOrderButtonLabel, messages.descendingOrderButtonLabel]
  }))), React.createElement(ModalFooter, null, React.createElement(Button, {
    type: "text",
    onClick: onReject,
    "aria-label": translator(messages.cancelSortButtonAriaLabel)
  }, translator(messages.cancelSortButtonLabel)), React.createElement(Button, {
    onClick: function () {
      return onSortingTrigger({
        selectedColumnId: selectedColumnId,
        sortingOrder: sortingOrder
      });
    },
    "aria-label": translator(messages.applySortButtonAriaLabel)
  }, translator(messages.applySortButtonLabel))));
};
ListViewSortingModal.displayName = "ListViewSortingModal";
export var SortingOrder;
!function (SortingOrder) {
  SortingOrder.ASCENDING = "ASCENDING", SortingOrder.DESCENDING = "DESCENDING";
}(SortingOrder || (SortingOrder = {})), ListViewSortingModal.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "ListViewSortingModal"
}, ListViewSortingModal.__docgenInfo = {
  componentName: "ListViewSortingModal",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "ListViewSortingModal",
  methods: [],
  actualName: "ListViewSortingModal"
};