import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _Object$entries from "@babel/runtime-corejs3/core-js-stable/object/entries";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
import { useState, useCallback, useEffect } from "react";
import get from "lodash/get";
import set from "lodash/set";
var EMPTY_FILTERS = {};
export var useFilterForm = function (_ref) {
  var filters = _ref.filters,
    onFiltersChange = _ref.onFiltersChange,
    _useState = useState(null != filters ? filters : EMPTY_FILTERS),
    _useState2 = _slicedToArray(_useState, 2),
    formData = _useState2[0],
    setFormData = _useState2[1];
  useEffect(function () {
    setFormData(null != filters ? filters : EMPTY_FILTERS);
  }, [filters]);
  var applyFilters = useCallback(function () {
      var _context;
      null == onFiltersChange || onFiltersChange({
        filters: _reduceInstanceProperty(_context = _Object$entries(formData)).call(_context, function (newFilters, _ref2) {
          var _ref3 = _slicedToArray(_ref2, 2),
            path = _ref3[0],
            value = _ref3[1];
          return null == value || "" === value || Array.isArray(value) && 0 === value.length || (newFilters[path] = value), newFilters;
        }, {})
      });
    }, [formData, onFiltersChange]),
    handleClearFilter = useCallback(function () {
      setFormData({}), null == onFiltersChange || onFiltersChange({});
    }, [onFiltersChange, setFormData]),
    handleFormEnter = useCallback(function (event) {
      "Enter" !== event.key || event.defaultPrevented || applyFilters();
    }, [applyFilters]);
  return {
    formData: formData,
    readValue: function (_id, path) {
      return get(formData, path);
    },
    handleDataChange: function (value, path) {
      setFormData(function (prevState) {
        var newState = function (target) {
          for (var i = 1; i < arguments.length; i++) {
            var source = null != arguments[i] ? arguments[i] : {};
            i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
              _defineProperty(target, key, source[key]);
            }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
              _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
            });
          }
          return target;
        }({}, prevState);
        return set(newState, path, value), newState;
      });
    },
    applyFilters: applyFilters,
    handleClearFilter: handleClearFilter,
    handleFormEnter: handleFormEnter
  };
};