import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import React, { createContext, useState, useRef, useMemo, useLayoutEffect } from "react";
import { NO_FOCUS } from "./constants";
import { createOnFocus } from "./createOnFocus";
import { createOnKeyDown } from "./createOnKeyDown";
import { useKeyboardNavigationParams } from "./useKeyboardNavigationParams";
export var KeyboardNavigationContext = createContext({
  focused: NO_FOCUS
});
export var KeyboardNavigationProvider = function (_ref) {
  var children = _ref.children,
    _useKeyboardNavigatio = useKeyboardNavigationParams(null == children ? void 0 : children.props.data),
    lastRowIndex = _useKeyboardNavigatio.lastRowIndex,
    firstColumnIndex = _useKeyboardNavigatio.firstColumnIndex,
    lastColumnIndex = _useKeyboardNavigatio.lastColumnIndex,
    _useState = useState(NO_FOCUS),
    _useState2 = _slicedToArray(_useState, 2),
    focused = _useState2[0],
    setFocused = _useState2[1],
    focusedRef = useRef({
      focused: focused,
      setFocused: setFocused
    }),
    parametersRef = useRef({
      lastRowIndex: lastRowIndex,
      firstColumnIndex: firstColumnIndex,
      lastColumnIndex: lastColumnIndex
    });
  useLayoutEffect(function () {
    focusedRef.current = {
      focused: focused,
      setFocused: setFocused
    };
  }, [focused]), useLayoutEffect(function () {
    parametersRef.current = {
      lastRowIndex: lastRowIndex,
      firstColumnIndex: firstColumnIndex,
      lastColumnIndex: lastColumnIndex
    };
  }, [lastRowIndex, firstColumnIndex, lastColumnIndex]);
  var onKeyDown = useMemo(function () {
      return createOnKeyDown(focusedRef, parametersRef);
    }, []),
    onFocus = useMemo(function () {
      return createOnFocus(focusedRef);
    }, []),
    focusedContextValue = useMemo(function () {
      return {
        onKeyDown: onKeyDown,
        onFocus: onFocus,
        focused: focused
      };
    }, [focused]);
  return React.createElement(KeyboardNavigationContext.Provider, {
    value: focusedContextValue
  }, children);
};
KeyboardNavigationProvider.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "KeyboardNavigationProvider"
}, KeyboardNavigationProvider.__docgenInfo = {
  componentName: "KeyboardNavigationProvider",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "KeyboardNavigationProvider",
  methods: [],
  actualName: "KeyboardNavigationProvider"
};