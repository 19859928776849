import { defineMessages } from "react-intl";
export default defineMessages({
  selectAllRows: {
    id: "jutro-dataview.listview.selection.selectAllRows",
    defaultMessage: "Select all visible rows"
  },
  deselectAllRows: {
    id: "jutro-dataview.listview.selection.deselectAllRows",
    defaultMessage: "Deselect all visible rows"
  },
  searchInputPlaceholder: {
    id: "jutro-dataview.ListView.searchInputPlaceholder",
    defaultMessage: "Search grid"
  },
  searchInputLabel: {
    id: "jutro-dataview.ListView.searchInputLabel",
    defaultMessage: "Search grid - entering text will update the data below"
  }
});