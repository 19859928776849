import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
var _excluded = ["value", "rawRow"];
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _Object$values from "@babel/runtime-corejs3/core-js-stable/object/values";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import { useMemo } from "react";
import keyBy from "lodash/keyBy";
import isEmpty from "lodash/isEmpty";
import isString from "lodash/isString";
import reduce from "lodash/fp/reduce";
import { useTranslator } from "@jutro/locale";
import { useColumnsContext } from "../features/columns";
import { useColumnsConfigContext } from "../features/columnsConfig";
var renderNothing = function () {
    return null;
  },
  configListToMap = reduce(function (acc, conf) {
    return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, conf.id, conf));
  }, {}),
  defaultColumnsConfigMap = {};
export var useColumnOptions = function () {
  var translator = useTranslator(),
    columns = useColumnsContext().columns.current,
    columnsConfig = useColumnsConfigContext().columnsConfig,
    columnsConfigMap = useMemo(function () {
      return columnsConfig.length ? configListToMap(columnsConfig) : defaultColumnsConfigMap;
    }, [columnsConfig]);
  return [useMemo(function () {
    var _context3,
      columnOptions = _mapInstanceProperty(_context3 = _Object$values(columns)).call(_context3, function (column) {
        return function (_ref) {
          var column = _ref.column,
            translator = _ref.translator,
            columnsConfigMap = _ref.columnsConfigMap,
            columnId = column.id,
            path = column.path,
            _column$columnDataTyp = column.columnDataType,
            columnDataType = void 0 === _column$columnDataTyp ? "unknown" : _column$columnDataTyp,
            classNames = column.classNames,
            renderCell = column.renderCell,
            renderHeader = column.renderHeader,
            sortable = column.sortable,
            columnConfig = columnsConfigMap[columnId] || {
              visible: !0
            };
          return _objectSpread(_objectSpread({
            Header: function () {
              return (null != renderHeader ? renderHeader : renderNothing)({
                translator: translator,
                columnProps: column
              });
            },
            Cell: function (_ref2) {
              var value = _ref2.value,
                rawRow = _ref2.rawRow,
                rest = _objectWithoutProperties(_ref2, _excluded);
              return (null != renderCell ? renderCell : renderNothing)(_objectSpread({
                translator: translator,
                columnProps: column,
                value: value,
                row: rawRow
              }, rest));
            },
            classNames: classNames,
            sortable: sortable,
            columnDataType: columnDataType,
            path: path,
            translator: translator,
            columnProps: column
          }, columnConfig), {}, {
            id: columnId
          });
        }({
          column: column,
          translator: translator,
          columnsConfigMap: columnsConfigMap
        });
      });
    return function (columns) {
      var _context,
        columnsConfig = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : [],
        columnsMap = keyBy(columns, "id");
      return _concatInstanceProperty(_context = []).call(_context, _toConsumableArray(isEmpty(columnsConfig) ? columns : []), _toConsumableArray(_reduceInstanceProperty(columnsConfig).call(columnsConfig, function (acc, columnConfig) {
        var _context2,
          column = columnsMap[isString(columnConfig) ? columnConfig : columnConfig.id];
        return column ? _concatInstanceProperty(_context2 = []).call(_context2, _toConsumableArray(acc), [column]) : acc;
      }, [])));
    }(columnOptions, columnsConfig);
  }, [columns, columnsConfig, translator, columnsConfigMap])];
};