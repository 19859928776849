import "core-js/modules/es.string.replace";
import _trimInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/trim";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import isString from "lodash/isString";
import set from "lodash/set";
import get from "lodash/get";
import isArray from "lodash/isArray";
import mustache from "mustache";
var variableExpr = new RegExp("".concat("{{", ".+").concat("}}"));
function hasVariable(str) {
  return variableExpr.test(str);
}
function splitKey(keyMaybeValue) {
  var _context,
    _context2,
    r = /([\w-.]+\s+)\|\|\s+(.*)/.exec(keyMaybeValue);
  return {
    key: r ? _trimInstanceProperty(_context = r[1]).call(_context) : keyMaybeValue,
    defaultValue: r ? _trimInstanceProperty(_context2 = r[2]).call(_context2).replace(/(^['"]|['"]$)/g, "") : void 0
  };
}
function getValue(keyMaybeValue, params) {
  var _context3,
    allowMissing = arguments.length > 2 && void 0 !== arguments[2] && arguments[2],
    _splitKey = splitKey(keyMaybeValue),
    key = _splitKey.key,
    defaultValue = _splitKey.defaultValue,
    value = get(params, key, defaultValue);
  null == value && (allowMissing || console.warn("Error, missing key ", key, " from: ", params), value = _concatInstanceProperty(_context3 = "".concat("{{").concat(keyMaybeValue)).call(_context3, "}}"));
  return value;
}
function substitute(str, params) {
  var allowMissing = arguments.length > 2 && void 0 !== arguments[2] && arguments[2],
    tokens = mustache.parse(str, ["{{", "}}"]),
    buffer = "";
  return tokens.forEach(function (token) {
    var symbol = token[0],
      textOrKey = token[1];
    switch (symbol) {
      case "name":
        buffer += getValue(textOrKey, params, allowMissing);
        break;
      case "text":
        buffer += textOrKey;
        break;
      default:
        throw new Error("Parse error, unexpected symbol: ".concat(symbol));
    }
  }), buffer;
}
function concatPath(path, addPath) {
  var _context5,
    _context4,
    str = "number" == typeof addPath ? "[".concat(addPath, "]") : addPath;
  return 0 === path.length ? str : "number" == typeof addPath ? _concatInstanceProperty(_context4 = "".concat(path)).call(_context4, str) : _concatInstanceProperty(_context5 = "".concat(path, ".")).call(_context5, str);
}
function traverse(val, callback) {
  var path = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : "";
  isArray(val) ? val.forEach(function (elem, i) {
    return traverse(elem, callback, concatPath(path, i));
  }) : val instanceof Object ? _Object$keys(val).forEach(function (key) {
    return traverse(val[key], callback, concatPath(path, key));
  }) : callback(val, path);
}
export default function substituteParametersValues(obj, params) {
  return traverse(obj, function (val, path) {
    isString(val) && hasVariable(val) && set(obj, path, substitute(val, params, !0));
  }), traverse(obj, function (val, path) {
    "string" == typeof val && hasVariable(val) && set(obj, path, substitute(val, obj));
  }), obj;
}