import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["children", "show", "onClose", "useOverlay", "overlayClassName", "closeOnOverlayClick", "confirmClose"];
import React from "react";
import { QuickViewOverlay } from "./QuickViewOverlay";
import { QuickViewContent } from "./QuickViewContent";
export var QuickViewWrapper = function (_ref) {
  var children = _ref.children,
    show = _ref.show,
    onClose = _ref.onClose,
    useOverlay = _ref.useOverlay,
    overlayClassName = _ref.overlayClassName,
    closeOnOverlayClick = _ref.closeOnOverlayClick,
    otherProps = (_ref.confirmClose, _objectWithoutProperties(_ref, _excluded));
  return React.createElement(React.Fragment, null, React.createElement(QuickViewContent, _extends({}, otherProps, {
    useOverlay: useOverlay,
    show: show,
    onClose: onClose
  }), children), useOverlay && React.createElement(QuickViewOverlay, {
    show: show,
    onClose: onClose,
    closeOnOverlayClick: closeOnOverlayClick,
    overlayClassName: overlayClassName
  }));
};
QuickViewWrapper.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "QuickViewWrapper"
}, QuickViewWrapper.__docgenInfo = {
  componentName: "QuickViewWrapper",
  packageName: "@jutro/quick-view",
  description: "",
  displayName: "QuickViewWrapper",
  methods: [],
  actualName: "QuickViewWrapper"
};