import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _Set from "@babel/runtime-corejs3/core-js-stable/set";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import { useEffect } from "react";
import keys from "lodash/fp/keys";
import pick from "lodash/fp/pick";
import { warning } from "@jutro/logger";
var getConsultUXMessage = function (prop, context) {
    var _context;
    return _concatInstanceProperty(_context = "Prop '".concat(prop, "' is not available in data view ")).call(_context, context, ". Consult UX for alternative solutions or use custom styles for the component.");
  },
  getUseAlternativeMessage = function (prop, alternative, context) {
    var _context2, _context3;
    return _concatInstanceProperty(_context2 = _concatInstanceProperty(_context3 = "Prop '".concat(prop, "' is not available in data view ")).call(_context3, context, ". Please use ")).call(_context2, alternative, " instead after replacing adapters with DataView components.");
  };
export var messages = {
  visible: getUseAlternativeMessage("visible", "DataViewColumnsConfig component", "columns"),
  onFilter: getUseAlternativeMessage("onFilter", "DataViewFiltering component with external helpers (useSyncData, useAsyncData)", "columns"),
  onSort: getUseAlternativeMessage("onSort", "DataViewSorting component with external helpers (useSyncData, useAsyncData)", "columns"),
  renderEditCell: getConsultUXMessage("renderEditCell", "columns"),
  editCellClass: getConsultUXMessage("editCellClass", "columns"),
  columnProportion: getConsultUXMessage("columnProportion", "columns"),
  width: getConsultUXMessage("width", "columns"),
  cell: getUseAlternativeMessage("cell", "renderCell", "columns"),
  headerClassName: getConsultUXMessage("headerClassName", "columns"),
  cellClassName: getConsultUXMessage("cellClassName", "columns"),
  textAlign: getConsultUXMessage("textAlign", "columns"),
  columnClassName: getConsultUXMessage("columnClassName", "columns"),
  valueProps: "Prop 'valueProps' is not available in data view columns. Please pass it's properties directly to the column.",
  fieldDatatype: getUseAlternativeMessage("fieldDatatype", "GenericColumn or other types of columns", "columns"),
  onAccessor: getUseAlternativeMessage("onAccessor", "onResolveValue", "columns"),
  columnsProportion: getConsultUXMessage("columnsProportion", "components"),
  renderPhoneCardComponent: getConsultUXMessage("renderPhoneCardComponent", "components"),
  title: getUseAlternativeMessage("title", "DataViewTitle with `title` or `render` prop", "components"),
  renderTitle: getUseAlternativeMessage("renderTitle", "DataViewTitle with `render` prop", "components"),
  titleId: "Prop 'titleId' is not available in data view components. You can pass `id` to the table instead.",
  titlePosition: getUseAlternativeMessage("titlePosition", "DataViewTitle with `className` prop", "components"),
  renderTitleAction: getUseAlternativeMessage("renderTitleAction", "DataViewHeaderActions with `render` prop", "components"),
  placeholder: getUseAlternativeMessage("placeholder", "DataViewOverlay with `noRowsMessage` prop", "components"),
  model: "Prop 'model' is not available in data view columns.",
  basePath: "Prop 'model' is not available in data view columns. Please use 'path' instead.",
  uiMetadata: "Prop 'model' is not available in data view columns.",
  pageProps: "Prop 'pageProps' is not available in data view columns.",
  readOnly: "Prop 'readOnly' is not available in data view columns. Columns are readonly by default",
  singleActionType: getUseAlternativeMessage("singleActionType", "Action with `wrapper` prop", "components"),
  hideIconsInDropdown: "Prop 'hideIconsInDropdown' is not available in data view columns.",
  tooltip: getUseAlternativeMessage("tooltip", "Action with `wrapperProps` prop", "components"),
  icon: getUseAlternativeMessage("icon", "Action with `wrapper` and `wrapperProps` props", "components"),
  isEditTrigger: "Prop 'isEditTrigger' is not available in data view columns.",
  triggerOnRowClick: "Prop 'triggerOnRowClick' is not available in data view columns."
};
var loggedMessages = new _Set();
export var useUnsupportedPropsWarning = function (componentProps) {
  useEffect(function () {
    keys(pick(_Object$keys(messages), componentProps)).forEach(function (key) {
      if (!loggedMessages.has(key)) {
        loggedMessages.add(key);
        var messageToDisplay = messages[key];
        messageToDisplay && warning(messageToDisplay, {
          bold: !0
        });
      }
    });
  }, [componentProps]);
};