import React, { memo } from "react";
import { DataViewHeader } from "../../features/header";
import { ColumnsConfigTrigger, useColumnsConfigContext } from "../../features/columnsConfig";
import { useFilteringContext } from "../../features/filtering";
import { useSearchContext } from "../../features/search";
import { useSelectionContext } from "../../features/selection";
import { SortingModalTrigger, useSortingContext } from "../../features/sorting";
import { SelectAllTrigger } from "./SelectAllTrigger";
import messages from "./ListViewHeader.messages";
var ListViewHeaderInternal = function (_ref) {
  var titleId = _ref.titleId,
    className = _ref.className,
    columnOptions = _ref.columnOptions,
    FiltersTriggerComponent = _ref.FiltersTriggerComponent,
    onGetRowId = _ref.onGetRowId,
    rowIds = _ref.rowIds,
    displayColumnsConfigTrigger = useColumnsConfigContext().modalAvailable,
    filtersTriggerProps = useFilteringContext(),
    _useSearchContext = useSearchContext(),
    searchEnabled = _useSearchContext.searchEnabled,
    onSearchChange = _useSearchContext.onSearchChange,
    searchValue = _useSearchContext.searchValue,
    _useSearchContext$pla = _useSearchContext.placeholder,
    placeholder = void 0 === _useSearchContext$pla ? messages.searchInputPlaceholder : _useSearchContext$pla,
    _useSearchContext$lab = _useSearchContext.label,
    label = void 0 === _useSearchContext$lab ? messages.searchInputLabel : _useSearchContext$lab,
    SearchComponent = _useSearchContext.render,
    shouldRenderSorting = useSortingContext().sortingEnabled && columnOptions.some(function (column) {
      return column.sortable;
    }),
    _useSelectionContext = useSelectionContext(),
    useSelectAll = _useSelectionContext.useSelectAll,
    onAllRowsSelect = _useSelectionContext.onAllRowsSelect,
    selectedRows = _useSelectionContext.selectedRows;
  return React.createElement(DataViewHeader, {
    titleId: titleId,
    className: className,
    rowIds: rowIds
  }, searchEnabled && React.createElement(SearchComponent, {
    onSearchChange: onSearchChange,
    searchValue: searchValue,
    placeholder: placeholder,
    label: label
  }), useSelectAll && React.createElement(SelectAllTrigger, {
    onAllRowsSelect: onAllRowsSelect,
    selectedRows: selectedRows,
    rowIds: rowIds
  }), shouldRenderSorting && React.createElement(SortingModalTrigger, {
    columnOptions: columnOptions,
    onGetRowId: onGetRowId
  }), FiltersTriggerComponent && React.createElement(FiltersTriggerComponent, filtersTriggerProps), displayColumnsConfigTrigger && React.createElement(ColumnsConfigTrigger, null));
};
ListViewHeaderInternal.displayName = "ListViewHeader";
export var ListViewHeader = memo(ListViewHeaderInternal);
ListViewHeaderInternal.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "ListViewHeader"
}, ListViewHeaderInternal.__docgenInfo = {
  componentName: "ListViewHeader",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "ListViewHeader",
  methods: [],
  actualName: "ListViewHeaderInternal"
};