import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _Object$entries from "@babel/runtime-corejs3/core-js-stable/object/entries";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import omit from "lodash/omit";
import keys from "lodash/keys";
import { useTranslator } from "@jutro/locale";
import { customColumnsUnsupportedProps } from "../../../types";
import { GenericColumn } from "../../../features/columns";
import { columnsMapping } from "./columnsMapping";
import { propsMapping, mapValueProps, mappedProps } from "./propsMapping";
var sharedColumnProps = keys(omit(GenericColumn.propTypes, customColumnsUnsupportedProps));
export var useMappedProps = function (props) {
  var _context,
    _context2,
    _context3,
    translator = useTranslator(),
    _columnsMapping = columnsMapping[props.fieldDatatype || "unknown"],
    component = _columnsMapping.component,
    allowedProps = _columnsMapping.allowedProps,
    transformedProps = _reduceInstanceProperty(_context = _Object$entries(propsMapping)).call(_context, function (acc, _ref) {
      var _ref2 = _slicedToArray(_ref, 2),
        propName = _ref2[0],
        propValue = (0, _ref2[1])(props, translator);
      return void 0 === propValue ? acc : _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, propName, propValue));
    }, {}),
    valueProps = mapValueProps(props),
    untransformedProps = _reduceInstanceProperty(_context2 = _Object$entries(props)).call(_context2, function (acc, _ref3) {
      var _ref4 = _slicedToArray(_ref3, 2),
        propName = _ref4[0],
        propValue = _ref4[1];
      return mappedProps.some(function (transformedProp) {
        return transformedProp === propName;
      }) ? acc : _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, propName, propValue));
    }, {});
  return {
    component: component,
    props: _reduceInstanceProperty(_context3 = _Object$entries(_objectSpread(_objectSpread(_objectSpread({}, untransformedProps), valueProps), transformedProps))).call(_context3, function (acc, _ref5) {
      var _context4,
        _ref6 = _slicedToArray(_ref5, 2),
        propName = _ref6[0],
        propValue = _ref6[1];
      return _concatInstanceProperty(_context4 = []).call(_context4, _toConsumableArray(allowedProps), _toConsumableArray(sharedColumnProps)).some(function (allowedProp) {
        return allowedProp === propName;
      }) ? _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, propName, propValue)) : acc;
    }, {})
  };
};