import _asyncToGenerator from "@babel/runtime-corejs3/helpers/asyncToGenerator";
import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _regeneratorRuntime from "@babel/runtime-corejs3/regenerator";
function _createForOfIteratorHelper(o, allowArrayLike) {
  var it = void 0 !== _Symbol && _getIteratorMethod(o) || o["@@iterator"];
  if (!it) {
    if (Array.isArray(o) || (it = function (o, minLen) {
      var _context8;
      if (!o) return;
      if ("string" == typeof o) return _arrayLikeToArray(o, minLen);
      var n = _sliceInstanceProperty(_context8 = Object.prototype.toString.call(o)).call(_context8, 8, -1);
      "Object" === n && o.constructor && (n = o.constructor.name);
      if ("Map" === n || "Set" === n) return _Array$from(o);
      if ("Arguments" === n || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
    }(o)) || allowArrayLike && o && "number" == typeof o.length) {
      it && (o = it);
      var i = 0,
        F = function () {};
      return {
        s: F,
        n: function () {
          return i >= o.length ? {
            done: !0
          } : {
            done: !1,
            value: o[i++]
          };
        },
        e: function (_e) {
          throw _e;
        },
        f: F
      };
    }
    throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }
  var err,
    normalCompletion = !0,
    didErr = !1;
  return {
    s: function () {
      it = it.call(o);
    },
    n: function () {
      var step = it.next();
      return normalCompletion = step.done, step;
    },
    e: function (_e2) {
      didErr = !0, err = _e2;
    },
    f: function () {
      try {
        normalCompletion || null == it.return || it.return();
      } finally {
        if (didErr) throw err;
      }
    }
  };
}
function _arrayLikeToArray(arr, len) {
  (null == len || len > arr.length) && (len = arr.length);
  for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i];
  return arr2;
}
import _findIndexInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find-index";
import _spliceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/splice";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _findInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find";
import _sliceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/slice";
import _Array$from from "@babel/runtime-corejs3/core-js-stable/array/from";
import _Symbol from "@babel/runtime-corejs3/core-js-stable/symbol";
import _getIteratorMethod from "@babel/runtime-corejs3/core-js/get-iterator-method";
import { useRef, useMemo, useCallback } from "react";
import isEmpty from "lodash/isEmpty";
export var useVisibilityManager = function () {
  var _ref4,
    _ref6,
    state = useRef([]),
    register = useCallback(function (_ref) {
      var _context,
        _context3,
        _context2,
        newViewId = _ref.id,
        show = _ref.show,
        onClose = _ref.onClose,
        onOpen = _ref.onOpen,
        views = state.current,
        viewIndex = _findIndexInstanceProperty(_context = state.current).call(_context, function (_ref2) {
          return _ref2.id === newViewId;
        });
      viewIndex >= 0 && _spliceInstanceProperty(_context2 = state.current).call(_context2, viewIndex, 1);
      state.current = _concatInstanceProperty(_context3 = []).call(_context3, _toConsumableArray(views), [{
        id: newViewId,
        show: show,
        onClose: onClose,
        onOpen: onOpen
      }]);
    }, []),
    unregister = useCallback(function (viewId) {
      var views = state.current,
        viewIndex = _findIndexInstanceProperty(views).call(views, function (_ref3) {
          return _ref3.id === viewId;
        });
      _spliceInstanceProperty(views).call(views, viewIndex, 1), state.current = views;
    }, []),
    open = useCallback((_ref4 = _asyncToGenerator(_regeneratorRuntime.mark(function _callee(nextViewId) {
      var _context4, nextView, _iterator, _step, view, _yield$view$onClose, shouldCloseCurrent, afterCurrentClose, _nextViewCloseRespons, nextViewCloseResponse;
      return _regeneratorRuntime.wrap(function (_context5) {
        for (;;) switch (_context5.prev = _context5.next) {
          case 0:
            if (!(nextView = _findInstanceProperty(_context4 = state.current).call(_context4, function (_ref5) {
              var id = _ref5.id;
              return !_ref5.show && id === nextViewId;
            }))) {
              _context5.next = 34;
              break;
            }
            if (isEmpty(state.current)) {
              _context5.next = 32;
              break;
            }
            _iterator = _createForOfIteratorHelper(state.current), _context5.prev = 4, _iterator.s();
          case 6:
            if ((_step = _iterator.n()).done) {
              _context5.next = 24;
              break;
            }
            if ((view = _step.value).id === nextViewId || !view.show) {
              _context5.next = 22;
              break;
            }
            return _context5.next = 11, view.onClose();
          case 11:
            if (_yield$view$onClose = _context5.sent, shouldCloseCurrent = _yield$view$onClose.shouldClose, afterCurrentClose = _yield$view$onClose.afterClose, shouldCloseCurrent) {
              _context5.next = 20;
              break;
            }
            return _context5.next = 17, null == nextView ? void 0 : nextView.onClose(!0);
          case 17:
            return null == (nextViewCloseResponse = _context5.sent) || null === (_nextViewCloseRespons = nextViewCloseResponse.afterClose) || void 0 === _nextViewCloseRespons || _nextViewCloseRespons.call(nextViewCloseResponse), _context5.abrupt("return");
          case 20:
            view.show = !1, null == afterCurrentClose || afterCurrentClose();
          case 22:
            _context5.next = 6;
            break;
          case 24:
            _context5.next = 29;
            break;
          case 26:
            _context5.prev = 26, _context5.t0 = _context5.catch(4), _iterator.e(_context5.t0);
          case 29:
            return _context5.prev = 29, _iterator.f(), _context5.finish(29);
          case 32:
            nextView.show = !0, nextView.onOpen("internal");
          case 34:
          case "end":
            return _context5.stop();
        }
      }, _callee, null, [[4, 26, 29, 32]]);
    })), function (_x) {
      return _ref4.apply(this, arguments);
    }), []),
    close = useCallback((_ref6 = _asyncToGenerator(_regeneratorRuntime.mark(function _callee2(viewId) {
      var _context6,
        ignoreConfirmation,
        view,
        _yield$view$onClose2,
        shouldClose,
        afterClose,
        _args2 = arguments;
      return _regeneratorRuntime.wrap(function (_context7) {
        for (;;) switch (_context7.prev = _context7.next) {
          case 0:
            if (ignoreConfirmation = _args2.length > 1 && void 0 !== _args2[1] && _args2[1], !(view = _findInstanceProperty(_context6 = state.current).call(_context6, function (_ref7) {
              var id = _ref7.id,
                show = _ref7.show;
              return id === viewId && show;
            }))) {
              _context7.next = 9;
              break;
            }
            return _context7.next = 5, view.onClose(ignoreConfirmation);
          case 5:
            _yield$view$onClose2 = _context7.sent, shouldClose = _yield$view$onClose2.shouldClose, afterClose = _yield$view$onClose2.afterClose, shouldClose ? (view.show = !1, null == afterClose || afterClose()) : view.onOpen("external");
          case 9:
          case "end":
            return _context7.stop();
        }
      }, _callee2);
    })), function (_x2) {
      return _ref6.apply(this, arguments);
    }), []);
  return useMemo(function () {
    return {
      register: register,
      open: open,
      close: close,
      unregister: unregister
    };
  }, [register, open, close, unregister]);
};