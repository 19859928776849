import { defineMessages } from "react-intl";
export var messages = defineMessages({
  loadingText: {
    id: "jutro-dataview.StateOverlay.loadingText",
    defaultMessage: "Loading data"
  },
  errorText: {
    id: "jutro-dataview.StateOverlay.errorText",
    defaultMessage: "We were not able to load the table data"
  },
  errorTitle: {
    id: "jutro-dataview.StateOverlay.errorTitle",
    defaultMessage: "Something went wrong"
  },
  errorRetry: {
    id: "jutro-dataview.StateOverlay.errorRetry",
    defaultMessage: "Try again"
  },
  noRowsText: {
    id: "jutro-dataview.StateOverlay.noRowsText",
    defaultMessage: "No data to display"
  },
  noRowsTitle: {
    id: "jutro-dataview.StateOverlay.noRowsTitle",
    defaultMessage: "Nothing to display"
  }
});