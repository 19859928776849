import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import React, { useRef } from "react";
import { useTranslator } from "@jutro/locale";
import { useKeyboardNavigation, HEADER_ROW } from "../../accessibility";
import { ExpandTrigger } from "./ExpandTrigger";
import { useExpandableContext } from "../DataViewExpandable";
import { messages } from "./ExpandCells.messages";
export var ExpandHeader = function (_ref) {
  var id = _ref.id,
    className = _ref.className,
    columnIndex = _ref.columnIndex,
    data = _ref.data,
    onGetRowId = _ref.onGetRowId,
    translator = useTranslator(),
    buttonRef = useRef(null),
    _useKeyboardNavigatio = useKeyboardNavigation(buttonRef, HEADER_ROW, columnIndex, "expansion"),
    onKeyDown = _useKeyboardNavigatio.onKeyDown,
    onFocus = _useKeyboardNavigatio.onFocus,
    _useExpandableContext = useExpandableContext(),
    onExpandAllRows = _useExpandableContext.onExpandAllRows,
    expandedRows = _useExpandableContext.expandedRows,
    _useExpandableContext2 = _useExpandableContext.onGetDisabled,
    onGetDisabled = void 0 === _useExpandableContext2 ? function () {
      return !1;
    } : _useExpandableContext2,
    areSomeRowsExpanded = Array.isArray(expandedRows) && expandedRows.length > 0,
    expandableRowsIds = _reduceInstanceProperty(data).call(data, function (rowsIdsArray, row, index) {
      var _context,
        rowId = onGetRowId(row, index);
      return onGetDisabled({
        rowId: rowId,
        row: row
      }) ? rowsIdsArray : _concatInstanceProperty(_context = []).call(_context, _toConsumableArray(rowsIdsArray), [rowId]);
    }, []),
    isAnyRowExpandable = expandableRowsIds.length > 0,
    showExpandAllTrigger = onExpandAllRows && isAnyRowExpandable;
  return React.createElement("th", {
    className: className,
    role: "columnheader",
    scope: "col",
    onKeyDown: onKeyDown,
    "aria-label": showExpandAllTrigger ? void 0 : translator(messages.noExpandAllLabel)
  }, showExpandAllTrigger && React.createElement(ExpandTrigger, {
    id: id,
    ariaLabel: areSomeRowsExpanded ? messages.collapseAllRows : messages.expandAllRows,
    onClick: function () {
      return onExpandAllRows(expandableRowsIds);
    },
    isExpanded: areSomeRowsExpanded,
    ref: buttonRef,
    onFocus: onFocus
  }));
};
ExpandHeader.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "ExpandHeader"
}, ExpandHeader.__docgenInfo = {
  componentName: "ExpandHeader",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "ExpandHeader",
  methods: [],
  actualName: "ExpandHeader"
};