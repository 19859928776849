import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _Array$from from "@babel/runtime-corejs3/core-js-stable/array/from";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import { useEffect, useRef } from "react";
import { focusableElementsSelector } from "../utils/a11y.utils";
export var useKeyboardNavigation = function (_ref2) {
  var dialogRef = _ref2.dialogRef,
    useOverlay = _ref2.useOverlay,
    focusPreviousElement = _ref2.focusPreviousElement,
    defaultDialogRef = useRef(null),
    contentRef = dialogRef || defaultDialogRef;
  return useEffect(function () {
    var handleKeyboardNavigation = function (_ref) {
      var wrapper = _ref.wrapper,
        useOverlay = _ref.useOverlay,
        focusPreviousElement = _ref.focusPreviousElement;
      return function (e) {
        var quickViewWrapper = wrapper.current;
        if (quickViewWrapper) {
          var focusableContentInDialog = _Array$from(quickViewWrapper.querySelectorAll(focusableElementsSelector)),
            firstFocusableElementInDialog = _toConsumableArray(focusableContentInDialog).shift(),
            lastFocusableElementInDialog = _toConsumableArray(focusableContentInDialog).pop();
          if ("Tab" === e.key) {
            var isFirstElementInDialogFocused = document.activeElement === firstFocusableElementInDialog,
              isLastElementInDialogFocused = document.activeElement === lastFocusableElementInDialog,
              isDialogFocused = document.activeElement === quickViewWrapper,
              isAboutToExitDialogAtTheTop = e.shiftKey && (isFirstElementInDialogFocused || isDialogFocused),
              isAboutToExitDialogAtTheBottom = !e.shiftKey && isLastElementInDialogFocused,
              _focusableContentInDi = _filterInstanceProperty(focusableContentInDialog).call(focusableContentInDialog, function (el) {
                return "close-icon-button" === el.id;
              }),
              closeButtonIcon = _slicedToArray(_focusableContentInDi, 1)[0];
            if (isAboutToExitDialogAtTheTop || isAboutToExitDialogAtTheBottom) {
              if (useOverlay && isAboutToExitDialogAtTheTop) return isDialogFocused && closeButtonIcon ? closeButtonIcon.focus() : null == lastFocusableElementInDialog || lastFocusableElementInDialog.focus(), void e.preventDefault();
              if (useOverlay && isAboutToExitDialogAtTheBottom) return null == firstFocusableElementInDialog || firstFocusableElementInDialog.focus(), void e.preventDefault();
              focusPreviousElement(), e.preventDefault();
            }
          }
        }
      };
    }({
      wrapper: contentRef,
      useOverlay: useOverlay,
      focusPreviousElement: focusPreviousElement
    });
    return document.addEventListener("keydown", handleKeyboardNavigation), function () {
      document.removeEventListener("keydown", handleKeyboardNavigation);
    };
  }, [useOverlay, contentRef, focusPreviousElement]), {
    focusableElementsSelector: focusableElementsSelector,
    contentRef: contentRef
  };
};