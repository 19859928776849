export var HEADER_ROW = -1;
export var FIRST_DATA_ROW = 0;
export var FIRST_DATA_COLUMN = 0;
export var NO_FOCUS = {
  row: -1 / 0,
  column: -1 / 0
};
export var FIRST_DATA_CELL = {
  row: FIRST_DATA_ROW,
  column: FIRST_DATA_COLUMN
};