import React from "react";
import { RadioColumn } from "@jutro/datatable";
import { useDeprecationWarning } from "@jutro/platform";
import { warning } from "@jutro/logger";
import { useUnsupportedPropsWarning } from "../useUnsupportedPropsWarning";
export var RadioColumnAdapter = function (props) {
  return useDeprecationWarning("RadioColumnAdapter", "DataViewSelection", "Refer to the documentation for the details", "9.0.0"), useUnsupportedPropsWarning(props), React.createElement(React.Fragment, null);
};
var radioColumnAdapterPropsTypes = RadioColumn.propTypes;
RadioColumnAdapter.propTypes = radioColumnAdapterPropsTypes, RadioColumnAdapter.displayName = "RadioColumnAdapter", RadioColumnAdapter.renderHeader = function () {
  return warning("Static method 'renderHeader' is not available in columns. Use renderHeader prop instead.");
}, RadioColumnAdapter.renderCell = function () {
  return warning("Static method 'renderCell' is not available in data view columns. Use renderCell prop instead.");
}, RadioColumnAdapter.__docgenInfo = {
  description: "Maps a part of RadioColumn props to data view GenericColumn\n@deprecated",
  methods: [{
    name: "renderHeader",
    docblock: null,
    modifiers: ["static"],
    params: [],
    returns: null
  }, {
    name: "renderCell",
    docblock: null,
    modifiers: ["static"],
    params: [],
    returns: null
  }],
  displayName: "RadioColumnAdapter",
  composes: ["@jutro/datatable"]
}, RadioColumnAdapter.__docgenInfo = {
  componentName: "RadioColumnAdapter",
  packageName: "@jutro/lab-preview-dataview",
  description: "Maps a part of RadioColumn props to data view GenericColumn",
  displayName: "RadioColumnAdapter",
  methods: [{
    name: "renderHeader",
    docblock: null,
    modifiers: ["static"],
    params: [],
    returns: null
  }, {
    name: "renderCell",
    docblock: null,
    modifiers: ["static"],
    params: [],
    returns: null
  }],
  actualName: "RadioColumnAdapter",
  composes: ["@jutro/datatable"]
};