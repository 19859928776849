import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import { useCallback, useEffect, useRef, useState } from "react";
import assign from "lodash/fp/assign";
import set from "lodash/fp/set";
import debounce from "lodash/debounce";
var paginationDefaults = {
    page: 1,
    pageSizes: [10, 25, 50],
    paginationType: "pages",
    pageSize: 10
  },
  getInitialRequestOptions = function (options) {
    var _ref = null != options ? options : {},
      defaultFilters = _ref.defaultFilters,
      initialSortedColumn = _ref.initialSortedColumn,
      initialSearch = _ref.initialSearch,
      _ref$initialPage = _ref.initialPage,
      initialPage = void 0 === _ref$initialPage ? 1 : _ref$initialPage,
      _ref$initialPageSize = _ref.initialPageSize,
      initialPageSize = void 0 === _ref$initialPageSize ? 10 : _ref$initialPageSize,
      _ref$pageSizes = _ref.pageSizes,
      pageSizes = void 0 === _ref$pageSizes ? [10, 25, 50] : _ref$pageSizes,
      _ref$paginationType = _ref.paginationType,
      paginationType = void 0 === _ref$paginationType ? "pages" : _ref$paginationType,
      sortingOptions = initialSortedColumn ? {
        sorting: {
          sortedColumn: initialSortedColumn
        }
      } : {},
      searchOptions = initialSearch ? {
        searchValue: initialSearch
      } : {};
    return _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, defaultFilters ? {
      filters: defaultFilters
    } : {}), sortingOptions), searchOptions), {}, {
      pagination: {
        page: initialPage,
        pageSize: initialPageSize,
        pageSizes: pageSizes,
        paginationType: paginationType
      }
    });
  };
export var useAsyncData = function (remoteClient, initialOptions) {
  var _initialOptions$initi,
    _initialOptions$debou,
    _currentOptions$filte,
    _useState = useState(!0),
    _useState2 = _slicedToArray(_useState, 2),
    isLoading = _useState2[0],
    setIsLoading = _useState2[1],
    _useState3 = useState({
      isError: !1
    }),
    _useState4 = _slicedToArray(_useState3, 2),
    error = _useState4[0],
    setError = _useState4[1],
    _useState5 = useState({
      data: [],
      totalItems: 0
    }),
    _useState6 = _slicedToArray(_useState5, 2),
    currentData = _useState6[0],
    setData = _useState6[1],
    _useState7 = useState(function () {
      return getInitialRequestOptions(initialOptions);
    }),
    _useState8 = _slicedToArray(_useState7, 2),
    currentOptions = _useState8[0],
    setOptions = _useState8[1],
    doNotCall = useRef(!1);
  useEffect(function () {
    doNotCall.current ? doNotCall.current = !1 : (setIsLoading(!0), setError({
      isError: !1
    }), remoteClient.read(currentOptions).then(function (_ref2) {
      var data = _ref2.data,
        totalItems = _ref2.totalItems,
        stateUpdate = _ref2.stateUpdate;
      setData(function (oldData) {
        return {
          data: data,
          totalItems: null != totalItems ? totalItems : oldData.totalItems
        };
      }), stateUpdate && (doNotCall.current = !0, setOptions(function (oldOptions) {
        return assign(oldOptions, stateUpdate);
      })), setIsLoading(!1);
    }).catch(function (e) {
      var _e$message;
      setError({
        isError: !0,
        error: e,
        errorMessage: null !== (_e$message = e.message) && void 0 !== _e$message ? _e$message : String(e)
      }), setIsLoading(!1);
    }));
  }, [remoteClient, currentOptions]);
  var _useState9 = useState(null !== (_initialOptions$initi = null == initialOptions ? void 0 : initialOptions.initialSearch) && void 0 !== _initialOptions$initi ? _initialOptions$initi : ""),
    _useState10 = _slicedToArray(_useState9, 2),
    searchValue = _useState10[0],
    setSearchValue = _useState10[1],
    resetState = useCallback(function () {
      return setOptions(getInitialRequestOptions(initialOptions));
    }, [initialOptions]),
    fetchAgain = useCallback(function () {
      return setOptions(function (options) {
        return _objectSpread({}, options);
      });
    }, []),
    onFiltersChange = useCallback(function (_ref3) {
      var filters = _ref3.filters;
      return setOptions(set("filters", filters));
    }, []),
    setSearchOptions = useCallback(debounce(function (value) {
      return setOptions(set("searchValue", value));
    }, null !== (_initialOptions$debou = null == initialOptions ? void 0 : initialOptions.debounceMs) && void 0 !== _initialOptions$debou ? _initialOptions$debou : 300), []),
    onSearchChange = useCallback(function (newSearchValue) {
      setSearchOptions(newSearchValue), setSearchValue(newSearchValue);
    }, [setSearchOptions]),
    onColumnSort = useCallback(function (_ref4) {
      var sortedColumn = _ref4.sortedColumn;
      return setOptions(set("sorting", {
        sortedColumn: sortedColumn
      }));
    }, []),
    onPaginationChange = useCallback(function (pagination) {
      setOptions(set("pagination", pagination));
    }, []);
  return {
    viewProps: {
      data: currentData.data
    },
    resetState: resetState,
    fetchAgain: fetchAgain,
    error: error.isError ? error.error : void 0,
    overlayProps: _objectSpread({
      isLoading: isLoading
    }, error.isError && _objectSpread(_objectSpread({}, error), {}, {
      renderErrorProps: {
        onRetryClick: fetchAgain
      }
    })),
    filteringProps: {
      filters: null !== (_currentOptions$filte = currentOptions.filters) && void 0 !== _currentOptions$filte ? _currentOptions$filte : {},
      onFiltersChange: onFiltersChange
    },
    searchProps: {
      onSearchChange: onSearchChange,
      searchValue: searchValue
    },
    sortingProps: _objectSpread(_objectSpread({}, currentOptions.sorting), {}, {
      onColumnSort: onColumnSort
    }),
    paginationProps: _objectSpread(_objectSpread(_objectSpread({}, paginationDefaults), currentOptions.pagination), {}, {
      totalItems: currentData.totalItems,
      onPaginationChange: onPaginationChange
    })
  };
};