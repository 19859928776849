import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { uniqueInnerId } from "@jutro/platform";
import { Flex, useBreakpoint } from "@jutro/layout";
import { useTranslator } from "@jutro/locale";
import { intlMessageShape } from "@jutro/prop-types";
import { Icon } from "@jutro/components";
import styles from "./QuickViewHeader.module.css";
var DefaultTitle = function (_ref) {
  var title = _ref.title,
    titleId = _ref.titleId,
    label = _ref.label,
    translator = useTranslator(),
    titleClassName = cx(styles.title, _defineProperty({}, styles.noLabelTitle, !label));
  return title ? React.createElement("span", {
    id: titleId,
    className: titleClassName
  }, translator(title)) : null;
};
export var QuickViewHeader = function (props) {
  var _useBreakpoint$breakp = useBreakpoint(props).breakpointProps,
    title = _useBreakpoint$breakp.title,
    _useBreakpoint$breakp2 = _useBreakpoint$breakp.renderTitle,
    Title = void 0 === _useBreakpoint$breakp2 ? DefaultTitle : _useBreakpoint$breakp2,
    label = _useBreakpoint$breakp.label,
    icon = _useBreakpoint$breakp.icon,
    className = _useBreakpoint$breakp.className,
    translator = useTranslator(),
    iconClassName = cx(styles.icon, _defineProperty({}, styles.noLabelIcon, !label)),
    titleId = uniqueInnerId("quickViewHeaderTitle", "titleId").titleId;
  return React.createElement(Flex, {
    alignItems: "center",
    wrap: !1,
    className: className
  }, icon && React.createElement(Icon, {
    icon: icon,
    className: iconClassName
  }), React.createElement(Flex, {
    direction: "column",
    gap: "none",
    wrap: !1,
    className: styles.titleWrapper
  }, label && React.createElement("label", {
    htmlFor: titleId,
    className: styles.label
  }, translator(label)), React.createElement(Title, {
    titleId: titleId,
    title: title,
    label: label
  })));
};
var quickViewHeaderBasePropTypes = {
  title: intlMessageShape,
  label: intlMessageShape,
  icon: PropTypes.string,
  renderTitle: PropTypes.func,
  className: PropTypes.string
};
QuickViewHeader.propTypes = _objectSpread(_objectSpread({}, quickViewHeaderBasePropTypes), {}, {
  phone: PropTypes.shape(quickViewHeaderBasePropTypes),
  phoneWide: PropTypes.shape(quickViewHeaderBasePropTypes),
  tablet: PropTypes.shape(quickViewHeaderBasePropTypes)
}), QuickViewHeader.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "QuickViewHeader",
  props: {
    title: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Title to be rendered inside the header."
    },
    label: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Label to be rendered above the title."
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Icon to be displayed on the left side of the title."
    },
    renderTitle: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback which renders a custom title."
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Class name for the header."
    },
    phone: {
      type: {
        name: "shape",
        value: {
          title: {
            name: "custom",
            raw: "intlMessageShape",
            description: "Title to be rendered inside the header.",
            required: !1
          },
          label: {
            name: "custom",
            raw: "intlMessageShape",
            description: "Label to be rendered above the title.",
            required: !1
          },
          icon: {
            name: "string",
            description: "Icon to be displayed on the left side of the title.",
            required: !1
          },
          renderTitle: {
            name: "func",
            description: "Callback which renders a custom title.",
            required: !1
          },
          className: {
            name: "string",
            description: "Class name for the header.",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          title: {
            name: "custom",
            raw: "intlMessageShape",
            description: "Title to be rendered inside the header.",
            required: !1
          },
          label: {
            name: "custom",
            raw: "intlMessageShape",
            description: "Label to be rendered above the title.",
            required: !1
          },
          icon: {
            name: "string",
            description: "Icon to be displayed on the left side of the title.",
            required: !1
          },
          renderTitle: {
            name: "func",
            description: "Callback which renders a custom title.",
            required: !1
          },
          className: {
            name: "string",
            description: "Class name for the header.",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          title: {
            name: "custom",
            raw: "intlMessageShape",
            description: "Title to be rendered inside the header.",
            required: !1
          },
          label: {
            name: "custom",
            raw: "intlMessageShape",
            description: "Label to be rendered above the title.",
            required: !1
          },
          icon: {
            name: "string",
            description: "Icon to be displayed on the left side of the title.",
            required: !1
          },
          renderTitle: {
            name: "func",
            description: "Callback which renders a custom title.",
            required: !1
          },
          className: {
            name: "string",
            description: "Class name for the header.",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
}, DefaultTitle.__docgenInfo = {
  componentName: "DefaultTitle",
  packageName: "@jutro/quick-view",
  description: "",
  displayName: "DefaultTitle",
  methods: [],
  actualName: "DefaultTitle"
}, QuickViewHeader.__docgenInfo = {
  componentName: "QuickViewHeader",
  packageName: "@jutro/quick-view",
  description: "",
  displayName: "QuickViewHeader",
  methods: [],
  actualName: "QuickViewHeader",
  props: {
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Title to be rendered inside the header."
    },
    label: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Label to be rendered above the title."
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Icon to be displayed on the left side of the title."
    },
    renderTitle: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback which renders a custom title."
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Class name for the header."
    },
    phone: {
      type: {
        name: "shape",
        value: {
          title: {
            name: "union",
            description: "Title to be rendered inside the header.",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          label: {
            name: "union",
            description: "Label to be rendered above the title.",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          icon: {
            name: "string",
            description: "Icon to be displayed on the left side of the title.",
            required: !1
          },
          renderTitle: {
            name: "func",
            description: "Callback which renders a custom title.",
            required: !1
          },
          className: {
            name: "string",
            description: "Class name for the header.",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          title: {
            name: "union",
            description: "Title to be rendered inside the header.",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          label: {
            name: "union",
            description: "Label to be rendered above the title.",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          icon: {
            name: "string",
            description: "Icon to be displayed on the left side of the title.",
            required: !1
          },
          renderTitle: {
            name: "func",
            description: "Callback which renders a custom title.",
            required: !1
          },
          className: {
            name: "string",
            description: "Class name for the header.",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          title: {
            name: "union",
            description: "Title to be rendered inside the header.",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          label: {
            name: "union",
            description: "Label to be rendered above the title.",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          icon: {
            name: "string",
            description: "Icon to be displayed on the left side of the title.",
            required: !1
          },
          renderTitle: {
            name: "func",
            description: "Callback which renders a custom title.",
            required: !1
          },
          className: {
            name: "string",
            description: "Class name for the header.",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
};