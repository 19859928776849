import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import React from "react";
import cx from "classnames";
import { useTranslator } from "@jutro/locale";
import { uniqueInnerId } from "@jutro/platform";
import { DropdownMenu, DropdownMenuLink } from "@jutro/components";
import { DropdownMenuTrigger } from "../../utils/DropdownMenuTrigger";
import styles from "../Columns.module.css";
import { messages } from "./ActionsColumn.messages";
export var ActionsDropdownMenu = function (_ref) {
  var _context,
    id = _ref.id,
    actionsToBeWrapped = _ref.actionsToBeWrapped,
    rowId = _ref.rowId,
    rawRow = _ref.rawRow,
    className = _ref.className,
    translator = useTranslator(),
    menuId = uniqueInnerId("".concat(rowId), "menuId").menuId,
    linkId = _concatInstanceProperty(_context = "".concat(menuId, "__")).call(_context, id || rowId),
    labelId = "".concat(linkId, "-label");
  return React.createElement(DropdownMenu, {
    menuClassName: cx(styles.dropDownMenuContainer, className),
    alignRight: !0,
    id: menuId,
    renderTrigger: DropdownMenuTrigger,
    openMenuId: "".concat(menuId, "-open"),
    keepContext: !0,
    triggerAriaLabel: messages.moreActions
  }, _mapInstanceProperty(actionsToBeWrapped).call(actionsToBeWrapped, function (action, actionId) {
    var _context2,
      _context3,
      _action$props = action.props,
      onClick = _action$props.onClick,
      onGetDisabled = _action$props.onGetDisabled,
      label = _action$props.label,
      wrapperProps = _action$props.wrapperProps;
    return React.createElement(DropdownMenuLink, {
      key: _concatInstanceProperty(_context2 = "".concat(linkId, "-")).call(_context2, actionId),
      id: _concatInstanceProperty(_context3 = "".concat(linkId, "-")).call(_context3, actionId),
      "aria-labelledby": labelId,
      onClick: function () {
        null == onClick || onClick({
          rowId: rowId,
          row: rawRow
        });
      },
      disabled: null == onGetDisabled ? void 0 : onGetDisabled({
        rowId: rowId,
        row: rawRow
      }),
      icon: null == wrapperProps ? void 0 : wrapperProps.icon
    }, React.createElement("span", null, translator(label)));
  }));
};
ActionsDropdownMenu.displayName = "ActionsDropdownMenu", ActionsDropdownMenu.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "ActionsDropdownMenu"
}, ActionsDropdownMenu.__docgenInfo = {
  componentName: "ActionsDropdownMenu",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "ActionsDropdownMenu",
  methods: [],
  actualName: "ActionsDropdownMenu"
};