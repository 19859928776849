import "core-js/modules/es.string.replace";
import _asyncToGenerator from "@babel/runtime-corejs3/helpers/asyncToGenerator";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _regeneratorRuntime from "@babel/runtime-corejs3/regenerator";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { useAuthState } from "./AuthStateProvider";
export var LoginCallback = function (_ref) {
  var ErrorComponent = _ref.errorComponent,
    _useState = useState(),
    _useState2 = _slicedToArray(_useState, 2),
    loginError = _useState2[0],
    setLoginError = _useState2[1],
    error = useAuth().error,
    oktaAuth = useAuthState().oktaAuth,
    history = useHistory();
  useEffect(function () {
    var _ref2,
      hasUnmounted = !1,
      handleLoginRedirect = (_ref2 = _asyncToGenerator(_regeneratorRuntime.mark(function _callee() {
        return _regeneratorRuntime.wrap(function (_context) {
          for (;;) switch (_context.prev = _context.next) {
            case 0:
              return _context.prev = 0, _context.next = 3, oktaAuth.handleLoginRedirect();
            case 3:
              _context.next = 8;
              break;
            case 5:
              _context.prev = 5, _context.t0 = _context.catch(0), hasUnmounted || setLoginError(_context.t0);
            case 8:
            case "end":
              return _context.stop();
          }
        }, _callee, null, [[0, 5]]);
      })), function () {
        return _ref2.apply(this, arguments);
      });
    return oktaAuth.isLoginRedirect() ? handleLoginRedirect() : history.replace("/"), function () {
      hasUnmounted = !0;
    };
  }, [history, oktaAuth]);
  var finalError = loginError || error;
  return finalError ? React.createElement(ErrorComponent, {
    error: finalError
  }) : null;
};
LoginCallback.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "LoginCallback"
}, LoginCallback.__docgenInfo = {
  componentName: "LoginCallback",
  packageName: "@jutro/auth",
  description: "",
  displayName: "LoginCallback",
  methods: [],
  actualName: "LoginCallback"
};