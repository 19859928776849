import _extends from "@babel/runtime-corejs3/helpers/extends";
import React from "react";
import pick from "lodash/pick";
import { IconButton } from "@jutro/components";
import { handleOnClick } from "./handleOnClick";
export var DropdownMenuTrigger = function (props, toggleMenu) {
  return React.createElement(IconButton, _extends({
    ref: props.ref,
    icon: "gw-more-vertical",
    onClick: function (e) {
      return handleOnClick(e, toggleMenu, !props.isOpen);
    }
  }, pick(props, ["aria-expanded", "aria-label", "onKeyDown"])));
};
DropdownMenuTrigger.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "DropdownMenuTrigger"
}, DropdownMenuTrigger.__docgenInfo = {
  componentName: "DropdownMenuTrigger",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "DropdownMenuTrigger",
  methods: [],
  actualName: "DropdownMenuTrigger"
};