import _asyncToGenerator from "@babel/runtime-corejs3/helpers/asyncToGenerator";
import _regeneratorRuntime from "@babel/runtime-corejs3/regenerator";
import React, { useContext, useCallback } from "react";
import { ModalNextContext } from "@jutro/components";
import { EditingModalHeader } from "./EditingModalHeader";
import { EditingModal } from "./EditingModal";
export var useModalForm = function (breakpointProps) {
  var _ref,
    showModal = useContext(ModalNextContext).showModal,
    onEditStart = useCallback((_ref = _asyncToGenerator(_regeneratorRuntime.mark(function _callee(props) {
      return _regeneratorRuntime.wrap(function (_context) {
        for (;;) switch (_context.prev = _context.next) {
          case 0:
            return _context.prev = 0, _context.next = 3, showModal(React.createElement(EditingModal, props)).then(function (res) {
              var _breakpointProps$onRo;
              return null === (_breakpointProps$onRo = breakpointProps.onRowEditSubmit) || void 0 === _breakpointProps$onRo ? void 0 : _breakpointProps$onRo.call(breakpointProps, res);
            }).catch(function (res) {
              var _breakpointProps$onRo2;
              return null === (_breakpointProps$onRo2 = breakpointProps.onRowEditSubmit) || void 0 === _breakpointProps$onRo2 ? void 0 : _breakpointProps$onRo2.call(breakpointProps, res);
            });
          case 3:
            _context.next = 7;
            break;
          case 5:
            _context.prev = 5, _context.t0 = _context.catch(0);
          case 7:
          case "end":
            return _context.stop();
        }
      }, _callee, null, [[0, 5]]);
    })), function (_x) {
      return _ref.apply(this, arguments);
    }), [breakpointProps, showModal]);
  return {
    onEditStart: onEditStart,
    renderFormWrapper: useCallback(function () {
      return null;
    }, []),
    renderHeader: EditingModalHeader
  };
};