import { defineMessages } from "react-intl";
export var messages = defineMessages({
  expandRowLabel: {
    id: "jutro-dataview.Expansion.expandRowLabel",
    defaultMessage: "Expand row"
  },
  collapseRowLabel: {
    id: "jutro-dataview.Expansion.collapseRowLabel",
    defaultMessage: "Collapse row"
  },
  expandAllRows: {
    id: "jutro-dataview.expandable.expandAllRows",
    defaultMessage: "Expand all rows"
  },
  collapseAllRows: {
    id: "jutro-dataview.expandable.collapseAllRows",
    defaultMessage: "Collapse all rows"
  },
  noExpandAllLabel: {
    id: "jutro-dataview.expandable.noExpandAllLabel",
    defaultMessage: "Rows expansion"
  },
  noExpandRowLabel: {
    id: "jutro-dataview.expandable.noExpandRowLabel",
    defaultMessage: "Row expansion unavailable"
  }
});