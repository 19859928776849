import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import React from "react";
import omit from "lodash/omit";
import PropTypes from "prop-types";
import { useDeprecationWarning } from "@jutro/platform";
import { ActionColumn } from "@jutro/datatable";
import { useBreakpoint } from "@jutro/layout";
import { warning } from "@jutro/logger";
import { useMappedProps } from "./useMappedProps/useMappedProps";
import { useUnsupportedPropsWarning } from "../useUnsupportedPropsWarning";
import { ActionsColumn } from "../../features/columns";
var actionColumnPropsKeys = _Object$keys(ActionColumn.propTypes);
export var ActionColumnAdapter = function (props) {
  var _context;
  useDeprecationWarning("ActionColumnAdapter", "ActionsColumn", "Refer to the documentation for the details", "9.0.0");
  var breakpointProps = useBreakpoint(props).breakpointProps;
  useUnsupportedPropsWarning(breakpointProps);
  var mappedProps = useMappedProps(_objectSpread(_objectSpread({}, breakpointProps), {}, {
      fieldDatatype: "action"
    })).props,
    children = _mapInstanceProperty(_context = React.Children).call(_context, props.children, function (action) {
      return "ActionItemAdapter" === action.type.displayName ? React.cloneElement(action, {
        onClick: function (options) {
          var _action$props$onClick, _action$props;
          return null === (_action$props$onClick = (_action$props = action.props).onClick) || void 0 === _action$props$onClick ? void 0 : _action$props$onClick.call(_action$props, null == options ? void 0 : options.row, null == options ? void 0 : options.rowId);
        }
      }) : action;
    });
  return React.createElement(ActionsColumn, mappedProps, children);
};
var actionColumnAdapterPropsTypes = _objectSpread(_objectSpread(_objectSpread({}, omit(ActionsColumn.propTypes, actionColumnPropsKeys)), ActionColumn.propTypes), {}, {
  id: PropTypes.string,
  children: PropTypes.node,
  phone: PropTypes.object,
  phoneWide: PropTypes.object,
  tablet: PropTypes.object
});
ActionColumnAdapter.propTypes = actionColumnAdapterPropsTypes, ActionColumnAdapter.displayName = "ActionColumnAdapter", ActionColumnAdapter.renderHeader = function () {
  return warning("Static method 'renderHeader' is not available in columns. Use renderHeader prop instead.");
}, ActionColumnAdapter.renderCell = function () {
  return warning("Static method 'renderCell' is not available in data view columns. Use renderCell prop instead.");
}, ActionColumnAdapter.__docgenInfo = {
  description: "Maps a part of ActionColumn props to data view ActionsColumn\n@deprecated",
  methods: [{
    name: "renderHeader",
    docblock: null,
    modifiers: ["static"],
    params: [],
    returns: null
  }, {
    name: "renderCell",
    docblock: null,
    modifiers: ["static"],
    params: [],
    returns: null
  }],
  displayName: "ActionColumnAdapter",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: ""
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: ""
    },
    phone: {
      type: {
        name: "object"
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "object"
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "object"
      },
      required: !1,
      description: ""
    }
  },
  composes: ["lodash", "@jutro/datatable"]
}, ActionColumnAdapter.__docgenInfo = {
  componentName: "ActionColumnAdapter",
  packageName: "@jutro/lab-preview-dataview",
  description: "Maps a part of ActionColumn props to data view ActionsColumn",
  displayName: "ActionColumnAdapter",
  methods: [{
    name: "renderHeader",
    docblock: null,
    modifiers: ["static"],
    params: [],
    returns: null
  }, {
    name: "renderCell",
    docblock: null,
    modifiers: ["static"],
    params: [],
    returns: null
  }],
  actualName: "ActionColumnAdapter",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: ""
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: ""
    },
    phone: {
      type: {
        name: "object"
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "object"
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "object"
      },
      required: !1,
      description: ""
    }
  },
  composes: ["lodash", "@jutro/datatable"]
};