import React from "react";
import { Button, ModalNext, ModalBody, ModalFooter } from "@jutro/components";
import { useTranslator } from "@jutro/locale";
import { uniqueInnerId } from "@jutro/platform";
import { useRowEdit } from "../EditForm/useRowEdit";
import { messages } from "./EditingModal.messages";
export var EditingModal = function (_ref) {
  var isOpen = _ref.isOpen,
    onResolve = _ref.onResolve,
    onReject = _ref.onReject,
    row = _ref.row,
    rowId = _ref.rowId,
    EditFormContent = _ref.renderFormContent,
    displayAllColumns = _ref.displayAllColumns,
    onRowSave = _ref.onRowSave,
    onRowEditSubmit = _ref.onRowEditSubmit,
    onEditCancel = _ref.onEditCancel,
    title = _ref.title,
    Header = _ref.renderHeader,
    formRules = _ref.formRules,
    displayBundledMessages = _ref.displayBundledMessages,
    translator = useTranslator(),
    titleId = uniqueInnerId("editingModalTitle", "titleId").titleId,
    _useRowEdit = useRowEdit({
      row: row,
      formRules: formRules,
      onRowSave: onRowSave,
      onRowEditSubmit: onRowEditSubmit,
      onResolve: onResolve,
      onEditCancel: onEditCancel,
      onReject: onReject
    }),
    onSaveButtonClick = _useRowEdit.onSaveButtonClick,
    onCancelButtonClick = _useRowEdit.onCancelButtonClick,
    editedRow = _useRowEdit.editedRow,
    setEditedRow = _useRowEdit.setEditedRow,
    isSubmitted = _useRowEdit.isSubmitted,
    registerValidation = _useRowEdit.registerValidation,
    resolveFormValidation = _useRowEdit.resolveFormValidation,
    validationMessages = _useRowEdit.validationMessages;
  return React.createElement(ModalNext, {
    isOpen: isOpen,
    onRequestClose: onReject,
    shouldCloseOnEsc: !1,
    ariaLabelledby: titleId
  }, Header && React.createElement(Header, {
    title: title,
    titleId: titleId,
    onClose: onReject,
    row: row,
    rowId: rowId
  }), React.createElement(ModalBody, null, EditFormContent && React.createElement(EditFormContent, {
    editedRow: editedRow,
    setEditedRow: setEditedRow,
    displayAllColumns: displayAllColumns,
    registerValidation: registerValidation,
    resolveFormValidation: resolveFormValidation,
    showErrors: isSubmitted,
    validationMessages: validationMessages,
    displayBundledMessages: displayBundledMessages
  })), React.createElement(ModalFooter, null, React.createElement(Button, {
    type: "text",
    onClick: onCancelButtonClick,
    "aria-label": translator(messages.cancelArialabel)
  }, translator(messages.cancel)), React.createElement(Button, {
    onClick: onSaveButtonClick,
    "aria-label": translator(messages.saveAriaLabel)
  }, translator(messages.save))));
};
EditingModal.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "EditingModal"
}, EditingModal.__docgenInfo = {
  componentName: "EditingModal",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "EditingModal",
  methods: [],
  actualName: "EditingModal"
};