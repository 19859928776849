import _extends from "@babel/runtime-corejs3/helpers/extends";
import React from "react";
import { IntlPhoneNumberField } from "@jutro/components";
import { getCommonEditProps } from "../editing/getCommonEditProps";
import { allowedPhoneFields } from "../../types";
import { GenericColumn, createColumnPropTypes } from "./GenericColumn";
var PhoneCell = function (_ref) {
    var props = _ref.columnProps,
      value = _ref.value,
      cellId = _ref.cellId;
    return React.createElement(IntlPhoneNumberField, _extends({}, props, {
      readOnly: !0,
      id: cellId,
      value: value
    }));
  },
  PhoneEditCell = function (props) {
    return React.createElement(IntlPhoneNumberField, getCommonEditProps(props));
  };
export var PhoneColumn = function (props) {
  return React.createElement(GenericColumn, _extends({}, props, {
    renderCell: PhoneCell,
    renderEditCell: PhoneEditCell,
    columnDataType: "phone",
    sortable: !1
  }));
};
var phoneColumnPropTypes = createColumnPropTypes(IntlPhoneNumberField.propTypes, allowedPhoneFields, ["sortable"]);
PhoneColumn.propTypes = phoneColumnPropTypes, PhoneColumn.displayName = "PhoneColumn", PhoneColumn.__docgenInfo = {
  description: "Column for Phone format values\n\n@metadataType container",
  methods: [],
  displayName: "PhoneColumn",
  composes: ["./GenericColumn"]
}, PhoneCell.__docgenInfo = {
  componentName: "PhoneCell",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "PhoneCell",
  methods: [],
  actualName: "PhoneCell"
}, PhoneEditCell.__docgenInfo = {
  componentName: "PhoneEditCell",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "PhoneEditCell",
  methods: [],
  actualName: "PhoneEditCell"
}, PhoneColumn.__docgenInfo = {
  componentName: "PhoneColumn",
  packageName: "@jutro/lab-preview-dataview",
  description: "Column for Phone format values",
  displayName: "PhoneColumn",
  methods: [],
  actualName: "PhoneColumn",
  metadataType: "container",
  composes: ["./GenericColumn"]
};