import React from "react";
import { DataViewHeader } from "../features/header";
import { ColumnsConfigTrigger, useColumnsConfigContext } from "../features/columnsConfig";
import { useFilteringContext } from "../features/filtering";
import { useSearchContext } from "../features/search";
import { messages } from "./TableView.messages";
export var TableViewHeader = function (_ref) {
  var titleId = _ref.titleId,
    className = _ref.className,
    FiltersTriggerComponent = _ref.FiltersTriggerComponent,
    rowIds = _ref.rowIds,
    displayColumnsConfigTrigger = useColumnsConfigContext().modalAvailable,
    _useSearchContext = useSearchContext(),
    searchEnabled = _useSearchContext.searchEnabled,
    onSearchChange = _useSearchContext.onSearchChange,
    searchValue = _useSearchContext.searchValue,
    _useSearchContext$pla = _useSearchContext.placeholder,
    placeholder = void 0 === _useSearchContext$pla ? messages.searchInputPlaceholder : _useSearchContext$pla,
    _useSearchContext$lab = _useSearchContext.label,
    label = void 0 === _useSearchContext$lab ? messages.searchInputLabel : _useSearchContext$lab,
    SearchComponent = _useSearchContext.render,
    filtersTriggerProps = useFilteringContext();
  return React.createElement(DataViewHeader, {
    titleId: titleId,
    className: className,
    rowIds: rowIds
  }, searchEnabled && React.createElement(SearchComponent, {
    onSearchChange: onSearchChange,
    searchValue: searchValue,
    placeholder: placeholder,
    label: label
  }), FiltersTriggerComponent && React.createElement(FiltersTriggerComponent, filtersTriggerProps), displayColumnsConfigTrigger && React.createElement(ColumnsConfigTrigger, null));
};
TableViewHeader.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "TableViewHeader"
}, TableViewHeader.__docgenInfo = {
  componentName: "TableViewHeader",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "TableViewHeader",
  methods: [],
  actualName: "TableViewHeader"
};