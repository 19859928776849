import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["icon"],
  _excluded2 = ["id", "label", "onClick", "wrapper", "wrapperProps"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { useTranslator } from "@jutro/locale";
import { useBreakpoint } from "@jutro/layout";
import { intlMessageShape, tooltipShape } from "@jutro/prop-types";
import { IconButton, Link } from "@jutro/components";
import { handleOnClick } from "../../utils/handleOnClick";
import styles from "../Columns.module.css";
var ActionIcon = function (_ref, ref) {
    var id = _ref.id,
      disabled = _ref.disabled,
      wrapperProps = _ref.wrapperProps,
      _onClick = _ref.onClick,
      _ref2 = wrapperProps || {},
      icon = _ref2.icon,
      restWrapperProps = _objectWithoutProperties(_ref2, _excluded);
    return React.createElement(IconButton, _extends({
      ref: ref,
      onClick: function (e) {
        handleOnClick(e, _onClick);
      },
      id: id,
      "data-testid": id,
      icon: icon || ""
    }, restWrapperProps, {
      className: cx(styles.iconAction, null == restWrapperProps ? void 0 : restWrapperProps.className),
      iconClassName: cx(styles.icon, null == restWrapperProps ? void 0 : restWrapperProps.iconClassName),
      disabled: disabled
    }));
  },
  ActionLink = function (_ref3, ref) {
    var id = _ref3.id,
      disabled = _ref3.disabled,
      wrapperProps = _ref3.wrapperProps,
      label = _ref3.label,
      _onClick2 = _ref3.onClick;
    return React.createElement(Link, _extends({
      ref: ref,
      onClick: function (e) {
        handleOnClick(e, _onClick2);
      },
      id: id,
      "data-testid": id
    }, wrapperProps, {
      className: cx(styles.linkAction, null == wrapperProps ? void 0 : wrapperProps.className),
      disabled: disabled
    }), label);
  },
  actionMap = {
    icon: ActionIcon,
    link: ActionLink
  };
export var Action = React.forwardRef(function (props, ref) {
  var translator = useTranslator(),
    breakpointProps = useBreakpoint(props).breakpointProps,
    id = breakpointProps.id,
    label = breakpointProps.label,
    onClick = breakpointProps.onClick,
    _breakpointProps$wrap = breakpointProps.wrapper,
    wrapper = void 0 === _breakpointProps$wrap ? "link" : _breakpointProps$wrap,
    wrapperProps = breakpointProps.wrapperProps,
    rest = _objectWithoutProperties(breakpointProps, _excluded2);
  return actionMap[wrapper](_objectSpread({
    id: id,
    label: translator(label),
    onClick: onClick,
    wrapperProps: wrapperProps
  }, rest), ref);
});
Action.displayName = "Action";
export var wrapperPropTypes = {
  icon: PropTypes.string,
  tooltip: tooltipShape,
  className: PropTypes.string,
  iconClassName: PropTypes.string
};
var actionPropTypes = {
    id: PropTypes.string.isRequired,
    label: intlMessageShape,
    onClick: PropTypes.func,
    wrapper: PropTypes.oneOf(["icon", "link"]),
    wrapperProps: PropTypes.shape(wrapperPropTypes),
    onGetDisabled: PropTypes.func
  },
  actionBreakpointPropTypes = _objectSpread(_objectSpread({}, actionPropTypes), {}, {
    id: PropTypes.string
  });
Action.propTypes = _objectSpread(_objectSpread({}, actionPropTypes), {}, {
  phone: PropTypes.shape(actionBreakpointPropTypes),
  phoneWide: PropTypes.shape(actionBreakpointPropTypes),
  tablet: PropTypes.shape(actionBreakpointPropTypes)
}), Action.defaultProps = {
  wrapper: "link"
}, Action.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "Action",
  props: {
    wrapper: {
      defaultValue: {
        value: "'link'",
        computed: !1
      },
      type: {
        name: "enum",
        value: [{
          value: "'icon'",
          computed: !1
        }, {
          value: "'link'",
          computed: !1
        }]
      },
      required: !1,
      description: "Determines the type of the Action, defaults to 'link'"
    },
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: ""
    },
    label: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Action label"
    },
    onClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback function used for the onClick event of the Action"
    },
    wrapperProps: {
      type: {
        name: "shape",
        value: {
          icon: {
            name: "string",
            required: !1
          },
          tooltip: {
            name: "custom",
            raw: "tooltipShape",
            required: !1
          },
          className: {
            name: "string",
            required: !1
          },
          iconClassName: {
            name: "string",
            required: !1
          }
        }
      },
      required: !1,
      description: "Specifies the properties to be passed to the wrapper component"
    },
    onGetDisabled: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to disable action for a row"
    },
    phone: {
      type: {
        name: "shape",
        value: {
          id: {
            name: "string",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          id: {
            name: "string",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          id: {
            name: "string",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
}, ActionIcon.__docgenInfo = {
  componentName: "ActionIcon",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "ActionIcon",
  methods: [],
  actualName: "ActionIcon"
}, ActionLink.__docgenInfo = {
  componentName: "ActionLink",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "ActionLink",
  methods: [],
  actualName: "ActionLink"
}, Action.__docgenInfo = {
  componentName: "Action",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "Action",
  methods: [],
  actualName: "Action",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: ""
    },
    label: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Action label"
    },
    onClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback function used for the onClick event of the Action"
    },
    wrapper: {
      type: {
        name: "enum",
        value: [{
          value: "'icon'",
          computed: !1
        }, {
          value: "'link'",
          computed: !1
        }]
      },
      required: !1,
      description: "Determines the type of the Action, defaults to 'link'",
      defaultValue: {
        value: "'link'",
        computed: !1
      }
    },
    wrapperProps: {
      type: {
        name: "shape",
        value: {
          icon: {
            name: "string",
            required: !1
          },
          tooltip: {
            name: "custom",
            raw: "tooltipShape",
            required: !1
          },
          className: {
            name: "string",
            required: !1
          },
          iconClassName: {
            name: "string",
            required: !1
          }
        }
      },
      required: !1,
      description: "Specifies the properties to be passed to the wrapper component"
    },
    onGetDisabled: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to disable action for a row"
    },
    phone: {
      type: {
        name: "shape",
        value: {
          id: {
            name: "string",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          id: {
            name: "string",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          id: {
            name: "string",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
};