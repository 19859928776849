import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["isOpen", "renderInlineFilters"];
import React from "react";
import { Collapse } from "@jutro/components";
import { FilterBar } from "../FilterBar";
export var InlineFilters = function (_ref) {
  var isOpen = _ref.isOpen,
    _ref$renderInlineFilt = _ref.renderInlineFilters,
    FiltersComponent = void 0 === _ref$renderInlineFilt ? FilterBar : _ref$renderInlineFilt,
    props = _objectWithoutProperties(_ref, _excluded);
  return React.createElement(Collapse, {
    isOpen: isOpen
  }, React.createElement(FiltersComponent, props));
};
InlineFilters.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "InlineFilters",
  props: {
    renderInlineFilters: {
      defaultValue: {
        value: "FilterBar",
        computed: !0
      },
      required: !1
    }
  }
}, InlineFilters.__docgenInfo = {
  componentName: "InlineFilters",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "InlineFilters",
  methods: [],
  actualName: "InlineFilters",
  props: {
    renderInlineFilters: {
      defaultValue: {
        value: "FilterBar",
        computed: !0
      },
      required: !1
    }
  }
};