import React from "react";
import { Chevron, Button } from "@jutro/components";
import { useTranslator } from "@jutro/locale";
import styles from "./ExpandTrigger.module.css";
export var ExpandTrigger = React.forwardRef(function (_ref, ref) {
  var id = _ref.id,
    isExpanded = _ref.isExpanded,
    onClick = _ref.onClick,
    onFocus = _ref.onFocus,
    ariaLabel = _ref.ariaLabel,
    translator = useTranslator();
  return React.createElement(Button, {
    id: String(id),
    "aria-label": translator(ariaLabel),
    type: "text",
    onClick: onClick,
    className: styles.button,
    ref: ref,
    onFocus: onFocus
  }, React.createElement(Chevron, {
    isOpen: isExpanded
  }));
});
ExpandTrigger.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "ExpandTrigger"
};