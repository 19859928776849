import _toArray from "@babel/runtime-corejs3/helpers/toArray";
import _sliceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/slice";
import React, { useMemo } from "react";
import { ViewContext } from "./ViewContext";
import { DataViewIdContextProvider } from "../hooks/useDataViewId";
import { ReorderedColumnsContextProvider } from "../features/columns";
var FeatureNode = function FeatureNode(_ref) {
    var providers = _ref.providers,
      children = _ref.children,
      _providers = _toArray(providers),
      provider = _providers[0],
      restProviders = _sliceInstanceProperty(_providers).call(_providers, 1),
      View = useMemo(function () {
        return React.createElement(FeatureNode, {
          providers: restProviders
        }, children);
      }, [children, restProviders]);
    return provider ? React.createElement(ViewContext.Provider, {
      value: View
    }, provider) : children;
  },
  FeaturesTree = function (_ref2) {
    var providers = _ref2.providers,
      children = _ref2.children;
    return React.createElement(FeatureNode, {
      providers: providers
    }, children);
  };
export var withDataViewFeaturesProvider = function (View) {
  var WithDataViewFeaturesProvider = function (props) {
    return React.createElement(DataViewIdContextProvider, null, React.createElement(FeaturesTree, {
      providers: React.Children.toArray(props.children)
    }, React.createElement(ReorderedColumnsContextProvider, null, React.createElement(View, props))));
  };
  return WithDataViewFeaturesProvider.displayName = View.displayName ? "withDataViewFeaturesProvider(".concat(View.displayName, ")") : "withDataViewFeaturesProvider", WithDataViewFeaturesProvider;
};
FeatureNode.__docgenInfo = {
  componentName: "FeatureNode",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "FeatureNode",
  methods: [],
  actualName: "FeatureNode"
}, FeaturesTree.__docgenInfo = {
  componentName: "FeaturesTree",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "FeaturesTree",
  methods: [],
  actualName: "FeaturesTree"
};