import _extends from "@babel/runtime-corejs3/helpers/extends";
import _asyncToGenerator from "@babel/runtime-corejs3/helpers/asyncToGenerator";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["triggerIcon", "areFiltersApplied"];
import _regeneratorRuntime from "@babel/runtime-corejs3/regenerator";
import React, { useContext } from "react";
import { ModalNextContext } from "@jutro/components";
import { FiltersTriggerButton } from "../FiltersTriggerButton";
import { FilteringModal } from "./FilteringModal";
export var FilteringModalTrigger = function (_ref) {
  var _ref2,
    triggerIcon = _ref.triggerIcon,
    areFiltersApplied = _ref.areFiltersApplied,
    otherProps = _objectWithoutProperties(_ref, _excluded),
    showModal = useContext(ModalNextContext).showModal,
    triggerModal = (_ref2 = _asyncToGenerator(_regeneratorRuntime.mark(function _callee() {
      return _regeneratorRuntime.wrap(function (_context) {
        for (;;) switch (_context.prev = _context.next) {
          case 0:
            return _context.prev = 0, _context.next = 3, showModal(React.createElement(FilteringModal, _extends({}, otherProps, {
              areFiltersApplied: areFiltersApplied
            })));
          case 3:
            _context.next = 7;
            break;
          case 5:
            _context.prev = 5, _context.t0 = _context.catch(0);
          case 7:
          case "end":
            return _context.stop();
        }
      }, _callee, null, [[0, 5]]);
    })), function () {
      return _ref2.apply(this, arguments);
    });
  return React.createElement(FiltersTriggerButton, {
    onClick: triggerModal,
    showBadge: areFiltersApplied,
    triggerIcon: triggerIcon
  });
};
FilteringModalTrigger.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "FilteringModalTrigger"
}, FilteringModalTrigger.__docgenInfo = {
  componentName: "FilteringModalTrigger",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "FilteringModalTrigger",
  methods: [],
  actualName: "FilteringModalTrigger"
};