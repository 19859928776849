import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import React, { useMemo } from "react";
import cx from "classnames";
import { useTranslator } from "@jutro/locale";
import { BodyCell } from "./cells/BodyCell";
import { ExpandCell, ExpandableRow, useExpandableContext } from "../features/expandable";
import { useReorderedColumnsContext } from "../features/columns";
import { useSelectionContext, SelectionCell } from "../features/selection";
import { getCellValue } from "../features/utils/getCellValue";
import styles from "./TableView.module.css";
import cellStyles from "./cells/Cells.module.css";
export var TableViewDataRow = function (_ref) {
  var stripedRows = _ref.stripedRows,
    classNames = _ref.classNames,
    rowIndex = _ref.rowIndex,
    rowId = _ref.rowId,
    rawRow = _ref.rawRow,
    _useExpandableContext = useExpandableContext(),
    _useExpandableContext2 = _useExpandableContext.expandedRows,
    expandedRows = void 0 === _useExpandableContext2 ? [] : _useExpandableContext2,
    expansionEnabled = _useExpandableContext.expansionEnabled,
    _onExpandRow = _useExpandableContext.onExpandRow,
    onGetDisabled = _useExpandableContext.onGetDisabled,
    translator = useTranslator(),
    columns = useReorderedColumnsContext(),
    isExpanded = _includesInstanceProperty(expandedRows).call(expandedRows, rowId),
    _useSelectionContext = useSelectionContext(),
    selectedRows = _useSelectionContext.selectedRows,
    onRowSelect = _useSelectionContext.onRowSelect,
    selectionEnabled = _useSelectionContext.selectionEnabled,
    isSelected = selectionEnabled && _includesInstanceProperty(selectedRows).call(selectedRows, rowId),
    isExpansionDisabled = useMemo(function () {
      return null == onGetDisabled ? void 0 : onGetDisabled({
        row: rawRow,
        rowId: rowId
      });
    }, [onGetDisabled, rowId, rawRow]),
    isRowStriped = stripedRows && rowIndex % 2 != 0,
    visibleColumns = _filterInstanceProperty(columns).call(columns, function (column) {
      return column.visible;
    }),
    getValue = function (column) {
      return getCellValue({
        rowId: rowId,
        rawRow: rawRow,
        column: column,
        translator: translator
      });
    },
    colSpan = Number(expansionEnabled) + Number(selectionEnabled) + visibleColumns.length;
  return React.createElement(React.Fragment, null, React.createElement("tr", {
    className: cx(_defineProperty({}, styles.stripedRow, isRowStriped), null == classNames ? void 0 : classNames.row),
    role: "row"
  }, selectionEnabled && React.createElement(SelectionCell, {
    key: "select_".concat(rowId),
    rowId: rowId,
    isSelected: isSelected,
    onRowSelect: onRowSelect,
    className: cx(cellStyles.cell, cellStyles.interactiveCell, null == classNames ? void 0 : classNames.selectionCell(isSelected)),
    rowIndex: rowIndex,
    columnIndex: expansionEnabled ? -2 : -1
  }), expansionEnabled && React.createElement(ExpandCell, {
    key: "expand_".concat(rowId),
    rowId: rowId,
    isExpanded: isExpanded,
    onExpandRow: function () {
      return null == _onExpandRow ? void 0 : _onExpandRow(rowId);
    },
    disabled: isExpansionDisabled,
    rowIndex: rowIndex,
    columnIndex: -1,
    className: cx(cellStyles.cell, cellStyles.interactiveCell)
  }), _mapInstanceProperty(visibleColumns).call(visibleColumns, function (column, columnIndex) {
    var _context;
    return React.createElement(BodyCell, {
      key: _concatInstanceProperty(_context = "".concat(column.id, "_")).call(_context, rowId),
      rowId: rowId,
      rawRow: rawRow,
      value: getValue(column),
      column: column,
      className: null == classNames ? void 0 : classNames.getCellClassName(column, getValue(column)),
      rowIndex: rowIndex,
      columnIndex: columnIndex
    });
  })), expansionEnabled && React.createElement(ExpandableRow, {
    className: cx(_defineProperty({}, styles.stripedRow, isRowStriped)),
    rawRow: rawRow,
    rowId: rowId,
    isExpanded: isExpanded,
    colSpan: colSpan,
    selectionEnabled: selectionEnabled
  }));
};
TableViewDataRow.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "TableViewDataRow"
}, TableViewDataRow.__docgenInfo = {
  componentName: "TableViewDataRow",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "TableViewDataRow",
  methods: [],
  actualName: "TableViewDataRow"
};