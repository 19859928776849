import React from "react";
import { ModalNext, ModalBody, ModalFooter, ModalHeader, Button } from "@jutro/components";
import { useTranslator } from "@jutro/locale";
import { uniqueInnerId } from "@jutro/platform";
import { KEEP_CHANGES, DISCARD_CHANGES } from "../types";
import messages from "./ConfirmationModal.messages";
export var ConfirmationModal = function (_ref) {
  var isOpen = _ref.isOpen,
    onResolve = _ref.onResolve,
    onReject = _ref.onReject,
    parentSelector = _ref.parentSelector,
    _ref$title = _ref.title,
    titleMessage = void 0 === _ref$title ? messages.title : _ref$title,
    _ref$notificationMess = _ref.notificationMessage,
    notificationMessage = void 0 === _ref$notificationMess ? messages.notification : _ref$notificationMess,
    _ref$keepChangesMessa = _ref.keepChangesMessage,
    keepChangesMessage = void 0 === _ref$keepChangesMessa ? messages.cancel : _ref$keepChangesMessa,
    _ref$discardChangesMe = _ref.discardChangesMessage,
    discardChangesMessage = void 0 === _ref$discardChangesMe ? messages.ignoreChanges : _ref$discardChangesMe,
    _ref$closeModalAriaLa = _ref.closeModalAriaLabel,
    closeModalAriaLabel = void 0 === _ref$closeModalAriaLa ? messages.closeModalAriaLabel : _ref$closeModalAriaLa,
    translator = useTranslator(),
    titleId = uniqueInnerId("quickViewLeaveModalTitle", "titleId").titleId,
    title = translator(titleMessage);
  return React.createElement(ModalNext, {
    isOpen: isOpen,
    onRequestClose: onReject,
    parentSelector: parentSelector,
    ariaLabelledby: titleId,
    shouldCloseOnEsc: !1
  }, React.createElement(ModalHeader, {
    title: title,
    titleId: titleId,
    onClose: function () {
      return onResolve(KEEP_CHANGES);
    },
    ariaLabel: translator(closeModalAriaLabel)
  }), React.createElement(ModalBody, {
    contentLayout: {
      component: "Grid",
      componentProps: {
        columns: ["1fr"],
        gap: "none",
        role: "group",
        "aria-label": title
      }
    }
  }, translator(notificationMessage)), React.createElement(ModalFooter, null, React.createElement(Button, {
    type: "text",
    onClick: function () {
      return onResolve(KEEP_CHANGES);
    }
  }, translator(keepChangesMessage)), React.createElement(Button, {
    onClick: function () {
      return onResolve(DISCARD_CHANGES);
    }
  }, translator(discardChangesMessage))));
};
ConfirmationModal.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "ConfirmationModal",
  props: {
    title: {
      defaultValue: {
        value: "messages.title",
        computed: !0
      },
      required: !1
    },
    notificationMessage: {
      defaultValue: {
        value: "messages.notification",
        computed: !0
      },
      required: !1
    },
    keepChangesMessage: {
      defaultValue: {
        value: "messages.cancel",
        computed: !0
      },
      required: !1
    },
    discardChangesMessage: {
      defaultValue: {
        value: "messages.ignoreChanges",
        computed: !0
      },
      required: !1
    },
    closeModalAriaLabel: {
      defaultValue: {
        value: "messages.closeModalAriaLabel",
        computed: !0
      },
      required: !1
    }
  }
}, ConfirmationModal.__docgenInfo = {
  componentName: "ConfirmationModal",
  packageName: "@jutro/quick-view",
  description: "",
  displayName: "ConfirmationModal",
  methods: [],
  actualName: "ConfirmationModal",
  props: {
    title: {
      defaultValue: {
        value: "messages.title",
        computed: !0
      },
      required: !1
    },
    notificationMessage: {
      defaultValue: {
        value: "messages.notification",
        computed: !0
      },
      required: !1
    },
    keepChangesMessage: {
      defaultValue: {
        value: "messages.cancel",
        computed: !0
      },
      required: !1
    },
    discardChangesMessage: {
      defaultValue: {
        value: "messages.ignoreChanges",
        computed: !0
      },
      required: !1
    },
    closeModalAriaLabel: {
      defaultValue: {
        value: "messages.closeModalAriaLabel",
        computed: !0
      },
      required: !1
    }
  }
};