import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useBreakpoint } from "@jutro/layout";
import { DropdownMenu, DropdownMenuLink } from "@jutro/components";
import { intlMessageShape } from "@jutro/prop-types";
import { useTranslator } from "@jutro/locale";
import messages from "./QuickViewHeaderActions.messages";
import { defaultActionsTrigger } from "./QuickViewHeaderActionsTrigger";
export var QuickViewHeaderAction = DropdownMenuLink;
export var QuickViewHeaderActions = function (props) {
  var _useBreakpoint$breakp = useBreakpoint(props).breakpointProps,
    _useBreakpoint$breakp2 = _useBreakpoint$breakp.label,
    label = void 0 === _useBreakpoint$breakp2 ? messages.actionsLabel : _useBreakpoint$breakp2,
    _useBreakpoint$breakp3 = _useBreakpoint$breakp.renderTrigger,
    renderTriggerProp = void 0 === _useBreakpoint$breakp3 ? defaultActionsTrigger : _useBreakpoint$breakp3,
    translator = useTranslator(),
    renderTrigger = useCallback(function (triggerProps, toggleMenu) {
      return renderTriggerProp(_objectSpread(_objectSpread({}, triggerProps), {}, {
        label: label,
        translator: translator
      }), toggleMenu);
    }, [label, renderTriggerProp, translator]);
  return React.createElement(DropdownMenu, {
    id: "quick-view-actions-menu",
    renderTrigger: renderTrigger
  }, props.children);
};
var quickViewHeaderActionsBasePropTypes = {
  label: intlMessageShape
};
QuickViewHeaderActions.propTypes = _objectSpread(_objectSpread({}, quickViewHeaderActionsBasePropTypes), {}, {
  phone: PropTypes.shape(quickViewHeaderActionsBasePropTypes),
  phoneWide: PropTypes.shape(quickViewHeaderActionsBasePropTypes),
  tablet: PropTypes.shape(quickViewHeaderActionsBasePropTypes)
}), QuickViewHeaderActions.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "QuickViewHeaderActions",
  props: {
    label: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Label to be rendered inside the dropdown menu trigger button."
    },
    phone: {
      type: {
        name: "shape",
        value: {
          label: {
            name: "custom",
            raw: "intlMessageShape",
            description: "Label to be rendered inside the dropdown menu trigger button.",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          label: {
            name: "custom",
            raw: "intlMessageShape",
            description: "Label to be rendered inside the dropdown menu trigger button.",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          label: {
            name: "custom",
            raw: "intlMessageShape",
            description: "Label to be rendered inside the dropdown menu trigger button.",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
}, QuickViewHeaderActions.__docgenInfo = {
  componentName: "QuickViewHeaderActions",
  packageName: "@jutro/quick-view",
  description: "",
  displayName: "QuickViewHeaderActions",
  methods: [],
  actualName: "QuickViewHeaderActions",
  props: {
    label: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Label to be rendered inside the dropdown menu trigger button."
    },
    phone: {
      type: {
        name: "shape",
        value: {
          label: {
            name: "union",
            description: "Label to be rendered inside the dropdown menu trigger button.",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          label: {
            name: "union",
            description: "Label to be rendered inside the dropdown menu trigger button.",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          label: {
            name: "union",
            description: "Label to be rendered inside the dropdown menu trigger button.",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          }
        }
      },
      required: !1,
      description: ""
    }
  }
};