import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
export var useColumnSorting = function (_ref) {
  var column = _ref.column,
    sortingEnabled = _ref.sortingEnabled,
    onColumnSort = _ref.onColumnSort,
    sortedColumnId = _ref.sortedColumnId,
    sortedColumnDesc = _ref.sortedColumnDesc,
    isSortable = sortingEnabled && !!column.sortable,
    isSorted = isSortable && sortedColumnId === column.id;
  return {
    isSortable: isSortable,
    isSorted: isSorted,
    onSortingTrigger: function () {
      var _context;
      isSortable && (null == onColumnSort || onColumnSort({
        sortedColumn: _concatInstanceProperty(_context = "".concat(isSorted && !sortedColumnDesc ? "-" : "")).call(_context, column.id),
        columnProps: column
      }));
    }
  };
};