import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
var _excluded = ["paginationType", "render", "onPaginationChange", "className"];
import React, { useCallback, useState } from "react";
import cx from "classnames";
import { InlineNotification } from "@jutro/components";
import { useTranslator } from "@jutro/locale";
import { usePaginationContext } from "./usePaginationContext";
import { useTitleContext } from "../title";
import { useOverlayContext } from "../overlay";
import { Pagination } from "./Pagination";
import { LoadMore } from "./LoadMore";
import styles from "./NavigationInternal.module.css";
import { messages } from "./NavigationInternal.messages";
var NavigationVariants = {
  none: function () {
    return null;
  },
  pages: Pagination,
  loadMore: LoadMore
};
export var NavigationInternal = function () {
  var translator = useTranslator(),
    _usePaginationContext = usePaginationContext(),
    paginationType = _usePaginationContext.paginationType,
    render = _usePaginationContext.render,
    onPaginationChange = _usePaginationContext.onPaginationChange,
    className = _usePaginationContext.className,
    paginationProps = _objectWithoutProperties(_usePaginationContext, _excluded),
    _useOverlayContext = useOverlayContext(),
    isError = _useOverlayContext.isError,
    isLoading = _useOverlayContext.isLoading,
    _useOverlayContext$er = _useOverlayContext.errorMessage,
    errorMessage = void 0 === _useOverlayContext$er ? messages.loadMoreError : _useOverlayContext$er,
    title = useTitleContext().title,
    ariaLabel = translator(messages.paginationAriaLabel, {
      tableName: translator(title)
    }),
    NavigationComponent = render || NavigationVariants[paginationType || "none"],
    _usePaginationError = function (paginationType, isError, onPaginationChange) {
      var isPaginationError = isError && "loadMore" === paginationType,
        _useState = useState(isPaginationError),
        _useState2 = _slicedToArray(_useState, 2),
        shouldShowPaginationError = _useState2[0],
        setShouldShowPaginationError = _useState2[1];
      return {
        shouldRenderInlineError: isPaginationError && shouldShowPaginationError,
        onPaginationErrorClose: useCallback(function () {
          setShouldShowPaginationError(!1);
        }, [setShouldShowPaginationError]),
        handlePagination: useCallback(function (args) {
          setShouldShowPaginationError(!0), null == onPaginationChange || onPaginationChange(args);
        }, [setShouldShowPaginationError, onPaginationChange])
      };
    }(paginationType, isError, onPaginationChange),
    shouldRenderInlineError = _usePaginationError.shouldRenderInlineError,
    onPaginationErrorClose = _usePaginationError.onPaginationErrorClose,
    handlePagination = _usePaginationError.handlePagination;
  return React.createElement(React.Fragment, null, shouldRenderInlineError && React.createElement(InlineNotification, {
    type: "error",
    message: errorMessage,
    onClosed: onPaginationErrorClose
  }), React.createElement(NavigationComponent, _extends({
    ariaLabel: ariaLabel,
    onPaginationChange: handlePagination,
    isLoading: isLoading
  }, paginationProps, {
    className: cx(className, styles.pagination)
  })));
};
NavigationInternal.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "NavigationInternal"
}, NavigationInternal.__docgenInfo = {
  componentName: "NavigationInternal",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "NavigationInternal",
  methods: [],
  actualName: "NavigationInternal"
};