import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["children"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import React, { useEffect } from "react";
import cx from "classnames";
import { useBreakpoint } from "@jutro/layout";
import { intlMessageShape } from "@jutro/prop-types";
import PropTypes from "prop-types";
import { withKeyboardNavigation } from "../features/accessibility";
import { TableViewDataRow } from "./TableViewDataRow";
import { TableViewHeadersRow } from "./TableViewHeadersRow";
import { TableViewHeader } from "./TableViewHeader";
import { useRowClassNames } from "./useRowClassNames";
import { useDistinctRowIdsCheck, useOnGetRowId, useColumnOptions } from "../hooks";
import { withDataViewFeaturesProvider } from "../featuresProvider";
import { useFilteringContext, useFiltering } from "../features/filtering";
import { FeaturesSideEffects } from "../features/FeaturesSideEffects";
import { useTableStateOverlay } from "../features/overlay";
import { Navigation } from "../features/pagination";
import { useSelectionContext } from "../features/selection";
import { useTitle } from "../features/title";
import styles from "./TableView.module.css";
import { useRowIds } from "../hooks/useRowIds";
export var TableView = withDataViewFeaturesProvider(withKeyboardNavigation(function (_ref) {
  _ref.children;
  var props = _objectWithoutProperties(_ref, _excluded),
    breakpointProps = useBreakpoint(props).breakpointProps,
    id = breakpointProps.id,
    _breakpointProps$data = breakpointProps.data,
    data = void 0 === _breakpointProps$data ? [] : _breakpointProps$data,
    ariaLabel = breakpointProps.ariaLabel,
    rowIdPath = breakpointProps.rowIdPath,
    onGetRowIdProp = breakpointProps.onGetRowId,
    className = breakpointProps.className,
    internalClassNames = breakpointProps.internalClassNames,
    stripedRows = breakpointProps.stripedRows,
    onStyleHeaderCell = breakpointProps.onStyleHeaderCell,
    onStyleRow = breakpointProps.onStyleRow,
    onStyleSelectionCell = breakpointProps.onStyleSelectionCell,
    onStyleCell = breakpointProps.onStyleCell,
    onComponentRender = breakpointProps.onComponentRender,
    onGetRowId = useOnGetRowId(onGetRowIdProp, rowIdPath),
    rowIds = useRowIds(data, onGetRowId);
  useDistinctRowIdsCheck(rowIds, "TableView");
  var _useColumnOptions = useColumnOptions(),
    columnOptions = _slicedToArray(_useColumnOptions, 1)[0];
  useEffect(function () {
    null == onComponentRender || onComponentRender({
      columnOptions: columnOptions,
      onGetRowId: onGetRowId
    });
  }, []);
  var _useTitle = useTitle(id, ariaLabel),
    titleId = _useTitle.titleId,
    a11yLabelProps = _useTitle.a11yLabelProps,
    onSelectionReset = useSelectionContext().onSelectionReset,
    _useFilteringContext = useFilteringContext(),
    onFiltersChange = _useFilteringContext.onFiltersChange,
    uiProps = _useFilteringContext.uiProps,
    filters = _useFilteringContext.filters,
    areFiltersApplied = _useFilteringContext.areFiltersApplied,
    _useFiltering = useFiltering({
      onBeforeFilter: onSelectionReset,
      defaultView: "inline"
    }),
    FiltersComponent = _useFiltering.FiltersComponent,
    FiltersTriggerComponent = _useFiltering.FiltersTriggerComponent,
    _useTableStateOverlay = useTableStateOverlay(data),
    shouldRenderOverlay = _useTableStateOverlay.shouldRenderOverlay,
    StateOverlay = _useTableStateOverlay.StateOverlay,
    getDataRowClassNames = useRowClassNames({
      internalClassNames: internalClassNames,
      onStyleRow: onStyleRow,
      onStyleSelectionCell: onStyleSelectionCell,
      onStyleCell: onStyleCell
    });
  return React.createElement(React.Fragment, null, React.createElement(FeaturesSideEffects, null), React.createElement("div", {
    className: cx(styles.wrapper, className),
    "data-testid": "table-wrapper"
  }, React.createElement(TableViewHeader, {
    titleId: titleId,
    className: null == internalClassNames ? void 0 : internalClassNames.header,
    FiltersTriggerComponent: FiltersTriggerComponent,
    rowIds: rowIds
  }), FiltersComponent && React.createElement(FiltersComponent, {
    onFiltersChange: onFiltersChange,
    uiProps: uiProps,
    filters: filters,
    areFiltersApplied: areFiltersApplied
  }), React.createElement("div", {
    className: styles.tableWrapper
  }, React.createElement("table", _extends({
    className: cx(styles.table, null == internalClassNames ? void 0 : internalClassNames.table),
    role: "grid"
  }, a11yLabelProps), React.createElement("thead", null, React.createElement(TableViewHeadersRow, {
    id: "".concat(id, "-headers"),
    onGetRowId: onGetRowId,
    internalClassNames: internalClassNames,
    onStyleHeaderCell: onStyleHeaderCell,
    rowIds: rowIds,
    data: data
  })), React.createElement("tbody", null, shouldRenderOverlay ? React.createElement(StateOverlay, null) : _mapInstanceProperty(data).call(data, function (row, rowIndex) {
    var rowId = onGetRowId(row, rowIndex);
    return React.createElement(TableViewDataRow, {
      key: rowId,
      stripedRows: stripedRows,
      classNames: getDataRowClassNames(row, rowId, rowIndex),
      rowIndex: rowIndex,
      rowId: rowId,
      rawRow: row
    });
  })))), React.createElement(Navigation, {
    className: null == internalClassNames ? void 0 : internalClassNames.footer
  })));
}));
var classNamesPropTypes = {
  table: PropTypes.string,
  headerRow: PropTypes.string,
  selectionHeaderCell: PropTypes.string,
  headerCell: PropTypes.string,
  row: PropTypes.string,
  selectionCell: PropTypes.string,
  cell: PropTypes.string,
  footer: PropTypes.string
};
export var tableViewBasePropTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.array,
  ariaLabel: intlMessageShape,
  rowIdPath: PropTypes.string,
  onGetRowId: PropTypes.func,
  className: PropTypes.string,
  internalClassNames: PropTypes.shape(classNamesPropTypes),
  stripedRows: PropTypes.bool,
  onStyleHeaderCell: PropTypes.func,
  onStyleRow: PropTypes.func,
  onStyleSelectionCell: PropTypes.func,
  onStyleCell: PropTypes.func,
  onComponentRender: PropTypes.func
};
var tableViewBreakpointPropTypes = _objectSpread(_objectSpread({}, tableViewBasePropTypes), {}, {
  id: PropTypes.string
});
TableView.propTypes = _objectSpread(_objectSpread({}, tableViewBasePropTypes), {}, {
  phone: PropTypes.shape(tableViewBreakpointPropTypes),
  phoneWide: PropTypes.shape(tableViewBreakpointPropTypes),
  tablet: PropTypes.shape(tableViewBreakpointPropTypes)
}), TableView.defaultProps = {
  data: [],
  stripedRows: !1
}, TableView.displayName = "TableView", TableView.__docgenInfo = {
  componentName: "TableView",
  packageName: "@jutro/lab-preview-dataview",
  description: "Component for displaying tabular data",
  displayName: "TableView",
  methods: [],
  actualName: "TableView",
  metadataType: "container",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Identifier of the component"
    },
    data: {
      type: {
        name: "array"
      },
      required: !1,
      description: "Data to be displayed in the table",
      defaultValue: {
        value: "[]",
        computed: !1
      }
    },
    ariaLabel: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Aria label text"
    },
    rowIdPath: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Path to the id of a row. By default, it is equal to 'id'."
    },
    onGetRowId: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to get a unique identifier of a row. The identifier is passed to other callbacks.\nReceives two arguments - `row` and `rowIndex`. Should return string.\nBy default, uses `rowIdPath` to get the identifier from a row.\nBe careful with using `rowIndex` for the id - it's not available in sorting and might be changed with filtering."
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Custom class name for the component. Applied to the same element as  wrapper` from `internalClassNames` prop"
    },
    internalClassNames: {
      type: {
        name: "shape",
        value: {
          table: {
            name: "string",
            required: !1
          },
          headerRow: {
            name: "string",
            required: !1
          },
          selectionHeaderCell: {
            name: "string",
            required: !1
          },
          headerCell: {
            name: "string",
            required: !1
          },
          row: {
            name: "string",
            required: !1
          },
          selectionCell: {
            name: "string",
            required: !1
          },
          cell: {
            name: "string",
            required: !1
          },
          footer: {
            name: "string",
            required: !1
          }
        }
      },
      required: !1,
      description: "Custom class names for the table and its major components."
    },
    stripedRows: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Defines if rows should have striping enabled",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    onStyleHeaderCell: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback for a dynamic calculation of a custom className for a cell in the header row"
    },
    onStyleRow: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback for a dynamic calculation of a custom className for a table body row"
    },
    onStyleSelectionCell: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback for a dynamic calculation of a custom className for the selection cell within the table body"
    },
    onStyleCell: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback for a dynamic calculation of a custom className for a cell within the table body"
    },
    onComponentRender: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback called on every render to access information on columns definitions"
    },
    phone: {
      type: {
        name: "shape",
        value: {
          id: {
            name: "string",
            description: "Identifier of the component",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          id: {
            name: "string",
            description: "Identifier of the component",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          id: {
            name: "string",
            description: "Identifier of the component",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
};