import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _Object$entries from "@babel/runtime-corejs3/core-js-stable/object/entries";
import _Object$fromEntries from "@babel/runtime-corejs3/core-js-stable/object/from-entries";
export var createUseMappedProps = function (mappers) {
  return function (inputProps, translator) {
    var _context,
      _context2,
      entries = _filterInstanceProperty(_context = _mapInstanceProperty(_context2 = _Object$entries(mappers)).call(_context2, function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2);
        return [_ref2[0], (0, _ref2[1])(inputProps, translator)];
      })).call(_context, function (_ref3) {
        return void 0 !== _slicedToArray(_ref3, 2)[1];
      });
    return _Object$fromEntries(entries);
  };
};
export var excludeNull = function (value) {
  return null !== value ? value : void 0;
};