import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import React from "react";
import cx from "classnames";
import { HeaderCell } from "./cells/HeaderCell";
import { ExpandHeader, useExpandableContext } from "../features/expandable";
import { useReorderedColumnsContext } from "../features/columns";
import { useSortingContext } from "../features/sorting";
import { SelectionHeader, useSelectionContext } from "../features/selection";
import cellStyles from "./cells/Cells.module.css";
export var TableViewHeadersRow = function (_ref) {
  var _context,
    id = _ref.id,
    internalClassNames = _ref.internalClassNames,
    onStyleHeaderCell = _ref.onStyleHeaderCell,
    onGetRowId = _ref.onGetRowId,
    rowIds = _ref.rowIds,
    data = _ref.data,
    columns = useReorderedColumnsContext(),
    _useSortingContext = useSortingContext(),
    sortingEnabled = _useSortingContext.sortingEnabled,
    sortedColumnId = _useSortingContext.sortedColumnId,
    sortedColumnDesc = _useSortingContext.sortedColumnDesc,
    onColumnSort = _useSortingContext.onColumnSort,
    selectionHeaderId = "".concat(id, "-select-all-checkbox"),
    expandHeaderId = "".concat(id, "-expand-all-trigger"),
    _useSelectionContext = useSelectionContext(),
    selectionEnabled = _useSelectionContext.selectionEnabled,
    selectedRows = _useSelectionContext.selectedRows,
    onAllRowsSelect = _useSelectionContext.onAllRowsSelect,
    expansionEnabled = useExpandableContext().expansionEnabled;
  return React.createElement("tr", {
    id: "".concat(id),
    className: null == internalClassNames ? void 0 : internalClassNames.headerRow,
    role: "row"
  }, selectionEnabled && React.createElement(SelectionHeader, {
    id: selectionHeaderId,
    className: cx(cellStyles.cell, cellStyles.headerCell, cellStyles.interactiveCell, null == internalClassNames ? void 0 : internalClassNames.selectionHeaderCell),
    columnIndex: expansionEnabled ? -2 : -1,
    rowIds: rowIds,
    selectedRows: selectedRows,
    onAllRowsSelect: onAllRowsSelect
  }), expansionEnabled && React.createElement(ExpandHeader, {
    id: expandHeaderId,
    className: cx(cellStyles.cell, cellStyles.headerCell, cellStyles.interactiveCell, null == internalClassNames ? void 0 : internalClassNames.selectionHeaderCell),
    columnIndex: -1,
    data: data,
    onGetRowId: onGetRowId
  }), _mapInstanceProperty(_context = _filterInstanceProperty(columns).call(columns, function (column) {
    return column.visible;
  })).call(_context, function (column, index) {
    var _column$classNames, _column$onStyleHeader;
    return React.createElement(HeaderCell, {
      key: column.id,
      column: column,
      columnIndex: index,
      sortingEnabled: sortingEnabled,
      onColumnSort: onColumnSort,
      sortedColumnId: sortedColumnId,
      sortedColumnDesc: sortedColumnDesc,
      className: cx(null == internalClassNames ? void 0 : internalClassNames.headerCell, null == onStyleHeaderCell ? void 0 : onStyleHeaderCell(column), null === (_column$classNames = column.classNames) || void 0 === _column$classNames ? void 0 : _column$classNames.header, null === (_column$onStyleHeader = column.onStyleHeaderCell) || void 0 === _column$onStyleHeader ? void 0 : _column$onStyleHeader.call(column, column))
    });
  }));
};
TableViewHeadersRow.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "TableViewHeadersRow"
}, TableViewHeadersRow.__docgenInfo = {
  componentName: "TableViewHeadersRow",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "TableViewHeadersRow",
  methods: [],
  actualName: "TableViewHeadersRow"
};