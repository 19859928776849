import React, { memo, useRef } from "react";
import { useTranslator } from "@jutro/locale";
import { CheckboxField } from "@jutro/components";
import { useKeyboardNavigation } from "../../accessibility";
import { messages } from "./SelectionCells.messages";
export var SelectionCell = memo(function (_ref) {
  var rowId = _ref.rowId,
    isSelected = _ref.isSelected,
    onRowSelect = _ref.onRowSelect,
    className = _ref.className,
    rowIndex = _ref.rowIndex,
    columnIndex = _ref.columnIndex,
    translator = useTranslator(),
    checkboxRef = useRef(null),
    _useKeyboardNavigatio = useKeyboardNavigation(checkboxRef, rowIndex, columnIndex, "selection"),
    onKeyDown = _useKeyboardNavigatio.onKeyDown,
    onFocus = _useKeyboardNavigatio.onFocus;
  return React.createElement("td", {
    id: "selection_".concat(rowIndex),
    className: className,
    onKeyDown: onKeyDown,
    role: "gridcell"
  }, React.createElement(CheckboxField, {
    id: "select-".concat(rowId),
    value: isSelected,
    onValueChange: function () {
      return onRowSelect({
        rowId: rowId
      });
    },
    label: translator(messages.selectRow),
    hideLabel: !0,
    checkboxRef: checkboxRef,
    onFocus: onFocus
  }));
});