import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
var _context;
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import React from "react";
import omit from "lodash/fp/omit";
import uniq from "lodash/fp/uniq";
import keys from "lodash/fp/keys";
import PropTypes from "prop-types";
import { numberDataType, dateTimeDataType, phoneDateType } from "@jutro/prop-types";
import { DisplayColumn } from "@jutro/datatable";
import { useBreakpoint } from "@jutro/layout";
import { useDeprecationWarning } from "@jutro/platform";
import { warning } from "@jutro/logger";
import { TextColumn, NumberColumn, CurrencyColumn, CategoryColumn, PhoneColumn, DateTimeColumn, BooleanColumn } from "../../features/columns";
import { useUnsupportedPropsWarning } from "../useUnsupportedPropsWarning";
import { useMappedProps } from "./useMappedProps/useMappedProps";
var dataTypes = uniq(_concatInstanceProperty(_context = []).call(_context, _toConsumableArray(numberDataType), _toConsumableArray(dateTimeDataType), _toConsumableArray(phoneDateType)));
export var DisplayColumnAdapter = function (props) {
  useDeprecationWarning("DisplayColumnAdapter", "GenericColumn or other column types", "Refer to the documentation for the details", "9.0.0");
  var breakpointProps = useBreakpoint(props).breakpointProps;
  useUnsupportedPropsWarning(breakpointProps);
  var _useMappedProps = useMappedProps(breakpointProps),
    Component = _useMappedProps.component,
    columnProps = _useMappedProps.props;
  return Component ? React.createElement(Component, columnProps) : React.createElement(React.Fragment, null);
};
var customColumnsPropTypes = _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, TextColumn.propTypes), NumberColumn.propTypes), CurrencyColumn.propTypes), CategoryColumn.propTypes), PhoneColumn.propTypes), DateTimeColumn.propTypes), BooleanColumn.propTypes),
  displayColumnAdapterPropsTypes = _objectSpread(_objectSpread(_objectSpread({}, omit(keys(DisplayColumn.propTypes), customColumnsPropTypes)), DisplayColumn.propTypes), {}, {
    dataType: PropTypes.oneOf(dataTypes),
    phone: PropTypes.object,
    phoneWide: PropTypes.object,
    tablet: PropTypes.object
  });
DisplayColumnAdapter.propTypes = displayColumnAdapterPropsTypes, DisplayColumnAdapter.displayName = "DisplayColumnAdapter", DisplayColumnAdapter.renderHeader = function () {
  return warning("Static method 'renderHeader' is not available in columns. Use renderHeader prop instead.");
}, DisplayColumnAdapter.renderCell = function () {
  return warning("Static method 'renderCell' is not available in data view columns. Use renderCell prop instead.");
}, DisplayColumnAdapter.__docgenInfo = {
  description: "Maps a part of DisplayColumn props to data view data type columns or GenericColumn\n@deprecated",
  methods: [{
    name: "renderHeader",
    docblock: null,
    modifiers: ["static"],
    params: [],
    returns: null
  }, {
    name: "renderCell",
    docblock: null,
    modifiers: ["static"],
    params: [],
    returns: null
  }],
  displayName: "DisplayColumnAdapter",
  props: {
    dataType: {
      type: {
        name: "enum",
        computed: !0,
        value: "dataTypes"
      },
      required: !1,
      description: ""
    },
    phone: {
      type: {
        name: "object"
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "object"
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "object"
      },
      required: !1,
      description: ""
    }
  },
  composes: ["lodash/fp", "@jutro/datatable"]
}, DisplayColumnAdapter.__docgenInfo = {
  componentName: "DisplayColumnAdapter",
  packageName: "@jutro/lab-preview-dataview",
  description: "Maps a part of DisplayColumn props to data view data type columns or GenericColumn",
  displayName: "DisplayColumnAdapter",
  methods: [{
    name: "renderHeader",
    docblock: null,
    modifiers: ["static"],
    params: [],
    returns: null
  }, {
    name: "renderCell",
    docblock: null,
    modifiers: ["static"],
    params: [],
    returns: null
  }],
  actualName: "DisplayColumnAdapter",
  props: {
    dataType: {
      type: {
        name: "enum",
        computed: !0,
        value: "dataTypes"
      },
      required: !1,
      description: ""
    },
    phone: {
      type: {
        name: "object"
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "object"
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "object"
      },
      required: !1,
      description: ""
    }
  },
  composes: ["lodash/fp", "@jutro/datatable"]
};