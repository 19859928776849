import { defineMessages } from "react-intl";
export default defineMessages({
  moreBatchActionsButtonTrigger: {
    id: "jutro-dataview.HeaderActions.moreBatchActionsButtonTrigger",
    defaultMessage: "More batch actions"
  },
  moreGlobalActionsButtonTrigger: {
    id: "jutro-dataview.HeaderActions.moreGlobalActionsButtonTrigger",
    defaultMessage: "More global actions"
  },
  headerActionsContainerLabel: {
    id: "jutro-dataview.DataView.headerActionsContainerLabel",
    defaultMessage: "{dataViewTitle} header actions"
  }
});