import React from "react";
import { IconButton } from "@jutro/components";
import { isPageInvalid } from "./helpers";
import styles from "./PageSwitcher.module.css";
import { messages } from "./PageSwitcher.messages";
export var PageSwitcherNavigation = function (_ref) {
  var totalItems = _ref.totalItems,
    page = _ref.page,
    setPage = _ref.setPage,
    lastPage = _ref.lastPage,
    isLoadingOrInvalid = _ref.isLoading || isPageInvalid(page, lastPage) || !totalItems,
    shouldBeDisabled = {
      prev: isLoadingOrInvalid || 1 === page,
      next: isLoadingOrInvalid || page === lastPage
    };
  return React.createElement(React.Fragment, null, React.createElement(IconButton, {
    icon: "gw-first-page",
    ariaLabel: messages.firstPageButton,
    className: styles.button,
    disabled: shouldBeDisabled.prev,
    onClick: function () {
      return setPage(1);
    }
  }), React.createElement(IconButton, {
    icon: "gw-keyboard-arrow-left",
    ariaLabel: messages.previousPageButton,
    className: styles.button,
    disabled: shouldBeDisabled.prev,
    onClick: function () {
      return setPage(page - 1);
    }
  }), React.createElement(IconButton, {
    icon: "gw-keyboard-arrow-right",
    ariaLabel: messages.nextPageButton,
    className: styles.button,
    disabled: shouldBeDisabled.next,
    onClick: function () {
      return setPage(page + 1);
    }
  }), React.createElement(IconButton, {
    icon: "gw-last-page",
    ariaLabel: messages.lastPageButton,
    className: styles.button,
    disabled: shouldBeDisabled.next,
    onClick: function () {
      return setPage(lastPage);
    }
  }));
};
PageSwitcherNavigation.__docgenInfo = {
  description: "Component displaying navigation buttons for pages switcher\n\n@metadataType container",
  methods: [],
  displayName: "PageSwitcherNavigation"
}, PageSwitcherNavigation.__docgenInfo = {
  componentName: "PageSwitcherNavigation",
  packageName: "@jutro/lab-preview-dataview",
  description: "Component displaying navigation buttons for pages switcher",
  displayName: "PageSwitcherNavigation",
  methods: [],
  actualName: "PageSwitcherNavigation",
  metadataType: "container"
};