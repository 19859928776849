import React, { useRef } from "react";
import { CheckboxField } from "@jutro/components";
import { useTranslator } from "@jutro/locale";
import { useKeyboardNavigation, HEADER_ROW } from "../../accessibility";
import { determineSelectionState } from "../helpers";
import { messages } from "./SelectionCells.messages";
export var SelectionHeader = function (_ref) {
  var _selectedRows$length,
    id = _ref.id,
    rowIds = _ref.rowIds,
    className = _ref.className,
    selectedRows = _ref.selectedRows,
    onAllRowsSelect = _ref.onAllRowsSelect,
    columnIndex = _ref.columnIndex,
    translator = useTranslator(),
    checkboxRef = useRef(null),
    _useKeyboardNavigatio = useKeyboardNavigation(checkboxRef, HEADER_ROW, columnIndex, "selection"),
    onKeyDown = _useKeyboardNavigatio.onKeyDown,
    onFocus = _useKeyboardNavigatio.onFocus,
    rowsSelectionSet = determineSelectionState({
      rowIds: rowIds,
      selectedRows: selectedRows
    }),
    icon = "some" === rowsSelectionSet ? "gw-remove" : "gw-check",
    value = "all" === rowsSelectionSet || "some" === rowsSelectionSet,
    label = translator(messages.selectAllRows, {
      selectedRowsNumber: String(null !== (_selectedRows$length = selectedRows.length) && void 0 !== _selectedRows$length ? _selectedRows$length : "0")
    });
  return React.createElement("th", {
    className: className,
    onKeyDown: onKeyDown,
    role: "columnheader",
    scope: "col"
  }, React.createElement(CheckboxField, {
    id: id,
    checkboxRef: checkboxRef,
    onFocus: onFocus,
    icon: icon,
    value: value,
    onValueChange: function () {
      return null == onAllRowsSelect ? void 0 : onAllRowsSelect({
        rowIds: rowIds
      });
    },
    label: label,
    hideLabel: !0
  }));
};
SelectionHeader.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "SelectionHeader"
}, SelectionHeader.__docgenInfo = {
  componentName: "SelectionHeader",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "SelectionHeader",
  methods: [],
  actualName: "SelectionHeader"
};