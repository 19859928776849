import { defineMessages } from "react-intl";
export default defineMessages({
  title: {
    id: "jutro-quick-view.QuickView.PreventRouteTitle",
    defaultMessage: "Unsaved changes"
  },
  notification: {
    id: "jutro-quick-view.QuickView.PreventRouteNotification",
    defaultMessage: "You have some unsaved changes that will be lost if you leave this page."
  },
  submitMessage: {
    id: "jutro-quick-view.QuickView.PreventRouteSubmitMessage",
    defaultMessage: "Leave page"
  },
  cancelMessage: {
    id: "jutro-quick-view.QuickView.PreventRouteCancelMessage",
    defaultMessage: "Stay on page"
  },
  cancelAriaLabel: {
    id: "jutro-quick-view.RouterPrompt.CancelAriaLabel",
    defaultMessage: "Cancel changes"
  },
  closeModalAriaLabel: {
    id: "jutro-quick-view.RouterPrompt.CloseModalAriaLabel",
    defaultMessage: "Close modal"
  }
});