import _extends from "@babel/runtime-corejs3/helpers/extends";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import React, { useMemo } from "react";
import { Button, DropdownMenuLink, DropdownMenu } from "@jutro/components";
import { useTranslator } from "@jutro/locale";
import { generateComponentId } from "@jutro/platform";
import { messages } from "./PageSizeSwitcher.messages";
import styles from "./PageSizeSwitcher.module.css";
export var PageSizeSwitcher = function (_ref) {
  var _context,
    pageSizes = _ref.pageSizes,
    setPageSize = _ref.onPageSizeChange,
    pageSize = _ref.pageSize,
    totalItems = _ref.totalItems,
    isLoading = _ref.isLoading,
    translator = useTranslator(),
    dropdownMenuId = useMemo(function () {
      return generateComponentId("page-size-dropdown");
    }, []),
    handlePageSizeSelected = function (evt) {
      evt.preventDefault();
      var newPageSize = Number(evt.currentTarget.dataset.code);
      setPageSize(newPageSize);
    },
    pageSizeOptions = _mapInstanceProperty(_context = _filterInstanceProperty(pageSizes).call(pageSizes, function (pageSizeOption) {
      return pageSizeOption <= totalItems || pageSizeOption === pageSize;
    })).call(_context, function (pageSizeOption) {
      return React.createElement(DropdownMenuLink, _extends({}, pageSizeOption === pageSize ? {
        disabled: !0,
        "aria-current": !0
      } : {}, {
        key: pageSizeOption,
        onClick: handlePageSizeSelected,
        "data-code": pageSizeOption,
        "data-testid": "page-size-option-".concat(pageSizeOption),
        "aria-label": translator(messages.rowsPerPageOption, {
          pageSize: String(pageSizeOption)
        })
      }), pageSizeOption);
    });
  return React.createElement("div", {
    className: styles.pageSizeSwitcher
  }, React.createElement("span", {
    className: styles.rowsPerPage,
    "aria-hidden": !0
  }, translator(messages.rowsPerPage)), React.createElement(DropdownMenu, {
    id: dropdownMenuId,
    renderTrigger: function (_ref2, toggleMenu) {
      var isOpen = _ref2.isOpen,
        ref = _ref2.ref,
        onKeyDown = _ref2.onKeyDown;
      return React.createElement(Button, {
        type: "text",
        onClick: function () {
          return toggleMenu();
        },
        icon: isOpen ? "gw-keyboard-arrow-up" : "gw-keyboard-arrow-down",
        iconPosition: "right",
        iconClassName: styles.dropdownButtonIcon,
        className: styles.dropdownButton,
        "aria-expanded": isOpen,
        "aria-haspopup": "true",
        disabled: isLoading,
        "aria-label": translator(messages.rowsPerPageButton, {
          pageSize: String(pageSize)
        }),
        ref: ref,
        onKeyDown: onKeyDown
      }, pageSize);
    },
    menuClassName: styles.dropdown
  }, pageSizeOptions));
};
PageSizeSwitcher.__docgenInfo = {
  description: "Component for changing page size",
  methods: [],
  displayName: "PageSizeSwitcher"
}, PageSizeSwitcher.__docgenInfo = {
  componentName: "PageSizeSwitcher",
  packageName: "@jutro/lab-preview-dataview",
  description: "Component for changing page size",
  displayName: "PageSizeSwitcher",
  methods: [],
  actualName: "PageSizeSwitcher"
};