import "core-js/modules/es.string.replace";
import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
var _excluded = ["children", "staticContext", "replace", "modalContext"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
function _createSuper(Derived) {
  var hasNativeReflectConstruct = function () {
    if ("undefined" == typeof Reflect || !_Reflect$construct) return !1;
    if (_Reflect$construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(_Reflect$construct(Boolean, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }();
  return function () {
    var result,
      Super = _getPrototypeOf(Derived);
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else result = Super.apply(this, arguments);
    return _possibleConstructorReturn(this, result);
  };
}
import _Object$entries from "@babel/runtime-corejs3/core-js-stable/object/entries";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import omit from "lodash/omit";
import cx from "classnames";
import { InlineLoader, withModalContext } from "@jutro/components";
import { intlMessageShape, intlToShape, IntlToShape } from "@jutro/prop-types";
import { isPromise } from "@jutro/platform";
import { TranslatorContext, getUrlTranslatorAndSanitizer } from "@jutro/locale";
import styles from "./AsyncAction.module.css";
import { handleAction } from "../actionHelper";
export var propTypes = {
  failTo: intlToShape,
  failToMessage: PropTypes.string,
  message: intlMessageShape,
  replace: PropTypes.bool,
  to: intlToShape,
  toMessage: PropTypes.string,
  onTrigger: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  allowNoLeadingSlash: PropTypes.bool
};
var defaultProps = {
    replace: !1,
    allowNoLeadingSlash: !1
  },
  createHandleClick = function (instance) {
    return function (event) {
      var _instance$props = instance.props,
        onClick = _instance$props.onClick,
        target = _instance$props.target,
        allowNoLeadingSlash = _instance$props.allowNoLeadingSlash,
        modalContext = _instance$props.modalContext;
      if (onClick && onClick(event), !(event.defaultPrevented || void 0 !== event.button && 0 !== event.button || target)) {
        if (event.preventDefault(), instance.state.loading) return;
        var resultException,
          _instance$props2 = instance.props,
          onTrigger = _instance$props2.onTrigger,
          to = _instance$props2.to,
          failTo = _instance$props2.failTo,
          message = _instance$props2.message,
          toMessage = _instance$props2.toMessage,
          failToMessage = _instance$props2.failToMessage,
          translator = instance.translator,
          urlTranslatorAndSanitizer = instance.urlTranslatorAndSanitizer,
          result = !1;
        try {
          result = onTrigger();
        } catch (ex) {
          resultException = ex;
        }
        var actionObject = result instanceof Object && !isPromise(result) ? result : {
            name: "unknown",
            result: result,
            resultException: resultException
          },
          translatedTo = urlTranslatorAndSanitizer(to, allowNoLeadingSlash),
          translatedFailTo = urlTranslatorAndSanitizer(failTo, allowNoLeadingSlash);
        _Object$entries({
          progress: {
            message: message
          },
          success: {
            path: translatedTo,
            message: toMessage
          },
          failure: {
            path: translatedFailTo,
            message: failToMessage
          }
        }).forEach(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 2),
            key = _ref2[0],
            value = _ref2[1];
          actionObject[key] || (actionObject[key] = value);
        }), handleAction(actionObject, instance, translator, modalContext);
      }
    };
  };
export var withAsyncAction = function (WrappedComponent) {
  var _class,
    AsyncAction = (_class = function (_Component) {
      _inherits(AsyncAction, Component);
      var _super = _createSuper(AsyncAction);
      function AsyncAction() {
        var _context, _this;
        _classCallCheck(this, AsyncAction);
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) args[_key] = arguments[_key];
        return _this = _super.call.apply(_super, _concatInstanceProperty(_context = [this]).call(_context, args)), _defineProperty(_assertThisInitialized(_this), "state", {
          loading: !1
        }), _defineProperty(_assertThisInitialized(_this), "translator", _this.context), _defineProperty(_assertThisInitialized(_this), "urlTranslatorAndSanitizer", getUrlTranslatorAndSanitizer(_this.context)), _defineProperty(_assertThisInitialized(_this), "handleClick", createHandleClick(_assertThisInitialized(_this))), _defineProperty(_assertThisInitialized(_this), "mounted", !0), _defineProperty(_assertThisInitialized(_this), "renderContent", function () {
          var children = _this.props.children;
          return _this.state.loading ? React.createElement(React.Fragment, null, React.createElement("div", {
            className: styles.underLoader
          }, _this.translator(children)), _this.renderLoaderContent()) : children;
        }), _this;
      }
      return _createClass(AsyncAction, [{
        key: "componentWillUnmount",
        value: function () {
          this.mounted = !1;
        }
      }, {
        key: "renderLoaderContent",
        value: function () {
          var _context2,
            message = this.state.message || this.props.message,
            loading = this.state.loading;
          if (!message) return React.createElement(InlineLoader, {
            loading: loading,
            className: styles.loaderOnly
          });
          var ellipsisClasses = _mapInstanceProperty(_context2 = [null, styles.ellipsisSecondDot, styles.ellipsisThirdDot]).call(_context2, function (specificClassName) {
            return cx(styles.ellipsis, specificClassName);
          });
          return React.createElement("div", {
            className: styles.loader,
            role: "alert"
          }, "".concat(this.translator(message), " "), _mapInstanceProperty(ellipsisClasses).call(ellipsisClasses, function (dotClassName, index) {
            return React.createElement("span", {
              key: index,
              className: dotClassName
            }, ".");
          }));
        }
      }, {
        key: "render",
        value: function () {
          var _omit = omit(this.props, ["translator", "allowNoLeadingSlash", "toMessage", "failTo", "failToMessage"]),
            props = (_omit.children, _omit.staticContext, _omit.replace, _omit.modalContext, _objectWithoutProperties(_omit, _excluded)),
            loading = (this.state || this.props).loading;
          return React.createElement(WrappedComponent, _extends({}, props, {
            onClick: this.handleClick,
            loading: loading
          }), this.renderContent());
        }
      }]), AsyncAction;
    }(), _defineProperty(_class, "propTypes", _objectSpread(_objectSpread({}, propTypes), WrappedComponent.propTypes)), _defineProperty(_class, "defaultProps", _objectSpread(_objectSpread({}, defaultProps), WrappedComponent.defaultProps)), _defineProperty(_class, "contextType", TranslatorContext), _class),
    wrapped = withRouter(withModalContext(AsyncAction));
  return wrapped.displayName = WrappedComponent.displayName || WrappedComponent.name || "Component", wrapped.WrappedComponent = WrappedComponent, wrapped;
};