import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["isOpen", "menuId", "label", "translator"];
import React from "react";
import { Button, Icon } from "@jutro/components";
import styles from "./QuickViewHeaderActions.module.css";
export var defaultActionsTrigger = function (_ref, toggleMenu) {
  _ref.isOpen;
  var menuId = _ref.menuId,
    label = _ref.label,
    translator = _ref.translator,
    other = _objectWithoutProperties(_ref, _excluded);
  return React.createElement(Button, _extends({
    className: styles.trigger,
    "aria-controls": menuId,
    onClick: function () {
      return toggleMenu();
    },
    type: "outlined",
    size: "small",
    renderContent: function () {
      return React.createElement(React.Fragment, null, React.createElement("span", null, translator(label)), React.createElement(Icon, {
        icon: "gw-expand-more"
      }));
    }
  }, other));
};
defaultActionsTrigger.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "defaultActionsTrigger"
}, defaultActionsTrigger.__docgenInfo = {
  componentName: "defaultActionsTrigger",
  packageName: "@jutro/quick-view",
  description: "",
  displayName: "defaultActionsTrigger",
  methods: [],
  actualName: "defaultActionsTrigger"
};