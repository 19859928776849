import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["value", "column", "className", "rowIndex", "columnIndex", "rawRow"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import React, { memo, useRef } from "react";
import cx from "classnames";
import { useTranslator } from "@jutro/locale";
import { useKeyboardNavigation } from "../../features/accessibility";
import styles from "./Cells.module.css";
export var BodyCell = memo(function (_ref) {
  var _context,
    _context2,
    value = _ref.value,
    column = _ref.column,
    className = _ref.className,
    rowIndex = _ref.rowIndex,
    columnIndex = _ref.columnIndex,
    rawRow = _ref.rawRow,
    rest = _objectWithoutProperties(_ref, _excluded),
    renderCell = column.renderCell,
    columnId = column.id,
    columnDataType = column.columnDataType,
    onFocusHandling = column.onFocusHandling,
    translator = useTranslator(),
    cellRef = useRef(null),
    _useKeyboardNavigatio = useKeyboardNavigation(cellRef, rowIndex, columnIndex, "cell", {
      columnDataType: columnDataType,
      customFocusHandler: onFocusHandling
    }),
    tabIndex = _useKeyboardNavigatio.tabIndex,
    onKeyDown = _useKeyboardNavigatio.onKeyDown,
    onFocus = _useKeyboardNavigatio.onFocus,
    cellTabIndex = "action" === columnDataType ? -1 : tabIndex;
  return React.createElement("td", {
    id: _concatInstanceProperty(_context = "".concat(columnId, "_")).call(_context, rowIndex),
    role: "gridcell",
    ref: cellRef,
    className: cx(styles.cell, _defineProperty({}, styles.tableActionsCell, "action" === columnDataType), className),
    tabIndex: cellTabIndex,
    onKeyDown: onKeyDown,
    onFocus: onFocus
  }, renderCell && renderCell(function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = null != arguments[i] ? arguments[i] : {};
      i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
        _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
      });
    }
    return target;
  }({
    cellId: _concatInstanceProperty(_context2 = "".concat(columnId, "_")).call(_context2, rowIndex, "_cell"),
    translator: translator,
    columnProps: column,
    value: value,
    row: rawRow
  }, rest)));
});