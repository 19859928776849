import React from "react";
import { CSSTransition } from "react-transition-group";
import styles from "./QuickView.module.css";
export var QuickViewAnimation = function (_ref) {
  var children = _ref.children,
    show = _ref.show,
    onEntered = _ref.onEntered;
  return React.createElement(CSSTransition, {
    key: "quick-view-slide-animation",
    in: show,
    classNames: styles.absoluteWrapper,
    onEntered: onEntered,
    timeout: 250,
    unmountOnExit: !0
  }, children);
};
QuickViewAnimation.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "QuickViewAnimation"
}, QuickViewAnimation.__docgenInfo = {
  componentName: "QuickViewAnimation",
  packageName: "@jutro/quick-view",
  description: "",
  displayName: "QuickViewAnimation",
  methods: [],
  actualName: "QuickViewAnimation"
};