import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React, { useCallback } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { useTranslator } from "@jutro/locale";
import { Button } from "@jutro/components";
import { intlMessageShape } from "@jutro/prop-types";
import { messages } from "./LoadMore.messages";
import { useData } from "../helpers";
import { LoadingContent } from "../../overlay";
import styles from "./LoadMore.module.css";
var loadMorePropTypes = {
    id: PropTypes.string,
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number,
    totalItems: PropTypes.number,
    onPaginationChange: PropTypes.func,
    className: PropTypes.string,
    ariaLabel: intlMessageShape,
    isLoading: PropTypes.bool,
    renderLoader: PropTypes.func,
    renderTrigger: PropTypes.func
  },
  DefaultTrigger = function (_ref) {
    var isLoading = _ref.isLoading,
      handleLoadMore = _ref.handleLoadMore,
      pageSize = _ref.pageSize,
      translator = useTranslator();
    return React.createElement(Button, {
      disabled: isLoading,
      "aria-label": translator(messages.loadMoreLabel, {
        pageSize: String(pageSize)
      }),
      onClick: handleLoadMore,
      type: "outlined"
    }, translator(messages.loadMore));
  },
  DefaultLoader = function () {
    var translator = useTranslator();
    return React.createElement(LoadingContent, {
      message: translator(messages.loading)
    });
  },
  defaultProps = {
    pageSize: 10,
    totalItems: 0,
    page: 1,
    renderTrigger: DefaultTrigger,
    renderLoader: DefaultLoader
  };
export var LoadMore = function (props) {
  var _defaultProps$props = _objectSpread(_objectSpread({}, defaultProps), props),
    id = _defaultProps$props.id,
    className = _defaultProps$props.className,
    ariaLabel = _defaultProps$props.ariaLabel,
    onPaginationChange = _defaultProps$props.onPaginationChange,
    page = _defaultProps$props.page,
    pageSize = _defaultProps$props.pageSize,
    totalItems = _defaultProps$props.totalItems,
    isLoading = _defaultProps$props.isLoading,
    renderTrigger = _defaultProps$props.renderTrigger,
    Loader = _defaultProps$props.renderLoader,
    translator = useTranslator(),
    hasMoreData = useData({
      pageSize: pageSize,
      page: page,
      totalItems: totalItems
    }).hasMoreData,
    handleLoadMore = useCallback(function () {
      null == onPaginationChange || onPaginationChange({
        page: page + 1,
        pageSize: pageSize,
        pageSizes: [],
        paginationType: "loadMore"
      });
    }, [onPaginationChange, page, pageSize]),
    trigger = isLoading && React.createElement(Loader, null) || renderTrigger({
      isLoading: isLoading,
      renderLoader: Loader,
      handleLoadMore: handleLoadMore,
      pageSize: pageSize
    }),
    classes = cx(styles.loadMore, className),
    navId = id ? {
      id: id
    } : {};
  return hasMoreData ? React.createElement("nav", _extends({}, navId, {
    className: classes,
    "aria-label": translator(ariaLabel)
  }), trigger) : null;
};
LoadMore.propTypes = loadMorePropTypes, LoadMore.defaultProps = defaultProps, LoadMore.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "LoadMore",
  props: {
    pageSize: {
      defaultValue: {
        value: "10",
        computed: !1
      },
      type: {
        name: "number"
      },
      required: !1,
      description: "Number of data entries per page"
    },
    totalItems: {
      defaultValue: {
        value: "0",
        computed: !1
      },
      type: {
        name: "number"
      },
      required: !1,
      description: "Total number of items (not only the displayed ones) for calculating the number of pages"
    },
    page: {
      defaultValue: {
        value: "1",
        computed: !1
      },
      type: {
        name: "number"
      },
      required: !1,
      description: "Current page number, used to control it from outside"
    },
    renderTrigger: {
      defaultValue: {
        value: '({\n    isLoading,\n    handleLoadMore,\n    pageSize,\n}) => {\n    const translator = useTranslator();\n\n    return (\n        <Button\n            disabled={isLoading}\n            aria-label={translator(messages.loadMoreLabel, {\n                pageSize: String(pageSize),\n            })}\n            onClick={handleLoadMore}\n            type="outlined"\n        >\n            {translator(messages.loadMore)}\n        </Button>\n    );\n}',
        computed: !1
      },
      type: {
        name: "func"
      },
      required: !1,
      description: "Function to render a trigger element. The trigger element takes three arguments:\n- An object of props for the component\n- An onCLick callback function\n- A boolean variable to control the state of visibility, like `isVisible`"
    },
    renderLoader: {
      defaultValue: {
        value: "() => {\n    const translator = useTranslator();\n\n    return <LoadingContent message={translator(messages.loading)} />;\n}",
        computed: !1
      },
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to render custom loader inside of trigger"
    },
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Identifier of the component"
    },
    onPaginationChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback called on every page change"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class to apply to the load more pagination component"
    },
    ariaLabel: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Aria label text"
    },
    isLoading: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "whether to show a loading state when data is being retrieved, if true disables changing page"
    }
  }
}, DefaultTrigger.__docgenInfo = {
  componentName: "DefaultTrigger",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "DefaultTrigger",
  methods: [],
  actualName: "DefaultTrigger"
}, DefaultLoader.__docgenInfo = {
  componentName: "DefaultLoader",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "DefaultLoader",
  methods: [],
  actualName: "DefaultLoader"
}, LoadMore.__docgenInfo = {
  componentName: "LoadMore",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "LoadMore",
  methods: [],
  actualName: "LoadMore",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Identifier of the component"
    },
    page: {
      type: {
        name: "number"
      },
      required: !1,
      description: "Current page number, used to control it from outside",
      defaultValue: {
        value: "1",
        computed: !1
      }
    },
    pageSize: {
      type: {
        name: "number"
      },
      required: !1,
      description: "Number of data entries per page",
      defaultValue: {
        value: "10",
        computed: !1
      }
    },
    totalItems: {
      type: {
        name: "number"
      },
      required: !1,
      description: "Total number of items (not only the displayed ones) for calculating the number of pages",
      defaultValue: {
        value: "0",
        computed: !1
      }
    },
    onPaginationChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback called on every page change"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class to apply to the load more pagination component"
    },
    ariaLabel: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Aria label text"
    },
    isLoading: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "whether to show a loading state when data is being retrieved, if true disables changing page"
    },
    renderLoader: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to render custom loader inside of trigger",
      defaultValue: {
        value: "() => {\n    const translator = useTranslator();\n    return React.createElement(LoadingContent, { message: translator(messages.loading) });\n}",
        computed: !1
      }
    },
    renderTrigger: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Function to render a trigger element. The trigger element takes three arguments:\n- An object of props for the component\n- An onCLick callback function\n- A boolean variable to control the state of visibility, like `isVisible`",
      defaultValue: {
        value: '({ isLoading, handleLoadMore, pageSize, }) => {\n    const translator = useTranslator();\n    return (React.createElement(Button, { disabled: isLoading, "aria-label": translator(messages.loadMoreLabel, {\n            pageSize: String(pageSize),\n        }), onClick: handleLoadMore, type: "outlined" }, translator(messages.loadMore)));\n}',
        computed: !1
      }
    }
  }
};