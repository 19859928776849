import _typeof from "@babel/runtime-corejs3/helpers/typeof";
import { createUseMappedProps, excludeNull } from "../mappers";
import styles from "./TableAdapter.module.css";
var titlePositionClasses = {
  left: styles.titleLeft,
  right: styles.titleRight,
  center: styles.titleCenter
};
export var useMappedTitleProps = createUseMappedProps({
  title: function (_ref) {
    var title = _ref.title;
    if ("string" == typeof title || "object" === _typeof(title)) return excludeNull(title);
  },
  render: function (_ref2, translator) {
    var _excludeNull,
      title = _ref2.title,
      renderTitle = _ref2.renderTitle;
    return "function" == typeof title ? title(translator) : null === (_excludeNull = excludeNull(renderTitle)) || void 0 === _excludeNull ? void 0 : _excludeNull(translator);
  },
  className: function (_ref3) {
    var titlePosition = _ref3.titlePosition;
    return titlePositionClasses[excludeNull(titlePosition)];
  }
});