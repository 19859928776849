import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import React from "react";
import { QuickViewHeader } from "../QuickViewHeader/QuickViewHeader";
import { QuickViewHeaderActions } from "../QuickViewHeaderActions/QuickViewHeaderActions";
import { QuickViewBody } from "../QuickViewBody/QuickViewBody";
import { QuickViewFooter } from "../QuickViewFooter/QuickViewFooter";
export var splitQuickViewChildren = function (children) {
  var _React$Children$toArr;
  return null === (_React$Children$toArr = React.Children.toArray(children)) || void 0 === _React$Children$toArr ? void 0 : _reduceInstanceProperty(_React$Children$toArr).call(_React$Children$toArr, function (acc, child) {
    return child.type === QuickViewHeader ? _objectSpread(_objectSpread({}, acc), {}, {
      header: child
    }) : child.type === QuickViewHeaderActions ? _objectSpread(_objectSpread({}, acc), {}, {
      actions: child
    }) : child.type === QuickViewBody ? _objectSpread(_objectSpread({}, acc), {}, {
      body: child
    }) : child.type === QuickViewFooter ? _objectSpread(_objectSpread({}, acc), {}, {
      footer: child
    }) : acc;
  }, {});
};