import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["render"];
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import React from "react";
import { Flex, useBreakpoint } from "@jutro/layout";
import { useTranslator } from "@jutro/locale";
import cx from "classnames";
import { useHeaderActionsContext } from "../../features/headerActions";
import { determineSelectionState, useSelectionContext } from "../../features/selection";
import { Title, useTitleContext } from "../../features/title";
import messages from "./DataViewHeader.messages";
import styles from "./DataViewHeader.module.css";
var ControlsWrapper = function (_ref) {
    var _context,
      children = _ref.children,
      translator = useTranslator(),
      title = useTitleContext().title,
      ariaLabel = translator(messages.toolbarContainerLabel, {
        dataViewTitle: translator(title)
      });
    return _filterInstanceProperty(_context = React.Children.toArray(children)).call(_context, Boolean).length ? React.createElement(Flex, {
      alignItems: "center",
      justifyContent: "right",
      className: styles.controlsWrapper,
      gap: "none",
      "aria-label": ariaLabel,
      role: "group"
    }, children) : null;
  },
  TitleWithActionsWrapper = function (_ref2) {
    var children = _ref2.children,
      breakpoint = useBreakpoint({}).breakpoint;
    return ["phone", "phoneWide"].some(function (br) {
      return br === breakpoint;
    }) ? React.createElement(Flex, {
      className: styles.titleWithActions,
      justifyContent: "left"
    }, children) : React.createElement(React.Fragment, null, children);
  },
  WrappedHeaderActions = function (_ref3) {
    var rowIds = _ref3.rowIds,
      _useSelectionContext = useSelectionContext(),
      selectedRows = _useSelectionContext.selectedRows,
      selectionEnabled = _useSelectionContext.selectionEnabled,
      rowsSelectionSet = determineSelectionState({
        rowIds: rowIds,
        selectedRows: selectedRows
      }),
      _useHeaderActionsCont = useHeaderActionsContext(),
      HeaderActions = _useHeaderActionsCont.render,
      props = _objectWithoutProperties(_useHeaderActionsCont, _excluded);
    return HeaderActions ? React.createElement(Flex, {
      className: styles.headerActions,
      gap: "none",
      justifyContent: "right"
    }, React.createElement(HeaderActions, _extends({}, props, selectionEnabled && {
      rowsSelectionSet: rowsSelectionSet,
      selectedRows: selectedRows
    }))) : React.createElement(React.Fragment, null);
  };
export var DataViewHeader = function (_ref4) {
  var titleId = _ref4.titleId,
    className = _ref4.className,
    children = _ref4.children,
    rowIds = _ref4.rowIds,
    breakpoint = useBreakpoint({}).breakpoint,
    isPhone = ["phone", "phoneWide"].some(function (br) {
      return br === breakpoint;
    }),
    headerElementsGap = isPhone ? "none" : "medium";
  return React.createElement(Flex, {
    className: cx(styles.header, _defineProperty({}, styles.wrappedHeader, isPhone), className),
    gap: headerElementsGap,
    "data-testid": "testHeader",
    alignItems: "top",
    justifyContent: "between"
  }, React.createElement(TitleWithActionsWrapper, null, React.createElement(Title, {
    titleId: titleId
  }), isPhone && React.createElement(WrappedHeaderActions, {
    rowIds: rowIds
  })), React.createElement(ControlsWrapper, null, children), !isPhone && React.createElement(WrappedHeaderActions, {
    rowIds: rowIds
  }));
};
DataViewHeader.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "DataViewHeader"
}, ControlsWrapper.__docgenInfo = {
  componentName: "ControlsWrapper",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "ControlsWrapper",
  methods: [],
  actualName: "ControlsWrapper"
}, TitleWithActionsWrapper.__docgenInfo = {
  componentName: "TitleWithActionsWrapper",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "TitleWithActionsWrapper",
  methods: [],
  actualName: "TitleWithActionsWrapper"
}, WrappedHeaderActions.__docgenInfo = {
  componentName: "WrappedHeaderActions",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "WrappedHeaderActions",
  methods: [],
  actualName: "WrappedHeaderActions"
}, DataViewHeader.__docgenInfo = {
  componentName: "DataViewHeader",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "DataViewHeader",
  methods: [],
  actualName: "DataViewHeader"
};