import React from "react";
import { Flex } from "@jutro/layout";
import { PageSwitcherSummary } from "./PageSwitcherSummary";
import { PageSwitcherNavigation } from "./PageSwitcherNavigation";
import styles from "./PageSwitcher.module.css";
export var PageSwitcher = function (_ref) {
  var totalItemsProp = _ref.totalItems,
    page = _ref.page,
    onPageChange = _ref.onPageChange,
    pageSize = _ref.pageSize,
    isLoading = _ref.isLoading,
    totalItems = totalItemsProp <= 0 ? 0 : totalItemsProp,
    lastPage = Math.ceil(totalItems / pageSize);
  return React.createElement(Flex, {
    gap: "small",
    alignItems: "center",
    className: styles.pageSwitcher
  }, React.createElement(PageSwitcherSummary, {
    totalItems: totalItems,
    page: page,
    lastPage: lastPage,
    pageSize: pageSize
  }), React.createElement(PageSwitcherNavigation, {
    totalItems: totalItems,
    page: page,
    setPage: onPageChange,
    lastPage: lastPage,
    isLoading: isLoading
  }));
};
PageSwitcher.__docgenInfo = {
  description: "Component for navigating between pages",
  methods: [],
  displayName: "PageSwitcher"
}, PageSwitcher.__docgenInfo = {
  componentName: "PageSwitcher",
  packageName: "@jutro/lab-preview-dataview",
  description: "Component for navigating between pages",
  displayName: "PageSwitcher",
  methods: [],
  actualName: "PageSwitcher"
};