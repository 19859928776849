import { useMemo, useContext } from "react";
import { BreakpointTrackerContext } from "@jutro/layout";
export var useDynamicStyles = function (containerBounds) {
  var breakpoint = useContext(BreakpointTrackerContext),
    right = document.documentElement.clientWidth - ((null == containerBounds ? void 0 : containerBounds.right) || 0),
    visibleHeight = function (containerBounds) {
      var clientHeight = document.documentElement.clientHeight,
        containerTop = null == containerBounds ? void 0 : containerBounds.top,
        containerBottom = null == containerBounds ? void 0 : containerBounds.bottom;
      return containerBottom > clientHeight && containerTop >= 0 ? clientHeight - containerTop : containerBottom < clientHeight && containerTop < 0 ? containerBottom : containerBottom > clientHeight && containerTop < 0 ? clientHeight : (null == containerBounds ? void 0 : containerBounds.height) || 0;
    }(containerBounds),
    totalHeight = (null == containerBounds ? void 0 : containerBounds.height) || 0,
    width = (null == containerBounds ? void 0 : containerBounds.width) || 0;
  return useMemo(function () {
    return {
      right: "phone" !== breakpoint ? right : 0,
      width: width,
      totalHeight: totalHeight,
      visibleHeight: visibleHeight
    };
  }, [breakpoint, right, width, totalHeight, visibleHeight]);
};