import React from "react";
import { IconButton } from "@jutro/components";
import { useTranslator } from "@jutro/locale";
import { determineSelectionState } from "../../features/selection";
import messages from "./ListViewHeader.messages";
export var SelectAllTrigger = function (_ref) {
  var selectedRows = _ref.selectedRows,
    onAllRowsSelect = _ref.onAllRowsSelect,
    rowIds = _ref.rowIds,
    translator = useTranslator(),
    rowsSelectionSet = determineSelectionState({
      rowIds: rowIds,
      selectedRows: selectedRows
    });
  return React.createElement(IconButton, {
    icon: "none" !== rowsSelectionSet ? "gw-check-circle" : "gw-check-circle-outline",
    onClick: function () {
      return null == onAllRowsSelect ? void 0 : onAllRowsSelect({
        rowIds: rowIds
      });
    },
    "aria-label": translator(messages.selectAllRows),
    role: "checkbox",
    "aria-checked": "none" !== rowsSelectionSet
  });
};
SelectAllTrigger.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "SelectAllTrigger"
}, SelectAllTrigger.__docgenInfo = {
  componentName: "SelectAllTrigger",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "SelectAllTrigger",
  methods: [],
  actualName: "SelectAllTrigger"
};