import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["viewChangeBreakpoint"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _indexOfInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/index-of";
import React from "react";
import PropTypes from "prop-types";
import { useBreakpoint, breakpoints } from "@jutro/layout";
import { intlMessageShape } from "@jutro/prop-types";
import { ListView } from "../ListView";
import { TableView } from "../TableView";
var DataViewComponentMap = {
  list: ListView,
  table: TableView
};
export var AdaptiveDataView = function (_ref) {
  var _ref$viewChangeBreakp = _ref.viewChangeBreakpoint,
    viewChangeBreakpoint = void 0 === _ref$viewChangeBreakp ? "phoneWide" : _ref$viewChangeBreakp,
    props = _objectWithoutProperties(_ref, _excluded),
    componentVariant = function (currentDevice, viewChangeBreakpoint) {
      return "desktop" !== currentDevice && _indexOfInstanceProperty(breakpoints).call(breakpoints, currentDevice) <= _indexOfInstanceProperty(breakpoints).call(breakpoints, viewChangeBreakpoint) ? "list" : "table";
    }(useBreakpoint({}).breakpoint, viewChangeBreakpoint),
    ViewComponent = DataViewComponentMap[componentVariant];
  return React.createElement(ViewComponent, props);
};
var classNamesPropTypes = {
  item: PropTypes.object,
  footer: PropTypes.string,
  header: PropTypes.string,
  table: PropTypes.string,
  headerRow: PropTypes.string,
  selectionHeaderCell: PropTypes.string,
  headerCell: PropTypes.string,
  row: PropTypes.string,
  selectionCell: PropTypes.string,
  cell: PropTypes.string
};
export var adaptiveDataViewBasePropTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.array,
  ariaLabel: intlMessageShape,
  rowIdPath: PropTypes.string,
  onGetRowId: PropTypes.func,
  className: PropTypes.string,
  internalClassNames: PropTypes.shape(classNamesPropTypes),
  onStyleHeaderCell: PropTypes.func,
  onStyleRow: PropTypes.func,
  onStyleCell: PropTypes.func,
  onComponentRender: PropTypes.func,
  stripedRows: PropTypes.bool,
  onStyleSelectionCell: PropTypes.func
};
var adaptiveDataViewBreakpointPropTypes = _objectSpread(_objectSpread({}, adaptiveDataViewBasePropTypes), {}, {
    id: PropTypes.string
  }),
  adaptiveDataViewPropTypes = _objectSpread(_objectSpread({}, adaptiveDataViewBasePropTypes), {}, {
    viewChangeBreakpoint: PropTypes.oneOf(["phone", "phoneWide", "tablet"]),
    phone: PropTypes.shape(adaptiveDataViewBreakpointPropTypes),
    phoneWide: PropTypes.shape(adaptiveDataViewBreakpointPropTypes),
    tablet: PropTypes.shape(adaptiveDataViewBreakpointPropTypes)
  });
AdaptiveDataView.propTypes = adaptiveDataViewPropTypes, AdaptiveDataView.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "AdaptiveDataView",
  props: {
    viewChangeBreakpoint: {
      defaultValue: {
        value: "'phoneWide'",
        computed: !1
      },
      type: {
        name: "enum",
        value: [{
          value: "'phone'",
          computed: !1
        }, {
          value: "'phoneWide'",
          computed: !1
        }, {
          value: "'tablet'",
          computed: !1
        }]
      },
      required: !1,
      description: ""
    },
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Identifier of the component"
    },
    data: {
      type: {
        name: "array"
      },
      required: !1,
      description: "Data to be displayed in the component"
    },
    ariaLabel: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Aria label text"
    },
    rowIdPath: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Path to the id of a row. By default it is equal to 'id'."
    },
    onGetRowId: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to get a unique identifier of a row. The identifier is passed to other callbacks.\nReceives two arguments - `row` and `rowIndex`. Should return string.\nBy default uses `rowIdPath` to get the identifier from a row.\nBe careful with using `rowIndex` for the id - it's not available in sorting and might be changed with filtering."
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Custom class name for the component. Applied to the same element as  wrapper` from `internalClassNames` prop"
    },
    internalClassNames: {
      type: {
        name: "shape",
        value: {
          item: {
            name: "object",
            required: !1
          },
          footer: {
            name: "string",
            required: !1
          },
          header: {
            name: "string",
            required: !1
          },
          table: {
            name: "string",
            required: !1
          },
          headerRow: {
            name: "string",
            required: !1
          },
          selectionHeaderCell: {
            name: "string",
            required: !1
          },
          headerCell: {
            name: "string",
            required: !1
          },
          row: {
            name: "string",
            required: !1
          },
          selectionCell: {
            name: "string",
            required: !1
          },
          cell: {
            name: "string",
            required: !1
          }
        }
      },
      required: !1,
      description: "Custom class names for the component and its major components."
    },
    onStyleHeaderCell: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback for a dynamic calculation of a custom classname for a cell in the header row"
    },
    onStyleRow: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback for a dynamic calculation of a custom classname for a table body's row"
    },
    onStyleCell: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback for a dynamic calculation of a custom classname for a cell within the table's body"
    },
    onComponentRender: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback called on every render to access information on columns definitions"
    },
    stripedRows: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Defines if rows should have striping enabled"
    },
    onStyleSelectionCell: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback for a dynamic calculation of a custom classname for the selection cell within the table's body"
    },
    phone: {
      type: {
        name: "shape",
        value: {
          id: {
            name: "string",
            description: "Identifier of the component",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          id: {
            name: "string",
            description: "Identifier of the component",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          id: {
            name: "string",
            description: "Identifier of the component",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
}, AdaptiveDataView.__docgenInfo = {
  componentName: "AdaptiveDataView",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "AdaptiveDataView",
  methods: [],
  actualName: "AdaptiveDataView",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Identifier of the component"
    },
    data: {
      type: {
        name: "array"
      },
      required: !1,
      description: "Data to be displayed in the component"
    },
    ariaLabel: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Aria label text"
    },
    rowIdPath: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Path to the id of a row. By default it is equal to 'id'."
    },
    onGetRowId: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to get a unique identifier of a row. The identifier is passed to other callbacks.\nReceives two arguments - `row` and `rowIndex`. Should return string.\nBy default uses `rowIdPath` to get the identifier from a row.\nBe careful with using `rowIndex` for the id - it's not available in sorting and might be changed with filtering."
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Custom class name for the component. Applied to the same element as  wrapper` from `internalClassNames` prop"
    },
    internalClassNames: {
      type: {
        name: "shape",
        value: {
          item: {
            name: "object",
            required: !1
          },
          footer: {
            name: "string",
            required: !1
          },
          header: {
            name: "string",
            required: !1
          },
          table: {
            name: "string",
            required: !1
          },
          headerRow: {
            name: "string",
            required: !1
          },
          selectionHeaderCell: {
            name: "string",
            required: !1
          },
          headerCell: {
            name: "string",
            required: !1
          },
          row: {
            name: "string",
            required: !1
          },
          selectionCell: {
            name: "string",
            required: !1
          },
          cell: {
            name: "string",
            required: !1
          }
        }
      },
      required: !1,
      description: "Custom class names for the component and its major components."
    },
    onStyleHeaderCell: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback for a dynamic calculation of a custom classname for a cell in the header row"
    },
    onStyleRow: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback for a dynamic calculation of a custom classname for a table body's row"
    },
    onStyleCell: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback for a dynamic calculation of a custom classname for a cell within the table's body"
    },
    onComponentRender: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback called on every render to access information on columns definitions"
    },
    stripedRows: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Defines if rows should have striping enabled"
    },
    onStyleSelectionCell: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback for a dynamic calculation of a custom classname for the selection cell within the table's body"
    },
    viewChangeBreakpoint: {
      type: {
        name: "enum",
        value: [{
          value: "'phone'",
          computed: !1
        }, {
          value: "'phoneWide'",
          computed: !1
        }, {
          value: "'tablet'",
          computed: !1
        }]
      },
      required: !1,
      description: "",
      defaultValue: {
        value: "'phoneWide'",
        computed: !1
      }
    },
    phone: {
      type: {
        name: "shape",
        value: {
          id: {
            name: "string",
            description: "Identifier of the component",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          id: {
            name: "string",
            description: "Identifier of the component",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          id: {
            name: "string",
            description: "Identifier of the component",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
};