import { defineMessages } from "react-intl";
export var messages = defineMessages({
  rowsPerPage: {
    id: "jutro-dataview.Pagination.rowsPerPage",
    defaultMessage: "Rows per page:"
  },
  rowsPerPageButton: {
    id: "jutro-dataview.Pagination.rowsPerPageButton",
    defaultMessage: "Rows per page: {pageSize}"
  },
  rowsPerPageOption: {
    id: "jutro-dataview.Pagination.rowsPerPageOption",
    defaultMessage: "{pageSize} rows per page"
  }
});