import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
var _ALIGN_TEXT_STYLES;
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _JSON$stringify from "@babel/runtime-corejs3/core-js-stable/json/stringify";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import get from "lodash/get";
import pick from "lodash/pick";
import omit from "lodash/omit";
import set from "lodash/fp/set";
import { InputField } from "@jutro/components";
import { warning } from "@jutro/logger";
import { intlMessageShape } from "@jutro/prop-types";
import { useBreakpoint } from "@jutro/layout";
import styles from "./Columns.module.css";
import { getCommonEditProps } from "../editing/getCommonEditProps";
import { useColumnsContext } from "./DataViewColumns";
export var INVALID_VALUE_PLACEHOLDER = "-";
export var ALIGN_TEXT_LEFT = "left";
export var ALIGN_TEXT_RIGHT = "right";
export var ALIGN_TEXT_CENTER = "center";
var ALIGN_TEXT_STYLES = (_defineProperty(_ALIGN_TEXT_STYLES = {}, ALIGN_TEXT_LEFT, styles.alignLeft), _defineProperty(_ALIGN_TEXT_STYLES, ALIGN_TEXT_RIGHT, styles.alignRight), _defineProperty(_ALIGN_TEXT_STYLES, ALIGN_TEXT_CENTER, styles.alignCenter), _ALIGN_TEXT_STYLES);
export var DefaultHeader = function (_ref) {
  var header = _ref.columnProps.header,
    translator = _ref.translator;
  return React.createElement("span", null, translator(header));
};
export var DefaultCell = function (_ref2) {
  var _context,
    value = _ref2.value,
    classNames = _ref2.columnProps.classNames,
    cellId = _ref2.cellId;
  if ("string" == typeof value || "number" == typeof value) return React.createElement("span", {
    id: cellId,
    className: null == classNames ? void 0 : classNames.cellContent
  }, value);
  void 0 !== value && warning(_concatInstanceProperty(_context = "Invalid value in a cell ".concat(cellId, ": ")).call(_context, _JSON$stringify(value), ". You can use onResolveValue prop in the column to change it to string or number"));
  return React.createElement("span", {
    id: cellId,
    className: null == classNames ? void 0 : classNames.cellContent
  }, INVALID_VALUE_PLACEHOLDER);
};
var DefaultEditCell = function (props) {
  var value = props.value;
  return React.createElement(InputField, _extends({}, getCommonEditProps(props), {
    value: String(value),
    inputType: "text",
    noDeprecationWarnings: ["registerValidation"]
  }));
};
export var GenericColumn = function (props) {
  var _breakpointProps$clas,
    _breakpointProps$clas2,
    alignClass,
    columnProps,
    breakpointProps = useBreakpoint(props).breakpointProps,
    registerColumn = useColumnsContext().registerColumn;
  return React.createElement(React.Fragment, null, (alignClass = ALIGN_TEXT_STYLES[breakpointProps.alignText || ALIGN_TEXT_LEFT], columnProps = _objectSpread(_objectSpread({
    renderHeader: breakpointProps.header ? DefaultHeader : void 0
  }, breakpointProps), {}, {
    headerAriaLabel: breakpointProps.headerAriaLabel || breakpointProps.header,
    classNames: _objectSpread(_objectSpread({}, null == props ? void 0 : props.classNames), {}, {
      header: cx(alignClass, null == breakpointProps || null === (_breakpointProps$clas = breakpointProps.classNames) || void 0 === _breakpointProps$clas ? void 0 : _breakpointProps$clas.header),
      cell: cx(alignClass, null == breakpointProps || null === (_breakpointProps$clas2 = breakpointProps.classNames) || void 0 === _breakpointProps$clas2 ? void 0 : _breakpointProps$clas2.cell)
    }),
    visible: !0,
    id: props.id
  }), null == registerColumn || registerColumn(columnProps), null));
};
GenericColumn.defaultProps = {
  renderCell: DefaultCell,
  renderEditCell: DefaultEditCell,
  columnDataType: "unknown",
  sortable: !0,
  displayInEditForm: "ifVisible",
  alignText: ALIGN_TEXT_LEFT,
  onResolveValue: function (_ref4) {
    var row = _ref4.row,
      path = _ref4.path;
    return path && get(row, path);
  },
  onSaveValue: function (_ref5) {
    var row = _ref5.row,
      newValue = _ref5.newValue,
      _ref5$columnProps = _ref5.columnProps,
      path = _ref5$columnProps.path,
      id = _ref5$columnProps.id;
    return set(null != path ? path : id, newValue, row);
  }
};
var columnClassNamesPropTypes = {
  cell: PropTypes.string,
  cellContent: PropTypes.string,
  header: PropTypes.string
};
export var genericColumnBasePropTypes = {
  id: PropTypes.string.isRequired,
  header: intlMessageShape,
  headerAriaLabel: intlMessageShape,
  path: PropTypes.string,
  onResolveValue: PropTypes.func,
  onSaveValue: PropTypes.func,
  renderHeader: PropTypes.func,
  classNames: PropTypes.shape(columnClassNamesPropTypes),
  alignText: PropTypes.oneOf([ALIGN_TEXT_LEFT, ALIGN_TEXT_RIGHT, ALIGN_TEXT_CENTER]),
  sortable: PropTypes.bool,
  displayInEditForm: PropTypes.oneOf(["always", "ifVisible", "never"]),
  onStyleHeaderCell: PropTypes.func,
  onStyleCell: PropTypes.func,
  onFocusHandling: PropTypes.func
};
var genericColumnPropTypes = _objectSpread(_objectSpread({}, genericColumnBasePropTypes), {}, {
    renderCell: PropTypes.func,
    renderEditCell: PropTypes.func,
    editConfig: PropTypes.objectOf(PropTypes.any),
    columnDataType: PropTypes.string
  }),
  genericColumnBreakpointPropTypes = _objectSpread(_objectSpread({}, genericColumnPropTypes), {}, {
    id: PropTypes.string
  });
GenericColumn.propTypes = _objectSpread(_objectSpread({}, genericColumnPropTypes), {}, {
  phone: PropTypes.shape(genericColumnBreakpointPropTypes),
  phoneWide: PropTypes.shape(genericColumnBreakpointPropTypes),
  tablet: PropTypes.shape(genericColumnBreakpointPropTypes)
}), GenericColumn.displayName = "GenericColumn";
export var createColumnPropTypes = function (fieldPropsTypes) {
  var _context2,
    _context3,
    allowFields = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : [],
    blockFields = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : [],
    breakpointFieldsOverrides = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : {},
    commonPropTypes = _objectSpread(_objectSpread({}, allowFields ? pick.apply(void 0, _concatInstanceProperty(_context2 = [fieldPropsTypes]).call(_context2, _toConsumableArray(allowFields))) : fieldPropsTypes), blockFields ? omit.apply(void 0, _concatInstanceProperty(_context3 = [genericColumnBreakpointPropTypes]).call(_context3, _toConsumableArray(blockFields))) : genericColumnBreakpointPropTypes),
    breakpointCommonPropTypes = _objectSpread(_objectSpread({}, commonPropTypes), breakpointFieldsOverrides);
  return _objectSpread(_objectSpread({}, commonPropTypes), {}, {
    phone: PropTypes.shape(breakpointCommonPropTypes),
    phoneWide: PropTypes.shape(breakpointCommonPropTypes),
    tablet: PropTypes.shape(breakpointCommonPropTypes)
  });
};
DefaultHeader.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "DefaultHeader"
}, DefaultCell.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "DefaultCell"
}, GenericColumn.__docgenInfo = {
  description: "A basic column for string and number values\n\n@metadataType container",
  methods: [],
  displayName: "GenericColumn",
  props: {
    renderCell: {
      defaultValue: {
        value: "({\n    value,\n    columnProps: { classNames },\n    cellId,\n}) => {\n    if (typeof value === 'string' || typeof value === 'number') {\n        return (\n            <span id={cellId} className={classNames?.cellContent}>\n                {value}\n            </span>\n        );\n    }\n\n    if (value !== undefined) {\n        warning(\n            `Invalid value in a cell ${cellId}: ${JSON.stringify(\n                value\n            )}. You can use onResolveValue prop in the column to change it to string or number`\n        );\n    }\n\n    return (\n        <span id={cellId} className={classNames?.cellContent}>\n            {INVALID_VALUE_PLACEHOLDER}\n        </span>\n    );\n}",
        computed: !1
      },
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to render the table body cell"
    },
    renderEditCell: {
      defaultValue: {
        value: "props => {\n    const { value } = props;\n\n    return (\n        <InputField\n            {...getCommonEditProps(props)}\n            value={String(value)}\n            inputType=\"text\"\n            // @ts-ignore\n            noDeprecationWarnings={['registerValidation']}\n        />\n    );\n}",
        computed: !1
      },
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to render edit cell during editing."
    },
    columnDataType: {
      defaultValue: {
        value: "'unknown'",
        computed: !1
      },
      type: {
        name: "string"
      },
      required: !1,
      description: "Type of data presented in the column. Helpful for handling type-specific operations, e.g., sorting."
    },
    sortable: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "Flag indicating if the sorting capability is available for the column"
    },
    displayInEditForm: {
      defaultValue: {
        value: "'ifVisible'",
        computed: !1
      },
      type: {
        name: "enum",
        value: [{
          value: "'always'",
          computed: !1
        }, {
          value: "'ifVisible'",
          computed: !1
        }, {
          value: "'never'",
          computed: !1
        }]
      },
      required: !1,
      description: 'Determines if edit form includes a field for this column. Values "always" and "never" override `displayAllColumns` prop in DataViewEditing.\nPossible values:\n- "always" - field will be visible in the edit form, even when the column is hidden in data view.\n- "ifVisible" - field will be visible only if the column is visible in data view.\n- "never" - field will never be visible in the edit form.\n@default "ifVisible"'
    },
    alignText: {
      defaultValue: {
        value: "'left'",
        computed: !1
      },
      type: {
        name: "enum",
        value: [{
          value: "'left'",
          computed: !1
        }, {
          value: "'right'",
          computed: !1
        }, {
          value: "'center'",
          computed: !1
        }]
      },
      required: !1,
      description: "Set the text align of the column"
    },
    onResolveValue: {
      defaultValue: {
        value: "({ row, path }) =>\npath && get(row, path)",
        computed: !1
      },
      type: {
        name: "func"
      },
      required: !1,
      description: "Alternative way of calculating the value - returned value is displayed in a cell"
    },
    onSaveValue: {
      defaultValue: {
        value: "({\n    row,\n    newValue,\n    columnProps: { path, id },\n}) => set(path ?? id, newValue, row)",
        computed: !1
      },
      type: {
        name: "func"
      },
      required: !1,
      description: "When editing, specify how a new value entered in the edit form is saved to the row.\nUseful when `onResolveValue` is defined, but `path` is not defined."
    },
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Column identifier; must be unique within a table"
    },
    header: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Content of the header cell"
    },
    headerAriaLabel: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "aria-label attribute of header"
    },
    path: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The full path of view model, it also acts as an accessor for row's data"
    },
    renderHeader: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to render the table header cell"
    },
    classNames: {
      type: {
        name: "shape",
        value: {
          cell: {
            name: "string",
            required: !1
          },
          cellContent: {
            name: "string",
            required: !1
          },
          header: {
            name: "string",
            required: !1
          }
        }
      },
      required: !1,
      description: "Custom classnames for header and body cells"
    },
    onStyleHeaderCell: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback for a dynamic calculation of a custom class name for a cell in the header row"
    },
    onStyleCell: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback for a dynamic calculation of a custom class name for a cell within the table body"
    },
    onFocusHandling: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback for custom implementation of element focus during keyboard navigation. Applicable only for TableView."
    },
    editConfig: {
      type: {
        name: "objectOf",
        value: {
          name: "any"
        }
      },
      required: !1,
      description: "Config for specifying additional props for fields rendered during editing"
    },
    phone: {
      type: {
        name: "shape",
        value: {
          id: {
            name: "string",
            description: "Column identifier; must be unique within a table",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          id: {
            name: "string",
            description: "Column identifier; must be unique within a table",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          id: {
            name: "string",
            description: "Column identifier; must be unique within a table",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
}, DefaultHeader.__docgenInfo = {
  componentName: "DefaultHeader",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "DefaultHeader",
  methods: [],
  actualName: "DefaultHeader"
}, DefaultCell.__docgenInfo = {
  componentName: "DefaultCell",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "DefaultCell",
  methods: [],
  actualName: "DefaultCell"
}, DefaultEditCell.__docgenInfo = {
  componentName: "DefaultEditCell",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "DefaultEditCell",
  methods: [],
  actualName: "DefaultEditCell"
}, GenericColumn.__docgenInfo = {
  componentName: "GenericColumn",
  packageName: "@jutro/lab-preview-dataview",
  description: "A basic column for string and number values",
  displayName: "GenericColumn",
  methods: [],
  actualName: "GenericColumn",
  metadataType: "container",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Column identifier; must be unique within a table"
    },
    header: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Content of the header cell"
    },
    headerAriaLabel: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "aria-label attribute of header"
    },
    path: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The full path of view model, it also acts as an accessor for row's data"
    },
    onResolveValue: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Alternative way of calculating the value - returned value is displayed in a cell",
      defaultValue: {
        value: "({ row, path }) => path && get(row, path)",
        computed: !1
      }
    },
    onSaveValue: {
      type: {
        name: "func"
      },
      required: !1,
      description: "When editing, specify how a new value entered in the edit form is saved to the row.\nUseful when `onResolveValue` is defined, but `path` is not defined.",
      defaultValue: {
        value: "({ row, newValue, columnProps: { path, id }, }) => set(path ?? id, newValue, row)",
        computed: !1
      }
    },
    renderHeader: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to render the table header cell"
    },
    classNames: {
      type: {
        name: "shape",
        value: {
          cell: {
            name: "string",
            required: !1
          },
          cellContent: {
            name: "string",
            required: !1
          },
          header: {
            name: "string",
            required: !1
          }
        }
      },
      required: !1,
      description: "Custom classnames for header and body cells"
    },
    alignText: {
      type: {
        name: "enum",
        value: [{
          value: "'left'",
          computed: !1
        }, {
          value: "'right'",
          computed: !1
        }, {
          value: "'center'",
          computed: !1
        }]
      },
      required: !1,
      description: "Set the text align of the column",
      defaultValue: {
        value: "'left'",
        computed: !1
      }
    },
    sortable: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Flag indicating if the sorting capability is available for the column",
      defaultValue: {
        value: "true",
        computed: !1
      }
    },
    displayInEditForm: {
      type: {
        name: "enum",
        value: [{
          value: "'always'",
          computed: !1
        }, {
          value: "'ifVisible'",
          computed: !1
        }, {
          value: "'never'",
          computed: !1
        }]
      },
      required: !1,
      description: 'Determines if edit form includes a field for this column. Values "always" and "never" override `displayAllColumns` prop in DataViewEditing.\nPossible values:\n- "always" - field will be visible in the edit form, even when the column is hidden in data view.\n- "ifVisible" - field will be visible only if the column is visible in data view.\n- "never" - field will never be visible in the edit form.\n@default "ifVisible"',
      defaultValue: {
        value: "'ifVisible'",
        computed: !1
      }
    },
    onStyleHeaderCell: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback for a dynamic calculation of a custom class name for a cell in the header row"
    },
    onStyleCell: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback for a dynamic calculation of a custom class name for a cell within the table body"
    },
    onFocusHandling: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback for custom implementation of element focus during keyboard navigation. Applicable only for TableView."
    },
    renderCell: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to render the table body cell",
      defaultValue: {
        value: "({ value, columnProps: { classNames }, cellId, }) => {\n    if (typeof value === 'string' || typeof value === 'number') {\n        return (React.createElement(\"span\", { id: cellId, className: classNames?.cellContent }, value));\n    }\n    if (value !== undefined) {\n        warning(`Invalid value in a cell ${cellId}: ${JSON.stringify(value)}. You can use onResolveValue prop in the column to change it to string or number`);\n    }\n    return (React.createElement(\"span\", { id: cellId, className: classNames?.cellContent }, INVALID_VALUE_PLACEHOLDER));\n}",
        computed: !1
      }
    },
    renderEditCell: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to render edit cell during editing.",
      defaultValue: {
        value: "props => {\n    const { value } = props;\n    return (React.createElement(InputField, { ...getCommonEditProps(props), value: String(value), inputType: \"text\", \n        // @ts-ignore\n        noDeprecationWarnings: ['registerValidation'] }));\n}",
        computed: !1
      }
    },
    editConfig: {
      type: {
        name: "objectOf",
        value: {
          name: "any"
        }
      },
      required: !1,
      description: "Config for specifying additional props for fields rendered during editing"
    },
    columnDataType: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Type of data presented in the column. Helpful for handling type-specific operations, e.g., sorting.",
      defaultValue: {
        value: "'unknown'",
        computed: !1
      }
    },
    phone: {
      type: {
        name: "shape",
        value: {
          id: {
            name: "string",
            description: "Column identifier; must be unique within a table",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          id: {
            name: "string",
            description: "Column identifier; must be unique within a table",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          id: {
            name: "string",
            description: "Column identifier; must be unique within a table",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
};