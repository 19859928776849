import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
var _excluded = ["visible"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import React from "react";
import { Grid } from "@jutro/layout";
import { InlineNotification } from "@jutro/components";
import { useTranslator } from "@jutro/locale";
import { useColumnsHiddenByUser } from "./useColumnsHiddenByUser";
import { messages } from "./DefaultEditForm.messages";
var isColumnVisible = function (_ref) {
    return "never" !== _ref.displayInEditForm;
  },
  getMessagesMapByLabel = function (columns, validationMessages) {
    var _context;
    return _reduceInstanceProperty(_context = _filterInstanceProperty(columns).call(columns, function (column) {
      var _validationMessages, _column$path;
      return null == validationMessages || null === (_validationMessages = validationMessages[null !== (_column$path = column.path) && void 0 !== _column$path ? _column$path : column.id]) || void 0 === _validationMessages ? void 0 : _validationMessages.length;
    })).call(_context, function (acc, column) {
      var _column$editConfig,
        _column$path2,
        label = (null === (_column$editConfig = column.editConfig) || void 0 === _column$editConfig ? void 0 : _column$editConfig.label) || column.header;
      return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, label, null == validationMessages ? void 0 : validationMessages[null !== (_column$path2 = column.path) && void 0 !== _column$path2 ? _column$path2 : column.id]));
    }, {});
  };
export var DefaultEditForm = function (_ref2) {
  var _context2,
    _context3,
    editedRow = _ref2.editedRow,
    setEditedRow = _ref2.setEditedRow,
    displayAllColumns = _ref2.displayAllColumns,
    registerValidation = _ref2.registerValidation,
    resolveFormValidation = _ref2.resolveFormValidation,
    showErrors = _ref2.showErrors,
    validationMessages = _ref2.validationMessages,
    displayBundledMessages = _ref2.displayBundledMessages,
    columns = _ref2.columns,
    columnsConfigModalAvailable = _ref2.columnsConfigModalAvailable,
    translator = useTranslator(),
    _useColumnsHiddenByUs = useColumnsHiddenByUser({
      columns: columns,
      columnsConfigModalAvailable: columnsConfigModalAvailable,
      displayAllColumns: displayAllColumns
    }),
    filteredColumns = _useColumnsHiddenByUs.filteredColumns,
    notification = _useColumnsHiddenByUs.notification,
    notificationAriaLabel = _useColumnsHiddenByUs.notificationAriaLabel,
    validationLabelAndMessages = {
      error: getMessagesMapByLabel(columns, null == validationMessages ? void 0 : validationMessages.error),
      warning: getMessagesMapByLabel(columns, null == validationMessages ? void 0 : validationMessages.warning)
    },
    inputs = _mapInstanceProperty(_context2 = _filterInstanceProperty(filteredColumns).call(filteredColumns, isColumnVisible)).call(_context2, function (_ref3) {
      _ref3.visible;
      var columnProps = _objectWithoutProperties(_ref3, _excluded),
        onResolveValue = columnProps.onResolveValue,
        onSaveValue = columnProps.onSaveValue,
        path = columnProps.path,
        EditCell = columnProps.renderEditCell,
        value = null == onResolveValue ? void 0 : onResolveValue({
          row: editedRow,
          path: path,
          columnProps: columnProps
        });
      return React.createElement(React.Fragment, {
        key: columnProps.id
      }, EditCell && React.createElement(EditCell, {
        value: value,
        columnProps: columnProps,
        onValueChange: function (newValue) {
          return setEditedRow((null == onSaveValue ? void 0 : onSaveValue({
            newValue: newValue,
            row: editedRow,
            columnProps: columnProps
          })) || {});
        },
        registerValidation: registerValidation,
        errorMessage: null == resolveFormValidation ? void 0 : resolveFormValidation(null != path ? path : columnProps.id),
        showErrors: showErrors
      }));
    });
  return React.createElement("div", {
    role: "group",
    "aria-label": _concatInstanceProperty(_context3 = "".concat(notificationAriaLabel, " ")).call(_context3, translator(messages.editFormGroup))
  }, React.createElement(Grid, null, notification, showErrors && displayBundledMessages && React.createElement(InlineNotification, {
    id: "editing-notification",
    message: validationLabelAndMessages,
    isBundleOpenByDefault: !0
  }), inputs));
};
DefaultEditForm.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "DefaultEditForm"
}, DefaultEditForm.__docgenInfo = {
  componentName: "DefaultEditForm",
  packageName: "@jutro/lab-preview-dataview",
  description: "",
  displayName: "DefaultEditForm",
  methods: [],
  actualName: "DefaultEditForm"
};